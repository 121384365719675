import videoImg from "./videoImg.png";

export const offerExTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "RKB6vCIGhcA",
    info: "OfferEx - Tedarikçi Nasıl Eklenir ?",
  },
  {
    id: "vd-2",
    videoImage: videoImg,
    videoUrl: "Z6HPpGn8Z30",
    info: "OfferEx - Kullanıcı Ekleme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-3",
    videoImage: videoImg,
    videoUrl: "62NIBmBFh1o",
    info: "OfferEx - Firma Bilgilerini Güncelleme İşlemleri Nasıl Yapılır?",
  },
  {
    id: "vd-4",
    videoImage: videoImg,
    videoUrl: "Wyx5ctFQ2co",
    info: "OfferEx - Nasıl İhale Oluşturulur?",
  },
  {
    id: "vd-5",
    videoImage: videoImg,
    videoUrl: "o42ypoqouKs",
    info: "OfferEx - Yönetici Tarafınfan Revize Edilen Teklifi Düzenleme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-6",
    videoImage: videoImg,
    videoUrl: "l8cbQ3qwCNE",
    info: "OfferEx - Yönetici Tarafınfan Revize Edilen İhaleyi Düzenleme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-7",
    videoImage: videoImg,
    videoUrl: "zStbKrUNeEs",
    info: "OfferEx - Tedarikçi Davet Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-8",
    videoImage: videoImg,
    videoUrl: "Uce68W_89X8",
    info: "OfferEx - İhaleye Gelen Teklifi Onaylama - Kabul Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-9",
    videoImage: videoImg,
    videoUrl: "AE9hotNiWTE",
    info: "OfferEx - İhaleye Gelen Teklife Revize Talep Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-10",
    videoImage: videoImg,
    videoUrl: "B9lbHoCOYwI",
    info: "OfferEx - Teklifi Onaylama ve Yayına Alma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-11",
    videoImage: videoImg,
    videoUrl: "xGUuHU59KMc",
    info: "OfferEx - İhaleyi Onaylama ve Yayına Alma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-12",
    videoImage: videoImg,
    videoUrl: "yR_1Omy6jhM",
    info: "OfferEx - İhaleyi Silme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-13",
    videoImage: videoImg,
    videoUrl: "lg6aPfcEt8s",
    info: "OfferEx - Yöneticinin Operatörün Teklifini Revize Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-14",
    videoImage: videoImg,
    videoUrl: "s4W-wRlpvo8",
    info: "OfferEx - İhaleyi Revize Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-15",
    videoImage: videoImg,
    videoUrl: "oe4QIgVCWig",
    info: "OfferEx - İhaleye Tedarikçi Tarafından Yapılan Revize Talebini Reddetme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-16",
    videoImage: videoImg,
    videoUrl: "xltVPQT-6FQ",
    info: "OfferEx - Gelen İhale Fırsatına Tekif Oluşturma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-17",
    videoImage: videoImg,
    videoUrl: "IZzZmnEsM6U",
    info: "OfferEx - Tedarikçi Silme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-18",
    videoImage: videoImg,
    videoUrl: "DkXHBG-Yq4w",
    info: "OfferEx - Tedarikçiyi İhaleden Çıkartma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-19",
    videoImage: videoImg,
    videoUrl: "sDDaIlYfoig",
    info: "OfferEx - Gelen İhale Fırsatını Onaylama ve İhaleye Katılma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-20",
    videoImage: videoImg,
    videoUrl: "iQo7yPC_88E",
    info: "OfferEx - Gelen İhale Fırsatına Revize Talep İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-21",
    videoImage: videoImg,
    videoUrl: "TaCc0cqpEkw",
    info: "OfferEx - İhaleyi Değiştirme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-22",
    videoImage: videoImg,
    videoUrl: "hVKPhiSrhlw",
    info: "OfferEx - İhaleye Tedarikçi Tarafından Yapılan Revize Talebini Onaylama İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-23",
    videoImage: videoImg,
    videoUrl: "I3mAcstaRkc",
    info: "OfferEx - Kullanıcının Bilgilerini Güncelleme İşlemleri Nasıl Yapılır?",
  },
  {
    id: "vd-24",
    videoImage: videoImg,
    videoUrl: "yxKyyFqVjkQ",
    info: "OfferEx - Kullanıcının Rolünü Değiştirme İşlemi Nasıl Yapılır?",
  },
];

export const opportunitiesPageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "xltVPQT-6FQ",
    info: "OfferEx - Gelen İhale Fırsatına Tekif Oluşturma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-2",
    videoImage: videoImg,
    videoUrl: "sDDaIlYfoig",
    info: "OfferEx - Gelen İhale Fırsatını Onaylama ve İhaleye Katılma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-3",
    videoImage: videoImg,
    videoUrl: "iQo7yPC_88E",
    info: "OfferEx - Gelen İhale Fırsatına Revize Talep İşlemi Nasıl Yapılır?",
  },
];

export const offersPageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "lg6aPfcEt8s",
    info: "OfferEx - Yöneticinin Operatörün Teklifini Revize Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-2",
    videoImage: videoImg,
    videoUrl: "B9lbHoCOYwI",
    info: "OfferEx - Teklifi Onaylama ve Yayına Alma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-3",
    videoImage: videoImg,
    videoUrl: "o42ypoqouKs",
    info: "OfferEx - Yönetici Tarafınfan Revize Edilen Teklifi Düzenleme İşlemi Nasıl Yapılır?",
  },
];

export const tendersPageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "oe4QIgVCWig",
    info: "OfferEx - İhaleye Tedarikçi Tarafından Yapılan Revize Talebini Reddetme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-2",
    videoImage: videoImg,
    videoUrl: "s4W-wRlpvo8",
    info: "OfferEx - İhaleyi Revize Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-3",
    videoImage: videoImg,
    videoUrl: "yR_1Omy6jhM",
    info: "OfferEx - İhaleyi Silme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-4",
    videoImage: videoImg,
    videoUrl: "xGUuHU59KMc",
    info: "OfferEx - İhaleyi Onaylama ve Yayına Alma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-5",
    videoImage: videoImg,
    videoUrl: "AE9hotNiWTE",
    info: "OfferEx - İhaleye Gelen Teklife Revize Talep Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-6",
    videoImage: videoImg,
    videoUrl: "Uce68W_89X8",
    info: "OfferEx - İhaleye Gelen Teklifi Onaylama - Kabul Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-7",
    videoImage: videoImg,
    videoUrl: "l8cbQ3qwCNE",
    info: "OfferEx - Yönetici Tarafınfan Revize Edilen İhaleyi Düzenleme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-8",
    videoImage: videoImg,
    videoUrl: "Wyx5ctFQ2co",
    info: "OfferEx - Nasıl İhale Oluşturulur?",
  },
  {
    id: "vd-9",
    videoImage: videoImg,
    videoUrl: "DkXHBG-Yq4w",
    info: "OfferEx - Tedarikçiyi İhaleden Çıkartma İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-21",
    videoImage: videoImg,
    videoUrl: "TaCc0cqpEkw",
    info: "OfferEx - İhaleyi Değiştirme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-22",
    videoImage: videoImg,
    videoUrl: "hVKPhiSrhlw",
    info: "OfferEx - İhaleye Tedarikçi Tarafından Yapılan Revize Talebini Onaylama İşlemi Nasıl Yapılır?",
  },
];

export const suppliersPageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "RKB6vCIGhcA",
    info: "OfferEx - Tedarikçi Nasıl Eklenir ?",
  },
  {
    id: "vd-2",
    videoImage: videoImg,
    videoUrl: "zStbKrUNeEs",
    info: "OfferEx - Tedarikçi Davet Etme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-3",
    videoImage: videoImg,
    videoUrl: "IZzZmnEsM6U",
    info: "OfferEx - Tedarikçi Silme İşlemi Nasıl Yapılır?",
  },
];

export const usersPageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "Z6HPpGn8Z30",
    info: "OfferEx - Kullanıcı Ekleme İşlemi Nasıl Yapılır?",
  },
  {
    id: "vd-23",
    videoImage: videoImg,
    videoUrl: "I3mAcstaRkc",
    info: "OfferEx - Kullanıcının Bilgilerini Güncelleme İşlemleri Nasıl Yapılır?",
  },
  {
    id: "vd-24",
    videoImage: videoImg,
    videoUrl: "yxKyyFqVjkQ",
    info: "OfferEx - Kullanıcının Rolünü Değiştirme İşlemi Nasıl Yapılır?",
  },
];

export const companyProfilePageTutorials = [
  {
    id: "vd-1",
    videoImage: videoImg,
    videoUrl: "62NIBmBFh1o",
    info: "OfferEx - Firma Bilgilerini Güncelleme İşlemleri Nasıl Yapılır?",
  },
];
