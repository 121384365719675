import "./uploadImageBox.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}

function UploadImageBox({t, images, setImages, formData, setFormData }) {
  const { token } = AuthContextValue();
  const { deleteMethod } = CrudContextValue();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (images && images.length > 0) {
      setCurrentImageIndex(images.length - 1);
    }
  }, [images]);

  function handleDrop(files) {
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const base64Data = e.target.result;

        if (
          images.some((img) => img.base64 === base64Data) ||
          formData.images.includes(base64Data)
        ) {
          alert(t("message_emty_form"));
        } else {
          const newImage = {
            id: generateId(),
            url: null,
            base64: base64Data,
          };

          setImages([...images, newImage]);

          setFormData({
            ...formData,
            images: [...formData.images, base64Data.split("base64,")[1]],
          });
        }
      };
    }
  }

  async function handleDelete(image) {
    const newImages = images.filter((img) => img.id !== image.id);

    if (currentImageIndex !== 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
    if (image.url && image.url.includes("/api/Images")) {
      setImages(newImages);

      const imagePath = image.url.split("images/")[1];

      await deleteMethod(
        `${process.env.REACT_APP_DELETE_IMAGE_URL}?file=${imagePath}`,
        token
      );
    } else {
      const newTenderImage = formData.images.filter(
        (prevImage) => prevImage !== image.base64.split("base64,")[1]
      );

      setImages(newImages);
      setFormData({ ...formData, images: newTenderImage });
    }
  }

  function handleNext() {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length ? 0 : prevIndex + 1
      );
    } else {
      setCurrentImageIndex(0);
    }
  }

  return (
    <>
      {images && images.length > 0 ? (
        <div className="upload-image-container">
          <img
            src={
              images[currentImageIndex].url
                ? images[currentImageIndex].url
                : images[currentImageIndex].base64
            }
            alt="foto"
          />

          <button
            className="delete-img-button"
            onClick={() => handleDelete(images[currentImageIndex])}
          >
            {t("button_edit_delete")}
          </button>

          <button className="next-img-button" onClick={handleNext}>
            {" "}
            {t("button_edit_next")}{" "}
          </button>

          <button
            className="new-img-button"
            onClick={() => document.getElementById("fileInput").click()}
          >
            +
          </button>
          <input
            style={{ display: "none" }}
            type="file"
            id="fileInput"
            onChange={(e) => handleDrop(e.target.files)}
            multiple
          />
        </div>
      ) : (
        <div className="upload-img-btn">
          <button onClick={() => document.getElementById("fileInput").click()}>
          {t("button_edit_upload")}
          </button>
          <input
            style={{ display: "none" }}
            type="file"
            id="fileInput"
            onChange={(e) => handleDrop(e.target.files)}
            multiple
          />
        </div>
      )}
    </>
  );
}

export default React.memo(withNamespaces()(UploadImageBox));
