import "./mediumTable.css";
import React from "react";
import { withNamespaces } from "react-i18next";

function MediumTable({
  t,

  type,

  selectValueId,
  selectValue,
  handleClickValue,

  columns,
  data,
}) {
  function formatDuration(duration) {
    if (duration < 0) {
      return t("table_edit_finish");
    }

    const days = Math.floor(duration / (24 * 60 * 60 * 1000));
    duration -= days * 24 * 60 * 60 * 1000;

    const hours = Math.floor(duration / (60 * 60 * 1000));
    duration -= hours * 60 * 60 * 1000;

    const minutes = Math.floor(duration / (60 * 1000));
    duration -= minutes * 60 * 1000;

    if (days === 0) {
      if (hours === 0) {
        return `${minutes} ${t("table_edit_minutes")}`;
      } else {
        return `${hours} ${t("table_edit_hours")}`;
      }
    }

    return `${days} ${t("table_edit_day")}`;
  }

  function statusText(statusParam) {
    return (
      <code
        style={{
          color:
            statusParam === "3" || statusParam === "4"
              ? "red"
              : statusParam === "1"
              ? "orange"
              : statusParam === "2"
              ? "green"
              : "",
        }}
      >
        {statusParam === "2" ? (
          <i className="fa-solid fa-check" />
        ) : statusParam === "3" ? (
          <i className="fa-solid fa-arrow-rotate-left" />
        ) : statusParam === "1" ? (
          <i className="fa-solid fa-clock" />
        ) : statusParam === "4" ? (
          <i className="fa-solid fa-circle-exclamation"></i>
        ) : null}{" "}
        {statusParam === "3"
          ? t("table_edit_revised_request")
          : statusParam === "1"
          ? t("table_edit_waiting")
          : statusParam === "2"
          ? t("table_edit_confirmed")
          : statusParam === "4"
          ? t("table_edit_reject")
          : ""}
      </code>
    );
  }

  return (
    <div className="medium-table-box">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              {columns.map((column, i) => {
                return (
                  <th key={i} title={column.header}>
                    {column.header}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              // verilen type değerine göre tabloya id ataması
              const id =
                type === "opportunities" || type === "tender"
                  ? row.tenderId
                  : type === "offer" || type === "waiting"
                  ? row.offerId
                  : row.id;

              const productName =
                row && row.tProductName
                  ? row.tProductName
                  : row.productName
                  ? row.productName
                  : "-";

              const remainingTime =
                row && new Date(row.tExpireDate) - Date.now();

              const opportunitiesRemainingTime =
                row.expireDate &&
                new Date(new Date(row.expireDate) - Date.now());

              const passedDayOffer =
                type === "opportunities" || type === "tender"
                  ? formatDuration(Date.now() - new Date(row.createdAt))
                  : type === "offer" || type === "waiting"
                  ? formatDuration(Date.now() - new Date(row.offerCreatedAt))
                  : "null";

              return (
                <tr
                  key={id}
                  onClick={() => handleClickValue(id, row)}
                  className={id === selectValueId ? "table-row-select" : ""}
                >
                  {columns.map((column, i) => {
                    return (
                      <td
                        key={i}
                        className={
                          id === selectValueId ? "table-row-select-text" : ""
                        }
                        title={row[column.key]}
                      >
                        {column.key === "productName" ? (
                          productName
                        ) : column.key === "remainingTime" ? (
                          formatDuration(
                            type === "opportunities" || type === "tender"
                              ? opportunitiesRemainingTime
                              : remainingTime
                          )
                        ) : column.key === "result" ||
                          column.key === "status" ? (
                          statusText(row[column.key])
                        ) : column.key === "passedDay" ? (
                          passedDayOffer
                        ) : column.key === "minPrice" ||
                          column.key === "maxPrice" ||
                          column.key === "totalPrice" ||
                          column.key === "total" ? (
                          <code>
                            {row["currency"] && row["currency"] !== "string"
                              ? Number(row[column.key]).toLocaleString(
                                  row["currency"],
                                  {
                                    style: "currency",
                                    currency: row["currency"],
                                  }
                                )
                              : Number(row[column.key]).toLocaleString()}
                          </code>
                        ) : (
                          row[column.key]
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withNamespaces()(MediumTable);
