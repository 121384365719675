import "./styles/profileEdit/profileEdit.css";

import useFetch from "../../hooks/UseFetch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";


import UserEditCard from "../../components/UserEditCard/UserEditCard";
import UserTenders from "../Users/UserTenders";
import UserOffers from "../Users/UserOffers";
import { AuthContextValue } from "../../context/AuthContext";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Loading from "../../components/Loading/Loading";

function ProfileEdit({t}) {
  const { userId } = useParams();
  const { token } = AuthContextValue();

  const [pageLink, setPageLink] = useState("tenders");

  const {
    useFetchRender: userDetailUseFetchRender,
    setUseFetchRender: setUserDetailUseFetchRender,
    error: userDetailError,
    loading: userDetailLoading,
    data: userDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?UserId=${userId}&Page=1&PageSize=10`,
    token
  );

  useEffect(() => {
    setUserDetailUseFetchRender(!userDetailUseFetchRender);
    console.log("userDetail");
  }, [userId]);

  function hanldeClickLink(link) {
    setPageLink(link);
  }

  return (
    <div className="profile-edit-container">
      <div className="edit-box">
        {userDetailError ? (
          <ErrorComponent error={userDetailError} />
        ) : userDetailLoading ? (
          <Loading />
        ) : userDetail !== null ? (
          <UserEditCard
            setUserUseFetchRender={setUserDetailUseFetchRender}
            userUseFetchRender={userDetailUseFetchRender}
            userError={userDetailError}
            userLoading={userDetailLoading}
            user={userDetail[0]}
          />
        ) : (
          <div>NULL</div>
        )}
      </div>
      <div className="right-container">
        <div className="page-link">
          <button
            onClick={() => hanldeClickLink("tenders")}
            className={
              pageLink === "tenders" ? "link-active" : "link-not-active"
            }
          >
           {t("button_tenders")}
          </button>
          <button
            onClick={() => hanldeClickLink("offers")}
            className={
              pageLink === "offers" ? "link-active" : "link-not-active"
            }
          >
            {t("button_offers")}
          </button>
        </div>

        <div className="table">
          {pageLink === "tenders" ? (
            <UserTenders id={userId} />
          ) : pageLink === "offers" ? (
            <UserOffers id={userId} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(ProfileEdit);
