import "./styles/userProfile/userProfile.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import useFetch from "../../hooks/UseFetch";
import { AuthContextValue } from "../../context/AuthContext";
import Loading from "../../components/Loading/Loading";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";
import UserDetailCard from "../../components/UserDetailCard/UserDetailCard";
import UserTenders from "./UserTenders";
import UserOffers from "./UserOffers";
import UserProfileEditBox from "../../components/EditBox/UserProfileEditBox";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";

function UserProfile({ t }) {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { token, loginUserId, userRole } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    companyDetail,
    companyUsersIds,
    notificationRender,
  } = GetContextValue();
  const { deleteMethod, createMethod } = CrudContextValue();

  const [resetPassLoading, setResetPassLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const [tableRender, setTableRender] = useState(false);

  const [pageLink, setPageLink] = useState(
    companyDetail.companyType === 1 || companyDetail.companyType === 3
      ? "tenders"
      : "offers"
  );

  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const handleOpenResetPasswordModal = () => setOpenResetPasswordModal(true);
  const handleCloseResetPasswordModal = () => setOpenResetPasswordModal(false);

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const handleOpenDeleteUserModal = () => setOpenDeleteUserModal(true);
  const handleCloseDeleteUserModal = () => setOpenDeleteUserModal(false);

  const {
    useFetchRender: userDetailUseFetchRender,
    setUseFetchRender: setUserDetailUseFetchRender,
    error: userDetailError,
    loading: userDetailLoading,
    data: userDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANY_USERS_URL}?UserId=${userId}&Page=1&PageSize=10`,
    token
  );

  useEffect(() => {
    setUserDetailUseFetchRender(!userDetailUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    setUserDetailUseFetchRender(!userDetailUseFetchRender);
  }, [userId]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  function hanldeClickLink(link) {
    setPageLink(link);
  }

  async function handleDeleteUser() {
    console.log(userDetail[0].email, " emailli kullanıcı silindi!");
    setDeleteUserLoading(true);

    const responseDeleteUser = await deleteMethod(
      `https://sosocrm.oetech.online/api/User/User-Delete-Test?email=${userDetail[0].email}`,
      token
    );

    setDeleteUserLoading(false);

    handleCloseDeleteUserModal();

    if (
      responseDeleteUser &&
      !responseDeleteUser.cathError &&
      !responseDeleteUser.isError
    ) {
      navigate("/users");
    }
  }

  async function handleResetPassword() {
    setResetPassLoading(true);

    const responsePost = await createMethod(
      `${process.env.REACT_APP_POST_FORGOT_PASSWORD_URL}?mail=${userDetail[0].email}`,
      null,
      null
    );

    console.log(responsePost);

    setResetPassLoading(false);

    handleCloseResetPasswordModal();
  }

  if (
    userRole !== "Admin" &&
    loginUserId !== userId &&
    (userRole !== "Manager" || !companyUsersIds.includes(userId))
  ) {
    return (
      <div className="table-null-text">
        {t("message_error_not_user_authority")}
      </div>
    );
  }

  return (
    <div className="users-detail-container">
      <div className="users-detail-container-top">
        {deleteUserLoading || resetPassLoading ? (
          <LoadingCircular />
        ) : openResetPasswordModal ? (
          <ConfirmModal
            text={`${userDetail && userDetail[0].firstName} ${t(
              "message_confirm_reset_user_password"
            )}`}
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_form_reset_password")}
            handleCloseModal={handleCloseResetPasswordModal}
            confirmFunc={handleResetPassword}
          />
        ) : openDeleteUserModal ? (
          <ConfirmModal
            text={`${
              userDetail && userDetail[0].firstName
            } ${t("message_confirm_delete_user")}`}
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_delete")}
            handleCloseModal={handleCloseDeleteUserModal}
            confirmFunc={handleDeleteUser}
          />
        ) : (
          <UserProfileEditBox
            type={"user-profile"}
            title={t("button_edit_user_profile")}
            handleClickValue={handleClickValue}
            selectValueId={userId}
            selectValue={userDetail ? userDetail[0] : null}
            useFetchRender={userDetailUseFetchRender}
            setUseFetchRender={setUserDetailUseFetchRender}
            handleOpenResetPasswordModal={handleOpenResetPasswordModal}
            handleOpenDeleteUserModal={handleOpenDeleteUserModal}
          />
        )}
      </div>

      <div className="users-detail-container-main">
        <div className="left-container">
          {userDetailError ? (
            <ErrorComponent error={userDetailError} />
          ) : userDetailLoading ? (
            <Loading />
          ) : (
            userDetail !== null && (
              <UserDetailCard
                userDetail={userDetail[0]}
                useFetchRender={userDetailUseFetchRender}
                setUseFetchRender={setUserDetailUseFetchRender}
              />
            )
          )}
        </div>
        <div className="right-container">
          <div className="right-container-top">
            <div className="right-container-page-link">
              {(companyDetail.companyType === 1 ||
                companyDetail.companyType === 3) && (
                <button
                  onClick={() => hanldeClickLink("tenders")}
                  className={
                    pageLink === "tenders" ? "link-active" : "link-not-active"
                  }
                >
                  {t("button_tenders")}
                </button>
              )}
              {(companyDetail.companyType === 2 ||
                companyDetail.companyType === 3 ||
                userRole === "Admin") && (
                <button
                  onClick={() => hanldeClickLink("offers")}
                  className={
                    pageLink === "offers" ? "link-active" : "link-not-active"
                  }
                >
                  {t("button_offers")}
                </button>
              )}
            </div>
            <div className="right-container-edit-buttons">
              <WhiteButton
                icon={<i className="fa-solid fa-arrow-rotate-right" />}
                tooltip={t("button_edit_render")}
                onClick={() => setTableRender(!tableRender)}
              />
              <Link
                to={
                  (selectValueId || selectValue) && pageLink === "tenders"
                    ? `/tenders/tender-detail/${selectValueId}`
                    : pageLink === "offers"
                    ? `/offers/offer-detail/${selectValueId}`
                    : false
                }
              >
                <WhiteButton
                  icon={<i className="fa-solid fa-info"></i>}
                  tooltip={t("button_edit_detail")}
                  disabled={selectValueId || selectValue ? false : true}
                />
              </Link>
            </div>
          </div>

          <div className="right-container-table">
            {pageLink === "tenders" ? (
              <UserTenders
                id={userId}
                setTableRender={setTableRender}
                tableRender={tableRender}
              />
            ) : pageLink === "offers" ? (
              <UserOffers
                id={userId}
                setTableRender={setTableRender}
                tableRender={tableRender}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(UserProfile);
