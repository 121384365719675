import React from "react";
import { withNamespaces } from "react-i18next";

import { Button, Result } from "antd";

function Page500({t}) {
  return (
    <Result
      status="500"
      title="500"
      subTitle={t("message_error_500")}
      extra={
        <Button type="primary" href="https://www.offerex.in/">
          {t("sidebar_homepage")}
        </Button>
      }
    />
  );
}

export default withNamespaces()(Page500);
