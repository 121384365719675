
function Reports() {
  return (
    <div className="table-null-text">
      Reports
    </div>
  )
}

export default Reports;
