function StarTicIcon() {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.2619 20L5.45237 16.9524L2.0238 16.1905L2.35714 12.6667L0.0238037 10L2.35714 7.33333L2.0238 3.80952L5.45237 3.04762L7.2619 0L10.5 1.38095L13.7381 0L15.5476 3.04762L18.9762 3.80952L18.6429 7.33333L20.9762 10L18.6429 12.6667L18.9762 16.1905L15.5476 16.9524L13.7381 20L10.5 18.619L7.2619 20ZM9.49999 13.381L14.8809 8L13.5476 6.61905L9.49999 10.6667L7.45238 8.66667L6.11904 10L9.49999 13.381Z"

            />
        </svg>
    )
}

export default StarTicIcon;
