import "./bidOfferCardJustPrice.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import InputPrice from "../form_components/Inputs/InputPrice/InputPrice";
import { Switch } from "antd";

function BidOfferCardJustPrice({
  t,
  offerFormData,
  setOfferFormData,
  offerFormDataErrors,
  currency,
  setCurrency,
  currencySymbol,
  setCurrencySymbol,
  tenderAmount,
}) {
  const [showInput, setShowInput] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    setShowInput(checked);
  };

  function handleChange(e) {
    const { name, value } = e.target;

    if (
      name === "laborPrice" ||
      name === "materialPrice" ||
      name === "shippingPrice"
    ) {
      const formattedVal = value.replace(/[^\d]/g, ""); // Sadece sayı karakterlerini al
      const numVal = Number(formattedVal);

      setOfferFormData({
        ...offerFormData,
        [name]: Number(numVal).toLocaleString(),
      });
    } else {
      setOfferFormData({
        ...offerFormData,
        [name]: value,
      });
    }
  }

  // const handleCurrencyChange = (e) => {
  //   const { value } = e.target;

  //   setCurrency(value);

  //   if (value === "TRY") {
  //     setCurrencySymbol("₺");
  //   } else if (value === "USD") {
  //     setCurrencySymbol("$");
  //   } else if (value === "EUR") {
  //     setCurrencySymbol("€");
  //   }
  // };

  useEffect(() => {
    if (
      tenderAmount &&
      offerFormData.laborPrice &&
      offerFormData.materialPrice &&
      offerFormData.shippingPrice
    ) {
      const unit = parseInt(
        parseInt(offerFormData.laborPrice.replace(".", "")) +
          parseInt(offerFormData.materialPrice.replace(".", ""))
      ).toLocaleString(offerFormData.currency);

      
      const total = (
        parseInt(
          (parseInt(offerFormData.laborPrice.replace(".", "")) +
            parseInt(offerFormData.materialPrice.replace(".", ""))) *
            parseInt(tenderAmount)
        ) + parseInt( showInput ? offerFormData.shippingPrice.replace(".", "") : 0)
      ).toLocaleString(offerFormData.currency);


      setTotalPrice(total);
      setUnitPrice(unit);
    }
  }, [
    tenderAmount,
    offerFormData.laborPrice,
    offerFormData.materialPrice,
    offerFormData.shippingPrice,
    showInput
  ]);

  useEffect(() => {
    setOfferFormData({
      ...offerFormData,
      unitPrice: unitPrice,
      totalPrice: totalPrice,
    });
  }, [unitPrice, totalPrice]);

  return (
    <div className="tender-detail-offer-form">
      {/* <div className="input-standart">
        <label>{t("select_currency")}: </label>
        <select
          style={{
            border: offerFormDataErrors.currencyError ? "2px solid red" : "",
          }}
          required
          value={currency}
          onChange={handleCurrencyChange}
        >
          <option value="TRY">TRY</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select>
      </div> */}

      <InputPrice
        required={true}
        errorBorder={offerFormDataErrors.laborPriceError}
        type={"text"}
        name={"laborPrice"}
        title={t("table_labor_price")}
        placeholder={`${t("table_labor_price")}...`}
        currencySymbol={currencySymbol}
        value={offerFormData.laborPrice}
        onChange={handleChange}
      />

      <InputPrice
        required={true}
        errorBorder={offerFormDataErrors.materialPriceError}
        type={"text"}
        name={"materialPrice"}
        title={t("table_materiel_price")}
        placeholder={`${t("table_materiel_price")}...`}
        currencySymbol={currencySymbol}
        value={offerFormData.materialPrice}
        onChange={handleChange}
      />

      <InputPrice
        type={"text"}
        title={t("table_unit_price")}
        placeholder={`${t("table_unit_price")}...`}
        currencySymbol={currencySymbol}
        value={unitPrice}
        onChange={handleChange}
      />

      <div className="input-standart">
        <label htmlFor="toggle">{t("table_unit_shipping_price")} :</label>
        <div className="toggle-buttons">
          <Switch checked={showInput} onChange={onChange} />
        </div>
      </div>

      {showInput && (
        <InputPrice
          required={true}
          errorBorder={offerFormDataErrors.shippingPrice}
          type={"text"}
          name={"shippingPrice"}
          title={t("table_unit_shipping_price")}
          placeholder={`${t("table_unit_shipping_price")}...`}
          currencySymbol={currencySymbol}
          value={offerFormData.shippingPrice}
          onChange={handleChange}
        />
      )}

      <InputPrice
        type={"text"}
        title={t("table_total_price")}
        placeholder={`${t("table_total_price")}...`}
        currencySymbol={currencySymbol}
        value={totalPrice}
        onChange={handleChange}
      />
    </div>
  );
}

export default withNamespaces()(BidOfferCardJustPrice);
