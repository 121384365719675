import "../StandartTable/standartTable.css";
import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import Pagination from "../Pagination/Pagination";
import images from "../../../assets/images";
import { getFetch } from "../../../hooks/getFetch";
import { AuthContextValue } from "../../../context/AuthContext";

function OpportunitiesOffersTable({
  t,

  type,

  selectValueId,
  selectValue,
  handleClickValue,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  data,
}) {
  const { token } = AuthContextValue();

  const [dataWithTenderDetails, setDataWithTenderDetails] = useState([]);

  function formatDuration(duration) {
    if (duration <= 0) {
      return t("table_edit_finish");
    }

    const days = Math.floor(duration / (24 * 60 * 60 * 1000));
    duration -= days * 24 * 60 * 60 * 1000;

    const hours = Math.floor(duration / (60 * 60 * 1000));
    duration -= hours * 60 * 60 * 1000;

    const minutes = Math.floor(duration / (60 * 1000));
    duration -= minutes * 60 * 1000;

    const seconds = Math.floor(duration / 1000);

    return `${days} ${t("table_edit_day")}, ${hours} ${t("table_edit_hours")}, ${minutes} ${t("table_edit_minutes")}, ${seconds} ${t("table_edit_second")}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const newData = await Promise.all(
        data.map(async (row) => {
          const tenderId =
            type === "offer"
              ? row.offerId
              : type === "tender"
              ? row.tenderId
              : null;
          if (tenderId) {
            try {
              const tenderDetail = await getFetch(
                `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${row.tenderId}`,
                token
              );
              return { ...row, ...tenderDetail.data.data };
            } catch (error) {
              console.error(
                `Error fetching tender detail for tenderId ${tenderId}`,
                error
              );
              return row;
            }
          }
          return row;
        })
      );

      setDataWithTenderDetails(newData);
    };

    fetchData();
    console.log(data);
  }, [data, type, token]);

  return (
    <div className="standart-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              <th title= {t("table_photo")}>{t("table_photo")} </th>
              <th title= {t("table_status")}>{t("table_status")} </th>
              <th title={t("table_offer_confirm_user")}>{t("table_offer_confirm_user")} </th>
              <th title= {t("table_issuing")}>{t("table_issuing")} </th>
              <th title= {"Kategori"}>{t("table_category")} </th>
              <th title={t("table_category")}>{t("table_product")} </th>
              <th title= {t("table_create_date")}>{t("table_create_date")} </th>
              <th title={t("table_remaining_time")}>{t("table_remaining_time")} </th>
              <th title= {t("table_total_price")}>{t("table_total_price")} </th>
            </tr>
          </thead>

          <tbody>
            {dataWithTenderDetails.map((row) => {
              const id =
                type === "offer"
                  ? row.offerId
                  : type === "tender"
                  ? row.tenderId
                  : null;

              return (
                <tr
                  key={id}
                  onClick={() => handleClickValue(id, row)}
                  style={{
                    height: "50px",
                  }}
                  className={id === selectValueId ? "table-row-select" : ""}
                >
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_photo")}
                  >
                    {row.images && row.images.length > 0 ? (
                      <div className="image-box">
                        <img
                          src={
                            row.images.filter((img) => !img.includes("pdf"))[0]
                          }
                          alt="offer-img"
                        />
                      </div>
                    ) : (
                      <div className="image-box">
                        <img src={images.noImage} alt="offer-img" />
                      </div>
                    )}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    style={{
                      color:
                        row.result === "1"
                          ? "orange"
                          : row.result === "3" || row.result === "5"
                          ? "red"
                          : row.result === "2"
                          ? "green"
                          : "",
                    }}
                    title={t("table_status")}
                  >
                    {row.result === "2" ? (
                      <i className="fa-solid fa-check" />
                    ) : row.result === "3" ? (
                      <i className="fa-solid fa-arrow-rotate-left" />
                    ) : row.result === "1" ? (
                      <i className="fa-solid fa-clock" />
                    ) : row.result === "5" ? (
                      <i className="fa-solid fa-circle-exclamation"></i>
                    ) : null}{" "}
                    {row.result === "2"
                      ? t("table_edit_confirmed")
                      : row.result === "3"
                      ? t("table_edit_revised_request")
                      : row.result === "1"
                      ? t("table_edit_waiting")
                      : row.result === "5"
                      ? t("table_edit_not_win")
                      : null}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_offer_confirm_user")}
                  >
                    {row.offerConfirmUser}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_issuing")}
                  >
                    {row.tCompanyName}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_category")}
                  >
                    {row.tenderCategories
                      ?.map((category) => category.categoryName)
                      .join(", ")}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_product")}
                  >
                    {row.tProductName}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_create_date")}
                  >
                    {new Date(row.tApproveDate).toLocaleString()}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_remaining_time")}
                  >
                    {formatDuration(
                      new Date(row.tExpireDate) - new Date()
                    )}{" "}
                  </td>
                  <td
                    className={
                      id === selectValueId ? "table-row-select-text" : ""
                    }
                    title={t("table_total_price")}
                  >
                    {row.currency && row.currency !== "string"
                      ? Number(row.totalPrice).toLocaleString(row.currency, {
                          style: "currency",
                          currency: row.currency,
                        })
                      : Number(row.totalPrice).toLocaleString()}{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default withNamespaces()(OpportunitiesOffersTable);