import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import TenderForm from "../../../components/TenderForm/TenderForm";

function TenderChangeStepOne({
  t,
  onNext,
  formData,
  step,
  images,
  setImages,
  documents,
  setDocuments,
  selectedCategories,
  setSelectedCategories,
  properties,
  setProperties,
}) {
  const navigate = useNavigate();

  const [stepOneForm, setStepOneForm] = useState({
    description: formData.description ? formData.description : "",
    productName: formData.productName ? formData.productName : "",
    currency: formData.currency ? formData.currency : "TRY",
    amount: formData.amount ? formData.amount : 0,
    maxPrice: formData.maxPrice ? formData.maxPrice : 0,
    minPrice: formData.minPrice ? formData.minPrice : 0,
    expireDate: formData.expireDate ? formData.expireDate : "",
    deadline: formData.deadline ? formData.deadline : "",
    categoryId: formData.categoryId ? formData.categoryId : [0],
    unitType: formData.unitType ? formData.unitType : 1,
    customer: formData.customer ? formData.customer : "string",
    images: [],
    note: formData.note ? formData.note : "",
    properties: formData.properties ? formData.properties : [],
    recursive: formData.recursive === true ? true : false,
    details: formData.details ? formData.details : {},
  });

  const handleNext = () => {
    const offerData = {
      ...stepOneForm,
      amount: parseInt(stepOneForm.amount),
      unitType: Number(stepOneForm.unitType),
    };

    onNext(offerData);
  };

  return (
    <div className="step-one-container">
      <div className="step-one-top">
        <div className="title-box">
          <WhiteButton
            icon={<i className="fa-solid fa-chevron-left"></i>}
            text={t("button_edit_back")}
            onClick={() => navigate(-1)}
          />
          <div className="title">
            <i className="fa-solid fa-plus"></i>
            <h4>
              {t("edit_tender_title")} ({formData.tenderNumber})
            </h4>
          </div>
        </div>

        <WhiteButton
          className={`${step !== 1 ? "display-none" : ""}`}
          text={<i className="fa-solid fa-chevron-right"></i>}
          icon={t("button_edit_next")}
          onClick={handleNext}
          disabled={step !== 1}
        />
      </div>
      <TenderForm
        setStepOneForm={setStepOneForm}
        stepOneForm={stepOneForm}
        documents={documents}
        setDocuments={setDocuments}
        images={images}
        setImages={setImages}
        stepOneFormErrors={{}}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        properties={properties}
        setProperties={setProperties}
      />
    </div>
  );
}

export default withNamespaces()(TenderChangeStepOne);
