import "./firstTimeOfferexModal.css";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";

import { Modal, Space } from "antd";
import images from "../../../assets/images";

function FirstTimeOfferexModal({ t }) {
  useEffect(() => {
    const isFirstTimeOfferex = localStorage.getItem("isFirstTimeOfferex");

    if (isFirstTimeOfferex) {
      showTutorialModal();
      localStorage.removeItem("isFirstTimeOfferex");
    }
  }, []);

  const showTutorialModal = () => {
    Modal.info({
      width: "95%",
      title: "Bilgilendirme",
      content: (
        <div className="first-time-offerex-modal-container">
          <h1 className="title">{t("first_time_offerex_modal_title")}</h1>
          <p>{t("first_time_offerex_modal_text1")}</p>
          <p>{t("first_time_offerex_modal_text2")}</p>
          <p>{t("first_time_offerex_modal_text3")}</p>
          <img src={images.tableSelectGif} alt="table_select" />
          <p>{t("first_time_offerex_modal_text4")}</p>
          <p>{t("first_time_offerex_modal_text5")}</p>
        </div>
      ),
      okText: "Tamam",
      onOk() {},
      bodyStyle: {
        overflow: "hidden",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 24,
        paddingRight: 24,
      },
    });
  };

  return <Space></Space>;
}

export default withNamespaces()(FirstTimeOfferexModal);
