import './detailText.css';

function DetailText({ title, text }) {

    return (
        <div className='detail-text-container'>
            <p className='detail-text-title'>{title} :</p>
            <p className='detail-text'>{text}</p>
        </div>
    )

}

export default DetailText
