import "./exChangeRateCard.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { GetContextValue } from "../../../context/GetContext";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";
import i18next from "i18next";

function ExChangeRateCard({ t }) {
  const { exChangeRate, exChangeRateLoading, exChangeRateError } =
    GetContextValue();

  const [filteredMoney, setFilteredMoney] = useState([]);

  useEffect(() => {
    if (!exChangeRateError && exChangeRate && exChangeRate.length > 0) {
      const filterData = exChangeRate.map((mny) => {
        if (mny.kodu === "USD") {
          mny.adi = t("table_currency_usd");
        } else if (mny.kodu === "EUR") {
          mny.adi = t("table_currency_eur");
        } else if (mny.kodu === "GBP") {
          mny.adi = t("table_currency_gbp");
        } else if (mny.kodu === "CNY") {
          mny.adi = t("table_currency_cny");
        }
        return mny;
      });

      setFilteredMoney(filterData);
    }
    
  }, [exChangeRate,i18next.language]);

  return (
    <div className="exchange-card-container">
      <div className="exchange-card-main">
        {exChangeRateError ? (
          <ErrorComponent error={exChangeRateError} />
        ) : exChangeRateLoading ? (
          <Loading />
        ) : exChangeRate !== null && exChangeRate.length > 0 ? (
          <>
            <table className="money-table">
              <thead>
                <tr>
                  <th title={t("table_currency")} >{t("table_currency") }</th>
                  <th title={t("table_currency_code")} >{t("table_currency_code") }</th>
                  <th title={t("table_currency_buying")} >{t("table_currency_buying") }</th>
                  <th title={t("table_currency_selling")} >{t("table_currency_selling") }</th>
                </tr>
              </thead>
              <tbody>
                {filteredMoney.map((money, index) => {
                  return (
                    <tr key={index}>
                      <td title={money.adi}>{money.adi}</td>
                      <td title={money.kodu}>{money.kodu}</td>
                      <td title={money.alisKuru}>{money.alisKuru}</td>
                      <td title={money.satisKuru}>{money.satisKuru}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(withNamespaces()(ExChangeRateCard));
