import './buttonWhiteOpacity.css';


function ButtonWhiteOpacity({
    icon,
    text,
    onClick,
    disabled,
    type,
    className
}) {

    return (
        <button
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`button-white-login-style ${className}`}
            
        >
            {icon}

            <span>
                {text}
            </span>
        </button>
    )
}

export default ButtonWhiteOpacity
