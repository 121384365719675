import "./basicTable.css";
import { withNamespaces } from "react-i18next";


import Pagination from "../Pagination/Pagination";

function BasicTable({
  t,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  columns,
  data,
}) {
  return (
    <div className="basic-table-container">
      <div className="basic-table-box">
        <table>
          <thead>
            <tr>
              {columns.map((column, i) => {
                return (
                  <th key={i} title={column.header}>
                    {column.header}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data.map((row, i) => {
              return (
                <tr
                  key={i}
                >
                  {columns.map((column, i) => {
                    return (
                      <td key={i} title={column.key === "status" && row[column.key] === true
                          ? t("table_edit_approved")
                          : row[column.key] === false
                          ? t("table_edit_waiting")
                          : row[column.key]}>
                        {column.key === "status" && row[column.key] === true
                          ? t("table_edit_approved")
                          : row[column.key] === false
                          ? t("table_edit_waiting")
                          : row[column.key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default withNamespaces()(BasicTable);
