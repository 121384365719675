import "./bannersContainer.css";
import React, { useEffect, useState } from "react";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Loading from "../Loading/Loading";
import BannerCardBig from "../Dashboard/BannerCard/BannerCardBig";
import BannerCardMedium from "../Dashboard/BannerCard/BannerCardMedium";
import ExChangeRateCard from "../Dashboard/ExChangeRateCard/ExChangeRateCard";
import BannerCardSmall from "../Dashboard/BannerCard/BannerCardSmall";
import { GetContextValue } from "../../context/GetContext";

function BannersContainer() {
  const {
    bannersError,
    bannersLoading,
    bannerData,

    currentIndexSmallBanner,
    setCurrentIndexSmallBanner,
    currentIndexSmallBanner2,
    setCurrentIndexSmallBanner2,
    currentIndexSmallBanner3,
    setCurrentIndexSmallBanner3,
    currentIndexMediumBanner,
    setCurrentIndexMediumBanner,
    currentIndexMediumBanner2,
    setCurrentIndexMediumBanner2,
    currentIndexBigBanner,
    setCurrentIndexBigBanner,
    currentIndexBigBanner2,
    setCurrentIndexBigBanner2,
  } = GetContextValue();

  const [smallBanners, setSmallBanners] = useState([]);
  const [smallBanners2, setSmallBanners2] = useState([]);
  const [smallBanners3, setSmallBanners3] = useState([]);
  const [mediumBanners, setMediumBanners] = useState([]);
  const [mediumBanners2, setMediumBanners2] = useState([]);
  const [bigBanners, setBigBanners] = useState([]);
  const [bigBanners2, setBigBanners2] = useState([]);

  console.log(bannerData);

  useEffect(() => {
    if (!bannersError && bannerData) {
      const small = bannerData.filter(
        (banner) => Number(banner.advertSize) === 1
      );
      const medium = bannerData.filter(
        (banner) => Number(banner.advertSize) === 2
      );
      const big = bannerData.filter(
        (banner) => Number(banner.advertSize) === 3
      );

      const chunkArray = (arr, chunkSize) => {
        const chunks = [];
        let i = 0;
        while (i < arr.length) {
          chunks.push(arr.slice(i, i + chunkSize));
          i += chunkSize;
        }
        return chunks;
      };

      if (small.length > 3) {
        const chunks = chunkArray(small, Math.ceil(small.length / 3));

        setSmallBanners(chunks[0] || []);
        setSmallBanners2(chunks[1] || []);
        setSmallBanners3(chunks[2] || []);
      } else {
        setSmallBanners(small);
        setSmallBanners2([]);
        setSmallBanners3([]);
      }

      if (medium.length > 4) {
        const mediumPart1 = medium.slice(0, Math.ceil(medium.length / 2));
        const mediumPart2 = medium.slice(Math.ceil(medium.length / 2));

        setMediumBanners(mediumPart1);
        setMediumBanners2(mediumPart2); // Create a separate state for additional medium banners
      } else {
        setMediumBanners(medium);
      }

      if (big.length > 4) {
        const bigPart1 = big.slice(0, Math.ceil(big.length / 2));
        const bigPart2 = big.slice(Math.ceil(big.length / 2));

        setBigBanners(bigPart1);
        setBigBanners2(bigPart2); // Create a separate state for additional big banners
      } else {
        setBigBanners(big);
      }
    }
  }, [bannerData]);

  return (
    <div className="banners-container">
      {bannersError ? (
        <ErrorComponent error={bannersError} />
      ) : bannersLoading ? (
        <Loading />
      ) : bannerData && bannerData.length > 0 ? (
        <>
          {bigBanners && bigBanners.length > 0 && (
            <div className="big-banner">
              {" "}
              <BannerCardBig
                bannerData={bigBanners}
                currentIndexBigBanner={currentIndexBigBanner}
                setCurrentIndexBigBanner={setCurrentIndexBigBanner}
              />
            </div>
          )}

          {mediumBanners && mediumBanners.length > 0 && (
            <div className="medium-banner">
              {" "}
              <BannerCardMedium
                bannerData={mediumBanners}
                currentIndexMediumBanner={currentIndexMediumBanner}
                setCurrentIndexMediumBanner={setCurrentIndexMediumBanner}
              />
            </div>
          )}
          <div className="exchangerate-card">
            <ExChangeRateCard />
          </div>

          {smallBanners && smallBanners.length > 0 && (
            <div className="small-banner">
              {" "}
              <BannerCardSmall
                bannerData={smallBanners}
                currentIndexSmallBanner={currentIndexSmallBanner}
                setCurrentIndexSmallBanner={setCurrentIndexSmallBanner}
              />
            </div>
          )}

          {mediumBanners2 && mediumBanners2.length > 0 && (
            <div className="medium-banner">
              {" "}
              <BannerCardMedium
                bannerData={mediumBanners2}
                currentIndexMediumBanner={currentIndexMediumBanner2}
                setCurrentIndexMediumBanner={setCurrentIndexMediumBanner2}
              />
            </div>
          )}

          {smallBanners2 && smallBanners2.length > 0 && (
            <div className="small-banner">
              {" "}
              <BannerCardSmall
                bannerData={smallBanners2}
                currentIndexSmallBanner={currentIndexSmallBanner2}
                setCurrentIndexSmallBanner={setCurrentIndexSmallBanner2}
              />
            </div>
          )}

          {bigBanners2 && bigBanners2.length > 0 && (
            <div className="big-banner">
              {" "}
              <BannerCardBig
                bannerData={bigBanners2}
                currentIndexBigBanner={currentIndexBigBanner2}
                setCurrentIndexBigBanner={setCurrentIndexBigBanner2}
              />
            </div>
          )}

          {smallBanners3 && smallBanners3.length > 0 && (
            <div className="small-banner">
              {" "}
              <BannerCardSmall
                bannerData={smallBanners3}
                currentIndexSmallBanner={currentIndexSmallBanner3}
                setCurrentIndexSmallBanner={setCurrentIndexSmallBanner3}
              />
            </div>
          )}
        </>
      ) : (
        "null"
      )}
    </div>
  );
}

export default React.memo(BannersContainer);
