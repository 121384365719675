import "./notificationBox.css";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContextValue } from "../../context/AuthContext";

function NotificationBox({ notification, isSelected, handleSelect }) {
  const { id, title, createdAt, body, isRead, values } = notification;
  const navigate = useNavigate();
  const { token } = AuthContextValue();

  const handleChange = () => {
    handleSelect(id, isSelected);
  };

  const handleGoNotification = async () => {
    const url = new URL(values);
    const route = url.pathname;

    const response = await markAsRead();

    if (response && (!response.catchError || !response.isError)) {
      console.log(response);
      navigate(route);
    }
  };

  // Okundu olarak işaretlemek için
  const markAsRead = async () => {
    const putData = {
      id: [id],
      status: true,
    };

    try {
      const response = await axios.put(
        process.env.REACT_APP_PUT_UPDATE_NOTIFICATION_STATUS_URL,
        JSON.stringify(putData),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token} `,
            withCredentials: true,
          },
        }
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        opacity: isRead ? "0.8" : "",
        fontWeight: isSelected ? "bold" : "",
      }}
      className="notification-box-container"
    >
      <div onClick={handleChange} className="notification-title-box">
        <div className="title-box-left">
          {isRead ? (
            <i className="fa-solid fa-envelope-open"></i>
          ) : (
            <i className="fa-solid fa-envelope"></i>
          )}
          <input type="checkbox" checked={isSelected} onChange={handleChange} className="checkbox" />
          <h4 className="title">{title}</h4>
        </div>
        <p className="create-date">{new Date(createdAt).toLocaleString()}</p>
      </div>
      <div onClick={handleGoNotification} className="notification-body-box">
        <p className="text">{body}</p>
      </div>
    </div>
  );
}

export default NotificationBox;
