import { getFetch } from "./getFetch";

export const getUserStatistics = async (userId, companyType, token) => {
  const promise1 = await getFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?OfferUserId=${userId}&Last=false&SortType=1&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { offerTotal: response.data.total };
  });
  //Başarılı Teklif Sayısı
  const promise2 = await getFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?OfferUserId=${userId}&OfferStatus=2&Result=2&Last=false&SortType=3&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { offerSuccess: response.data.total };
  });

  //Başarısız Teklif Sayısı
  const promise3 = await getFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?OfferUserId=${userId}&OfferStatus=2&Result=3&Last=false&SortType=3&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { offerUnSuccessful: response.data.total };
  });

  //Onay Bekleyen Teklif Sayısı
  const promise4 = await getFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?OfferUserId=${userId}&OfferStatus=2&Result=1&Last=false&SortType=3&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { offerWaitingApp: response.data.total };
  });

  const promise5 = await getFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?UserId=${userId}&Last=false&SortType=1&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { tenderTotal: response.data.total };
  });

  //Aktif İhale Sayısı
  const promise6 = await getFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?UserId=${userId}&Status=2&Last=false&SortType=1&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { tenderActive: response.data.total };
  });

  //Sonuçlanan İhale Sayısı
  const promise7 = await getFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?UserId=${userId}&Status=5&Last=false&SortType=1&Page=${1}&PageSize=${1}`,
    token
  ).then((response) => {
    return { tenderOver: response.data.total };
  });

  let userStatistics;

  if (companyType === 1) {
    const combinedPromise = Promise.all([promise5, promise6, promise7]);

    userStatistics = combinedPromise.then((results) => {
      const combinedData = {
        ...results[0], // tenderTotal
        ...results[1], // tenderActive
        ...results[2], // tenderOver
      };

      return combinedData;
    });
  } else if (companyType === 2) {
    const combinedPromise = Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
    ]);

    userStatistics = combinedPromise.then((results) => {
      const combinedData = {
        ...results[0], // offerTotal
        ...results[1], // offerSuccess
        ...results[2], // offerUnSuccessful
        ...results[3], // offerWaitingApp
      };

      return combinedData;
    });
  } else if (companyType === 3) {
    const combinedPromise = Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
      promise5,
      promise6,
      promise7,
    ]);

    userStatistics = combinedPromise.then((results) => {
      const combinedData = {
        ...results[0], // offerTotal
        ...results[1], // offerSuccess
        ...results[2], // offerUnSuccessful
        ...results[3], // offerWaitingApp
        ...results[4], // tenderTotal
        ...results[5], // tenderActive
        ...results[6], // tenderOver
      };

      return combinedData;
    });
  } else {
    return null;
  }

  return userStatistics;
};
