import "./styles/mediumTableDetailCard/mediumTableDetailCard.css";

import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import MediumTable from "../../components/Table/MediumTable/MediumTable";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import Loading from "../../components/Loading/Loading";
import { Link, useNavigate } from "react-router-dom";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function BidsInTenders({ t }) {
  const navigate = useNavigate();

  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  /* Tablodan seçilen değerleri tutan stateler */
  const [selectValue, setSelectValue] = useState(null);
  const [selectValueId, setSelectValueId] = useState(null);

  const {
    setUseFetchRender: setOffersUseFetchRender,
    useFetchRender: offersUseFetchRender,
    error: offersError,
    loading: offersLoading,
    data: offers,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?TenderCompanyId=${loginUserCompanyId}&Last=true&SortType=3&Page=${1}&PageSize=${5}`,
    token
  );

  const columns = [
    {
      key: "result",
      header: t("table_status"),
    },
    {
      key: "createdUser",
      header: t("support_request_created_user"),
    },
    {
      key: "productName",
      header:t("table_product"),
    },
    {
      key: "passedDay",
      header: t("table_passed_time"),
    },
  ];

  useEffect(() => {
    setOffersUseFetchRender(!offersUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  // function handleClickValue(valueId, value) {
  //   setSelectValueId(valueId);
  //   setSelectValue(value);
  // }

  function handleClickValue(valueId, value) {
    if (selectValue === value && selectValueId === valueId) {
      setSelectValue(null);
      setSelectValueId(null);
      // navigate(`/tenders/tender-offer-detail/${selectValueId}`);
    } else {
      setSelectValueId(valueId);
      setSelectValue(value);
    }
  }

  return (
    <div className="medium-table-container">
      <div className="table-top">
        <div className="title-box">
          <i className="fa-solid fa-star"></i>
          <h5>{t("incoming_offers_title")}</h5>
        </div>
        <div className="buttons">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
            tooltip={t("button_edit_render")}
            onClick={() => {
              setOffersUseFetchRender(!offersUseFetchRender);
              setSelectValue(null);
              setSelectValueId(null);
            }}
          />
          <Link
            to={
              selectValue || selectValueId
                ? `/tenders/tender-offer-detail/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              disabled={selectValue || selectValueId ? false : true}
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={t("button_edit_detail")}
            />
          </Link>
        </div>
      </div>

      <div className="table-main">
        {offersError ? (
          <ErrorComponent error={offersError} />
        ) : offersLoading ? (
          <Loading />
        ) : offers !== null && offers.length > 0 ? (
          <MediumTable
            type="offer"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            columns={columns}
            data={offers}
          />
        ) : (
          <p className="table-null-text">{t("message_no_incoming_offer")}</p>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(BidsInTenders);
