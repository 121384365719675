import "./companiesApplying.css";

import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import Loading from "../../components/Loading/Loading";
import { GetContextValue } from "../../context/GetContext";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { CrudContextValue } from "../../context/CrudContext";
import DescriptionModal from "../../components/Modals/DescriptionModal/DescriptionModal";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function CompaniesApplying({ t }) {
  const { token } = AuthContextValue();
  const { selectValueId, setSelectValueId, selectValue, setSelectValue } =
    GetContextValue();
  const { createMethod } = CrudContextValue();

  const [approvedLoading, setApprovedLoading] = useState(false);

  const [selectCompanyType, setSelectCompanyType] = useState(1);

  const [descriptionNote, setDescriptionNote] = useState("");

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [openDescriptionNote, setOpenDescriptionNote] = useState(false);
  const handleOpenDescriptionNote = () => setOpenDescriptionNote(true);
  const handleCloseDescriptionNote = () => setOpenDescriptionNote(false);

  const [filteredCompaniesApplying, setFilteredCompaniesApplying] = useState(
    []
  );

  const [companiesApplyingPage, setCompaniesApplyingPage] = useState(1);
  const [companiesApplyingRowsPerPage, setCompaniesApplyingRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setCompaniesApplyingUseFetchRender,
    useFetchRender: companiesApplyingUseFetchRender,
    error: companiesApplyingError,
    loading: companiesApplyingLoading,
    data: companiesApplying,
    total: companiesApplyingTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANIES_APPLYING_URL}?page=${companiesApplyingPage}&pageSize=${companiesApplyingRowsPerPage}`,
    token
  );

  useEffect(() => {
    if (!companiesApplyingError && companiesApplying) {
      const filteredData = companiesApplying.filter(
        (company) => company.status === false
      );
      setFilteredCompaniesApplying(companiesApplying);
    }
  }, [companiesApplying]);

  console.log(companiesApplying);

  const columns = [
    {
      key: "invitedCompanyName",
      header: t("table_company_name"),
    },
    {
      key: "invite-status",
      header: t("table_status"),
    },
    {
      key: "companyType",
      header: t("table_company_type"),
    },
    {
      key: "invitersCompanyName",
      header: t("table_referance"),
    },
    {
      key: "managerEmail",
      header: t("table_manager_mail"),
    },
    {
      key: "companyEmail",
      header: t("table_company_mail"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "description",
      header: t("table_description"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
    setDescriptionNote(value.description);
    setSelectCompanyType(Number(value.companyType));
  }

  async function handleConfirm() {
    const companyConfirmData = {
      id: selectValueId,
      approve: true,
      companyType: selectCompanyType,
    };
    console.log("firma onaylandı", companyConfirmData);

    setApprovedLoading(true);

    await createMethod(
      `${process.env.REACT_APP_POST_COMPANY_REGISTER_APPROVE_URL}?id=${selectValueId}&approve=true&companyType=${selectCompanyType}`,
      null,
      token
    );

    setApprovedLoading(false);

    handleCloseConfirmModal();

    setSelectValue(null);
    setSelectValueId(null);
  }

  return (
    <div className="companyies-applying-main">
      <div className="companyies-applying-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {approvedLoading ? (
          <LoadingCircular />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_confirm")}
            handleCloseModal={handleCloseConfirmModal}
            confirmFunc={handleConfirm}
            text={`${selectValue.invitedCompanyName} ${t(
              "message_invite_confirm"
            )}`}
          />
        ) : (
          <div className="box-top">
            <div className="box-title">
              {selectValue && selectValue?.status === false ? (
                <p className="approved-message">
                  {t("invite_company_approved_info")}
                </p>
              ) : (
                <>
                  <i className="fa-solid fa-plus"></i>
                  <h4>{t("company_approved_title")}</h4>
                </>
              )}
            </div>
            <div className="box-buttons">
              {selectValue && selectValue?.status === false && (
                <div className="box-buttons-select-container">
                  <label htmlFor="companyType">
                    {" "}
                    {t("table_company_type")} :{" "}
                  </label>
                  <select
                    name="companyType"
                    id="companyType"
                    value={selectCompanyType}
                    onChange={(e) =>
                      setSelectCompanyType(Number(e.target.value))
                    }
                  >
                    <option value={1}>
                      {" "}
                      {t("select_options_maincompany")}{" "}
                    </option>
                    <option value={2}> {t("select_options_supplier")}</option>
                    <option value={3}>
                      {" "}
                      {t("select_options_maincompany_supplier")}
                    </option>
                  </select>
                </div>
              )}

              {descriptionNote && (
                <WhiteButton
                  icon={<i className="fa-solid fa-note-sticky"></i>}
                  tooltip={t("table_description")}
                  onClick={handleOpenDescriptionNote}
                />
              )}

              <WhiteButton
                icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
                tooltip={t("button_edit_render")}
                onClick={() => {
                  setSelectValue(null);
                  setSelectValueId(null);

                  setCompaniesApplyingUseFetchRender(
                    !companiesApplyingUseFetchRender
                  );
                }}
              />

              {selectValue && selectValue?.status === false && (
                <WhiteButton
                  icon={<i className="fa-solid fa-check"></i>}
                  tooltip={t("button_edit_confirm")}
                  onClick={handleOpenConfirmModal}
                  disabled={selectValue ? false : true}
                />
              )}
            </div>

            {openDescriptionNote && (
              <DescriptionModal
                title={t("application_description")}
                descriptionNote={descriptionNote}
                openDescriptionNote={openDescriptionNote}
                handleCloseDescriptionNote={handleCloseDescriptionNote}
              />
            )}
          </div>
        )}
      </div>

      <div className="companyies-applying-table">
        {companiesApplyingError ? (
          <ErrorComponent error={companiesApplyingError} />
        ) : companiesApplyingLoading ? (
          <Loading />
        ) : filteredCompaniesApplying !== null &&
          filteredCompaniesApplying.length > 0 ? (
          <StandartTable
            type="companies-app"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={companiesApplyingUseFetchRender}
            setUseFetchRender={setCompaniesApplyingUseFetchRender}
            total={companiesApplyingTotal}
            page={companiesApplyingPage}
            setPage={setCompaniesApplyingPage}
            rowsPerPage={companiesApplyingRowsPerPage}
            setRowsPerPage={setCompaniesApplyingRowsPerPage}
            columns={columns}
            data={filteredCompaniesApplying}
            // data={rows}

            setSelectCompanyType={setSelectCompanyType}
          />
        ) : (
          <div className="table-null-text">
            {t("message_no_company_application")}
          </div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(CompaniesApplying);
