import "./inputPrice.css";

function InputPrice({
  required,
  type,
  title,
  name,
  placeholder,
  span,
  currencySymbol,
  errorBorder,
  ...props
}) {
  return (
    <div className="input-standart">
      <label htmlFor={name}>{`${required ? "*" : ""} ${title}`} : </label>
      <div className="input-price">
        <input
          {...props}
          className={`${errorBorder ? "input-error" : ""} `}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
        />
        <span>{currencySymbol}</span>
      </div>
    </div>
  );
}

export default InputPrice;
// ₺
// $
// €
