import "./styles/supplyProcess/supplyProcess.css";
import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import { GetContextValue } from "../../context/GetContext";
import OngoingOrders from "./OngoingOrders";
import ResultingOrders from "./ResultingOrders";
import OnGoingShipments from "./OnGoingShipments";
import ResultingShipments from "./ResultingShipments";
import Search from "antd/es/input/Search";
import Select from "../../components/form_components/Select/Select";

function SupplyProcess({ t }) {
  const { companyDetail } = GetContextValue();

  const [searchBar, setSearchBar] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [supplyProcessPageLink, setSupplyProcessPageLink] = useState(
    Number(companyDetail?.companyType) === 1
      ? "ongoing-orders"
      : Number(companyDetail?.companyType) === 2
      ? "ongoing-shipments"
      : "ongoing-orders"
  );
  useEffect(() => {
    setSupplyProcessPageLink(
      Number(companyDetail?.companyType) === 1
        ? "ongoing-orders"
        : Number(companyDetail?.companyType) === 2
        ? "ongoing-shipments"
        : "ongoing-orders"
    );
  }, [companyDetail]);

  function handleClickLink(link) {
    setSupplyProcessPageLink(link);
  }

  const selectOptions = [
    { key: "", text: t("select_options_all") },
    { key: 1, text: t("select_being_supplied") },
    { key: 2, text: t("select_during_production") },
    { key: 3, text: t("select_in_preparation") },
    { key: 4, text: t("select_shipment_preparation") },
  ];

  return (
    <div className="supplyprocess-container">
      <div className="supplyprocess-title-container">
        <div className="title-box">
          <i className="fa-solid fa-boxes-packing"></i>
          <h4 className="title">{t("procuremen_process_title")}</h4>
        </div>

        <div className="right-box">
          <Select
            options={selectOptions}
            select={statusFilter}
            setSelect={setStatusFilter}
          />
          <Search
            value={searchBar}
            onChange={(e) => setSearchBar(e.target.value)}
            placeholder={t("input_tender_number")}
          />
        </div>
      </div>

      <div className="supplyprocess-links">
        <div className="left-box">
          {Number(companyDetail.companyType) === 1 ||
          Number(companyDetail.companyType) === 3 ? (
            <>
              <ButtonText
                icon={<i className="fa-solid fa-truck-field"></i>}
                text={t("button_ongoing_orders")}
                onClick={() => handleClickLink("ongoing-orders")}
                className={
                  supplyProcessPageLink === "ongoing-orders"
                    ? "button-active"
                    : ""
                }
              />

              <ButtonText
                icon={<i className="fa-solid fa-handshake"></i>}
                text={t("button_resulting_orders")}
                onClick={() => handleClickLink("resulting-orders")}
                className={
                  supplyProcessPageLink === "resulting-orders"
                    ? "button-active"
                    : ""
                }
              />
            </>
          ) : Number(companyDetail.companyType) === 2 ||
            Number(companyDetail.companyType) === 3 ? (
            <>
              <ButtonText
                icon={<i className="fa-solid fa-truck-field"></i>}
                text={t("button_ongoing_shipments")}
                onClick={() => handleClickLink("ongoing-shipments")}
                className={
                  supplyProcessPageLink === "ongoing-shipments"
                    ? "button-active"
                    : ""
                }
              />

              <ButtonText
                icon={<i className="fa-solid fa-handshake"></i>}
                text={t("button_resulting_shipments")}
                onClick={() => handleClickLink("resulting-shipments")}
                className={
                  supplyProcessPageLink === "resulting-shipments"
                    ? "button-active"
                    : ""
                }
              />
            </>
          ) : null}
        </div>
      </div>

      <div className="supplyprocess-main-container">
        {supplyProcessPageLink === "ongoing-orders" ? (
          <OngoingOrders statusFilter={statusFilter} searchBar={searchBar} />
        ) : supplyProcessPageLink === "resulting-orders" ? (
          <ResultingOrders statusFilter={statusFilter} searchBar={searchBar} />
        ) : supplyProcessPageLink === "ongoing-shipments" ? (
          <OnGoingShipments statusFilter={statusFilter} searchBar={searchBar} />
        ) : supplyProcessPageLink === "resulting-shipments" ? (
          <ResultingShipments
            statusFilter={statusFilter}
            searchBar={searchBar}
          />
        ) : (
          <div className="table-null-text">{t("message_no_page")}</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(SupplyProcess);
