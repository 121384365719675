import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

import { Button, Form, Input } from "antd";
import { CrudContextValue } from "../../context/CrudContext";
import { AuthContextValue } from "../../context/AuthContext";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { useLocation } from "react-router-dom";
import Modal from "antd/es/modal/Modal";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const validateMessages = {
  required: '${label} ${t("message_compulsory")}',
};

function SupportRequestForm({
  t,
  handleCloseModal,
  setActiveTab,
  setSupportRequestUseFetchRender,
  supportRequestUseFetchRender,
}) {
  const { pathname } = useLocation();
  const { token } = AuthContextValue();
  const { createMethod } = CrudContextValue();
  const [supportTickets, setSupportTickets] = useState([]);

  const [imageModal, setImageModal] = useState(false);

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [sumbitLoading, setSumbitLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("file : ", file);

    // Resmi Base64 formatında oku
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      // Base64 formatındaki resmi "setImage" fonksiyonu ile atama
      setImage(reader.result.split(",")[1]); // "reader.result" Base64 formatındaki resim verisidir
    };

    // Fotoğrafı oku ve URL'sini ayarla
    const imageURL = URL.createObjectURL(file);
    setImageUrl(imageURL);
  };

  const onFinish = async (values) => {
    setSumbitLoading(true);
    console.log({ ...values, images: image !== null ? [image] : [] });
    console.log(image);

    const formData = { ...values, images: image !== null ? [image] : [] };
    const response = await createMethod(
      process.env.REACT_APP_POST_ADD_SUPPORT_URL,
      JSON.stringify(formData),
      token
    );

    setSumbitLoading(false);

    if (response && !response.catchError && !response.isError) {
      setSupportRequestUseFetchRender(!supportRequestUseFetchRender);

      if (pathname === "/support-request") {
        handleCloseModal();
      } else {
        setTimeout(() => {
          setActiveTab("1");
        }, 3000);
      }
    } else {
      console.log("Destek talep ekleme oluşturma hatası : ", {
        catchError: response.catchError,
        isError: response.isError,
      });
    }
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 12,
      }}
      labelWrap
      layout="horizontal"
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="title"
        label={t("support_request_ticket_title")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="body"
        label={t("support_request_description")}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        name="images"
        label={t("support_request_photo")}
        valuePropName="fileList"
        getValueFromEvent={(event) => {
          if (Array.isArray(event)) {
            return event;
          }
          const fileList = event && event.fileList;
          return fileList;
        }}
      >
        <input type="file" onChange={handleImageChange} />
        {imageUrl && (
          <img
            onClick={() => setImageModal(true)}
            src={imageUrl}
            alt="Ticket"
            style={{
              cursor: "pointer",
              marginTop: "10px",
              width: "200px",
              objectFit: "contain",
            }}
          />
        )}
      </Form.Item>

      <Modal
        open={imageModal}
        onCancel={() => setImageModal(false)}
        children={
          <img
            src={imageUrl}
            alt="Ticket"
            style={{
              textAlign:"center",
              width: "80%",
              height: "70vh",
              objectFit: "contain",
            }}
          />
        }
        width={"80%"}
        
        footer={null}
      />

      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 8,
        }}
      >
        <Button type="primary" htmlType="submit">
          {t("button_edit_send")}
        </Button>
      </Form.Item>
      {sumbitLoading && <LoadingCircular />}
    </Form>
  );
}

export default withNamespaces()(SupportRequestForm);
