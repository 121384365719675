import "./tenderAddSupplier.css";
import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import SelectTable from "../Table/SelectTable/SelectTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import Loading from "../Loading/Loading";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { Modal, Select } from "antd";
import axios from "axios";
import { GetContextValue } from "../../context/GetContext";

function TenderAddSupplier({
  t,
  tenderId,
  formData,
  onSubmit,
  handleCloseTenderAddSuppModal,
  supplerRender,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [filteredSuppliers, setFilteredSuppliers] = useState([]);

  const [selectValue, setSelectValue] = useState(t("sidebar_suppliers"));
  // tablo filtreleme
  // 1 = onay beklyen
  // 2 = tedarikçilerim
  // 3 = reddedilen
  const [tableFilter, setTableFilter] = useState(2);
  const [searchText, setSearchText] = useState("");

  const [tenderCompanyId, setTenderCompanyId] = useState([]);

  const [tenderCategoryIds, setTenderCategoryIds] = useState([]);
  const [postCategoryIds, setPostCategoryIds] = useState([]);

  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);
  const [mySuppliersTotal, setMySuppliersTotal] = useState(0);

  const [mySuppliers, setMySuppliers] = useState([]);
  const [mySuppliersLoading, setMySuppliersLoading] = useState(false);
  const [mySuppliersError, setMySuppliersError] = useState(null);
  const [mySuppliersRender, setMySuppliersRender] = useState(false);

  useEffect(() => {
    if (loginUserCompanyId) {
      const postDataMySup = {
        companyId: loginUserCompanyId,
        status: tableFilter,
        searchedText: searchText,
        companyCategoryIds: postCategoryIds,
        page: mySuppliersPage,
        pageSize: mySuppliersRowsPerPage,
      };

      async function getMySupplier() {
        setMySuppliersLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_GET_MY_SUPPLIER_URL,
            JSON.stringify(postDataMySup),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            // console.log(
            //   "REACT_APP_GET_MY_SUPPLIER_URL responseSupp.data.isError : ",
            //   responseSupp.data.errorMessageList[0]
            // );
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setMySuppliersError(responseSupp.data.isError);
            }
          } else {
            // console.log("responseSuppSupp : ", responseSupp);
            setMySuppliers(responseSupp.data.data);
          }

          if (responseSupp.data.total > 0) {
            setMySuppliersTotal(responseSupp.data.total);
          }
        } catch (error) {
          console.log("REACT_APP_GET_MY_SUPPLIER_URL, catchError :", error);
          setMySuppliersError(error);
        }
        setMySuppliersLoading(false);
      }

      if (token && loginUserCompanyId) {
        getMySupplier();
      }
    }
  }, [
    loginUserCompanyId,
    notificationRender,
    mySuppliersRender,
    searchText,
    tableFilter,
    postCategoryIds,
  ]);

  const [recomendedPage, setRecomendedPage] = useState(1);
  const [recomendedRowsPerPage, setRecomendedRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setRecomendedUseFetchRender,
    useFetchRender: recomendedUseFetchRender,
    error: recomendedError,
    loading: recomendedLoading,
    data: recomended,
    total: recomendedTotal,
  } = useFetch(
    `${process.env.REACT_APP_TENDER_RECOMENDED_COMPANIES_URL}?tenderId=${tenderId}`,
    token
  );

  useEffect(() => {
     setMySuppliersRender(!mySuppliersRender);
  }, [supplerRender]);

  useEffect(() => {
    if (formData && formData.categoryId) {
      const categIds = formData.categoryId.map((cate) => cate.categoryId);
      setTenderCategoryIds(categIds);
    }
  }, [formData]);

  const handleChangeSupplierTy = (value) => {
    console.log(`selected ${value}`);
    if (value === "for-tender") {
      setPostCategoryIds(tenderCategoryIds);
    } else {
      setPostCategoryIds([]);
    }
  };

  useEffect(() => {
    if (mySuppliers && formData) {
      // console.log("formData : ",formData);
      const tenderComp = formData.map((comp) => comp.companyId);
      const filteredMySupp = mySuppliers.filter(
        (supp) => !tenderComp.includes(supp.companyId)
      );
      setFilteredSuppliers(filteredMySupp);
    }
  }, [mySuppliers, formData]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectValue(value);
  };

  const handleSubmit = async () => {
    if (tenderCompanyId.length <= 0) {
      alert(t("message_emty_form"));
      return;
    } else {
      const tenderCompanyIds = tenderCompanyId.map((comp) => comp.companyId);

      setUpdateLoading(true);

      await onSubmit(tenderCompanyIds);

      setUpdateLoading(false);
    }

    handleCloseConfirmModal();
    handleCloseTenderAddSuppModal();
  };

  return (
    <div className="tender-add-supplier-container">
      <div className="tender-add-supplier-top">
        {updateLoading ? (
          <LoadingCircular />
        ) : confirmModalOpen ? (
          <Modal
            open={confirmModalOpen}
            children={t("message_confirm_add_suppliers_in_tender")}
            cancelText={t("button_edit_cancel")}
            okText={t("button_edit_add")}
            onOk={handleSubmit}
            onCancel={handleCloseConfirmModal}
          />
        ) : (
          <>
            <div className="tender-add-supplier-top-left">
              <div className="title-box">
                <div className="title">
                  <i className="fa-solid fa-cubes"></i>
                  <h4>{t("select_supplier_title")}</h4>
                </div>
              </div>
            </div>
            <div className="tender-add-supplier-top-right">
              <Select
                defaultValue={t("sidebar_suppliers")}
                style={{
                  width: "auto",
                }}
                onChange={handleChange}
                options={[
                  {
                    value: t("sidebar_suppliers"),
                    label: t("sidebar_suppliers"),
                  },
                  {
                    value: t("button_recommended"),
                    label: t("button_recommended"),
                  },
                ]}
              />
              <WhiteButton
                icon={<i className="fa-solid fa-location-arrow"></i>}
                text={t("button_edit_confirm")}
                onClick={handleOpenConfirmModal}
              />
            </div>
          </>
        )}
      </div>

      <div
        className={`tender-add-supplier-table ${
          confirmModalOpen || updateLoading ? "disabled" : ""
        }`}
      >
        {selectValue === t("sidebar_suppliers") ? (
          <>
            {mySuppliersError ? (
              <ErrorComponent error={mySuppliersError} />
            ) : mySuppliersLoading ? (
              <Loading />
            ) : filteredSuppliers !== null && filteredSuppliers.length > 0 ? (
              <SelectTable
                total={mySuppliersTotal}
                page={mySuppliersPage}
                setPage={setMySuppliersPage}
                rowsPerPage={mySuppliersRowsPerPage}
                setRowsPerPage={setMySuppliersRowsPerPage}
                selectedRows={tenderCompanyId}
                setSelectedRows={setTenderCompanyId}
                data={filteredSuppliers}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            )}
          </>
        ) : (
          <>
            {recomendedError ? (
              <ErrorComponent error={recomendedError} />
            ) : recomendedLoading ? (
              <Loading />
            ) : recomended !== null && recomended.length > 0 ? (
              <SelectTable
                total={recomendedTotal}
                page={recomendedPage}
                setPage={setRecomendedPage}
                rowsPerPage={recomendedRowsPerPage}
                setRowsPerPage={setRecomendedRowsPerPage}
                selectedRows={tenderCompanyId}
                setSelectedRows={setTenderCompanyId}
                data={recomended}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(TenderAddSupplier);
