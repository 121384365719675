import menuIcon from "./menu.svg";
import searchIcon from "./search.svg";
import profileIcon from "./profile.svg";
import offerexLogoDark from "./offerexDarkLogo.svg";
import offerexLogoLight from "./offerexLightLogo.svg";

const icons = {
    menuIcon,
    searchIcon,
    profileIcon,
    offerexLogoDark,
    offerexLogoLight
}


export default icons;