import "./carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import ModalCarousel from "../Modals/ModalCarousel/ModalCarousel";

const Carousel = ({ images }) => {
  const [modalCarouselImageIndex, setModalCarouselImageIndex] = useState(0);

  const [openModalCarousel, setOpenModalCarousel] = useState(false);
  const handleOpenModalCarousel = () => setOpenModalCarousel(true);
  const handleCloseModalCarousel = () => setOpenModalCarousel(false);

  const [firstImage, setFirstImage] = useState(0);
  const [secondImage, setSecondImage] = useState(1);

  function handleClikNext() {
    if (secondImage < images.length - 1) {
      setFirstImage(firstImage + 1);
      setSecondImage(secondImage + 1);
    }
  }

  function handleClikPrev() {
    if (firstImage > 0) {
      setFirstImage(firstImage - 1);
      setSecondImage(secondImage - 1);
    }
  }

  function handleClickImage(clickImageIndex) {
    setModalCarouselImageIndex(clickImageIndex);

    handleOpenModalCarousel();
  }

  return (
    <div className="offer-detail-card-images-container">
      {images.length <= 1 ? (
        <div className="slider-image-box">
          <img
            className="slider-img"
            src={
              images[firstImage].includes("http")
                ? images[firstImage]
                : `${process.env.REACT_APP_GET_IMAGE_URL}${images[firstImage]}`
            }
            alt="offer-img"
            onClick={() => handleClickImage(firstImage)}
          />
        </div>
      ) : (
        <>
          <div className="slider-image-box">
            <img
              className="slider-img"
              src={
                images[firstImage].includes("http")
                  ? images[firstImage]
                  : `${process.env.REACT_APP_GET_IMAGE_URL}${images[firstImage]}`
              }
              alt="offer-img"
              onClick={() => handleClickImage(firstImage)}
            />
          </div>

          <div className="slider-image-box">
            <img
              className="slider-img"
              src={
                images[secondImage].includes("http")
                  ? images[secondImage]
                  : `${process.env.REACT_APP_GET_IMAGE_URL}${images[secondImage]}`
              }
              alt="offer-img"
              onClick={() => handleClickImage(secondImage)}
            />
          </div>
          <button onClick={handleClikNext} className="next-btn">
            {" "}
            {">"}{" "}
          </button>
          <button onClick={handleClikPrev} className="prev-btn">
            {" "}
            {"<"}{" "}
          </button>
        </>
      )}
      {openModalCarousel && (
        <ModalCarousel
          clickImageIndex={modalCarouselImageIndex}
          images={images}
          openModalCarousel={openModalCarousel}
          handleCloseModalCarousel={handleCloseModalCarousel}
        
        />
      )}
    </div>
  );
};

export default Carousel;