import React, { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import SelectTable from "../../../components/Table/SelectTable/SelectTable";
import { AuthContextValue } from "../../../context/AuthContext";
import useFetch from "../../../hooks/UseFetch";
import Loading from "../../../components/Loading/Loading";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import ErrorComponent from "../../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../../components/LoadingCircular/LoadingCircular";
import AddRecommedSupplier from "../../../components/AddRecommedSupplier/AddRecommedSupplier";
import Search from "antd/es/transfer/search";
import { GetContextValue } from "../../../context/GetContext";
import { Select } from "antd";
import axios from "axios";

function TenderChangeNewTenderStepTwo({
  t,
  onPrev,
  onSubmit,
  formData,
  setFormData,
  step,
  setStep,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();


  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [tenderCategoryIds, setTenderCategoryIds] = useState([]);
  const [postCategoryIds, setPostCategoryIds] = useState([]);

  const [updateLoading, setUpdateLoading] = useState(false);
  // tablo filtreleme
  // 1 = onay beklyen
  // 2 = tedarikçilerim
  // 3 = reddedilen
  const [tableFilter, setTableFilter] = useState(2);
  const [searchText, setSearchText] = useState("");
  const [tenderCompanyId, setTenderCompanyId] = useState(
    formData.tenderCompanyId ? formData.tenderCompanyId : []
  );

  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);
  const [mySuppliersTotal, setMySuppliersTotal] = useState(0);

  const [mySuppliers, setMySuppliers] = useState([]);
  const [mySuppliersLoading, setMySuppliersLoading] = useState(false);
  const [mySuppliersError, setMySuppliersError] = useState(null);
  const [mySuppliersRender, setMySuppliersRender] = useState(false);

  useEffect(() => {
    if (loginUserCompanyId) {
      const postDataMySup = {
        companyId: loginUserCompanyId,
        status: tableFilter,
        searchedText: searchText,
        companyCategoryIds: postCategoryIds,
        page: mySuppliersPage,
        pageSize: mySuppliersRowsPerPage,
      };

      async function getMySupplier() {
        setMySuppliersLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_GET_MY_SUPPLIER_URL,
            JSON.stringify(postDataMySup),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            // console.log(
            //   "REACT_APP_GET_MY_SUPPLIER_URL responseSupp.data.isError : ",
            //   responseSupp.data.errorMessageList[0]
            // );
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setMySuppliersError(responseSupp.data.isError);
            }
          } else {
            // console.log("responseSuppSupp : ", responseSupp);
            setMySuppliers(responseSupp.data.data);
          }

          if (responseSupp.data.total > 0) {
            setMySuppliersTotal(responseSupp.data.total);
          }
        } catch (error) {
          console.log("REACT_APP_GET_MY_SUPPLIER_URL, catchError :", error);
          setMySuppliersError(error);
        }
        setMySuppliersLoading(false);
      }

      if (token && loginUserCompanyId) {
        getMySupplier();
      }
    }
  }, [
    loginUserCompanyId,
    notificationRender,
    mySuppliersRender,
    searchText,
    tableFilter,
    postCategoryIds,
  ]);

  const columns = [
    {
      key: "",
      header: t("table_select"),
    },
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "companyCategory",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    {
      key: "address",
      header: t("table_address"),
    },
  ];

  useEffect(() => {
    setMySuppliersRender(!mySuppliersRender);
  }, [searchText]);

  useEffect(() => {
    if (formData && formData.categoryId) {
      const categIds = formData.categoryId.map((cate) => cate.categoryId);
      setTenderCategoryIds(categIds);
    }
  }, [formData]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value === "for-tender") {
      setPostCategoryIds(tenderCategoryIds);
    } else {
      setPostCategoryIds([]);
    }
  };


  const handlePrev = () => {
    const tenderCompanyIds = tenderCompanyId.map((comp) => {
      if (comp.id) {
        return comp.companyId;
      } else {
        return comp;
      }
    });
    onPrev({ tenderCompanyId: tenderCompanyIds });
  };

  const handleSubmit = async () => {
    if (tenderCompanyId.length <= 0) {
      alert(t("message_emty_form"));
      return;
    } else {
      const tenderCompanyIds = tenderCompanyId.map((comp) => comp.companyId);

      setUpdateLoading(true);

      await onSubmit({ tenderCompanyId: tenderCompanyIds });

      setUpdateLoading(false);
    }

    handleCloseConfirmModal();
  };

  return (
    <div className="step-two-container">
      <div className="step-two-top">
        {updateLoading ? (
          <LoadingCircular />
        ) : confirmModalOpen ? (
          <ConfirmModal
            text={t("message_create_tender")}
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_send")}
            confirmFunc={handleSubmit}
            handleCloseModal={handleCloseConfirmModal}
          />
        ) : (
          <>
            <div className="step-two-top-left">
              <div className="title-box">
                <WhiteButton
                  className={`${step !== 2 ? "display-none" : ""}`}
                  icon={<i className="fa-solid fa-chevron-left"></i>}
                  tooltip={t("button_edit_back")}
                  onClick={handlePrev}
                  disabled={step !== 2}
                />
                <div className="title">
                  <i className="fa-solid fa-cubes"></i>
                  <h4>{t("select_supplier_title")}</h4>
                </div>
              </div>
            </div>
            <div className="step-two-top-right">
              <Search
                placeholder={t("button_edit_search")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Select
                defaultValue="all"
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "all",
                    label: t("select_options_all"),
                  },
                  {
                    value: "for-tender",
                    label: t("select_for_tender_category"),
                  },
                ]}
              />
              <WhiteButton
                icon={<i className="fa-solid fa-arrow-rotate-right" />}
                tooltip={t("button_edit_render")}
                onClick={() =>
                  setMySuppliersRender(!mySuppliersRender)
                }
              />
              <WhiteButton
                icon={<i className="fa-solid fa-location-arrow"></i>}
                tooltip={t("button_edit_confirm")}
                onClick={handleOpenConfirmModal}
              />
            </div>
          </>
        )}
      </div>

      <div
        className={`supplier-table ${
          confirmModalOpen || updateLoading ? "disabled" : ""
        }`}
      >
        <div className="table-one">
          {mySuppliersError ? (
            <ErrorComponent error={mySuppliersError} />
          ) : mySuppliersLoading ? (
            <Loading />
          ) : mySuppliers !== null && mySuppliers.length > 0 ? (
            <SelectTable
              total={mySuppliersTotal}
              page={mySuppliersPage}
              setPage={setMySuppliersPage}
              rowsPerPage={mySuppliersRowsPerPage}
              setRowsPerPage={setMySuppliersRowsPerPage}
              selectedRows={tenderCompanyId}
              setSelectedRows={setTenderCompanyId}
              columns={columns}
              data={mySuppliers}
            />
          ) : (
            <div className="table-null-text">
              {t("message_emty_companylist")}
            </div>
          )}
        </div>
        <div className="table-two">
          <AddRecommedSupplier
            tenderData={formData}
            setMySuppliersUseFetchRender={setMySuppliersRender}
            mySuppliersUseFetchRender={mySuppliersRender}
          />
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderChangeNewTenderStepTwo);
