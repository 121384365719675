function SearchIcon({color, width, height}) {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"

        >
            <g filter="url(#filter0_dd_418_111)">
                <path
                    fill={color}

                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.157 29.8945C25.157 29.2725 25.2795 28.6566 25.5175 28.0819C25.7556 27.5072 26.1045 26.985 26.5443 26.5452C26.9842 26.1053 27.5064 25.7564 28.081 25.5184C28.6557 25.2803 29.2717 25.1578 29.8937 25.1578C30.5157 25.1578 31.1317 25.2803 31.7064 25.5184C32.2811 25.7564 32.8032 26.1053 33.2431 26.5452C33.6829 26.985 34.0318 27.5072 34.2699 28.0819C34.5079 28.6566 34.6304 29.2725 34.6304 29.8945C34.6304 31.1508 34.1314 32.3556 33.2431 33.2439C32.3548 34.1322 31.15 34.6313 29.8937 34.6313C28.6374 34.6313 27.4326 34.1322 26.5443 33.2439C25.656 32.3556 25.157 31.1508 25.157 29.8945ZM29.8937 22C28.6627 22.0001 27.4487 22.2881 26.3489 22.841C25.249 23.3939 24.2936 24.1964 23.5592 25.1843C22.8247 26.1722 22.3314 27.3181 22.1187 28.5306C21.906 29.7432 21.9799 30.9886 22.3343 32.1675C22.6887 33.3464 23.3139 34.4261 24.16 35.3203C25.006 36.2145 26.0495 36.8984 27.207 37.3175C28.3645 37.7366 29.6039 37.8792 30.8264 37.7339C32.0488 37.5886 33.2203 37.1594 34.2473 36.4807L39.3029 41.5374C39.5991 41.8336 40.0009 42 40.4198 42C40.8386 42 41.2404 41.8336 41.5366 41.5374C41.8328 41.2412 41.9992 40.8395 41.9992 40.4206C41.9992 40.0017 41.8328 39.6 41.5366 39.3038L36.4799 34.2481C37.2664 33.0583 37.716 31.6775 37.7807 30.2526C37.8454 28.8277 37.5228 27.4119 36.8473 26.1557C36.1718 24.8994 35.1685 23.8497 33.9442 23.118C32.7198 22.3862 31.3201 21.9999 29.8937 22Z"

                />
            </g>
            <defs>
                <filter id="filter0_dd_418_111" x="0" y="0" width="57.9991" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="6" operator="erode" in="SourceAlpha" result="effect1_dropShadow_418_111" />
                    <feOffset dx="10" dy="10" />
                    <feGaussianBlur stdDeviation="6" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.65098 0 0 0 0 0.705882 0 0 0 0 0.784314 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_418_111" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-10" dy="-10" />
                    <feGaussianBlur stdDeviation="6" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_418_111" result="effect2_dropShadow_418_111" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_418_111" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

export default SearchIcon;
