import "./selectTable.css";
import { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

import Pagination from "../Pagination/Pagination";
import images from "../../../assets/images";

function SelectTable({
  t,

  selectedRows,
  setSelectedRows,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  columns,
  data,
}) {
  const [selectedRowsIds, setSelectedRowsIds] = useState(
    selectedRows ? selectedRows.map((item) => item.companyId) : []
  );

  useEffect(() => {
    const selectedRowsIds = selectedRows.map((item) => item.companyId);
    setSelectedRowsIds(selectedRowsIds);
  }, [selectedRows]);

  function handleSelect(companyId, row) {
    if (companyId || row) {
      if (selectedRowsIds.includes(row.companyId)) {
        // Aksi takdirde, seçili satırların listesinden kaldırın
        setSelectedRows(
          selectedRows.filter(
            (selected) => selected.companyId !== row.companyId
          )
        );
      } else {
        // Eğer onay kutusu işaretlenirse, seçili satırların listesine ekleyin
        setSelectedRows([
          ...selectedRows,
          { companyId: row.companyId, companyName: row.companyName },
        ]);
      }
    }
  }

  function handleChange(e, row) {
    const { checked } = e.target;

    if (checked) {
      // Eğer onay kutusu işaretlenirse, seçili satırların listesine ekleyin
      setSelectedRows([
        ...selectedRows,
        { companyId: row.companyId, companyName: row.companyName },
      ]);
    } else {
      // Aksi takdirde, seçili satırların listesinden kaldırın
      setSelectedRows(
        selectedRows.filter((selected) => selected.companyId !== row.companyId)
      );
    }
  }

  return (
    <div className="select-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              <th>{t("table_select")}</th>
              <th>{t("table_photo")}</th>
              <th>{t("table_company_name")}</th>
              <th>{t("table_category")}</th>
              <th>{t("table_email")}</th>
              <th>{t("table_phone_number")}</th>
              <th>{t("table_distance")}</th>
              {data && data.length > 0 && data[0].IsSupplier && (
                <th>{t("table_status")}</th>
              )}
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              return (
                <tr
                  key={row.companyId}
                  onClick={() => handleSelect(row.companyId, row)}
                  style={{
                    height: "50px",
                  }}
                  className={
                    selectedRowsIds.includes(row.companyId)
                      ? "table-row-select"
                      : ""
                  }
                >
                  <td>
                    <input
                      name="company"
                      type="checkbox"
                      checked={selectedRowsIds.includes(row.companyId)}
                      onChange={(e) => handleChange(e, row)}
                    />
                  </td>
                  <td title={row.logo}>
                    <div className="image-box">
                      <img
                        src={row.logo}
                        onError={({ currentTarget }) => {
                          currentTarget.src = images.noImage;
                        }}
                        alt={row.logo}
                        title={row.logo}
                      />
                    </div>
                  </td>
                  <td title={row.companyName}>{row.companyName}</td>
                  <td
                    title={row.categories
                      .map((category) => category.categoryName)
                      .join(", ")}
                  >
                    {" "}
                    {row.categories
                      .map((category) => category.categoryName)
                      .join(", ")}
                  </td>
                  <td title={row.email}>{row.email}</td>
                  <td title={row.phoneNumber}>{row.phoneNumber}</td>
                  <td title={row.distance}>
                    {row.distance ? row.distance : "-"}
                  </td>
                  {row.IsSupplier && (
                    <td
                      title={
                        row.IsSupplier
                          ? t("table_supplier")
                          : t("table_no_supplier")
                      }
                    >
                      {row.IsSupplier
                        ? t("table_supplier")
                        : t("table_no_supplier")}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}

export default withNamespaces()(SelectTable);
