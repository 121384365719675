function MenuIcon({ onClick, color, width, height }) {
    return (
        <svg
            onClick={onClick}
            
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"

            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_dd_418_107)">
                <path
                    fill={color}

                    d="M23 23H43V25.5H23V23ZM23 31.75H36.3333V34.25H23V31.75ZM23 40.5H43V43H23V40.5Z"

                />
                <path
                    stroke={color}

                    d="M23 22.5H22.5V23V25.5V26H23H43H43.5V25.5V23V22.5H43H23ZM23 31.25H22.5V31.75V34.25V34.75H23H36.3333H36.8333V34.25V31.75V31.25H36.3333H23ZM23 40H22.5V40.5V43V43.5H23H43H43.5V43V40.5V40H43H23Z"
                />
            </g>

        </svg>

    )
}

export default MenuIcon;
