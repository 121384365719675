import "../Opportunities/styles/tenderDetailOffer/tenderDetailOffer.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";

import useFetch from "../../hooks/UseFetch";
import useFetchIf from "../../hooks/useFetchIf";

import Loading from "../../components/Loading/Loading";

import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";

import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { toast } from "react-toastify";
import BidOfferCard from "../../components/BidOfferCard/BidOfferCard";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}
function formatDate(dateValue) {
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return [year, month, day].join("-");
}

function OfferEdit({ t }) {
  const { offerId } = useParams();
  const navigate = useNavigate();

  const [tenderId, setTenderId] = useState();

  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();

  const [updatedLoading, setUpdatedLoading] = useState(false);

  const [currency, setCurrency] = useState("TRY"); // default currency
  const [currencySymbol, setCurrencySymbol] = useState("₺"); // default currency symbol
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [offerFormData, setOfferFormData] = useState({
    tenderId: Number(tenderId),
    description: "",
    note: "",
    currency: currency,
    laborPrice: 0,
    materialPrice: 0,
    unitPrice: 0,
    totalPrice: 0,
    shippingPrice: "0",
    deadline: "",
    images: [],
  });

  /* form hata durumları */
  const [offerFormDataErrors, setOfferFormDataErrors] = useState({
    currencyError: false,
    laborPriceError: false,
    materialPriceError: false,
    unitPriceError: false,
    deadlineError: false,
  });

  /* ONAY MODAL */
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = () => {
    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!offerFormData.currency) {
      inputErrors.currencyError = true;
    }

    if (!offerFormData.laborPrice) {
      inputErrors.laborPriceError = true;
    }

    if (!offerFormData.materialPrice) {
      inputErrors.materialPriceError = true;
    }

    if (!offerFormData.unitPrice) {
      inputErrors.unitPriceError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setOfferFormDataErrors(inputErrors);
      alert(t("message_emty_form"));
    } else {
      setOpenUpdateModal(true);
    }
  };
  const handleCloseUpdateModal = () => setOpenUpdateModal(false);

  //Burada routedan aldığım offerId parametresi ile istek yapıyorum
  //dönen değerden aldığım tenderId ile ihale detaylarını veritabanından çekiyorum.
  /* ihale detay isteği */
  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetchIf(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token,
    tenderId
  );

  const {
    setUseFetchRender: setOfferDetailUseFetchRender,
    useFetchRender: offerDetailUseFetchRender,
    error: offerDetailError,
    loading: offerDetailLoading,
    data: offerDetail,
    total: offerDetailTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_DETAIL_URL}?id=${offerId}`,
    token
  );
  useEffect(() => {
    setOfferDetailUseFetchRender(!offerDetailUseFetchRender);
  }, [offerId]);

  useEffect(() => {
    if (offerDetail !== null) {
      setTenderId(offerDetail.tenderId);
      setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);

      setCurrency(offerDetail.currency);
      if (offerDetail.currency === "TRY") {
        setCurrencySymbol("₺");
      } else if (offerDetail.currency === "USD") {
        setCurrencySymbol("$");
      } else if (offerDetail.currency === "EUR") {
        setCurrencySymbol("€");
      }

      setOfferFormData({
        offerId: Number(offerId),
        description: offerDetail.description ? offerDetail.description : "",
        note: offerDetail.note ? offerDetail.note : "",
        currency: offerDetail.currency ? offerDetail.currency : "TRY",
        laborPrice: offerDetail.laborPrice
          ? offerDetail.laborPrice.toLocaleString()
          : 0,
        materialPrice: offerDetail.materialPrice
          ? offerDetail.materialPrice.toLocaleString()
          : 0,
        unitPrice: offerDetail.unitPrice
          ? offerDetail.unitPrice.toLocaleString()
          : 0,
        shippingPrice: offerDetail.shippingPrice
          ? offerDetail.shippingPrice.toLocaleString()
          : "0",
        totalPrice: offerDetail.totalPrice
          ? offerDetail.totalPrice.toLocaleString()
          : 0,
        deadline: offerDetail.deadline ? formatDate(offerDetail.deadline) : "",
        images: [],
      });

      if (offerDetail.images) {
        let filterImg = [];
        let filterDocument = [];

        offerDetail.images.map((img) => {
          if (img.includes("pdf")) {
            filterDocument.push(img);
          } else {
            filterImg.push(img);
          }
        });

        setImages(filterImg.map((img) => ({ id: generateId(), url: img })));
        setDocuments(
          filterDocument.length > 0
            ? filterDocument.map((doc) => ({ id: generateId(), fileName: doc }))
            : []
        );
      }
    }
  }, [offerDetail]);

  async function updateFunc() {
    function formatDateApi(date) {
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      const formattedDate = new Date(date)
        .toLocaleDateString("en-US", options)
        .replace(/\//g, "-");
      const [month, day, year] = formattedDate.split("-");
      const formattedDateString = `${year}-${month}-${day}T00:00:00.000Z`;
      return formattedDateString;
    }

    console.log(offerFormData);

    const offerData = {
      ...offerFormData,
      laborPrice: parseInt(offerFormData.laborPrice.replace(/[,.]/g, "")),
      materialPrice: parseInt(offerFormData.materialPrice.replace(/[,.]/g, "")),
      unitPrice: parseInt(offerFormData.unitPrice.replace(/[,.]/g, "")),
      shippingPrice: parseInt(offerFormData.shippingPrice.replace(/[,.]/g, "")),
      totalPrice: parseInt(offerFormData.totalPrice.replace(/[,.]/g, "")),
      note: offerFormData.note ? offerFormData.note : "string",
      deadline: formatDateApi(offerFormData.deadline),
    };
    console.log("offerData : ", offerData);
    setUpdatedLoading(true);

    const responseBidOffer = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_OFFER_URL,
      JSON.stringify(offerData),
      token
    );

    setUpdatedLoading(false);

    if (
      responseBidOffer &&
      !responseBidOffer.catchError &&
      !responseBidOffer.isError
    ) {
      toast.info(t("message_update_offer_success"), {
        autoClose: 3000,
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }

    handleCloseUpdateModal();
  }

  return (
    <div className="tender-detail-offer-container">
      <div
        className={`opportunities-tender-detail ${
          updatedLoading || openUpdateModal ? "disabled-box" : ""
        }`}
      >
        {tenderDetailError ? (
          <div>Error</div>
        ) : tenderDetailLoading ? (
          <Loading />
        ) : tenderDetail !== null ? (
          <TenderDetailCard tenderDetail={tenderDetail} />
        ) : null}
      </div>

      <div className="tender-detail-offer-form-panel">
        <div className="tender-detail-top">
          {updatedLoading ? (
            <LoadingCircular />
          ) : openUpdateModal ? (
            <ConfirmModal
              confirmFunc={updateFunc}
              cancelButtonText={t("button_edit_cancel")}
              confirmButtonText={t("button_edit_update")}
              handleCloseModal={handleCloseUpdateModal}
              text={"message_update_offer_confirm"}
            />
          ) : (
            <>
              <div className="tender-detail-top-title">
                <i className="fa-solid fa-bahai"></i>
                <h4>{t("update_offer_title")}</h4>
              </div>
              <div className="tender-detail-top-right">
                <WhiteButton
                  icon={<i className="fa-solid fa-floppy-disk"></i>}
                  text={t("button_edit_update")}
                  onClick={handleOpenUpdateModal}
                />
              </div>
            </>
          )}
        </div>

        <div
          className={`bid-offer-form ${
            updatedLoading || openUpdateModal ? "disabled-box" : ""
          }`}
        >
          {offerFormData.laborPrice ? (
            <BidOfferCard
              images={images}
              setImages={setImages}
              documents={documents}
              setDocuments={setDocuments}
              offerFormData={offerFormData}
              setOfferFormData={setOfferFormData}
              offerFormDataErrors={offerFormDataErrors}
              currency={currency}
              setCurrency={setCurrency}
              currencySymbol={currencySymbol}
              setCurrencySymbol={setCurrencySymbol}
              tenderAmount={tenderDetail.amount}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(OfferEdit);
