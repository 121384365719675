import "./styles/tenderDetailManager/tenderDetailManager.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";

import useFetch from "../../hooks/UseFetch";

import StandartTable from "../../components/Table/StandartTable/StandartTable";
import Loading from "../../components/Loading/Loading";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import { toast } from "react-toastify";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";

function TenderDetailManager({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const { token } = AuthContextValue();
  const { deleteMethod, updateMethod } = CrudContextValue();
  const { selectValue, notificationRender } = GetContextValue();

  const [approvedLoading, setApprovedLoading] = useState(false);
  const [revisedLoading, setRevisedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [offerConfirm, setOfferConfirm] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const [openRevisedModal, setOpenRevisedModal] = useState(false);
  const handleOpenRevisedModal = () => setOpenRevisedModal(true);
  const handleCloseRevisedModal = () => setOpenRevisedModal(false);

  const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  const [formattedTime, setFormattedTime] = useState("");

  const [supplierstatus, setSupplierstatus] = useState("");
  const [suppliersPage, setSuppliersPage] = useState(1);
  const [suppliersRowsPerPage, setSuppliersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  const {
    setUseFetchRender: setSuppliersUseFetchRender,
    useFetchRender: suppliersUseFetchRender,
    error: suppliersError,
    loading: suppliersLoading,
    data: suppliers,
    total: suppliersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL}?TenderId=${tenderId}&Last=false&SortType=1&Page=${suppliersPage}&PageSize=${suppliersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "companyName",
      header: t("table_supplier"),
    },
    {
      key: "city",
      header: t("table_city"),
    },
    {
      key: "distance",
      header: t("table_distance"),
    },
    {
      key: "averageScore",
      header: t("table_score"),
    },
  ];

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }, [notificationRender]);

  function hanldeClickLink(link, aa) {
    console.log(link, aa);
    // setSupplierstatus(link);
    // setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }

  useEffect(() => {
    /* tenderdetailde revize açıklaması gelmiyor sayfa yenilenirse selectValue sıfırlanıyor ve not görünmüyor */
    if (tenderDetail) {
      setRevisedNote(tenderDetail.confirmDescription);
    }
  }, [tenderDetail]);

  async function confirmFunction() {
    if (offerConfirm === "confirm") {
      setApprovedLoading(true);

      const response = await updateMethod(
        `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${tenderId}&status=Onaylandı&confirmDescription=$Onaylandı`,
        null,
        token
      );

      if (response) {
        setApprovedLoading(false);

        handleCloseConfirmModal();
        setTenderDetailUseFetchRender(!suppliersUseFetchRender);

        toast.info(t("message_create_tender_success"), {
          autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
        });

        setTimeout(() => {
          navigate("/tenders");
        }, 3000);
      }

      setApprovedLoading(false);
    } else if (offerConfirm === "delete") {
      console.log(selectValue, " ihale silindi!");
      setDeleteLoading(true);

      const response = await deleteMethod(
        `${process.env.REACT_APP_DELETE_TENDER_URL}?id=${tenderId}`,
        token
      );

      if (response) {
        setDeleteLoading(false);

        handleCloseConfirmModal();

        toast.info(t("message_create_tender_success"), {
          autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
        });

        setTimeout(() => {
          navigate("/tenders");
        }, 3000);
      }
      setDeleteLoading(false);
    }
  }

  async function handleRevisedTender(revisedDescription) {
    console.log(
      `${tenderId} idli İhale Revize edildi \n Revize açıklaması: ${revisedDescription}`
    );

    setRevisedLoading(true);

    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_STATUS_URL}?tenderId=${tenderId}&status=Revize&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      setTenderDetailUseFetchRender(!suppliersUseFetchRender);
      handleCloseRevisedModal();

      toast.info(t("message_create_tender_success"), {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/tenders");
      }, 3000);
    }

    setRevisedLoading(false);
  }


  return (
    <div className="manager-tender-detail-container">
      <div className="manager-tender-detail-container-top">
        {approvedLoading || revisedLoading || deleteLoading ? (
          <LoadingCircular />
        ) : openConfirmModal ? (
          <ConfirmModal
            confirmFunc={confirmFunction}
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={
              offerConfirm === "confirm"
                ? t("button_edit_confirm")
                : offerConfirm === "delete"
                ? t("button_edit_delete")
                : ""
            }
            handleCloseModal={handleCloseConfirmModal}
            text={
              offerConfirm === "confirm"
                ? t("message_create_tender")
                : offerConfirm === "delete"
                ? t("message_delete_tender")
                : ""
            }
          />
        ) : openRevisedModal ? (
          <RevisedModal
            confirmText={revisedLoading ? t("loading") : t("button_revised")}
            cancelText={t("button_edit_cancel")}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedTender}
          />
        ) : (
          <div className="top-box">
            <div className="tender-detail-title">
              <i className="fa-solid fa-ticket-simple"></i>
              <h4>{t("button_edit_tender_detail")}</h4>
            </div>

            <div className="tender-edit-buttons">
              {tenderDetail && Number(tenderDetail.status) === 3 ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-note-sticky"></i>}
                    tooltip={t("button_edit_revised_note")}
                    onClick={() => {
                      setOfferConfirm("revised-note");
                      handleOpenRevisedNote();
                    }}
                    // disabled={selectValueId || selectValue ? false : true}
                  />

                  <Link to={`/tenders/tender-edit/${tenderId}`}>
                    <WhiteButton
                      icon={<i className="fa-solid fa-pen-to-square"></i>}
                      tooltip={t("button_edit_update")}
                      // disabled={selectValueId || selectValue ? false : true}
                    />
                  </Link>

                  <WhiteButton
                    icon={<i className="fa-solid fa-trash-can"></i>}
                    tooltip={t("button_edit_delete")}
                    onClick={() => {
                      setOfferConfirm("delete");
                      handleOpenConfirmModal();
                    }}
                    // disabled={selectValueId || selectValue ? false : true}
                  />
                </>
              ) : (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-arrow-rotate-left"></i>}
                    tooltip={t("button_edit_render")}
                    onClick={() => {
                      setSuppliersUseFetchRender(!suppliersUseFetchRender);
                      setTenderDetailUseFetchRender(
                        !tenderDetailUseFetchRender
                      );
                    }}
                    className={"button-white-active"}
                  />
                  <WhiteButton
                    icon={<i className="fa-solid fa-trash"></i>}
                    tooltip={t("button_edit_delete")}
                    onClick={() => {
                      setOfferConfirm("delete");
                      handleOpenConfirmModal();
                    }}
                  />

                  <WhiteButton
                    icon={<i className="fa-solid fa-arrows-rotate"></i>}
                    tooltip={t("button_revised_request")}
                    onClick={() => {
                      setOfferConfirm("revised");
                      handleOpenRevisedModal();
                    }}
                  />

                  <WhiteButton
                    icon={<i className="fa-solid fa-check"></i>}
                    tooltip={t("button_edit_confirm")}
                    onClick={() => {
                      setOfferConfirm("confirm");
                      handleOpenConfirmModal();
                    }}
                  />
                </>
              )}
            </div>
            {openRevisedNote && (
              <RevisedDescriptionModal
                revisedNote={revisedNote}
                openRevisedNote={openRevisedNote}
                handleCloseRevisedNote={handleCloseRevisedNote}
              />
            )}
          </div>
        )}
      </div>

      <div
        className={`manager-tender-detail-container-bottom ${
          approvedLoading ||
          deleteLoading ||
          revisedLoading ||
          openConfirmModal ||
          openRevisedModal
            ? "disabled-box"
            : ""
        }`}
      >
        <div className="manager-tender-detail">
          {tenderDetailError ? (
            <ErrorComponent error={tenderDetailError} />
          ) : tenderDetailLoading ? (
            <Loading />
          ) : tenderDetail !== null ? (
            <TenderDetailCard tenderDetail={tenderDetail} manager={true} />
          ) : null}
        </div>

        <div className="manager-tender-detail-table">
          {suppliersError ? (
            <ErrorComponent error={suppliersError} />
          ) : suppliersLoading ? (
            <Loading />
          ) : suppliers !== null ? (
            <StandartTable
              type="ofCompany"
              // searchText={searchText}
              // setSearchText={setSearchText}
              selectValueId={null}
              selectValue={null}
              handleClickValue={hanldeClickLink}
              useFetchRender={suppliersUseFetchRender}
              setUseFetchRender={setSuppliersUseFetchRender}
              total={suppliersTotal}
              page={suppliersPage}
              setPage={setSuppliersPage}
              rowsPerPage={suppliersRowsPerPage}
              setRowsPerPage={setSuppliersRowsPerPage}
              columns={columns}
              data={suppliers}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderDetailManager);