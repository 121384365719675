import "./loadingCircular.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 44, borderWidth: "10px" }} />
);
function LoadingCircular() {
  return (
    <div className="loading-circular-container">
      {/* <div className="circular-progress"></div> */}
      <Spin indicator={antIcon} />
    </div>
  );
}

export default LoadingCircular;
