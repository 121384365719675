import React from "react";
import { withNamespaces } from "react-i18next";

import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

function Page404({t}) {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("message_error_404")}
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          {t("button_back_home")}
        </Button>
      }
    />
  );
}

export default withNamespaces()(Page404);
