import "./errorComponent.css";

import { withNamespaces } from "react-i18next";

const ErrorComponent = ({ t, error }) => {
  return (
    <div className="error-box-container">
      {error?.code ? (
        <>
          <div className="error-title">
            <h5>{t("message_error_code")}</h5>
            <p>{error.code}</p>
          </div>
          <div className="error-message">
            <h5>{t("message")}</h5>
            <code>{JSON.stringify(error.message)}</code>
          </div>
        </>
      ) : (
        <>
          <div className="error-message">
            <h5>{t("message")}</h5>
            <code>{error}</code>
          </div>
        </>
      )}
    </div>
  );
};

export default withNamespaces()(ErrorComponent);
