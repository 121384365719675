import "./styles/Layout/layout.css";

import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import BreadCrumbs from "./components/BreadCrumbs/BreadCrumbs";
import { AuthContextValue } from "./context/AuthContext";
import Loading from "./components/Loading/Loading";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";

function Layout({ t }) {
  const navigate = useNavigate();
  const { token } = AuthContextValue();

  const [openSideBar, setOpenSideBar] = useState(false);

  /* token yoksa login sayfasına yönlendirir */
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!token) {
      setLoading(true);

      navigate("/login");

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [token, navigate]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setOpenSideBar(true);
      } else {
        setOpenSideBar(false);
      }
    };

    const handleOutsideClick = (e) => {
      const target = e.target;
      const navbar = document.querySelector(".navbar");
      const sidebar = document.querySelector(".sidebar");

      if (
        navbar &&
        !navbar.contains(target) &&
        sidebar &&
        !sidebar.contains(target)
      ) {
        if (window.innerWidth < 768) {
          setOpenSideBar(true);
        }
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={`layout-container`}>
          <div className={`navbar`}>
            <Navbar openSideBar={openSideBar} setOpenSideBar={setOpenSideBar} />
          </div>

          <div className={`contents-container`}>
            <div
              className={`sidebar ${
                openSideBar ? "open-sidebar" : "close-sidebar"
              }`}
            >
              <Sidebar openSideBar={openSideBar} />
            </div>

            <div className="main">
              <div className="main-container">
                {/* Sayfalar burda görünecek */}
                <Outlet />
              </div>

              <div className="breadcrumbs">
                <BreadCrumbs />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withNamespaces()(Layout);
