import { Tooltip } from "antd";
import "./select.css";

import { withNamespaces } from "react-i18next";

function Select({
  t,

  name,
  options,

  select,
  setSelect,
}) {
  function handleChange(e) {
    setSelect(e.target.value);
    console.log("filterTable : ", e.target.value);
  }

  return (
    <Tooltip placement="top" title={t("select_options_filtered")}>
      <select
        name={name}
        id={name}
        value={select}
        onChange={handleChange}
        className="select-filter-sort"
      >
        {options.map((option) => {
          return (
            <option key={option.key} value={option.key}>
              {option.text}
            </option>
          );
        })}
      </select>
    </Tooltip>
  );
}

export default withNamespaces()(Select);
