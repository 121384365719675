import "./styles/editBox.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import { CrudContextValue } from "../../context/CrudContext";
import { AuthContextValue } from "../../context/AuthContext";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";

function CompanyEditBox({
  t,

  type,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */

  const { token } = AuthContextValue();
  const { deleteMethod } = CrudContextValue();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* SEARCH-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "add":
        console.log("Ekle ye tıklandı!");

        setSearchOpen(false);

        break;

      case "edit":
        console.log("Edit e tıklandı!");
        console.log("düzenlenicek value : ", selectValue);

        setSearchOpen(false);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        setSearchOpen(false);

        handleOpenDeleteModal();

        break;

      default:
        break;
    }
  }

  async function handleDelete() {

    console.log(selectValue, " firma silindi!");

    await deleteMethod(
      `${process.env.REACT_APP_DELETE_COMPANY_URL}?id=${selectValueId}`,
      token
    );

    setUseFetchRender(!useFetchRender);

    handleCloseDeleteModal();
  }

  return (
    <div className="edit-container">
      <div className="edit-box">
        <div className="edit-container-title">
          <div className="title">
            <i className="fa-solid fa-industry"></i>
            <h4 className="title">{t("companylist_title")}</h4>
          </div>
        </div>
        <div className="edit-container-button-group">
          <WhiteButton
            icon={<i className="fa-solid fa-arrow-rotate-right" />}
            tooltip={t("button_edit_render")}
            onClick={() => handleClick("render")}
            className="button-white-active"
          />

          <WhiteButton
            icon={
              isSearchOpen ? (
                <div ref={searchRef} className="search-container">
                  <i className="fa-solid fa-magnifying-glass" />
                  <input
                    type="text"
                    placeholder={t("button_edit_search")}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              ) : (
                <i className="fa-solid fa-magnifying-glass" />
              )
            }
            tooltip={t("button_edit_search")}
            onClick={() => handleClick("search")}
          />

          <Link
            to={
              selectValueId || selectValue
                ? `company-profile/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={t("button_edit_company_profile")}
              onClick={() => handleClick("profile")}
              disabled={selectValueId || selectValue ? false : true}
              className={
                selectValueId || selectValue
                  ? "button-white-active"
                  : "button-white-disabled"
              }
            />
          </Link>

          <Link
            to={
              selectValueId || selectValue
                ? `company-edit/${selectValueId}`
                : false
            }
          >
            <WhiteButton
              icon={<i className="fa-solid fa-pen-to-square"></i>}
              tooltip={t("button_edit_update")}
              onClick={() => handleClick("edit")}
              disabled={selectValueId || selectValue ? false : true}
            />
          </Link>

          <WhiteButton
            icon={<i className="fa-solid fa-trash-can"></i>}
            tooltip={t("button_edit_delete")}
            onClick={() => handleClick("delete")}
            disabled={selectValueId || selectValue ? false : true}
            className={
              selectValueId || selectValue
                ? "button-white-active"
                : "button-white-disabled"
            }
          />

          <Link to={`company-add`}>
            <WhiteButton
              icon={<i className="fa-solid fa-plus" />}
              tooltip={t("button_edit_add")}
              onClick={() => handleClick("add")}
              className="button-white-active"
            />
          </Link>
        </div>

        {deleteModalOpen && (
          <DeleteModal
            text={t("message_delete_company")}
            handleCloseModal={handleCloseDeleteModal}
            handleDelete={handleDelete}
          />
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(CompanyEditBox);
