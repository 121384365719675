import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import axios from "axios";

import Select from "react-select";
import InputStandart from "../form_components/Inputs/InputStandart/InputStandart";
import UploadImageBox from "../UploadImageBox/UploadImageBox";
import DocumentUploader from "../DocumanUploder/DocumanUploader";
import InputPrice from "../form_components/Inputs/InputPrice/InputPrice";
import { GetContextValue } from "../../context/GetContext";
import { Badge, Button, DatePicker, Popconfirm, Switch } from "antd";
import AddPropertyFormModal from "../Modals/AddPropertyFormModal/AddPropertyFormModal";
import { AuthContextValue } from "../../context/AuthContext";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import TenderFormAddDetailModal from "../Modals/TenderFormAddDetailModal/TenderFormAddDetailModal";

function TenderForm({
  t,
  stepOneForm,
  setStepOneForm,
  documents,
  setDocuments,
  images,
  setImages,
  stepOneFormErrors,
  selectedCategories,
  setSelectedCategories,
  properties,
  setProperties,
}) {
  const { token } = AuthContextValue();
  const { categoryList } = GetContextValue();

  const [addPropertModal, setAddPropertModal] = useState(false);

  const showModal = () => {
    setAddPropertModal(true);
  };

  const handleCancel = () => {
    setAddPropertModal(false);
  };

  const handleDeleteProperty = async (property) => {
    /* Veritabanından ve localden siler */
    if (property.propertyId) {
      const updatedProperties = properties.filter(
        (prop) => prop.propertyId !== property.propertyId
      );
      setProperties(updatedProperties);
      try {
        console.log(
          process.env.REACT_APP_DELETE_TENDER_PROPERTIES_URL,
          JSON.stringify([property.propertyId])
        );
        await axios.delete(process.env.REACT_APP_DELETE_TENDER_PROPERTIES_URL, {
          data: JSON.stringify([property.propertyId]),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
            withCredentials: true,
          },
        });
      } catch (error) {
        console.log(
          "process.env.REACT_APP_DELETE_TENDER_PROPERTIES_URL catchError : ",
          error
        );
      }
    } else {
      /*Localden siler */
      const updatedProperties = properties.filter(
        (prop) => prop.propertyId !== property.propertyId
      );
      setProperties(updatedProperties);
    }
  };

  const handleChangeValue = (id, newValue) => {
    const updatedProperties = properties.map((property) => {
      if (property.id === id) {
        return { ...property, value: newValue };
      }
      return property;
    });
    setProperties(updatedProperties);
  };

  const handleAddProperty = (values) => {
    console.log(values);
    setProperties([...properties, values]);
    setStepOneForm({
      ...stepOneForm,
      properties: [...properties, values],
    });
    setAddPropertModal(false);
  };

  const handleChangeCategory = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
    setStepOneForm({
      ...stepOneForm,
      categoryId: selectedOptions.map((category) => ({
        categoryId: category.value,
        categoryName: category.label,
      })),
    });
  };

  const categoryOptions = categoryList.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));

  const unitTypeOptions = [
    { label: t("select_piece"), value: 1 },
    { label: t("select_ton"), value: 2 },
    { label: t("select_kg"), value: 3 },
    { label: t("select_g"), value: 4 },
    { label: t("select_meter"), value: 5 },
    { label: t("select_centimeter"), value: 6 },
    { label: t("select_milimeter"), value: 7 },
    { label: t("select_liter"), value: 8 },
    { label: t("select_inch"), value: 9 },
  ];

  const [currency, setCurrency] = useState("TRY"); // default currency
  const [currencySymbol, setCurrencySymbol] = useState("₺"); // default currency symbol

  const handleCurrencyChange = (e) => {
    const { value } = e.target;

    setCurrency(value);
    setStepOneForm({
      ...stepOneForm,
      currency: value,
    });

    if (value === "TRY") {
      setCurrencySymbol("₺");
    } else if (value === "USD") {
      setCurrencySymbol("$");
    } else if (value === "EUR") {
      setCurrencySymbol("€");
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "minPrice" || name === "maxPrice") {
      const formattedVal = value.replace(/[^\d]/g, ""); // Sadece sayı karakterlerini al
      const numVal = Number(formattedVal).toLocaleString();

      setStepOneForm({
        ...stepOneForm,
        [name]: numVal,
      });
    } else {
      setStepOneForm({
        ...stepOneForm,
        [name]: value,
      });
    }
  }

  return (
    <div className="step-one-form-container">
      <div className="input-standart">
        <label htmlFor="category">* {t("table_category")} : </label>
        <Select
          required
          isMulti
          options={categoryOptions}
          value={selectedCategories}
          onChange={handleChangeCategory}
          name="category"
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={`${t("table_category")}...`}
          menuPlacement="bottom"
          menuPosition="fixed"
          menuShouldScrollIntoView={true}
          styles={{
            menu: (base) => ({
              ...base,
              height: "300px",
              border: stepOneFormErrors.categoryIdError ? "2px solid red" : "",
            }),
          }}
        />
      </div>

      <InputStandart
        required={true}
        errorBorder={stepOneFormErrors.productNameError}
        type={"text"}
        title={t("table_product")}
        name={"productName"}
        placeholder={`${t("table_product")}...`}
        onChange={handleChange}
        value={stepOneForm.productName}
      />

      <div className="input-standart">
        <label htmlFor={"description"} style={{ textIndent: "7px" }}>
          {t("table_description")} :{" "}
        </label>
        <textarea
          name="description"
          id="description"
          cols="30"
          rows="2"
          placeholder={`${t("table_description")}...`}
          onChange={handleChange}
          value={stepOneForm.description}
        />
      </div>

      {properties &&
        properties.map((property) => (
          <div
            className="input-standart"
            key={property.id ? property.id : property.propertyId}
          >
            <label>{property.name} : </label>
            <input
              type="text"
              value={property.value}
              onChange={(e) => handleChangeValue(property.id, e.target.value)}
            />
            <Popconfirm
              title={t("message_confirm_delete_property")}
              onConfirm={() => handleDeleteProperty(property)}
              okText={t("button_edit_confirm")}
              cancelText={t("button_edit_cancel")}
            >
              <Badge count={<div>x</div>} style={{ cursor: "pointer" }} />
            </Popconfirm>
          </div>
        ))}

      <div className="input-standart">
        <Button onClick={showModal}>{t("new_property_add_title")}</Button>
        <AddPropertyFormModal
          visible={addPropertModal}
          onCancel={handleCancel}
          onAddProperty={handleAddProperty}
        />
      </div>

      <div className="input-two">
        <div className="input-standart">
          <label htmlFor={"amount"}>* {t("table_amount")} : </label>
          <div className="input-select-box">
            <input
              required
              style={{
                border: stepOneFormErrors.amountError ? "2px solid red" : "",
              }}
              type={"number"}
              title={t("table_amount")}
              name={"amount"}
              placeholder={`${t("table_amount")}...`}
              onChange={handleChange}
              value={stepOneForm.amount}
            />

            <select
              name="unitType"
              style={{
                border: stepOneFormErrors.unitTypeError ? "2px solid red" : "",
              }}
              value={stepOneForm.unitType}
              onChange={handleChange}
            >
              {unitTypeOptions.map((unitType) => {
                return (
                  <option key={unitType.value} value={unitType.value}>
                    {unitType.label}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="input-standart">
        <label> {t("input_recursive")} : </label>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={stepOneForm.recursive}
          onChange={() => {
            setStepOneForm({
              ...stepOneForm,
              recursive: !stepOneForm.recursive,
              details: {
                frequency: 1,
                startDate: "",
                finishDate: "",
                quantityDistribution: [],
              },
            });
          }}
        />
      </div>
      {stepOneForm.recursive && (
        <TenderFormAddDetailModal
          stepOneForm={stepOneForm}
          setStepOneForm={setStepOneForm}
        />
      )}

      <div className="input-standart">
        <label>* {t("select_currency")} : </label>
        <select
          required
          value={currency}
          onChange={handleCurrencyChange}
          style={{
            border: stepOneFormErrors.currencyError ? "2px solid red" : "",
          }}
        >
          <option value="TRY">TRY</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select>
      </div>

      <InputPrice
        required={true}
        errorBorder={stepOneFormErrors.minPriceError}
        type={"text"}
        title={t("table_min_price")}
        name={"minPrice"}
        placeholder={`${t("table_min_price")}...`}
        currencySymbol={currencySymbol}
        onChange={handleChange}
        value={stepOneForm.minPrice}
      />

      <InputPrice
        required={true}
        errorBorder={stepOneFormErrors.maxPriceError}
        type={"text"}
        title={t("table_max_price")}
        name={"maxPrice"}
        placeholder={`${t("table_max_price")}...`}
        currencySymbol={currencySymbol}
        onChange={handleChange}
        value={stepOneForm.maxPrice}
      />

      <InputStandart
        required={true}
        errorBorder={stepOneFormErrors.expireDateError}
        type={"date"}
        title={t("table_expire_date")}
        name={"expireDate"}
        placeholder={`${t("table_expire_date")}...`}
        onChange={handleChange}
        value={stepOneForm.expireDate}
      />

      {!stepOneForm.recursive && (
        <InputStandart
          // required={true}
          errorBorder={stepOneFormErrors.deadlineError}
          type={"date"}
          title={t("table_deadline")}
          name={"deadline"}
          placeholder={`${t("table_deadline")}...`}
          onChange={handleChange}
          value={stepOneForm.deadline}
        />
      )}

      <div className="input-standart documan">
        <label style={{ textIndent: "7px" }}>{t("input_photo")} : </label>
        <UploadImageBox
          images={images}
          setImages={setImages}
          formData={stepOneForm}
          setFormData={setStepOneForm}
        />
      </div>

      <div className="input-standart documan">
        <label style={{ textIndent: "7px" }}>{t("input_documents")} : </label>
        <DocumentUploader
          documents={documents}
          setDocuments={setDocuments}
          formData={stepOneForm}
          setFormData={setStepOneForm}
        />
      </div>

      <InputStandart
        labelClass="note-indent"
        type={"text"}
        title={t("input_note")}
        name={"note"}
        placeholder={`${t("input_note")}...`}
        onChange={handleChange}
        value={stepOneForm.note}
      />
    </div>
  );
}

export default withNamespaces()(TenderForm);
