import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import Select from "../form_components/Select/Select";

function AllOffersEditBox({
  t,

  searchText,
  setSearchText,

  tableFilter,
  setTableFilter,

  useFetchRender,
  setUseFetchRender,

  selectValue,
  selectValueId,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* SEARCH-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      default:
        break;
    }
  }

  const selectOptions = [
    { key: 1, text: t("select_options_increased_price") },
    { key: 2, text: t("select_options_decreasing_price") },
    { key: 3, text: t("select_options_new_to_old") },
    { key: 4, text: t("select_options_old_to_new") },
  ];

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <div className="title">
              <i className="fa-solid fa-star" />
              <h4 className="title">{t("offers_title")}</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={t("button_edit_render")}
              onClick={() => handleClick("render")}
            />

            <WhiteButton
              icon={<i className="fa-solid fa-magnifying-glass" />}
              text={
                isSearchOpen ? (
                  <div ref={searchRef} className="search-container">
                    <input
                      type="text"
                      placeholder={t("button_edit_search")}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                ) : null
              }
              onClick={() => handleClick("search")}
            />

            <Select
              name="filter"
              options={selectOptions}
              select={tableFilter}
              setSelect={setTableFilter}
              className="select-filter-sort"
            />

            <Link
              to={
                selectValueId || selectValue
                  ? `offer-detail/${selectValueId}`
                  : false
              }
            >
              <WhiteButton
                icon={<i className="fa-solid fa-info" />}
                tooltip={t("button_edit_detail")}
                disabled={selectValueId || selectValue ? false : true}
              />
            </Link>
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(AllOffersEditBox);
