import "./styles/mainCompanies.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { GetContextValue } from "../../context/GetContext";
import MainCompaniesEditBox from "../../components/EditBox/MainCompaniesEditBox";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { CrudContextValue } from "../../context/CrudContext";
import axios from "axios";
import { Button, Modal } from "antd";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function MainCompanies({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
    companyDetail,
  } = GetContextValue();
  const { deleteMethod, createMethod } = CrudContextValue();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [addMainCompanyModalOpen, setAddMainCompanyModalOpen] = useState(false);
  const handleOpenAddMainCompanyModal = () => setAddMainCompanyModalOpen(true);
  const handleCloseAddMainCompanyModal = () =>
    setAddMainCompanyModalOpen(false);
  const [addMainCompanyLoading, setAddMainCompanyLoading] = useState(false);

  const [mainCompanyStatus, setMainCompanyStatus] = useState("Ana Firmalarım");
  // tablo filtreleme
  // 1 = onay beklyen
  // 2 = tedarikçilerim
  // 3 = reddedilen
  const [tableFilter, setTableFilter] = useState(2);
  const [searchText, setSearchText] = useState("");

  const [recommended, setRecommended] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(false);
  const [recommendedError, setRecommendedError] = useState(null);
  const [recommendedRender, setRecommendedRender] = useState(false);

  const [mainCompaniesPage, setMainCompaniesPage] = useState(1);
  const [mainCompaniesRowsPerPage, setMainCompaniesRowsPerPage] = useState(10);
  const [mainCompaniesTotal, setMainCompaniesTotal] = useState(0);
  const [mainCompanies, setMainCompanies] = useState([]);
  const [mainCompaniesLoading, setMainCompaniesLoading] = useState(false);
  const [mainCompaniesError, setMainCompaniesError] = useState(null);
  const [mainCompaniesRender, setMainCompaniesRender] = useState(false);

  useEffect(() => {
    if (loginUserCompanyId) {
      const postDataMyMainComp = {
        companyId: loginUserCompanyId,
        status: tableFilter,
        searchedText: searchText,
        companyCategoryIds: [],
        page: mainCompaniesPage,
        pageSize: mainCompaniesRowsPerPage,
      };

      async function getMainCompanies() {
        setMainCompaniesLoading(true);
        try {
          const responseMainComp = await axios.post(
            process.env.REACT_APP_GET_MY_MAIN_COMPANY_LIST_URL,
            JSON.stringify(postDataMyMainComp),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("deme:", responseMainComp);
          if (responseMainComp.data.isError) {
            // console.log(
            //   "REACT_APP_GET_MY_SUPPLIER_URL responseMainComp.data.isError : ",
            //   responseMainComp.data.errorMessageList[0]
            // );
            if (
              responseMainComp.data.errorMessageList[0] !== "Sonuç bulunamadı"
            ) {
              setMainCompaniesError(responseMainComp.data.isError);
            }
          } else {
            // console.log("responseMainCompSupp : ", responseMainComp);
            setMainCompanies(responseMainComp.data.data);
          }

          if (responseMainComp.data.total > 0) {
            setMainCompaniesTotal(responseMainComp.data.total);
          }
        } catch (error) {
          console.log(
            "REACT_APP_GET_MY_MAIN_COMPANY_LIST_URL, catchError :",
            error
          );
          setMainCompaniesError(error);
        }
        setMainCompaniesLoading(false);
      }

      if (token && loginUserCompanyId) {
        getMainCompanies();
      }
    }
  }, [
    loginUserCompanyId,
    notificationRender,
    mainCompaniesRender,
    searchText,
    tableFilter,
  ]);

  useEffect(() => {
    if (loginUserCompanyId && companyDetail.categories) {
      const companyCategoryIds = companyDetail?.categories?.map((cate) =>
        Number(cate.categoryId)
      );
      const postData = {
        categoryIds: companyCategoryIds,
        companyId: loginUserCompanyId,
      };

      async function getData() {
        setRecommendedLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_POST_RECOMENDED_MAINCOMPANIES_URL,
            JSON.stringify(postData),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            console.log(
              "REACT_APP_POST_RECOMENDED_MAINCOMPANIES_URL responseSupp.data.isError : ",
              responseSupp.data.errorMessageList[0]
            );
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setRecommendedError(responseSupp.data.isError);
            }
          } else {
            console.log("responseSuppSupp : ", responseSupp);
            setRecommended(responseSupp.data.data);
          }
        } catch (error) {
          console.log(
            "REACT_APP_POST_RECOMENDED_MAINCOMPANIES_URL, catchError :",
            error
          );
          setRecommendedError(error);
        }
        setRecommendedLoading(false);
      }

      if (token && loginUserCompanyId) {
        getData();
      }
    }
  }, [
    loginUserCompanyId,
    notificationRender,
    recommendedRender,
    companyDetail,
  ]);

  const columns = [
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },

    mainCompanyStatus === "Tavsiye Edilenler"
      ? {
          key: "status",
          header: t("table_status"),
        }
      : {
          key: "distance",
          header: t("table_distance"),
        },
    {
      key: "addresses",
      header: t("table_address"),
    },
  ];

  useEffect(() => {
    setMainCompaniesRender(!mainCompaniesRender);
  }, [notificationRender]);

  useEffect(() => {
    if (mainCompanyStatus === "Ana Firmalarım") {
      setTableFilter(2);
    } else if (mainCompanyStatus === "Onay Bekleyenler") {
      setTableFilter(1);
    } else if (mainCompanyStatus === "Reddedilen") {
      setTableFilter(3);
    }
  }, [mainCompanyStatus]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function deleteMainCompany() {
    console.log(`companyId=${selectValueId}&supplierId=${loginUserCompanyId}`);
    await deleteMethod(
      `${process.env.REACT_APP_DELETE_SUPPLIER_URL}?companyId=${selectValueId}&supplierId=${loginUserCompanyId}`,
      token
    );
    setMainCompaniesRender(!mainCompaniesRender);
    setRecommendedRender(!recommendedRender);
    handleCloseDeleteModal();
  }

  async function handleAddMainCompany() {
    setAddMainCompanyLoading(true);

    const newSupplier = {
      mainCompanyId: selectValueId,
    };

    await createMethod(
      process.env.REACT_APP_POST_SEND_SUPPLIER_REQUEST_URL,
      JSON.stringify(newSupplier),
      token
    );

    setAddMainCompanyLoading(false);

    handleCloseAddMainCompanyModal();

    setRecommendedRender(!recommendedRender);
  }

  return (
    <div className="maincompanies-container">
      <div className="maincompanies-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {deleteModalOpen ? (
          <ConfirmModal
            handleCloseModal={handleCloseDeleteModal}
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelButtonText={t("button_edit_cancel")}
            confirmIcon={<i className="fa-solid fa-trash-can"></i>}
            confirmButtonText={t("button_edit_delete")}
            text={t("message_delete_maincompany")}
            confirmFunc={deleteMainCompany}
          />
        ) : (
          <MainCompaniesEditBox
            mainCompanyStatus={mainCompanyStatus}
            searchText={searchText}
            setSearchText={setSearchText}
            useFetchRender={mainCompaniesRender}
            setUseFetchRender={setMainCompaniesRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenAddMainCompanyModal={handleOpenAddMainCompanyModal}
          />
        )}
      </div>

      <div className="maincompanies-page-link">
        <div className="opportunities-button-groups">
          <ButtonText
            icon={<i className="fa-solid fa-table-cells-large"></i>}
            text={t("my_maincompanies_title")}
            onClick={() => setMainCompanyStatus("Ana Firmalarım")}
            className={
              mainCompanyStatus === "Ana Firmalarım" ? "button-active" : ""
            }
          />
          <ButtonText
            icon={<i className="fa-solid fa-clock-rotate-left"></i>}
            text={t("button_confirmation_waiting")}
            onClick={() => setMainCompanyStatus("Onay Bekleyenler")}
            className={
              mainCompanyStatus === "Onay Bekleyenler" ? "button-active" : ""
            }
          />
          <ButtonText
            icon={<i className="fa-solid fa-circle-exclamation"></i>}
            text={t("table_edit_reject")}
            onClick={() => setMainCompanyStatus("Reddedilen")}
            className={
              mainCompanyStatus === "Reddedilen" ? "button-active" : ""
            }
          />

          <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={t("button_recommended")}
            onClick={() => setMainCompanyStatus("Tavsiye Edilenler")}
            className={
              mainCompanyStatus === "Tavsiye Edilenler" ? "button-active" : ""
            }
          />
         
          {addMainCompanyModalOpen && (
            <Modal
              open={addMainCompanyModalOpen}
              onCancel={handleCloseAddMainCompanyModal}
              title={t("add_main_company_title")}
              children={
                <div className="confirm-modal-container">
                  <p>
                    {`${selectValue.companyName} ${t(
                      "message_confirm_add_maincompany"
                    )}`}
                  </p>
                  <div className="modal-actions-button">
                    <Button type="primary" onClick={handleAddMainCompany}>
                      {t("button_edit_confirm")}
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleCloseAddMainCompanyModal}
                    >
                      {t("button_edit_cancel")}
                    </Button>
                  </div>
                </div>
              }
              footer={null}
            />
          )}
        </div>
      </div>

      <div className="maincompanies-table">
        {mainCompanyStatus === "Ana Firmalarım" ||
        mainCompanyStatus === "Onay Bekleyenler" ||
        mainCompanyStatus === "Reddedilen" ? (
          <>
            {mainCompaniesError ? (
              <ErrorComponent error={mainCompaniesError} />
            ) : mainCompaniesLoading ? (
              <Loading />
            ) : mainCompanies !== null ? (
              <StandartTable
                type="company"
                searchText={searchText}
                setSearchText={setSearchText}
                selectValueId={selectValueId}
                selectValue={selectValue}
                handleClickValue={handleClickValue}
                useFetchRender={mainCompaniesRender}
                setUseFetchRender={setMainCompaniesRender}
                total={mainCompaniesTotal}
                page={mainCompaniesPage}
                setPage={setMainCompaniesPage}
                rowsPerPage={mainCompaniesRowsPerPage}
                setRowsPerPage={setMainCompaniesRowsPerPage}
                columns={columns}
                data={mainCompanies}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            )}
          </>
        ) : (
          <>
            {recommendedError ? (
              <ErrorComponent error={recommendedError} />
            ) : recommendedLoading ? (
              <Loading />
            ) : recommended !== null ? (
              <StandartTable
                type="company"
                searchText={searchText}
                setSearchText={setSearchText}
                selectValueId={selectValueId}
                selectValue={selectValue}
                handleClickValue={handleClickValue}
                useFetchRender={recommendedRender}
                setUseFetchRender={setRecommendedRender}
                total={null}
                page={null}
                setPage={null}
                rowsPerPage={null}
                setRowsPerPage={null}
                columns={columns}
                data={recommended}
              />
            ) : mainCompanyStatus === "Tavsiye Edilenler" ? (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            ) : null}
          </>
        )}
      </div>
      {addMainCompanyLoading && <LoadingCircular />}
    </div>
  );
}

export default withNamespaces()(MainCompanies);
