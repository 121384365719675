import "./styles/howItWorkStyle/howItWork.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import { withNamespaces } from "react-i18next";


import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import InputSearch from "../../components/form_components/Inputs/InputSearch/InputSearch";
import VideosPage from "./VideosPage";
import DocumentsPage from "./DocumentsPage";

function HowItWork({t}) {
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];

  const [searchBar, setSearchBar] = useState("");

  const [howItWorkPageLink, setHowItWorkPageLink] = useState("videos");

  function handleClickLink(link) {
    setHowItWorkPageLink(link);
  }


  return (
    <div className="how-it-work-container">
      <div className="howitwork-title-container">
        <div className="title-box">
          <i className="fa-solid fa-question" />
          <h4 className="title">
            {t("howitwork_title")} {""}
            {page === "offers"
              ? t("howitwork_title")
              : page === "opportunities"
              ? t("howitwork_opportunities_page_transactions")
              : page === "suppliers"
              ? t("howitwork_supplier_page_transactions")
              : page === "users"
              ? t("howitwork_users_page_transactions")
              : page === "tenders"
              ? t("howitwork_tender_page_transactions")
              : page === "company-list"
              ? t("howitwork_companyinfo_page_transactions")
              : page === "company-profile-edit"
              ? t("howitwork_companyinfo_page_transactions")
              : ""}
          </h4>
        </div>

        <div className="right-box">
          <InputSearch
            value={searchBar}
            onChange={(e) => setSearchBar(e.target.value)}
          />
        </div>
      </div>

      <div className="howitwork-links">
        <div className="left-box">
          <ButtonText
            icon={<i className="fa-solid fa-file-pdf"></i>}
            text={t("button_documents")}
            onClick={() => handleClickLink("documents")}
            className={howItWorkPageLink === "documents" ? "button-active" : ""}
          />

          <ButtonText
            icon={<i className="fa-solid fa-video"></i>}
            text={t("button_videos")}
            onClick={() => handleClickLink("videos")}
            className={howItWorkPageLink === "videos" ? "button-active" : ""}
          />
        </div>
      </div>

      <div className="howitwork-main-container">
        {howItWorkPageLink === "documents" ? (
          <DocumentsPage />
        ) : howItWorkPageLink === "videos" ? (
          <VideosPage searchBar={searchBar} />
        ) : (
          <div className="table-null-text">{t("message_no_page")}</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(HowItWork);
