import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";


import { toast } from "react-toastify";

import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import { GetContextValue } from "../../context/GetContext";
import CaptureModal from "../../components/Modals/CaptureModal/CaptureModal";

function ProfileEdit({t}) {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();

  const {
    setUserUseFetchRender,
    userUseFetchRender,
    userError,
    userLoading,
    user,
  } = GetContextValue();

  /* kullanıcı fotoğrafı */
  const initialUserImgValues = {
    userId: userId,
    image: "",
  };

  const [userImgValues, setUserImgValues] = useState(initialUserImgValues);

  /* kullanıcı bilgileri */
  const initialUserInfoValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    // oldPassword: ""
  };

  const [passwordInputShow, setPasswordInputShow] = useState(false);
  const [userInfoValues, setUserInfoValues] = useState(initialUserInfoValues);

  /* kullanıcı şifresi */
  const initialUserPasswordValues = {
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  };

  const [userPasswordValues, setUserPasswordValues] = useState(
    initialUserPasswordValues
  );

  const [image, setImage] = useState();
  const [capturedImage, setCapturedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const webcamRef = useRef(null);

  useEffect(() => {
    if (user && user[0]) {
      setImage(user[0].image);

      setUserInfoValues({
        firstName: user[0].firstName,
        lastName: user[0].lastName,
        phoneNumber: user[0].phoneNumber,
        email: user[0].email,
        oldPassword: "",
      });
    }
  }, [userId, user, userUseFetchRender]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setImage(imageSrc);

    // console.log("imageSrc : ", imageSrc.split("base64,")[1]);

    setUserImgValues({
      ...userImgValues,
      image: imageSrc.split("base64,")[1],
    });
  };

  const handleConfirm = () => {
    setModalIsOpen(false);
  };

  const handleCancel = () => {
    setImage(user[0].image);
    setCapturedImage(null);
    setModalIsOpen(false);
  };

  function handleChangeImg(e) {
    console.log(e.target.value);
    const { name, files } = e.target;

    if (name === "image") {
      setImage();
      setCapturedImage(null);

      setImage(URL.createObjectURL(files[0]));

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        // console.log("result : ", e.target.result.split("base64,")[1]);

        setUserImgValues({
          ...userImgValues,
          image: e.target.result.split("base64,")[1],
        });
      };
    }
  }

  function handleChangeInfo(e) {
    const { name, value } = e.target;

    /* Emailde değişiklik olursa şifre bölümü görünücek */
    if (name === "email") {
      if (value !== user[0].email) {
        setPasswordInputShow(true);
      } else {
        setPasswordInputShow(false);
      }
    }

    setUserInfoValues({
      ...userInfoValues,
      [name]: value,
    });
  }

  function handleChangePassword(e) {
    const { name, value } = e.target;

    setUserPasswordValues({
      ...userPasswordValues,
      [name]: value,
    });
  }

  async function handleImgSubmit(e) {
    e.preventDefault();

    console.log("userImgValues :", userImgValues);

    // REACT_APP_PUT_UPDATE_USER_IMAGE_URL
    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_USER_IMAGE_URL,
      JSON.stringify(userImgValues),
      token
    );

    setUserUseFetchRender(!userUseFetchRender);
  }

  async function handleInfoSubmit(e) {
    e.preventDefault();

    console.log("handleInfoSubmit : ", userInfoValues);

    // REACT_APP_PUT_UPDATE_USER_ROLE_URL
    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_USER_URL,
      JSON.stringify(userInfoValues),
      token
    );

    if (userInfoValues.email !== user[0].email) {
      /* kullanıcı emailini değiştirdiğinde local e kayıtlı token silinir */
      localStorage.removeItem("token");

      setTimeout(() => {
        /*  Tekrardan giriş yapması için login sayfasına yönlendirilir */
        navigate("/login");
      }, 2000);
    }

    setUserUseFetchRender(!userUseFetchRender);
  }

  async function handlePasswordSubmit(e) {
    e.preventDefault();

    console.log("handlePasswordSubmit : ", userPasswordValues);

    //  setUserUseFetchRender(!userUseFetchRender);

    if (
      userPasswordValues.newPassword &&
      userPasswordValues.newPassword2 &&
      userPasswordValues.oldPassword
    ) {
      if (userPasswordValues.newPassword === userPasswordValues.newPassword2) {

        // REACT_APP_PUT_UPDATE_USER_PASSWORD_URL
        await updateMethod(
          process.env.REACT_APP_PUT_UPDATE_USER_PASSWORD_URL,
          JSON.stringify(userPasswordValues),
          token
        );

        // /* kullanıcı şifresini değiştirdiğinde local e kayıtlı token silinir */
        localStorage.removeItem("token");

        setTimeout(() => {
          /*  Tekrardan giriş yapması için login sayfasına yönlendirilir */
          navigate("/login");
        }, 2000);
      } else {
        toast.error(t("message_no_same_password_and_confirm_password"), {
          autoClose: 5000,
        });
      }
    } else {
      toast.error(t("message_emty_form"), {
        autoClose: 5000,
      });
    }
  }

  return (
    <div className="profile-edit-container">
      <form onSubmit={handleImgSubmit} className="profile-edit-img-form">
        <div className="img-container">
          {userError ? (
            <span>{t("error")}</span>
          ) : userLoading ? (
            <span>{t("loading")}</span>
          ) : user ? (
            <img
              src={image}
              alt={"profil"}
            />
          ) : (
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              alt={"profil"}
            />
          )}

          <div className="img-buttons">
            <input
              style={{ display: "none" }}
              multiple
              name="image"
              onChange={handleChangeImg}
              type="file"
              id="contained-button-file"
              accept="image/*"
            />
            <label htmlFor="contained-button-file">{t("button_edit_upload")}</label>

            <ButtonWhiteOpacity
              text={t("button_edit_take_photo")}
              type="button"
              onClick={() => setModalIsOpen(true)}
            />
          </div>

          {modalIsOpen && (
            <CaptureModal
              modalIsOpen={modalIsOpen}
              capturedImage={capturedImage}
              webcamRef={webcamRef}
              handleConfirm={handleConfirm}
              handleCapture={handleCapture}
              handleCancel={handleCancel}
              setCapturedImage={setCapturedImage}
            />
          )}
        </div>

        <ButtonWhiteOpacity
          type="submit"
          text={t("button_edit_photo")}
          className="button-apply img-update-button"
        />
      </form>

      <form
        onSubmit={handlePasswordSubmit}
        className="profile-edit-password-form"
      >
        <div className="profile-edit-password-inputs">
          <InputStandart
            title={t("input_password_old")}
            name="oldPassword"
            type="password"
            placeholder={`${t("input_password_old")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.oldPassword}
          />

          <InputStandart
            title={t("input_password_new")}
            name="newPassword"
            type="password"
            placeholder={`${t("input_password_new")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.newPassword}
          />

          <InputStandart
            title={t("input_password_again")}
            name="newPassword2"
            type="password"
            placeholder={`${t("input_password_again")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.newPassword2}
          />
        </div>

        <ButtonWhiteOpacity
          type="submit"
          text={t("button_update_password")}
          className="button-apply password-update-button"
        />
      </form>

      <form onSubmit={handleInfoSubmit} className="profile-edit-info-form">
        <div className="profile-edit-info-inputs">
          <InputStandart
            title={t("input_firstname")}
            name="firstName"
            type="text"
            placeholder={`${t("input_firstname")}...`}
            onChange={handleChangeInfo}
            value={userInfoValues.firstName}
          />

          <InputStandart
            title={t("input_lastname")}
            name="lastName"
            type="text"
            placeholder={`${t("input_lastname")}...`}
            onChange={handleChangeInfo}
            value={userInfoValues.lastName}
          />

          <InputStandart
            title={t("input_phone")}
            name="phoneNumber"
            type="tel"
            placeholder={`${t("input_phone")}...`}
            onChange={handleChangeInfo}
            value={userInfoValues.phoneNumber}
          />

          <InputStandart
            title={t("input_email")}
            name="email"
            type="tel"
            placeholder={`${t("input_email")}...`}
            onChange={handleChangeInfo}
            value={userInfoValues.email}
          />
          {passwordInputShow ? (
            <InputStandart
              title={t("input_password")}
              name="oldPassword"
              type="password"
              placeholder={`${t("input_password")}...`}
              onChange={handleChangeInfo}
              value={userInfoValues.oldPassword}
            />
          ) : null}
        </div>

        <ButtonWhiteOpacity
          type="submit"
          text={t("button_update_information")}
          className="button-apply info-update-button"
        />
      </form>
    </div>
  );
}

export default withNamespaces()(ProfileEdit);
