import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function CompanyOffers({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [companyOffersPage, setCompanyOffersPage] = useState(1);
  const [companyOffersRowsPerPage, setCompanyOffersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setCompanyOffersUseFetchRender,
    useFetchRender: companyOffersUseFetchRender,
    error: companyOffersError,
    loading: companyOffersLoading,
    data: companyOffers,
    total: companyOffersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${id}&Last=false&SortType=1&Page=${companyOffersPage}&PageSize=${companyOffersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: t("table_photo"),
    },
    {
      key: "result",
      header: t("table_status"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "materialPrice",
      header: t("table_materiel_price"),
    },
    {
      key: "laborPrice",
      header: t("table_labor_price"),
    },
    {
      key: "unitPrice",
      header: t("table_unit_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setCompanyOffersUseFetchRender(!companyOffersUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <>
      {companyOffersError ? (
        <ErrorComponent error={companyOffersError} />
      ) : companyOffersLoading ? (
        <Loading />
      ) : companyOffers !== null ? (
        <StandartTable
          type="offer"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={companyOffersUseFetchRender}
          setUseFetchRender={setCompanyOffersUseFetchRender}
          total={companyOffersTotal}
          page={companyOffersPage}
          setPage={setCompanyOffersPage}
          rowsPerPage={companyOffersRowsPerPage}
          setRowsPerPage={setCompanyOffersRowsPerPage}
          columns={columns}
          data={companyOffers}
        />
      ) : (
        <div className="table-null-text">{t("message_emty_company_offers")}</div>
      )}
    </>
  );
}

export default withNamespaces()(CompanyOffers);
