import { Radio, Select } from "antd";
import "./bannerEditForm.css";
import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import ImageCropUploader from "../ImageCropUploader/ImageCropUploader";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";

// Function to format date in "yyyy-MM-dd" format
const formatDateToISODate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const BannerEditForm = ({ t, banner, onSubmit, onCancel }) => {
  const { userRole } = AuthContextValue();
  const {
    allCompaniesError,
    allCompaniesData,
    categoryList,
    categoryListError,
  } = GetContextValue();

  const [image, setImage] = useState([]);

  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [allCompaniesDataOptions, setAllCompaniesDataOptions] = useState([]);
  const advertSize = [
    { label: t("banner_small"), value: 1 },
    { label: t("banner_medium"), value: 2 },
    { label: t("banner_big"), value: 3 },
  ];
  const advertType =
    userRole === "Admin"
      ? [
          { label: t("banner_all_saw"), value: 1 },
          { label: t("banner_select_companies_saw"), value: 2 },
          { label: t("banner_select_categories_saw"), value: 3 },
        ]
      : [
          { label: t("banner_all_saw"), value: 1 },
          { label: t("banner_select_categories_saw"), value: 3 },
        ];

  const [bannerImage, setBannerImage] = useState("");
  const [editedBanner, setEditedBanner] = useState({
    id: banner.advertId,
    index: banner.index,
    companyName: banner.companyName,
    displayTime: banner.displayTime ? banner.displayTime : "5",
    startDate: banner.startDate,
    finishDate: banner.finishDate,
    images: [],
    type: banner.advertType ? banner.advertType : 1,
    size: banner.advertSize ? banner.advertSize : 1,
    relatedIds: banner.relatedIds ? banner.relatedIds : [],
  });

  useEffect(() => {
    setEditedBanner({
      id: banner.advertId,
      index: banner.index,
      companyName: banner.companyName,
      displayTime: banner.displayTime ? banner.displayTime : "5",
      startDate: banner.startDate,
      finishDate: banner.finishDate,
      images: [],
      type: banner.advertType ? banner.advertType : 1,
      size: banner.advertSize ? Number(banner.advertSize) : 1,
      relatedIds: banner.relatedIds ? banner.relatedIds : [],
    });
    setBannerImage(banner.image);
    setImage([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: banner.image,
      },
    ]);
    console.log(banner);
  }, [banner]);

  useEffect(() => {
    if (!categoryListError && categoryList) {
      const filterData = categoryList.map((categ) => ({
        label: categ.categoryName,
        value: categ.id,
      }));

      setCategoryListOptions(filterData);
    }
  }, [categoryList]);

  useEffect(() => {
    if (!allCompaniesError && allCompaniesData) {
      const filterData = allCompaniesData.map((comp) => ({
        label: comp.companyName,
        value: comp.companyId,
      }));

      setAllCompaniesDataOptions(filterData);
    }
  }, [allCompaniesData]);

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the input field is for startDate or finishDate
    // and format the date value accordingly
    const formattedValue =
      name === "startDate" || name === "finishDate"
        ? formatDateToISODate(value)
        : value;

    setEditedBanner((prevBanner) => ({
      ...prevBanner,
      [name]: formattedValue,
    }));
  };

  // Function to handle image input changes
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    setBannerImage(URL.createObjectURL(imageFile));

    const reader = new FileReader();

    reader.onloadend = () => {
      setEditedBanner((prevBanner) => ({
        ...prevBanner,
        images: [reader.result.split(",")[1]], // Set the base64 encoded image data as the new image value
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  const handleChangeCategory = (value) => {
    setEditedBanner((prevBanner) => ({
      ...prevBanner,
      relatedIds: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the onSubmit function passed as a prop with the editedBanner data
    if (image.length < 1) {
      alert(t("message_emty_form"));
      return;
    }

    const submitData = {
      ...editedBanner,
      images: image[0].thumbUrl ? [image[0].thumbUrl.split(",")[1]] : [],
    };

    console.log("update banner :", submitData);

    onSubmit(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className="banner-edit-form-container">
      <div className="form-group">
        <div className="example-banner-box">
          <div className="banners">
            {editedBanner.size === 1 ? (
              <div className="small-banner">{t("banner_small")}</div>
            ) : editedBanner.size === 2 ? (
              <div className="medium-banner">{t("banner_medium")}</div>
            ) : editedBanner.size === 3 ? (
              <div className="big-banner">{t("banner_big")}</div>
            ) : null}
          </div>

          <p className="banner-example-info"> {t("banner_banner_sizes")}</p>
        </div>
        <label htmlFor="index">{t("banner_banner_size")} :</label>
        <Radio.Group
          name="size"
          onChange={handleChange}
          value={editedBanner.size}
        >
          {advertSize.map((type) => (
            <Radio key={type.value} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div className="form-group">
        <label>{t("input_photo")}:</label>
        <ImageCropUploader image={image} setImage={setImage} />
        {/* {bannerImage && (
          <div className="image-box">
            <img src={bannerImage} alt="videoImage" />
          </div>
        )}

        <label htmlFor="image" className="change-image-label">
          {t("input_photo_add")}
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
        /> */}
      </div>
      <div className="form-group">
        <label htmlFor="index">{t("banner_saw_people")}:</label>
        <Radio.Group
          name="type"
          onChange={handleChange}
          value={editedBanner.type}
        >
          {advertType.map((type) => (
            <Radio key={type.value} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {editedBanner.type === 3 ? (
        <div className="form-group">
          <label htmlFor="index">{t("banner_special_category")}:</label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "200px",
            }}
            placeholder={t("table_category")}
            defaultValue={editedBanner.relatedIds}
            onChange={handleChangeCategory}
            options={categoryListOptions}
            optionFilterProp="label"
          />
        </div>
      ) : editedBanner.type === 2 ? (
        <div className="form-group">
          <label htmlFor="index">{t("banner_special_companies")}:</label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "200px",
            }}
            placeholder={t("table_company_name")}
            defaultValue={editedBanner.relatedIds}
            onChange={handleChangeCategory}
            options={allCompaniesDataOptions}
            optionFilterProp="label"
          />
        </div>
      ) : null}

      <div className="form-group">
        <label htmlFor="companyName">{t("table_issuing")}:</label>
        <h3>{editedBanner.companyName}</h3>
      </div>
      <div className="form-group">
        <label htmlFor="index">{t("banner_index")}:</label>
        <input
          type="number"
          id="index"
          name="index"
          value={editedBanner.index}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="displayTime">
          {t("banner_duration")} ({t("banner_duration_second")}):
        </label>
        <input
          type="text"
          id="displayTime"
          name="displayTime"
          value={editedBanner.displayTime}
          onChange={handleChange}
        />
        <p className="note">{t("banner_duration_description")}</p>
      </div>
      <div className="date-container">
        <div className="form-group">
          <label htmlFor="startDate">{t("banner_start_date")}:</label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formatDateToISODate(editedBanner.startDate)}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="finishDate">{t("banner_end_date")}:</label>
          <input
            type="date"
            id="finishDate"
            name="finishDate"
            value={formatDateToISODate(editedBanner.finishDate)}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-buttons">
        <button type="submit">{t("button_edit_save")}</button>
        <button
          type="button"
          onClick={() => {
            onCancel();
            setBannerImage("");
          }}
        >
          {t("button_edit_cancel")}
        </button>
      </div>
    </form>
  );
};

export default withNamespaces()(BannerEditForm);
