import "./userEditCard.css";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { toast } from "react-toastify";

import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import CaptureModal from "../../components/Modals/CaptureModal/CaptureModal";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";

function UserEditCard({
  t,
  setUserUseFetchRender,
  userUseFetchRender,
  userError,
  userLoading,
  user,
}) {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { token, userInfoRender, setUserInfoRender } = AuthContextValue();
  const { updateMethod, deleteMethod } = CrudContextValue();

  const [userEditInfoLoading, setUserEditInfoLoading] = useState(false);
  const [userEditPassLoading, setUserEditPassLoading] = useState(false);

  /* kullanıcı fotoğrafı */
  const initialUserImgValues = {
    userId: userId,
    image: "",
  };

  const [userImgValues, setUserImgValues] = useState(initialUserImgValues);

  /* kullanıcı bilgileri */
  const initialUserInfoValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    oldPassword: "",
    language: "",
  };

  const [passwordInputShow, setPasswordInputShow] = useState(false);
  const [userInfoValues, setUserInfoValues] = useState(initialUserInfoValues);

  const [userInfoError, setUserInfoError] = useState({
    oldPasswordError: false,
  });

  /* kullanıcı şifresi */
  const initialUserPasswordValues = {
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  };

  const [userPasswordValues, setUserPasswordValues] = useState(
    initialUserPasswordValues
  );

  const [userPassError, setUserPassError] = useState({
    oldPasswordError: false,
    newPasswordError: false,
    newPassword2Error: false,
  });

  const [image, setImage] = useState();
  const [capturedImage, setCapturedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const webcamRef = useRef(null);

  useEffect(() => {
    const userLang = JSON.parse(
      localStorage.getItem("setting-values")
    ).systemLanguage;

    if (user) {
      setImage(user.image);

      setUserInfoValues({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        email: user.email,
        oldPassword: "",
        language: userLang,
      });
    }
  }, [userId, user, userUseFetchRender]);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    // setImage(imageSrc);

    setUserImgValues({
      ...userImgValues,
      image: imageSrc.split("base64,")[1],
    });
  };

  const handleConfirm = async () => {
    const imageData = {
      ...userImgValues,
      image: capturedImage.split("base64,")[1],
    };

    const responseImage = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_USER_IMAGE_URL,
      JSON.stringify(imageData),
      token
    );
    setCapturedImage(null);

    if (responseImage && !responseImage.catchError && !responseImage.isError) {
  
      setUserUseFetchRender(!userUseFetchRender);
      setUserInfoRender(!userInfoRender);

      window.location.reload();
    }

    setModalIsOpen(false);
  };

  const handleCancel = () => {
    setImage(user.image);
    setCapturedImage(null);
    setModalIsOpen(false);
  };

  function handleChangeImg(e) {
    console.log(e.target.value);
    const { name, files } = e.target;

    if (name === "image") {
      setImage();
      setCapturedImage(null);

      setImage(URL.createObjectURL(files[0]));

      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async (e) => {
        // console.log("result : ", e.target.result.split("base64,")[1]);

        // setUserImgValues({
        //   ...userImgValues,
        //   image: e.target.result.split("base64,")[1],
        // });

        await updateMethod(
          process.env.REACT_APP_PUT_UPDATE_USER_IMAGE_URL,
          JSON.stringify({
            ...userImgValues,
            image: e.target.result.split("base64,")[1],
          }),
          token
        );

        setUserUseFetchRender(!userUseFetchRender);
        setUserInfoRender(!userInfoRender);
        window.location.reload();
      };
    }
  }

  function handleChangeInfo(e) {
    const { name, value } = e.target;

    /* Emailde değişiklik olursa şifre bölümü görünücek */
    if (name === "email") {
      if (value !== user.email) {
        setPasswordInputShow(true);
      } else {
        setPasswordInputShow(false);
      }
    }

    setUserInfoValues({
      ...userInfoValues,
      [name]: value,
    });
  }

  function handleChangePassword(e) {
    const { name, value } = e.target;

    setUserPasswordValues({
      ...userPasswordValues,
      [name]: value,
    });
  }

  async function handleImgSubmit(e) {
    e.preventDefault();

    console.log("userImgValues :", userImgValues);

    // REACT_APP_PUT_UPDATE_USER_IMAGE_URL
    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_USER_IMAGE_URL,
      JSON.stringify(userImgValues),
      token
    );

    setUserUseFetchRender(!userUseFetchRender);
  }

  async function handleInfoSubmit(e) {
    e.preventDefault();

    console.log("handleInfoSubmit : ", userInfoValues);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!userInfoValues.oldPassword) {
      inputErrors.oldPassword = true;
    }

    const form = document.getElementById("info-form");

    if (!form.checkValidity() && Object.keys(inputErrors).length > 0) {
      // Gerekli alanlar doldurulmamışsa, hata mesajını göster veya uygun işlemi yap
      
      // Eğer hata durumları varsa, setCompanyAddressError işlevini çağırın
      console.log(inputErrors);
      setUserInfoError(inputErrors);
      alert(t("message_emty_form"));
      return;
    }

    if (user.email !== userInfoValues.email && !userInfoValues.oldPassword) {
      alert(t("message_alert_user_email_change_password_required"));
    } else {
      setUserEditInfoLoading(true);

      console.log(userInfoValues);
      // REACT_APP_PUT_UPDATE_USER_ROLE_URL
      const infoResponse = await updateMethod(
        process.env.REACT_APP_PUT_UPDATE_USER_URL,
        JSON.stringify(userInfoValues),
        token
      );

      setUserEditInfoLoading(false);

      if (infoResponse && !infoResponse.catchError && !infoResponse.isError) {
        if (userInfoValues.email !== user.email) {
          /* kullanıcı emailini değiştirdiğinde local e kayıtlı token silinir */
          localStorage.removeItem("token");

          setTimeout(() => {
            /*  Tekrardan giriş yapması için login sayfasına yönlendirilir */
            navigate("/login");
          }, 2000);
        }

        setUserUseFetchRender(!userUseFetchRender);
        setUserInfoRender(!userInfoRender);
      }
    }
  }

  async function handlePasswordSubmit(e) {
    e.preventDefault();

    console.log("handlePasswordSubmit : ", userPasswordValues);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!userPasswordValues.oldPassword) {
      inputErrors.oldPasswordError = true;
    }

    if (!userPasswordValues.newPassword) {
      inputErrors.newPasswordError = true;
    }

    if (!userPasswordValues.newPassword2) {
      inputErrors.newPassword2Error = true;
    }

    const form = document.getElementById("password-form");

    if (!form.checkValidity() && Object.keys(inputErrors).length > 0) {
      // Gerekli alanlar doldurulmamışsa, hata mesajını göster veya uygun işlemi yap
      console.log("Gerekli alanları doldurun");
      // Eğer hata durumları varsa, setCompanyAddressError işlevini çağırın
      console.log(inputErrors);
      setUserPassError(inputErrors);
      alert(t("message_emty_form"));
      return;
    }

    if (
      userPasswordValues.newPassword &&
      userPasswordValues.newPassword2 &&
      userPasswordValues.oldPassword
    ) {
      if (userPasswordValues.newPassword === userPasswordValues.newPassword2) {
        console.log("var");

        setUserEditPassLoading(true);

        // REACT_APP_PUT_UPDATE_USER_PASSWORD_URL
        const responsePass = await updateMethod(
          process.env.REACT_APP_PUT_UPDATE_USER_PASSWORD_URL,
          JSON.stringify(userPasswordValues),
          token
        );

        setUserEditPassLoading(false);

        if (responsePass && !responsePass.catchError && !responsePass.isError) {
          // /* kullanıcı şifresini değiştirdiğinde local e kayıtlı token silinir */
          localStorage.removeItem("token");

          setTimeout(() => {
            /*  Tekrardan giriş yapması için login sayfasına yönlendirilir */
            navigate("/login");
          }, 2000);

          toast.info(
            t("message_info_new_password_login"),
            {
              autoClose: 5000,
            }
          );
        }
      } else {
        toast.error(t("message_no_same_password_and_confirm_password"), {
          autoClose: 5000,
        });
      }
    } else {
      toast.error(t("message_emty_form"), {
        autoClose: 5000,
      });
    }
  }

  async function handleDeleteImage() {
    const imagePath = image.split("images/")[1];
    console.log(imagePath);

    await deleteMethod(
      `${process.env.REACT_APP_DELETE_IMAGE_URL}?file=${imagePath}`,
      token
    );

    setUserUseFetchRender(!userUseFetchRender);
    setUserInfoRender(!userInfoRender);
  }

  return (
    <div className="user-edit-card-container">
      <div className="info-edit-container">
        <div className="top-box">
          <div className="title-box">
            <WhiteButton
              icon={<i className="fa-solid fa-chevron-left"></i>}
              text={t("button_edit_back")}
              onClick={() => navigate(-1)}
            />
            <div className="title">
              <i className="fa-solid fa-pen"></i>
              <h5>{t("user_info_edit_title")}</h5>
            </div>
          </div>
          <div className="button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-floppy-disk"></i>}
              text={userEditInfoLoading ? t("loading") : t("button_edit_save")}
              onClick={handleInfoSubmit}
            />
          </div>
        </div>
        <div className="main-box">
          <div className="image-container">
            <div className="image-box">
              {image ? (
                <img
                  src={image}
                  alt="foto"
                />
              ) : (
                <img
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="profile-img"
                />
              )}

              <img className="image-detail" src={image} alt="Profil" />
            </div>
            <div className="image-buttons">
              <input
                style={{ display: "none" }}
                multiple
                name="image"
                onChange={handleChangeImg}
                type="file"
                id="contained-button-file"
                accept="image/*"
              />
              <WhiteButton
                icon={<label htmlFor="contained-button-file">{t("button_edit_upload")}</label>}
              />

              <WhiteButton icon={t("button_edit_take_photo")} onClick={() => setModalIsOpen(true)} />
              <WhiteButton icon={t("button_edit_remove")} onClick={handleDeleteImage} />
            </div>
            {modalIsOpen && (
              <CaptureModal
                modalIsOpen={modalIsOpen}
                capturedImage={capturedImage}
                webcamRef={webcamRef}
                handleConfirm={handleConfirm}
                handleCapture={handleCapture}
                handleCancel={handleCancel}
                setCapturedImage={setCapturedImage}
              />
            )}
          </div>
          <form id="info-form" className="input-container">
            <InputStandart
              name="firstName"
              type="text"
              title={t("input_firstname")}
              placeholder={`${t("input_firstname")}...`}
              onChange={handleChangeInfo}
              value={userInfoValues.firstName}
            />

            <InputStandart
              name="lastName"
              type="text"
              title={t("input_lastname")}
              placeholder={`${t("input_lastname")}...`}
              onChange={handleChangeInfo}
              value={userInfoValues.lastName}
            />

            <InputStandart
              name="phoneNumber"
              type="tel"
              title={t("input_phone")}
              placeholder={`${t("input_phone")}...`}
              onChange={handleChangeInfo}
              value={userInfoValues.phoneNumber}
            />

            <InputStandart
              name="email"
              type="tel"
              title={t("input_email")}
              placeholder={`${t("input_email")}...`}
              onChange={handleChangeInfo}
              value={userInfoValues.email}
            />
            {passwordInputShow ? (
              <InputStandart
                className="red-input"
                required={true}
                errorBorder={userInfoError.oldPasswordError}
                name="oldPassword"
                type="password"
                title={t("input_password_required")}
                placeholder={`${t("input_password")}...`}
                onChange={handleChangeInfo}
                value={userInfoValues.oldPassword}
              />
            ) : null}
          </form>
        </div>
      </div>
      <div className="change-pass-container">
        <div className="top-box">
          <div className="title-box">
            <div className="title">
              <i className="fa-solid fa-key"></i>
              <h5>{t("change_password_title")}</h5>
            </div>
          </div>
        </div>
        <form id="password-form" className="main-box">
          <InputStandart
            required={true}
            errorBorder={userPassError.oldPasswordError}
            name="oldPassword"
            type="password"
            title={t("input_password_old")}
            placeholder={`${t("input_password_old")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.oldPassword}
          />

          <InputStandart
            required={true}
            errorBorder={userPassError.newPasswordError}
            name="newPassword"
            type="password"
            title={t("input_password_new")}
            placeholder={`${t("input_password_new")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.newPassword}
          />

          <InputStandart
            required={true}
            errorBorder={userPassError.newPassword2Error}
            name="newPassword2"
            type="password"
            title={t("input_password_again")}
            placeholder={`${t("input_password_again")}...`}
            onChange={handleChangePassword}
            value={userPasswordValues.newPassword2}
          />

          <WhiteButton
            icon={t("button_change_password")}
            // text={userEditPassLoading ? t("loading") : t("button_change_password")}
            onClick={handlePasswordSubmit}
          />
        </form>
      </div>
    </div>
  );
}

export default withNamespaces()(UserEditCard);
