import "../style/detailCardStyle/detailCardStyle.css";

import React from "react";
import { withNamespaces } from "react-i18next";

import { Link } from "react-router-dom";
import { GetContextValue } from "../../../context/GetContext";
import Loading from "../../Loading/Loading";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";

function PerformancesDetailCard({t}) {
  const {
    usersStatistics,
    usersStatisticsLoading,
    usersStatisticsError,
    companyDetail,
  } = GetContextValue();

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-users"></i>
          <h5>{t("performances_title")}</h5>
        </div>
        <div className="card-buttons">
          <Link to={"/users"}>
            <WhiteButton
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={t("button_edit_detail")}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {usersStatisticsError ? (
            <ErrorComponent error={usersStatisticsError} />
          ) : usersStatisticsLoading ? (
            <Loading />
          ) : usersStatistics !== null && usersStatistics.length > 0 ? (
            usersStatistics.map((user) => {
              return (
                <li key={user.userId}>
                  <p>
                    {user.firstName} {user.lastName}
                  </p>
                  {companyDetail.companyType === 3 && (
                    <p>{`${user.offerTotal} ${t("table_offer")}  ${user.tenderTotal} ${t("table_tender")}`}</p>
                  )}
                  {companyDetail.companyType === 1 && (
                    <p>{`${user.tenderTotal} ${t("table_tender")}`}</p>
                  )}
                  {companyDetail.companyType === 2 && (
                    <p>{`${user.offerTotal} ${t("table_offer")}`}</p>
                  )}
                </li>
              );
            })
          ) : (
            <p className="table-null-text">{t("message_emty_users")}</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default withNamespaces()(PerformancesDetailCard);
