import "./whiteButton.css";
import { Button, Tooltip } from "antd";

function WhiteButton({
  type,
  text,
  icon,
  tooltip,
  onClick,
  disabled,
  className,
}) {
  return (
    <Tooltip placement="top" title={tooltip}>
      <Button
        disabled={disabled}
        onClick={onClick}
        style={{ borderRadius: "10px", border: "none" }}
        className={`white-button ${className} ${
          disabled ? "white-button-disabled" : "white-button-cursor"
        }`}
      >
        {icon && <span className="white-button-icon">{icon}</span>}
        {text && <span className="white-button-text">{text}</span>}
        {/* {text} */}
      </Button>
    </Tooltip>
  );
}

export default WhiteButton;
{
  /* <button
  type={type}
  className={`white-button ${className} ${
    disabled ? "white-button-disabled" : "white-button-cursor"
  }`}
  onClick={onClick}
  disabled={disabled}
>
  {icon && <span className="white-button-icon">{icon}</span>}
  {text && <span className="white-button-text">{text}</span>}
</button>; */
}
