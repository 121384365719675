import "./captureModal.css";
import { withNamespaces } from "react-i18next";

import Webcam from "react-webcam";
import Modal from "react-modal";

function CaptureModal({
  t,
  modalIsOpen,
  capturedImage,
  webcamRef,
  handleConfirm,
  handleCapture,
  handleCancel,
  setCapturedImage,
}) {
  async function handleClickConfirm() {
    await handleConfirm();
  }

  return (
    <Modal isOpen={modalIsOpen} className="capture-modal" ariaHideApp={false}>
      <div className="capture-modal-container">
        <div className="capture-modal-box">
          {capturedImage === null && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="capture-modal-webcam"
            />
          )}
          {capturedImage ? (
            <>
              <img src={capturedImage} alt="Captured" />
              <div className="modal-button-groups">
                <button type="submit" onClick={handleClickConfirm}>
                  {t("button_edit_confirm")}
                </button>
                <button onClick={() => setCapturedImage(null)}>
                  {t("button_edit_take_photo_again")}
                </button>
                <button onClick={handleCancel}>
                  {t("button_edit_cancel")}
                </button>
              </div>
            </>
          ) : (
            <div className="modal-button-groups">
              <button onClick={handleCapture}>
                {t("button_edit_take_photo")}
              </button>
              <button onClick={handleCancel}>{t("button_edit_cancel")}</button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default withNamespaces()(CaptureModal);
