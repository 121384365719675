import "./styles/tenderEdit.css";

import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { CrudContextValue } from "../../../context/CrudContext";
import { AuthContextValue } from "../../../context/AuthContext";
import TenderEditStepOne from "./TenderEditStepOne";
import TenderEditStepTwo from "./TenderEditStepTwo";
import useFetch from "../../../hooks/UseFetch";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}

function formatDate(dateValue) {
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return [year, month, day].join("-");
}

function TenderEdit({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { token } = AuthContextValue();
  const { updateMethod } = CrudContextValue();

  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(1);

  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState();
  const [properties, setProperties] = useState([]);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  useEffect(() => {
    if (!tenderDetailError && tenderDetail && tenderDetail.minPrice) {
      setFormData({
        tenderId: parseInt(tenderId),
        categoryId: tenderDetail.categories,
        description: tenderDetail.description,
        productName: tenderDetail.productName,
        amount: tenderDetail.amount,
        minPrice: tenderDetail.minPrice.toLocaleString(),
        maxPrice: tenderDetail.maxPrice.toLocaleString(),
        currency: tenderDetail.currency ? tenderDetail.currency : "TRY",
        expireDate: formatDate(tenderDetail.expireDate),
        deadline: formatDate(tenderDetail.deadline),
        customer: tenderDetail.customer,
        unitType: tenderDetail.unitType,
        tenderCompanyId: tenderDetail.companies,
        images: [],
        note: tenderDetail.note,
        properties: tenderDetail.properties ? tenderDetail.properties : [],
        recursive:
          tenderDetail.frequency && tenderDetail.frequency.length > 0
            ? true
            : false,
        details:
          tenderDetail.frequency && tenderDetail.frequency.length > 0
            ? {
                ...tenderDetail.frequency[0],
                quantityDistribution:
                  tenderDetail.frequency[0].quantityDetailResponse,
                frequency: tenderDetail.frequency[0].frequencyType,
              }
            : {},
      });

      if (tenderDetail.categories) {
        const newCategoryId = tenderDetail.categories.map((category) => ({
          label: category.categoryName,
          value: category.categoryId,
        }));
        setSelectedCategories(newCategoryId);
      }

      if (tenderDetail.properties) {
        setProperties(tenderDetail.properties ? tenderDetail.properties : []);
      }

      /* images */
      if (tenderDetail.images) {
        let filterImg = [];
        let filterDocument = [];

        tenderDetail.images.map((img) => {
          if (img.includes("pdf")) {
            filterDocument.push(img);
          } else {
            filterImg.push(img);
          }
        });

        setImages(filterImg.map((img) => ({ id: generateId(), url: img })));
        setDocuments(
          filterDocument.length > 0
            ? filterDocument.map((doc) => ({ id: generateId(), fileName: doc }))
            : []
        );
      }
    }
  }, [tenderDetail]);

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handlePrev = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    setFormData({ ...formData, ...data });

    // kategorilerin sadece idlerini göndermek için
    const newCategories = formData.categoryId.map((category) => {
      if (category.categoryId) {
        return category.categoryId;
      } else {
        return category;
      }
    });

    // Sadece yeni verileri göndermek için
    const propertiesFilter = formData.properties.reduce((acc, prop) => {
      if (!prop.propertyId) {
        return [
          ...acc,
          {
            name: prop.name,
            value: prop.value,
          },
        ];
      }
      return acc;
    }, []);

    const newTender = {
      ...formData,
      ...data,
      categoryId: newCategories,
      categoryId: newCategories,
      minPrice:
        typeof formData.minPrice === "string"
          ? parseInt(formData.minPrice.replace(/[,.]/g, ""))
          : formData.minPrice,
      maxPrice:
        typeof formData.maxPrice === "string"
          ? parseInt(formData.maxPrice.replace(/[,.]/g, ""))
          : formData.maxPrice,
      properties: propertiesFilter,
      details: {
        frequency: formData.details.frequency,
        startDate: formData.details.startDate,
        finishDate: formData.details.finishDate,
        quantityDistribution: formData.details.quantityDistribution,
      },
      deadline: formData.recursive
        ? formData.details.finishDate
        : formData.deadline,
    };

    console.log("Güncellenen ihale : ", newTender);

    const response = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_TENDER_URL,
      JSON.stringify(newTender),
      token
    );

    if (response && !response.catchError && !response.isError) {
      toast.info(t("message_create_tender_success"), {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        // navigate(`/tenders/tender-detail/${tenderId}`);
        navigate(`/tenders`);
      }, 3000);
    }

    setStep(1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tender-add">
      {formData && formData.productName && (
        <>
          {windowWidth < 1028 ? (
            <>
              {step === 1 ? (
                <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                  <TenderEditStepOne
                    onNext={handleNext}
                    formData={formData}
                    step={step}
                    images={images}
                    setImages={setImages}
                    documents={documents}
                    setDocuments={setDocuments}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    properties={properties}
                    setProperties={setProperties}
                  />
                </div>
              ) : step === 2 ? (
                <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                  <TenderEditStepTwo
                    onPrev={handlePrev}
                    onSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                  />
                </div>
              ) : (
                "null"
              )}
            </>
          ) : (
            <>
              <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                <TenderEditStepOne
                  onNext={handleNext}
                  formData={formData}
                  step={step}
                  images={images}
                  setImages={setImages}
                  documents={documents}
                  setDocuments={setDocuments}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  properties={properties}
                  setProperties={setProperties}
                />
              </div>
              <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                <TenderEditStepTwo
                  onPrev={handlePrev}
                  onSubmit={handleSubmit}
                  formData={formData}
                  setFormData={setFormData}
                  step={step}
                  setStep={setStep}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withNamespaces()(TenderEdit);
