import "./inputStandart.css";

function InputStandart({
  type,
  title,
  name,
  placeholder,
  required,
  errorBorder,
  className,
  labelClass,
  ...props
}) {
  return (
    <div className={`input-standart ${className}`}>
      <label htmlFor={name} className={labelClass}>
        {`${required ? "*" : ""} ${title}`} :{" "}
      </label>
      <input
        {...props}
        className={`${errorBorder ? "input-error" : ""} `}
        required={required}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputStandart;
