import "./styles/tenderDetailOffer/tenderDetailOffer.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";

import useFetch from "../../hooks/UseFetch";
import useFetchIf from "../../hooks/useFetchIf";

import Loading from "../../components/Loading/Loading";

import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";

import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { toast } from "react-toastify";
import BidOfferCard from "../../components/BidOfferCard/BidOfferCard";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import { GetContextValue } from "../../context/GetContext";
import { Alert, Space } from "antd";
import { tenderTimeOverCalcFunc } from "../../hooks/tenderTimeOver";

function TenderDetailOffer({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const { token, loginUserCompanyId } = AuthContextValue();
  const { createMethod, updateMethod } = CrudContextValue();
  const { notificationRender } = GetContextValue();

  const [bidOfferLoading, setBidOfferLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [revisedLoading, setRevisedLoading] = useState(false);

  const [cardRender, setCardRender] = useState(false);

  const [currency, setCurrency] = useState("TRY"); // default currency
  const [currencySymbol, setCurrencySymbol] = useState("₺"); // default currency symbol
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [offerFormData, setOfferFormData] = useState({
    tenderId: Number(tenderId),
    description: "",
    note: "",
    currency: currency,
    laborPrice: 0,
    materialPrice: 0,
    unitPrice: 0,
    totalPrice: 0,
    shippingPrice: "0",
    deadline: "",
    images: [],
  });

  /* form hata durumları */
  const [offerFormDataErrors, setOfferFormDataErrors] = useState({
    currencyError: false,
    laborPriceError: false,
    materialPriceError: false,
    unitPriceError: false,
    deadlineError: false,
  });

  /* ihale detay isteği */
  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  const {
    setUseFetchRender: setMyTenderApprovedDetailUseFetchRender,
    useFetchRender: myTenderApprovedDetailUseFetchRender,
    error: myTenderApprovedDetailError,
    loading: myTenderApprovedDetailLoading,
    data: myTenderApprovedDetail,
  } = useFetchIf(
    `${process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL}?TenderId=${
      tenderDetail?.tenderId
    }&CompanyId=${loginUserCompanyId}&Last=false&SortType=1&Page=${1}&PageSize=${1}`,
    token,
    tenderDetail?.tenderId
  );

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    setMyTenderApprovedDetailUseFetchRender(
      !myTenderApprovedDetailUseFetchRender
    );
  }, [notificationRender, cardRender]);

  /* ihale detay */
  // Manager tarafından yapılan ihalye katılma onayı
  async function tenderConfirm() {
    handleCloseConfirmModal();

    setApprovedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_COMPANY_STATUS_URL}?tenderId=${tenderId}&companyId=${loginUserCompanyId}&status=Onaylandı`,
      null,
      token
    );

    setApprovedLoading(false);

    setMyTenderApprovedDetailUseFetchRender(
      !myTenderApprovedDetailUseFetchRender
    );
  }

  // Manager tarafından yapılan ihalye revize isteği
  async function opportunitiesRevised(revisedDescription) {
    setRevisedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_COMPANY_STATUS_URL}?tenderId=${tenderId}&companyId=${loginUserCompanyId}&status=3&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);
  }

  /* ONAY MODAL */
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => {
    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!offerFormData.currency) {
      inputErrors.currencyError = true;
    }

    if (!offerFormData.laborPrice) {
      inputErrors.laborPriceError = true;
    }

    if (!offerFormData.materialPrice) {
      inputErrors.materialPriceError = true;
    }

    if (!offerFormData.unitPrice) {
      inputErrors.unitPriceError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setOfferFormDataErrors(inputErrors);
      alert(t("message_emty_form"));
    } else {
      setOpenConfirmModal(true);
    }
  };
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  async function confirmFunction() {
    const offerData = {
      ...offerFormData,
      currency: currency,
      laborPrice: parseInt(offerFormData.laborPrice.replace(/[,.]/g, "")),
      materialPrice: parseInt(offerFormData.materialPrice.replace(/[,.]/g, "")),
      unitPrice: parseInt(offerFormData.unitPrice.replace(/[,.]/g, "")),
      shippingPrice: parseInt(offerFormData.shippingPrice.replace(/[,.]/g, "")),
      totalPrice: parseInt(offerFormData.totalPrice.replace(/[,.]/g, "")),
    };

    console.log("offerData : ", offerData);

    setBidOfferLoading(true);

    const responseBidOffer = await createMethod(
      process.env.REACT_APP_POST_ADD_OFFER_URL,
      JSON.stringify(offerData),
      token
    );

    setBidOfferLoading(false);

    if (responseBidOffer) {
      if (!responseBidOffer.cathError && !responseBidOffer.isError) {
        toast.info(t("message_update_offer_success"), {
          autoClose: 3000,
        });

        setTimeout(() => {
          navigate("/offers");
        }, 3000);
      }
    }

    handleCloseConfirmModal();
  }

  return (
    <div className={`tender-detail-offer-container `}>
      <div
        className={`opportunities-tender-detail ${
          approvedLoading || openConfirmModal ? "disabled-box" : ""
        }`}
      >
        {tenderDetailError ? (
          <ErrorComponent error={tenderDetailError} />
        ) : tenderDetailLoading ? (
          <Loading />
        ) : tenderDetail !== null ? (
          <TenderDetailCard
            tenderDetail={tenderDetail}
            setCardRender={setCardRender}
            cardRender={cardRender}
            opportunitiesConfirmStatus={
              myTenderApprovedDetail[0]
                ? myTenderApprovedDetail[0]?.status
                : null
            }
            opportunitiesRevisedResult={
              myTenderApprovedDetail[0]
                ? myTenderApprovedDetail[0]?.result
                : null
            }
            opportunitiesConfirmFunc={tenderConfirm}
            opportunitiesConfirmLoading={approvedLoading}
            opportunitiesRevisedFunc={opportunitiesRevised}
            opportunitiesRevisedLoading={revisedLoading}
          />
        ) : null}
      </div>

      <div className="tender-detail-offer-form-panel">
        {myTenderApprovedDetailError ? (
          <ErrorComponent error={myTenderApprovedDetailError} />
        ) : myTenderApprovedDetailLoading ? (
          <Loading />
        ) : (myTenderApprovedDetail &&
            myTenderApprovedDetail[0]?.status === "1") ||
          myTenderApprovedDetail[0]?.status === "3" ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 className="table-null-text">
              {t("message_info_first_join_tender")}
            </h4>
          </div>
        ) : myTenderApprovedDetail &&
          myTenderApprovedDetail[0]?.status === "2" ? (
          <>
            <div className="tender-detail-top">
              {bidOfferLoading ? (
                <LoadingCircular />
              ) : openConfirmModal ? (
                <ConfirmModal
                  confirmFunc={confirmFunction}
                  cancelButtonText={t("button_edit_cancel")}
                  confirmButtonText={t("button_edit_send")}
                  handleCloseModal={handleCloseConfirmModal}
                  text={t("message_send_offer")}
                />
              ) : tenderTimeOverCalcFunc(
                  new Date(tenderDetail?.expireDate) - new Date()
                ) === "overTime" ? (
                <Space
                  direction="vertical"
                  style={{ width: "100%", whiteSpace: "normal" }}
                >
                  <Alert
                    message={t("message_no_over_tender_alert")}
                    type="error"
                    showIcon
                  />
                </Space>
              ) : (
                <>
                  <div className="tender-detail-top-title">
                    <i className="fa-solid fa-bahai"></i>
                    <h4>{t("button_request_offer")}</h4>
                  </div>
                  <div className="tender-detail-top-right">
                    <WhiteButton
                      icon={<i className="fa-solid fa-location-arrow"></i>}
                      tooltip={t("button_edit_send")}
                      onClick={handleOpenConfirmModal}
                    />
                  </div>
                </>
              )}
            </div>

            <div
              className={`bid-offer-form ${
                tenderTimeOverCalcFunc(
                  new Date(tenderDetail?.expireDate) - new Date()
                ) === "overTime"
                  ? "disabled-box"
                  : null
              } ${approvedLoading || openConfirmModal ? "disabled-box" : ""}`}
            >
              {offerFormData ? (
                <BidOfferCard
                  images={images}
                  setImages={setImages}
                  documents={documents}
                  setDocuments={setDocuments}
                  offerFormData={offerFormData}
                  setOfferFormData={setOfferFormData}
                  offerFormDataErrors={offerFormDataErrors}
                  currency={currency}
                  setCurrency={setCurrency}
                  currencySymbol={currencySymbol}
                  setCurrencySymbol={setCurrencySymbol}
                  tenderAmount={tenderDetail.amount}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default withNamespaces()(TenderDetailOffer);
