import './breadCrumbs.css';

import { Link, useLocation } from "react-router-dom";

function BreadCrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    return (
        <div className='breadcrumbs-container'>
            <ol>
                {
                    pathnames.length === 0 &&
                    <li><i className="fa-solid fa-house"></i></li>
                }
                {pathnames.map((part, index) => (
                    
                    <li key={index}>
                        <Link to={`/${pathnames.slice(0, index + 1).join("/")}`}>
                            {
                                index > 0 &&
                                <i className="fa-solid fa-chevron-right" />
                            }
                            {part}
                        </Link>
                    </li>
                ))}
            </ol>
        </div>
    );
}

export default BreadCrumbs;
