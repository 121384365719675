import "./supplyProcessCardMainComp.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { Modal, Button } from "antd";
import { AuthContextValue } from "../../context/AuthContext";
import { getFetch } from "../../hooks/getFetch";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import RatingComponent from "../RatingComponent/RatingComponent";

function deadlineConvert(deadline) {
  if (deadline) {
    const formattedDeadline = new Date(deadline);

    // Tarih ve saat ayrı ayrı alınabilir
    const tarih = formattedDeadline.toLocaleDateString(); // Tarih örneği: "4 Eylül 2023"

    return `${tarih}`;
  } else {
  }
}

function SupplyProcessCardMainComp({ t, tenderId }) {
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();
  const { updateMethod, createMethod } = CrudContextValue();
  const [updateLoading, setUpdateLoading] = useState(false);

  const statusMap = {
    1: { name: t("select_being_supplied"), color: "#00cc00" },
    2: { name: t("select_during_production"), color: "#ff9900" },
    3: { name: t("select_in_preparation"), color: "#ff3300" },
    4: { name: t("select_shipment_preparation"), color: "#cc00cc" },
    5: { name: t("select_shipped"), color: "#3366ff" },
  };

  const optionsRadioButton = [{ label: t("select_shipped"), value: 5 }];

  const [renderItem, setRenderItem] = useState(false);

  const [tenderData, setTenderData] = useState({
    TenderNumber: "",
    Deadline: "",
    ProductName: "",
    TenderDescription: "",
    TenderCompanyName: "",
    TenderPhoneNumber: "",
    Status: "",
  });
  const [status, setStatus] = useState({ name: "", color: "" });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(tenderData?.Status);

  const [ratingLoading, setRatingLoading] = useState(false);
  const [ratingCompanyId, setRatingCompanyId] = useState(null);
  const [openRatingModal, setOpenRatingModalModal] = useState(false);
  const handleOpenRatingModalModal = () => setOpenRatingModalModal(true);
  const handleCloseRatingModalModal = () => setOpenRatingModalModal(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    async function getData() {
      const responseData = await getFetch(
        `${
          process.env.REACT_APP_GET_PROCUREMENT_LIST_URL
        }?TenderId=${tenderId}&Page=${1}&PageSize=${1}`,
        token
      );

      if (
        responseData &&
        !responseData.catchError &&
        !responseData.data.isError
      ) {
        setTenderData(responseData.data.data[0]);
        setStatus(statusMap[responseData.data.data[0]?.Status]);
        setCurrentStatus(responseData.data.data[0]?.Status);
        setRatingCompanyId(responseData.data.data[0]?.TenderCompanyId);
      }
    }

    if (tenderId) {
      getData();
    }
  }, [tenderId, renderItem, notificationRender]);

  async function handleRating(ratingData) {
    setRatingLoading(true);

    console.log({ companyId: ratingCompanyId, ...ratingData });

    const postRateData = { companyId: ratingCompanyId, ...ratingData };

    const rateResponse = await createMethod(
      process.env.REACT_APP_POST_COMPANY_RATE_URL,
      JSON.stringify(postRateData),
      token
    );

    setRatingLoading(false);

    if (rateResponse && !rateResponse.catchError && !rateResponse.isError) {
    } else {
      alert(t("message_no_rate"));
    }
    handleCloseRatingModalModal();
  }

  async function handleUpdateSupplyStatus() {
    const updateData = {
      tenderNumber: tenderData?.TenderNumber,
      status: newStatus?.value,
      description: "",
    };

    setUpdateLoading(true);

    const response = await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_PROCUREMENT_URL,
      JSON.stringify(updateData),
      token
    );

    if (response && !response.catchError && !response.data.isError) {
      setCurrentStatus(newStatus?.value);
      setIsModalVisible(false);
      setRenderItem(!renderItem);

      handleOpenRatingModalModal();
    } else {
      console.log("REACT_APP_PUT_UPDATE_PROCUREMENT_URL error ");
    }

    setUpdateLoading(false);
  }

  return (
    <div className="supplyprocess-suppliercomp-card">
      <div className="supplyprocess-header">
        <h3>
          {t("procuremen_process_detail_tender_number")} :{" "}
          {tenderData?.TenderNumber}
        </h3>
        <div className="deadline">
          <p>
            {t("procuremen_process_detail_deadline")} :{" "}
            {deadlineConvert(tenderData?.Deadline)}
          </p>

          <Link
            to={`/supply-process/detail/${tenderData?.TenderId}/${tenderData?.OfferId}`}
          >
            {t("button_edit_detail")}
          </Link>
        </div>
      </div>
      <div className="supplyprocess-body">
        <p className="product-name">
          {t("procuremen_process_detail_product")} : {tenderData?.ProductName}
        </p>
        <p className="supplyprocess-description">
          {t("procuremen_process_detail_offer_description")} :{" "}
          {tenderData?.Description}
        </p>
        <p className="supplyprocess-company">
          {t("procuremen_process_detail_supplier")} :{" "}
          {tenderData?.OfferCompanyName}
        </p>
        <p className="supplyprocess-phone">
          {t("procuremen_process_detail_supplier_phone")} :{" "}
          {tenderData?.OfferPhoneNumber}
        </p>
      </div>
      <div className="supplyprocess-bottom">
        <div className="status-badge">
          <span className="badge" style={{ backgroundColor: status.color }}>
            {status.name}
          </span>
        </div>
        {Number(tenderData?.Status) !== 5 && (
          <div className="supplyprocess-button-groups">
            {optionsRadioButton.map((button) => {
              return (
                <Button
                  key={button.value}
                  type={
                    Number(currentStatus) === Number(button.value)
                      ? "primary"
                      : "default"
                  }
                  onClick={() => {
                    showModal();
                    setNewStatus(button);
                  }}
                >
                  {button.label}
                </Button>
              );
            })}
          </div>
        )}
      </div>
      {updateLoading && <LoadingCircular />}
      {openRatingModal && (
        <RatingComponent handleRating={handleRating} loading={ratingLoading} />
      )}
      <Modal
        title={t("procuremen_process_change_status_title")}
        open={isModalVisible}
        onOk={handleUpdateSupplyStatus}
        onCancel={handleCancel}
      >
        <p>
          {t("message_confirm_change_supplyprocess_status_text1")}{" "}
          <strong>"{newStatus?.label}"</strong>{" "}
          {t("message_confirm_change_supplyprocess_status_text2")}
        </p>
      </Modal>
    </div>
  );
}

export default withNamespaces()(SupplyProcessCardMainComp);
