import "./styles/tenderOfferDetail/tenderOfferDetail.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContextValue } from "../../context/AuthContext";

import Loading from "../../components/Loading/Loading";
import useFetch from "../../hooks/UseFetch";
import useFetchIf from "../../hooks/useFetchIf";

import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import OfferDetailCard from "../../components/OfferDetailCard/OfferDetailCard";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

/* İHALEYE GELEN TEKLİFİN DETAYLARININ GÖSTERİLDİĞİ SAYFA */
function TenderOfferDetail() {
  const { offerId } = useParams();
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  const [tenderId, setTenderId] = useState("");

  //Burada routedan aldığım offerId parametresi ile istek yapıyorum
  //dönen değerden aldığım tenderId ile ihale detaylarını veritabanından çekiyorum.
  /* ihale detay isteği */
  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetchIf(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token,
    tenderId
  );

  const {
    error: offerDetailError,
    loading: offerDetailLoading,
    data: offerDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_DETAIL_URL}?id=${offerId}`,
    token
  );

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    if (offerDetail !== null) {
      setTenderId(offerDetail.tenderId);
      setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    }
  }, [offerDetail]);

  return (
    <div className="tender-offer-detail-container">
      <div className="tender-detail">
        {tenderDetailError ? (
          <ErrorComponent error={tenderDetailError} />
        ) : tenderDetailLoading ? (
          <Loading />
        ) : tenderDetail !== null ? (
          <TenderDetailCard tenderDetail={tenderDetail} />
        ) : null}
      </div>

      <div className="tender-offer-detail">
        {offerDetailError ? (
          <ErrorComponent error={offerDetailError} />
        ) : offerDetailLoading ? (
          <Loading />
        ) : offerDetail !== null ? (
          <OfferDetailCard offerDetail={offerDetail} />
        ) : null}
      </div>
    </div>
  );
}

export default TenderOfferDetail;
