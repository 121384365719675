import "./addRecommedSupplierTable.css";
import { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

import Pagination from "../Pagination/Pagination";
import images from "../../../assets/images";
import { Button, Modal } from "antd";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";

function AddRecommedSupplierTable({
  t,

  selectedRows,
  setSelectedRows,

  total,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,

  columns,
  data,

  onSubmit,
}) {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [supplier, setSupplier] = useState(null);

  async function handleAddSupplier() {
    if (supplier) {
      await onSubmit(supplier.companyId);
    }
    setOpenConfirmModal(false);
  }

  return (
    <div className="add-recommed-supplier-table-container">
      <div className="table-box">
        <table>
          <thead>
            <tr>
              <th title={t("table_photo")}>{t("table_photo")} </th>
              <th title={t("table_company_name")}>
                {t("table_company_name")}{" "}
              </th>
              <th title={t("table_category")}>{t("table_category")} </th>
              <th title={t("table_email")}>{t("table_email")} </th>
              <th title={t("table_phone_number")}>
                {t("table_phone_number")}{" "}
              </th>
              <th title={t("sidebar_settings")}>{t("sidebar_settings")} </th>
            </tr>
          </thead>

          <tbody>
            {data.map((row) => {
              
              return (
                <tr key={row.companyId}>
                  <td title={row.logo}>
                    <div className="image-box">
                      <img
                        src={row.logo}
                        onError={({ currentTarget }) => {
                          currentTarget.src = images.noImage;
                        }}
                        alt={row.logo}
                        title={row.logo}
                      />
                    </div>
                  </td>
                  <td title={row.companyName}>{row.companyName}</td>
                  <td
                    title={row.categories
                      .map((category) => category.categoryName)
                      .join(", ")}
                  >
                    {row.categories
                      .map((category) => category.categoryName)
                      .join(", ")}
                  </td>
                  <td title={row.email}>{row.email}</td>
                  <td title={row.phoneNumber}>{row.phoneNumber}</td>
                  <td>
                    <WhiteButton
                      text={t("button_edit_add")}
                      onClick={() => {
                        setOpenConfirmModal(true);
                        setSupplier(row);
                      }}
                    ></WhiteButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        total={total}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {openConfirmModal && (
        <Modal
          onCancel={() => setOpenConfirmModal(false)}
          open={openConfirmModal}
          title={t("add_supplier_title")}
          children={
            <div className="confirm-modal-container">
              <p>
                {`${supplier.companyName} ${t("message_confirm_add_supplier")}`}
              </p>
              <div className="modal-actions-button">
                <Button type="primary" onClick={handleAddSupplier}>
                  {t("button_edit_confirm")}
                </Button>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setOpenConfirmModal(false);
                  }}
                >
                  {t("button_edit_cancel")}
                </Button>
              </div>
            </div>
          }
          footer={null}
        />
      )}
    </div>
  );
}

export default withNamespaces()(AddRecommedSupplierTable);
