import "./addRecommedSupplier.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import useFetch from "../../hooks/UseFetch";
import { GetContextValue } from "../../context/GetContext";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import Loading from "../Loading/Loading";
import AddRecommedSupplierTable from "../Table/AddRecommedSupplierTable/AddRecommedSupplierTable";
import { AuthContextValue } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { CrudContextValue } from "../../context/CrudContext";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { getFetch } from "../../hooks/getFetch";
import axios from "axios";

function AddRecommedSupplier({
  t,
  tenderData,
  setMySuppliersUseFetchRender,
  mySuppliersUseFetchRender,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender } = GetContextValue();
  const { createMethod } = CrudContextValue();

  const [addSuppLoading, setAddSuppLoading] = useState(false);

  const [recommended, setRecommended] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(false);
  const [recommendedError, setRecommendedError] = useState(null);
  const [recommendedRender, setRecommendedRender] = useState(false);

  useEffect(() => {
    if (tenderData && tenderData.categoryId) {
      const cataegoryIds = tenderData.categoryId.map(
        (categ) => categ.categoryId
      );

      const postData = {
        categoryIds: cataegoryIds,
        companyId: loginUserCompanyId,
      };

      async function getData() {
        setRecommendedLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_POST_RECOMENDED_SUPPLIER_URL,
            JSON.stringify(postData),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setRecommendedError(responseSupp.data.isError);
            }
          } else {
            setRecommended(responseSupp.data.data);
          }
        } catch (error) {
          console.log(
            "REACT_APP_POST_RECOMENDED_SUPPLIER_URL, catchError :",
            error
          );
          setRecommendedError(error);
        }
        setRecommendedLoading(false);
      }

      if (token && tenderData && tenderData.categoryId) {
        getData();
      }
    }
  }, [loginUserCompanyId, tenderData, notificationRender, recommendedRender]);

  async function handleAddSupplier(supplierId) {
    setAddSuppLoading(true);

    const newSupplier = {
      supplierCompanyId: supplierId,
    };

    await createMethod(
      process.env.REACT_APP_POST_ADD_SUPPLIER_URL,
      JSON.stringify(newSupplier),
      token
    );

    setAddSuppLoading(false);

    setRecommendedRender(!recommendedRender);
    setMySuppliersUseFetchRender(!mySuppliersUseFetchRender);
  }

  return (
    <div className="add-recommended-supplier-container">
      <div className="add-recommended-supplier-top-box">
        <h4>{t("supplier_recommended_title")}</h4>
      </div>
      <div className="add-recommended-supplier-table">
        {recommendedError ? (
          <ErrorComponent error={recommendedError} />
        ) : recommendedLoading ? (
          <Loading />
        ) : recommended !== null && recommended.length > 0 ? (
          <AddRecommedSupplierTable
            total={null}
            page={null}
            setPage={null}
            rowsPerPage={null}
            setRowsPerPage={null}
            data={recommended}
            onSubmit={handleAddSupplier}
          />
        ) : (
          <div className="table-null-text">{t("message_emty_companylist")}</div>
        )}
      </div>

      {addSuppLoading && <LoadingCircular />}
    </div>
  );
}

export default withNamespaces()(AddRecommedSupplier);
