import React from "react";
import { withNamespaces } from "react-i18next";

import { Form, Input, Modal, Button } from "antd";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}

const AddPropertyForm = ({ t, visible, onCancel, onAddProperty }) => {
  const [form] = Form.useForm();

  const handleAddProperty = () => {
    form.validateFields().then((values) => {
      onAddProperty({ ...values, id: generateId() });
      form.resetFields();
    });
  };

  return (
    <Modal
      title={t("new_property_add_title")}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("button_edit_cancel")}
        </Button>,
        <Button key="add" type="primary" onClick={handleAddProperty}>
          {t("button_edit_add")}
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          label={t("input_title")}
          name="name"
          rules={[{ required: true, message: t("message_compulsory") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("input_content")}
          name="value"
          rules={[{ required: true, message: t("message_compulsory") }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withNamespaces()(AddPropertyForm);
