import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../../../components/form_components/Buttons/WhiteButton/WhiteButton";
import TenderForm from "../../../components/TenderForm/TenderForm";

function TenderAddStepOne({
  t,
  onNext,
  formData,
  step,
  selectedCategories,
  setSelectedCategories,
  properties,
  setProperties,
}) {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [stepOneForm, setStepOneForm] = useState({
    description: formData.description ? formData.description : "",
    productName: formData.productName ? formData.productName : "",
    currency: formData.currency ? formData.currency : "TRY",
    amount: formData.amount ? formData.amount : 0,
    maxPrice: formData.maxPrice ? formData.maxPrice : 0,
    minPrice: formData.minPrice ? formData.minPrice : 0,
    expireDate: formData.expireDate ? formData.expireDate : "",
    deadline: formData.deadline ? formData.deadline : "",
    categoryId: formData.categoryId ? formData.categoryId : [0],
    unitType: formData.unitType ? formData.unitType : 1,
    customer: formData.customer ? formData.customer : "string",
    images: formData.images ? formData.images : [],
    note: formData.note ? formData.note : "",
    properties: [],
    recursive: false,
    details:{}
  });
  
  /* form hata durumları */
  const [stepOneFormErrors, setStepOneFormErrors] = useState({
    productNameError: false,
    currencyError: false,
    amountError: false,
    maxPriceError: false,
    minPriceError: false,
    expireDateError: false,
    deadlineError: false,
    categoryIdError: false,
    unitTypeError: false,
  });

  const handleNext = () => {
    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!stepOneForm.productName) {
      inputErrors.productNameError = true;
    }

    if (!stepOneForm.currency) {
      inputErrors.currencyError = true;
    }

    if (!stepOneForm.amount) {
      inputErrors.amountError = true;
    }

    if (!stepOneForm.maxPrice) {
      inputErrors.maxPriceError = true;
    }

    if (!stepOneForm.minPrice) {
      inputErrors.minPriceError = true;
    }

    if (!stepOneForm.expireDate) {
      inputErrors.expireDateError = true;
    }

    if (!stepOneForm.recursive && !stepOneForm.deadline) {
      inputErrors.deadlineError = true;
    }

    if (!stepOneForm.categoryId) {
      inputErrors.categoryIdError = true;
    }

    if (!stepOneForm.unitType) {
      inputErrors.unitTypeError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setStepOneFormErrors(inputErrors);
      alert(t("message_emty_form"));
    } else {
      const offerData = {
        ...stepOneForm,
        amount: parseInt(stepOneForm.amount),
        unitType: Number(stepOneForm.unitType),
      };

      setStepOneFormErrors({
        productNameError: false,
        currencyError: false,
        amountError: false,
        maxPriceError: false,
        minPriceError: false,
        expireDateError: false,
        deadlineError: false,
        categoryIdError: false,
        unitTypeError: false,
      });

      onNext(offerData);
    }
  };

  return (
    <div className="step-one-container">
      <div className="step-one-top">
        <div className="title-box">
          <WhiteButton
            icon={<i className="fa-solid fa-chevron-left"></i>}
            text={t("button_edit_back")}
            onClick={() => navigate(-1)}
          />

          <div className="title">
            <i className="fa-solid fa-plus"></i>
            <h4>{t("create_tender_title")}</h4>
          </div>
        </div>

        <WhiteButton
          className={`${step !== 1 ? "display-none" : ""}`}
          text={<i className="fa-solid fa-chevron-right"></i>}
          icon={step === 1 ? t("button_edit_next") : ""}
          onClick={handleNext}
          disabled={step !== 1}
        />
      </div>
      <TenderForm
        setStepOneForm={setStepOneForm}
        stepOneForm={stepOneForm}
        documents={documents}
        setDocuments={setDocuments}
        images={images}
        setImages={setImages}
        stepOneFormErrors={stepOneFormErrors}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        properties={properties}
        setProperties={setProperties}
      />
    </div>
  );
}

export default withNamespaces()(TenderAddStepOne);
