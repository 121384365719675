import "./tutorialModal.css";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";

import { Modal, Space } from "antd";

function TutorialModal({t}) {
  useEffect(() => {
    const isFirstTimeUser = localStorage.getItem("isFirstTimeUser");

    if (isFirstTimeUser) {
      showTutorialModal();
      localStorage.removeItem("isFirstTimeUser");
    }
  }, []);

  const showTutorialModal = () => {
    Modal.info({
      width: "60%",
      title: "Bilgilendirme",
      content: (
        <div className="tutorial-modal-container">
          <h1 className="title">{t("tutorial_modal_title")}</h1>
          <p>
          {t("tutorial_modal_text1_1")}
          </p>
          <p>
            {t("tutorial_modal_text2_1")} <span className="ask-icon">"?"</span> {t("tutorial_modal_text2_2")} <span className="howtowork-text">"{t("tutorial_modal_howtowork_text")}"</span> {t("tutorial_modal_text2_3")}
          </p>
        </div>
      ),
      okText: t("button_ok"),
      onOk() {},
    });
  };

  return <Space></Space>;
}

export default withNamespaces()(TutorialModal);
