import "./styles/resetPassword.css";

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import axios from "axios";
import { toast } from "react-toastify";

import ButtonWhiteOpacity from "../../components/form_components/Buttons/ButtonWhiteOpacity/ButtonWhiteOpacity";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";

import icons from "../../assets/icons/index";
import LangBox from "../../components/LangBox/LangBox";
import { ThemeContextValue } from "../../context/ThemeContext";

const { offerexLogoDark, offerexLogoLight } = icons;

function ResetPassword({ t }) {
  const navigate = useNavigate();
  const { userId, resetToken } = useParams();

  const { theme } = ThemeContextValue();

  const localValues = JSON.parse(localStorage.getItem("setting-values"));

  const [language, setLanguage] = useState(
    localValues ? localValues.systemLanguage : "tr"
  );

  const [resetPassLoading, setResetPassLoading] = useState(false);

  const initialResetPasswordValues = {
    userId: userId,
    resetToken: resetToken,
    newPassword: "",
  };

  const [resetPasswordValues, setResetPasswordValues] = useState(
    initialResetPasswordValues
  );
  const [confirmPassword, setConfirmPassword] = useState("");

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "newPassword") {
      setResetPasswordValues({
        ...resetPasswordValues,
        newPassword: value,
      });
    }

    if (name === "language") {
      setLanguage(value);

      if (localValues) {
        localStorage.setItem(
          "setting-values",
          JSON.stringify({ ...localValues, systemLanguage: value })
        );
      } else {
        localStorage.setItem(
          "setting-values",
          JSON.stringify({ systemLanguage: value })
        );
      }
    }
  }

  /* reset token onay işlemi */
  useEffect(() => {
    async function verifyToken() {
      const response = await axios.post(
        `${process.env.REACT_APP_POST_RESET_TOKEN_URL}?resetToken=${resetToken}&userId=${userId}`
      );

      console.log("responseVerifyToken: ", response);
    }

    verifyToken();
  }, [userId, resetToken]);

  async function handleSubmit(e) {
    e.preventDefault();

    async function registerPost() {
      setResetPassLoading(true);

      try {
        const response = await axios.post(
          process.env.REACT_APP_POST_UPDATE_PASSWORD_URL,
          JSON.stringify(resetPasswordValues),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log("sonuc : ", response);

        if (!response.data.isError) {
          toast.info(t("message_info_register_success"), {
            autoClose: 3000, // 3 saniye sonra otomatik olarak kapat
          });

          setTimeout(() => {
            navigate("/login");
          }, 3000);

          toast.success(response.data.data, {
            autoClose: 2000, // 2 saniye sonra otomatik olarak kapat
          });
        } else {
          response.data.errorMessageList.map((message) => toast.error(message));
        }

        setResetPassLoading(false);
      } catch (err) {
        console.log("REACT_APP_POST_CONFIRMED_REGISTER_URL error : ", err);

        toast.error(t("message_error_catch_response"));

        setResetPassLoading(false);
      }
    }

    if (resetPasswordValues.newPassword === confirmPassword) {
      console.log("resetPasswordValues : ", resetPasswordValues);
      await registerPost();
    } else {
      console.log("resetPasswordValues : ", resetPasswordValues);

      toast.error(t("message_no_same_password_and_confirm_password"));
    }
  }

  return (
    <div className="reset-password-container">
      <div className="offerex-logo-container">
        <img
          src={
            theme === "light"
              ? offerexLogoDark
              : theme === "dark"
              ? offerexLogoLight
              : offerexLogoDark
          }
          alt="offerex-logo"
        />
      </div>

      <form onSubmit={handleSubmit} className="reset-password-box">
        <h4>{t("update_password_title")}</h4>

        <p>{t("update_password_info_new_password")}</p>

        <div className="form-line">
          <InputStandart
            required={true}
            name="newPassword"
            type="password"
            title={t("input_password")}
            placeholder={`${t("input_password")}...`}
            onChange={handleChange}
            value={resetPasswordValues.password}
          />

          <InputStandart
            required={true}
            name="confirmPassword"
            type="password"
            title={t("input_password_again")}
            placeholder={`${t("input_password_again")}...`}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
        </div>

        <div className="reset-password-buttons">
          <ButtonWhiteOpacity
            type="submit"
            text={resetPassLoading ? t("loading") : t("button_form_reset_password")}
            className="button-apply"
            disabled={
              resetPassLoading ||
              !resetPasswordValues.newPassword ||
              !confirmPassword
            }
          />

          <ButtonWhiteOpacity
            type="button"
            text={t("button_edit_cancel")}
            className="button-apply"
            onClick={() => navigate("/")}
          />
        </div>
      </form>

      <div className="lang-box">
        <LangBox />
      </div>

      <div className="copyright-container">
        <p>OETech Solutions - 2023</p>
      </div>
    </div>
  );
}

export default withNamespaces()(ResetPassword);
