import { useState, useEffect } from "react";
import axios from "axios";
import { AuthContextValue } from "../context/AuthContext";

function useFetchPost(url, postData, token) {
  const { setErrorMessage } = AuthContextValue();
  const [useFetchRender, setUseFetchRender] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(10);

  useEffect(() => {
    setError(null);
    setLoading(true);

    async function getData() {
      try {
        const response = await axios.post(url, postData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        });

        setData(response.data.data);

        if (response.data.isError) {
          //data yoksa null geliyorsa error komponentinde error mesajı görünmemesi için
          if (response.data.errorMessageList[0] !== "Sonuç bulunamadı") {
            setError(response.data.errorMessageList[0]);
          }
        }

        if (response.data.total > 0) {
          setTotal(response.data.total);
        }

        setLoading(false);

        return response.data;
      } catch (error) {
        console.log("error : ", error);

        if (
          (error && error.code === "ECONNABORTED") ||
          error.code === "ERR_BAD_RESPONSE"
        ) {
          setErrorMessage(error.code);
          // navigate("/error", { state: { message: error.message } });
          console.log(error);
        }

        setError(error);

        setLoading(false);

        return { catch: error };
      }
    }

    if (token) {
      getData();
    }
  }, [url, total, useFetchRender]);

  return { error, loading, data, total, setUseFetchRender, useFetchRender };
}

export default useFetchPost;
