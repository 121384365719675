import "./inputLogin.css";

function InputLogin({
  type,
  required,
  title,
  name,
  placeholder,
  disabled,
  errorMessage,
  errorBorder,
  className,
  ...props
}) {
  return (
    <div className={`input-login-container ${className}`}>
      <label htmlFor={name}>{`${required ? "*" : ""} ${title}`} : </label>
      <div className="input-login">
        <input
          {...props}
          required={required}
          className={
            disabled ? "input-login-disabled" : errorBorder ? "input-error" : ""
          }
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={disabled}
        />
        {errorMessage && (
          <div className="input-error-message">{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default InputLogin;
