import React, { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { getFetch } from "../../../hooks/getFetch";
import { AuthContextValue } from "../../../context/AuthContext";

const DistrictComponent = ({
  required,
  selectedCity,
  setSelectedDistrict,
  selectedDistrict,
}) => {
  const { token } = AuthContextValue();

  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFetch(
          `${process.env.REACT_APP_GET_DISTRICTS_URL}?cityId=${selectedCity}`,
          token
        );
        if (response.data) {
          setDistricts(response.data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    if (selectedCity) {
      fetchData();
    }
  }, [selectedCity]);


  const handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    setSelectedDistrict(selectedDistrictId);
  };

  return (
    <CustomSelect
      required={required}
      value={selectedDistrict}
      options={districts}
      onChange={handleDistrictChange}
    />
  );
};

export default DistrictComponent;
