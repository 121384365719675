import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function ConfirmationTenders({ t, tableFilter, searchText, tableRender }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [confirmationTendersPage, setConfirmationTendersPage] = useState(1);
  const [confirmationTendersRowsPerPage, setConfirmationTendersRowsPerPage] =
    useState(10);

  const status = 1;

  const {
    setUseFetchRender: setConfirmationTendersUseFetchRender,
    useFetchRender: confirmationTendersUseFetchRender,
    error: confirmationTendersError,
    loading: confirmationTendersLoading,
    data: confirmationTenders,
    total: confirmationTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?Status=${status}&CompanyId=${loginUserCompanyId}&Last=false&SortType=${tableFilter}&SearchedText=${searchText}&Page=${confirmationTendersPage}&PageSize=${confirmationTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: t("table_photo"),
    },
    {
      key: "status",
      header: t("table_status"),
    },
    {
      key: "tenderNumber",
      header: t("table_tender_number"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "minPrice",
      header: t("table_min_price"),
    },
    {
      key: "maxPrice",
      header: t("table_max_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setConfirmationTendersUseFetchRender(!confirmationTendersUseFetchRender);
  }, [tableRender, notificationRender]);

  return (
    <>
      {confirmationTendersError ? (
        <ErrorComponent error={confirmationTendersError} />
      ) : confirmationTendersLoading ? (
        <Loading />
      ) : confirmationTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={confirmationTendersUseFetchRender}
          setUseFetchRender={setConfirmationTendersUseFetchRender}
          total={confirmationTendersTotal}
          page={confirmationTendersPage}
          setPage={setConfirmationTendersPage}
          rowsPerPage={confirmationTendersRowsPerPage}
          setRowsPerPage={setConfirmationTendersRowsPerPage}
          columns={columns}
          data={confirmationTenders}
        />
      ) : (
        <div className="table-null-text">
          {t("message_no_confirmation_tender")}
        </div>
      )}
    </>
  );
}

export default withNamespaces()(ConfirmationTenders);
