import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function ConfirmationOffers({ t }) {
  const { pathname } = useLocation();

  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [searchText, setSearchText] = useState("");

  const [confirmationOffersPage, setConfirmationOffersPage] = useState(1);
  const [confirmationOffersRowsPerPage, setConfirmationOffersRowsPerPage] =
    useState(10);

  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);

  const {
    setUseFetchRender: setConfirmationOffersUseFetchRender,
    useFetchRender: confirmationOffersUseFetchRender,
    error: confirmationOffersError,
    loading: confirmationOffersLoading,
    data: confirmationOffers,
    total: confirmationOffersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${loginUserCompanyId}&OfferStatus=OnayBekliyor&Last=false&SortType=${tableFilter}&page=${confirmationOffersPage}&pageSize=${confirmationOffersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: t("table_photo"),
    },
    {
      key: "offerCompanyCategoryName",
      header: t("table_category"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "materialPrice",
      header: t("table_materiel_price"),
    },
    {
      key: "laborPrice",
      header: t("table_labor_price"),
    },
    {
      key: "unitPrice",
      header: t("table_unit_price"),
    },
    {
      key: "totalPrice",
      header: t("table_total_price"),
    },
  ];

  useEffect(() => {
    setConfirmationOffersUseFetchRender(!confirmationOffersUseFetchRender);
  }, [notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <div className="offers-main">
      <div className="offers-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {/* <OfferEditBox
          type={"offer"}

          searchText={searchText}
          setSearchText={setSearchText}

          tableFilter={tableFilter}
          setTableFilter={setTableFilter}

          useFetchRender={confirmationOffersUseFetchRender}
          setUseFetchRender={setConfirmationOffersUseFetchRender}

          selectValueId={selectValueId}
          setSelectValueId={setSelectValueId}

          selectValue={selectValue}
          setSelectValue={setSelectValue}
        /> */}
      </div>

      <div className="page-link">
        <div className="opportunities-button-groups">
          <Link
            to="/opportunities"
            className={pathname === "/opportunities" ? "button-active" : ""}
          >
            {t("button_opportunities")}
          </Link>

          <hr />

          <Link
            to="/opportunities/offers"
            className={
              pathname === "/opportunities/offers" ? "button-active" : ""
            }
          >
            {t("button_offers")}
          </Link>

          <hr />

          <Link
            to="/opportunities/confirmation-offers"
            className={
              pathname === "/opportunities/confirmation-offers"
                ? "button-active"
                : ""
            }
          >
            {t("button_confirmation_offers")}
          </Link>

          <hr />

          <Link
            to="/opportunities/revised-offers"
            className={
              pathname === "/opportunities/revised-offers"
                ? "button-active"
                : ""
            }
          >
            {t("button_revised_offers")}
          </Link>
        </div>
      </div>

      <div className="offers-table">
        {confirmationOffersError ? (
          <ErrorComponent error={confirmationOffersError} />
        ) : confirmationOffersLoading ? (
          <Loading />
        ) : confirmationOffers !== null ? (
          <StandartTable
            type="offer"
            searchText={searchText}
            setSearchText={setSearchText}
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={confirmationOffersUseFetchRender}
            setUseFetchRender={setConfirmationOffersUseFetchRender}
            total={confirmationOffersTotal}
            page={confirmationOffersPage}
            setPage={setConfirmationOffersPage}
            rowsPerPage={confirmationOffersRowsPerPage}
            setRowsPerPage={setConfirmationOffersRowsPerPage}
            columns={columns}
            data={confirmationOffers}
          />
        ) : (
          <div className="table-null-text">
            {t("message_no_confirmation_offer")}
          </div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(ConfirmationOffers);
