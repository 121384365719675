import "./styles/homePage.css";
import React from "react";
import { withNamespaces } from "react-i18next";

import NotificationDetailCard from "../../components/Dashboard/NotificationDetailCard/NotificationDetailCard";
import OpportunitiesCard from "./OpportunitiesCard";
import OffersCard from "./OffersCard";
import WaitingOffersCard from "./WaitingOffersCard";
import DeadlinesDetailCard from "../../components/Dashboard/DeadlinesDetailCard/DeadlinesDetailCard";
import PerformancesDetailCard from "../../components/Dashboard/PerformancesDetailCard/PerformancesDetailCard";
import { GetContextValue } from "../../context/GetContext";
import WaitingTendersCard from "./WaitingTendersCard";
import TendersCard from "./TendersCard";
import BidsInTenders from "./BidsInTenders";
import TenderRevisedCard from "../../components/Dashboard/TenderRevisedCard/TenderRevisedCard";
import { AuthContextValue } from "../../context/AuthContext";
import AdminsCard from "../../components/Dashboard/AdminsCard/AdminsCard";
import CompanyApplications from "../../components/Dashboard/CompanyApplications/CompanyApplications";
import AllTenderCard from "./AllTenderCard";
import AllOfferCard from "./AllOfferCard";
import SupportRequestCard from "../../components/Dashboard/SupportRequestCard/SupportRequestCard";
import FirstTimeOfferexModal from "../../components/Modals/FirstTimeOfferexModal/FirstTimeOfferexModal";

import BannersContainer from "../../components/BannersContainer/BannersContainer";

function HomePage({ t }) {
  const { userRole } = AuthContextValue();
  const { companyDetail } = GetContextValue();

  return (
    <div className="homepage-container">
      <BannersContainer />

      <div className="homepage-detail-cards">
        <div className="card">
          <NotificationDetailCard />
        </div>
        <div className="card">
          {userRole === "Admin" ? <AdminsCard /> : <PerformancesDetailCard />}
        </div>
        <div className="card">
          {userRole === "Admin" ? (
            <CompanyApplications />
          ) : (
            <>
              {(companyDetail.companyType === 2 ||
                companyDetail.companyType === 3) && <DeadlinesDetailCard />}
              {companyDetail.companyType === 1 && <TenderRevisedCard />}
            </>
          )}
        </div>
      </div>
      <div className="homepage-tables">
        {userRole === "Admin" ? (
          <>
            <SupportRequestCard />
            <AllTenderCard />
            <AllOfferCard />
          </>
        ) : (
          <>
            {companyDetail.companyType === 1 ? (
              <>
                <div className="table">
                  <BidsInTenders />
                </div>
                <div className="table">
                  <TendersCard />
                </div>
                <div className="table">
                  <WaitingTendersCard />
                </div>
              </>
            ) : companyDetail.companyType === 2 ? (
              <>
                <div className="table">
                  <OpportunitiesCard />
                </div>
                <div className="table">
                  <OffersCard />
                </div>
                <div className="table">
                  <WaitingOffersCard />
                </div>
              </>
            ) : companyDetail.companyType === 3 ? (
              <>
                <div className="table">
                  <OpportunitiesCard />
                </div>
                <div className="table">
                  <OffersCard />
                </div>
                <div className="table">
                  <WaitingOffersCard />
                </div>
                <div className="table">
                  <BidsInTenders />
                </div>
                <div className="table">
                  <TendersCard />
                </div>
                <div className="table">
                  <WaitingTendersCard />
                </div>
              </>
            ) : (
              "null"
            )}
          </>
        )}
      </div>

      <FirstTimeOfferexModal />
    </div>
  );
}

export default withNamespaces()(React.memo(HomePage));
