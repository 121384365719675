import "./styles/supplyProcessDetail/supplyProcessDetail.css";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Loading from "../../components/Loading/Loading";
import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import OfferDetailCard from "../../components/OfferDetailCard/OfferDetailCard";
import useFetchIf from "../../hooks/useFetchIf";
import useFetch from "../../hooks/UseFetch";
import { GetContextValue } from "../../context/GetContext";
import { AuthContextValue } from "../../context/AuthContext";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function SupplyProcessDetail({ t }) {
  const { tenderId, offerId } = useParams();
  const { token } = AuthContextValue();
  const { notificationRender } = GetContextValue();

  //Burada routedan aldığım offerId parametresi ile istek yapıyorum
  //dönen değerden aldığım tenderId ile ihale detaylarını veritabanından çekiyorum.
  /* ihale detay isteği */
  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetchIf(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token,
    tenderId
  );

  const {
    error: offerDetailError,
    loading: offerDetailLoading,
    data: offerDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_DETAIL_URL}?id=${offerId}`,
    token
  );

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
  }, [notificationRender]);

  function convertPrice(price, currency) {
    return currency && currency !== "string"
      ? Number(price).toLocaleString(currency, {
          style: "currency",
          currency: currency,
        })
      : Number(price).toLocaleString("TRY", {
          style: "currency",
          currency: "TRY",
        });
  }

  function dateConvert(date) {
    if (date) {
      const formattedDate = new Date(date);

      // Tarih ve saat ayrı ayrı alınabilir
      const tarih = formattedDate.toLocaleDateString(); // Tarih örneği: "4 Eylül 2023"

      console.log(`Tarih: ${tarih}`);

      return `${tarih}`;
    } else {
      console.log("Date bilgisi bulunamadı.");
    }
  }
  console.log(tenderDetail);

  const generatePDF = () => {
    // PDF dokümanı için içerik ve formatı tanımlayın
    const documentDefinition = {
      content: [
        {
          columns: [
            // Sol üst: İhale portalı logosu
            {
              width: "20%", // Sol üstün genişliği
              text: "",
            },
            // Ortada: Başlık
            {
              width: "60%", // Ortanın genişliği
              stack: [
                { text: "OfferEx", style: "header" }, // Başlık
                {
                  text: t("supply_process_writer_pdf_title"),
                  style: "subHeader",
                }, // Alt Başlık
              ],
              alignment: "center", // Ortalama
            },
            // Sağ üst: Boş
            {
              width: "20%", // Sağ üstün genişliği
              text: "",
            },
          ],
        },
        // İhale Detayları ve Teklif Detayları
        {
          columns: [
            // Sol: İhale Detayları
            {
              width: "100%", // Solun genişliği
              stack: [
                {
                  text: t("supply_process_writer_pdf_tender_detail"),
                  style: "subHeader",
                }, // İhale alt başlık
                // İhale detaylarını burada ekleyin...
                {
                  text:
                    t("supply_process_writer_pdf_tender_number") +
                    tenderDetail.tenderNumber,
                },
                {
                  text:
                    t("supply_process_writer_pdf_product") +
                    tenderDetail.productName,
                },
                {
                  text:
                    t("supply_process_writer_pdf_companyname") +
                    tenderDetail.companyName,
                },
                {
                  text:
                    t("supply_process_writer_pdf_created_user") +
                    tenderDetail.createdUser,
                },
                {
                  text:
                    t("supply_process_writer_pdf_tender_description") +
                    tenderDetail.description,
                },
                {
                  text:
                    t("supply_process_writer_pdf_created_date") +
                    dateConvert(tenderDetail.createdAt),
                },
                {
                  text:
                    t("supply_process_writer_pdf_expire_date") +
                    dateConvert(tenderDetail.expireDate),
                },
                {
                  text:
                    t("supply_process_writer_pdf_deadline_date") +
                    dateConvert(tenderDetail.deadline),
                },
                {
                  text:
                    t("supply_process_writer_pdf_amount") +
                    `${tenderDetail.amount} ${
                      tenderDetail.unitType === 1
                        ? t("select_piece")
                        : tenderDetail.unitType === 2
                        ? t("select_ton")
                        : tenderDetail.unitType === 3
                        ? t("select_kg")
                        : tenderDetail.unitType === 4
                        ? t("select_g")
                        : tenderDetail.unitType === 5
                        ? t("select_meter")
                        : tenderDetail.unitType === 6
                        ? t("select_centimeter")
                        : tenderDetail.unitType === 7
                        ? t("select_milimeter")
                        : tenderDetail.unitType === 8
                        ? t("select_liter")
                        : tenderDetail.unitType === 9
                        ? t("select_inch")
                        : ""
                    }`,
                },
              ],
            },
          ],
        },

        {
          columns: [
            // Sağ: Teklif Detayları
            {
              width: "100%", // Sağın genişliği
              stack: [
                {
                  text: t("supply_process_writer_pdf_offer_detail"),
                  style: "subHeader",
                }, // Teklif alt başlık
                // Teklif detaylarını burada ekleyin...
                {
                  text:
                    t("supply_process_writer_pdf_companyname") +
                    offerDetail.offerCompanyName,
                },
                {
                  text:
                    t("supply_process_writer_pdf_created_user") +
                    offerDetail.createdUser,
                },
                {
                  text:
                    t("supply_process_writer_pdf_offer_description") +
                    offerDetail.description,
                },
                {
                  text:
                    t("supply_process_writer_pdf_offer_laborprice") +
                    convertPrice(offerDetail.laborPrice, offerDetail.currency),
                },
                {
                  text:
                    t("supply_process_writer_pdf_offer_materialprice") +
                    convertPrice(
                      offerDetail.materialPrice,
                      offerDetail.currency
                    ),
                },
                {
                  text:
                    t("supply_process_writer_pdf_offer_unitprice") +
                    convertPrice(offerDetail.unitPrice, offerDetail.currency),
                },

                {
                  text:
                    t("supply_process_writer_pdf_offer_shippingprice") +
                    convertPrice(offerDetail.shippingprice, offerDetail.currency),
                },

                {
                  text:
                    t("supply_process_writer_pdf_offer_totalprice") +
                    convertPrice(offerDetail.totalPrice, offerDetail.currency),
                },
                { text: t("supply_process_writer_pdf_deadline_date") + dateConvert(offerDetail.deadline) },
                {
                  text:
                  t("supply_process_writer_pdf_offer_created_date") +
                    dateConvert(offerDetail.offerCreatedAt),
                },
              ],
            },
          ],
        },

        // Sayfanın alt kısmında ihale usulleri ile ilgili teknik ve resmi bilgiler
        // {
        //   text: [
        //     "İhale usulleri ve ilgili teknik ve resmi bilgiler bu alanda yer alabilir. Bu alana ihale usulleri, katılım koşulları, süreçler ve diğer önemli bilgileri ekleyebilirsiniz.",
        //     "Ayrıca, ihale ve tekliflerinizin nasıl işlediği hakkında bilgiler de burada yer alabilir.",
        //   ],
        //   style: "infoText",
        // },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 10, 0, 10],
        },
        subHeader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 10],
        },
        infoText: {
          fontSize: 12,
          margin: [0, 10, 0, 10],
        },
      },
    };

    if (tenderDetail && offerDetail) {
      // PDF dokümanını oluşturun
      const pdfDoc = pdfMake.createPdf(documentDefinition);

      // PDF'i indirme işlemi
      pdfDoc.download(t("supply_process_writer_pdf_name"));
    } else {
      alert(t("error"));
    }
  };

  return (
    <div className="supplyprocess-detail-container">
      <div className="supplyprocess-detail-container-top">
        <div className="top-box">
          <div className="supplyprocess-detail-title">
            <i className="fa-solid fa-ticket-simple"></i>
            <h4>{t("procuremen_process_detail_title")}</h4>
          </div>

          <div className="supplyprocess-edit-buttons">
            <WhiteButton text={t("button_create_pdf")} onClick={generatePDF} />
          </div>
        </div>
      </div>

      <div className="supplyprocess-detail-container-bottom">
        <div className="supplyprocess-tender-detail">
          {tenderDetailError ? (
            <ErrorComponent error={tenderDetailError} />
          ) : tenderDetailLoading ? (
            <Loading />
          ) : tenderDetail !== null ? (
            <TenderDetailCard tenderDetail={tenderDetail} />
          ) : null}
        </div>

        <div className="supplyprocess-offer-detail">
          {offerDetailError ? (
            <ErrorComponent error={offerDetailError} />
          ) : offerDetailLoading ? (
            <Loading />
          ) : offerDetail !== null ? (
            <OfferDetailCard offerDetail={offerDetail} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(SupplyProcessDetail);
