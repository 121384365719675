import './inputRadioText.css';

function InputRadioText({
    id,
    label,
    value,
    name,
    formValue,

    ...props
}) {

    
    return (
        <div className="inputradiotext-container">
            <label
                htmlFor={id}
                className={formValue === value ? "inputradiotext-active" : ""}
            >
                {label}
            </label>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}

                {...props}
            />
        </div>
    )
}

export default InputRadioText
