import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import Loading from "../../components/Loading/Loading";
import useFetch from "../../hooks/UseFetch";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function ConfirmationPendingOpportunities({
  t,
  tableFilter,
  searchText,
  tableRender,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [
    confirmationPendingOpportunitiesPage,
    setConfirmationPendingOpportunitiesPage,
  ] = useState(1);
  const [
    confirmationPendingOpportunitiesRowsPerPage,
    setConfirmationPendingOpportunitiesRowsPerPage,
  ] = useState(10);

  const opportunitiesStatus = 1;

  const {
    setUseFetchRender: setConfirmationPendingOpportunitiesUseFetchRender,
    useFetchRender: confirmationPendingOpportunitiesUseFetchRender,
    error: confirmationPendingOpportunitiesError,
    loading: confirmationPendingOpportunitiesLoading,
    data: confirmationPendingOpportunities,
    total: confirmationPendingOpportunitiesTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_SUPPLIER_LIST_URL}?SortType=${tableFilter}&Status=${opportunitiesStatus}&CompanyId=${loginUserCompanyId}&Page=${confirmationPendingOpportunitiesPage}&PageSize=${confirmationPendingOpportunitiesRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "amount",
      header: t("table_amount"),
    },
    {
      key: "distance",
      header: t("table_distance"),
    },
    {
      key: "duration",
      header: t("table_duration"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "remainingTime",
      header: t("table_remaining_time"),
    },
  ];

  useEffect(() => {
    setConfirmationPendingOpportunitiesUseFetchRender(
      !confirmationPendingOpportunitiesUseFetchRender
    );
  }, [tableRender, notificationRender]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  return (
    <>
      {confirmationPendingOpportunitiesError ? (
        <ErrorComponent error={confirmationPendingOpportunitiesError} />
      ) : confirmationPendingOpportunitiesLoading ? (
        <Loading />
      ) : confirmationPendingOpportunities !== null &&
        confirmationPendingOpportunities.length > 0 ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={confirmationPendingOpportunitiesUseFetchRender}
          setUseFetchRender={setConfirmationPendingOpportunitiesUseFetchRender}
          total={confirmationPendingOpportunitiesTotal}
          page={confirmationPendingOpportunitiesPage}
          setPage={setConfirmationPendingOpportunitiesPage}
          rowsPerPage={confirmationPendingOpportunitiesRowsPerPage}
          setRowsPerPage={setConfirmationPendingOpportunitiesRowsPerPage}
          columns={columns}
          data={confirmationPendingOpportunities}
        />
      ) : (
        <div className="table-null-text">
          {t("message_no_active_opportunities")}
        </div>
      )}
    </>
  );
}

export default withNamespaces()(ConfirmationPendingOpportunities);
