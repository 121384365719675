import "../../styles/materiel/materiel.css";
import "./styles/opportunities.css";

import { Outlet } from "react-router-dom";
import { withNamespaces } from "react-i18next";

function OpportunitiesLayout({ t }) {
  return (
    <div className="opportunities-layout">
      {/* Tablolar buraya gelicek */}
      <Outlet />
    </div>
  );
}

export default withNamespaces()(OpportunitiesLayout);
