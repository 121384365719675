import "./historyOffersModal.css";
import { Modal } from "antd";

import { withNamespaces } from "react-i18next";
import { AuthContextValue } from "../../../context/AuthContext";
import { GetContextValue } from "../../../context/GetContext";
import { useEffect, useState } from "react";
import useFetch from "../../../hooks/UseFetch";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import Loading from "../../Loading/Loading";
import StandartTable from "../../Table/StandartTable/StandartTable";
import OfferDetailCard from "../../OfferDetailCard/OfferDetailCard";
import WhiteButton from "../../form_components/Buttons/WhiteButton/WhiteButton";

function HistoryOffersModal({
  t,
  isModalOpen,
  handleCloseModal,
  supplierId,
  tenderId,
  tableRender,
}) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [offerDetailData, setOfferDetailData] = useState();

  const [supplierOldOffersPage, setSupplierOldOffersPage] = useState(1);
  const [supplierOldOffersRowsPerPage, setSupplierOldOffersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setSupplierOldOffersUseFetchRender,
    useFetchRender: supplierOldOffersUseFetchRender,
    error: supplierOldOffersError,
    loading: supplierOldOffersLoading,
    data: supplierOldOffers,
    total: supplierOldOffersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_OFFER_LIST_URL}?OfferCompanyId=${supplierId}&TenderId=${tenderId}&Last=false&SortType=3&Page=${supplierOldOffersPage}&PageSize=${supplierOldOffersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: t("table_photo"),
    },
    {
      key: "offerCompanyName",
      header: t("table_issuing"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "totalPrice",
      header: t("table_total_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    // setSelectValueId(valueId);
    // setSelectValue(value);
    setOfferDetailData(value);
    console.log(value);
  }

  useEffect(() => {
    setSupplierOldOffersUseFetchRender(!supplierOldOffersUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <Modal
      width={"50%"}
      style={{ height: "500px" }}
      title={
        offerDetailData ? (
          <WhiteButton
            icon={<i className="fa-solid fa-chevron-left" />}
            tooltip={t("button_edit_back")}
            onClick={() => setOfferDetailData()}
          />
        ) : (
          <h4>
            <i className="fa-solid fa-clock-rotate-left"></i> {t("history_offer_title")}
          </h4>
        )
      }
      open={isModalOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={null}
    >
      <div className="history-offers-modal-container">
        {!offerDetailData ? (
          <>
            {supplierOldOffersError ? (
              <ErrorComponent error={supplierOldOffersError} />
            ) : supplierOldOffersLoading ? (
              <Loading />
            ) : supplierOldOffers !== null ? (
              <StandartTable
                type="offer"
                selectValueId={selectValueId}
                selectValue={selectValue}
                handleClickValue={handleClickValue}
                useFetchRender={supplierOldOffersUseFetchRender}
                setUseFetchRender={setSupplierOldOffersUseFetchRender}
                total={supplierOldOffersTotal}
                page={supplierOldOffersPage}
                setPage={setSupplierOldOffersPage}
                rowsPerPage={supplierOldOffersRowsPerPage}
                setRowsPerPage={setSupplierOldOffersRowsPerPage}
                columns={columns}
                data={supplierOldOffers}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_company_tenders")}
              </div>
            )}
          </>
        ) : (
          <OfferDetailCard offerDetail={offerDetailData} />
        )}
      </div>
    </Modal>
  );
}

export default withNamespaces()(HistoryOffersModal);
