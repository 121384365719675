import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function UserTenders({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [userTenderPage, setUserTenderPage] = useState(1);
  const [userTenderRowsPerPage, setUserTenderRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setUserTendersUseFetchRender,
    useFetchRender: userTendersUseFetchRender,
    error: userTenderError,
    loading: userTenderLoading,
    data: userTender,
    total: userTenderTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?UserId=${id}&Last=false&SortType=1&Page=${userTenderPage}&PageSize=${userTenderRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: t("table_photo"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "expireDate",
      header: t("table_expire_date"),
    },
    {
      key: "minPrice",
      header: t("table_min_price"),
    },
    {
      key: "maxPrice",
      header: t("table_max_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setUserTendersUseFetchRender(!userTendersUseFetchRender);
  }, [tableRender, notificationRender]);

  return (
    <>
      {userTenderError ? (
        <ErrorComponent error={userTenderError} />
      ) : userTenderLoading ? (
        <Loading />
      ) : userTender !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={userTendersUseFetchRender}
          setUseFetchRender={setUserTendersUseFetchRender}
          total={userTenderTotal}
          page={userTenderPage}
          setPage={setUserTenderPage}
          rowsPerPage={userTenderRowsPerPage}
          setRowsPerPage={setUserTenderRowsPerPage}
          columns={columns}
          data={userTender}
        />
      ) : (
        <div className="table-null-text">{t("message_user_no_tender")}</div>
      )}
    </>
  );
}

export default withNamespaces()(UserTenders);
