import "./inputSearch.css";
import { withNamespaces } from "react-i18next";

import icons from "../../../../assets/icons/index";
import SearchIcon from "../../../Icons/SearchIcon";

const { searchIcon } = icons;

function InputSearch({t, value, onChange }) {
  return (
    <div className="navbar-search">
      {/* <img src={searchIcon} alt="search icon" /> */}
      <SearchIcon color="#535B6A" width="58" height="58" />
      <input
        type="text"
        placeholder={`${t("button_edit_search")}...`}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default withNamespaces()(InputSearch);
