import "./companyDetailCard.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import DetailText from "../DetailText/DetailText";
import { getCompanyStatistics } from "../../hooks/getCompanyStatistics";
import { AuthContextValue } from "../../context/AuthContext";

function CompanyDetailCard({ t, companyDetail }) {
  const { token } = AuthContextValue();
  const [companyStatistic, setCompanyStatistic] = useState({});

  function addressFilter(address) {
    let output = "-";

    if (address) {
      if (address.city) {
        output = address.city;
      }

      if (address.district) {
        output = output.concat("/", address.district);
      }

      if (address.neighbourhood) {
        output = output.concat("/", address.neighbourhood);
      }

      if (address.street) {
        output = output.concat("/", address.street);
      }

      if (address.floorNumber) {
        output = output.concat("/", address.floorNumber, t("input_floor2"));
      }

      if (address.doorNumber) {
        output = output.concat("/", address.doorNumber);
      }
    }

    return output;
  }

  useEffect(() => {
    async function getData() {
      const responseData = await getCompanyStatistics(
        companyDetail.companyId,
        companyDetail.companyType,
        token
      );

      setCompanyStatistic(responseData);
    }

    if (companyDetail) {
      getData();
    }
  }, [companyDetail, token]);

  return (
    <div className="company-detail-card-container">
      <div className="company-detail-card-top-box">
        <div className="image-box">
          <img src={companyDetail.logo} alt="logo" />
          <div className="detail-image-box">
            <img
              src={companyDetail.logo}
              alt={companyDetail.logo}
              className="image-detail"
            />
          </div>
        </div>
        <div className="user-detail-name-role">
          <h4>
            <strong>{companyDetail.companyName} </strong>
          </h4>
          <p>
            {companyDetail.companyType === 1
              ? t("select_options_maincompany")
              : companyDetail.companyType === 2
              ? t("select_options_supplier")
              : companyDetail.companyType === 3
              ? t("select_options_maincompany_supplier")
              : "null"}
          </p>
        </div>
      </div>
      <div className="company-detail-card-bottom-box">
        <DetailText title={`${t("table_email")}`} text={companyDetail.email} />
        <DetailText title={`${t("table_phone_number")}`} text={companyDetail.phoneNumber} />
        <DetailText title={`${t("table_register_date")}`} text={companyDetail.createdAt} />
        <DetailText
          title={`${t("table_address")}`}
          text={addressFilter(companyDetail.addresses[0])}
        />
        <DetailText title={`${t("table_manager")}`} text={companyDetail.managerName} />
        <DetailText
          title={`${t("table_manager_mail")}`}
          text={companyDetail.managerEmail}
        />
        <DetailText
          title={`${t("table_company_category")}`}
          text={companyDetail.categories
              ?.map((category) => category.categoryName)
              .join(", ")}
        />
        {companyDetail.companyType === 1 && (
          <>
            <DetailText
              title={`${t("table_active_tender")}`}
              text={`${companyStatistic.tenderActive} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_finish_tender")}`}
              text={`${companyStatistic.tenderOver} ${t("select_piece")}`}
            />
            {/* <DetailText
              title={"Toplam İhale:"}
              text={`${companyStatistic.tenderTotal} ${t("select_piece")}`}
            /> */}
            <DetailText
              title={`${t("table_total_tender")}`}
              text={`${
                companyStatistic.tenderActive + companyStatistic.tenderOver
              } ${t("select_piece")}`}
            />
          </>
        )}

        {companyDetail.companyType === 2 && (
          <>
            <DetailText
              title={`${t("table_success_offer")}`}
              text={`${companyStatistic.offerSuccess} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_failed_offer")}`}
              text={`${companyStatistic.offerUnSuccessful} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_waiting_confirm_offer")}`}
              text={`${companyStatistic.offerWaitingApp} ${t("select_piece")}`}
            />
            {/* <DetailText
              title={`${t("table_total_offer")}`}
              text={`${companyStatistic.offerTotal} ${t("select_piece")}`}
            /> */}
            <DetailText
              title={`${t("table_total_offer")}`}
              text={`${
                companyStatistic.offerSuccess +
                companyStatistic.offerUnSuccessful +
                companyStatistic.offerWaitingApp
              } ${t("select_piece")}`}
            />
          </>
        )}

        {companyDetail.companyType === 3 && (
          <>
            <DetailText
              title={`${t("table_active_tender")}`}
              text={`${companyStatistic.tenderActive} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_finish_tender")}`}
              text={`${companyStatistic.tenderOver} ${t("select_piece")}`}
            />
            {/* <DetailText
              title={`${t("table_total_tender")}`}
              text={`${companyStatistic.tenderTotal} ${t("select_piece")}`}
            /> */}
            <DetailText
              title={`${t("table_total_tender")}`}
              text={`${
                companyStatistic.tenderActive + companyStatistic.tenderOver
              } ${t("select_piece")}`}
            />

            <DetailText
              title={`${t("table_success_offer")}`}
              text={`${companyStatistic.offerSuccess} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_failed_offer")}`}
              text={`${companyStatistic.offerUnSuccessful} ${t("select_piece")}`}
            />
            <DetailText
              title={`${t("table_waiting_confirm_offer")}`}
              text={`${companyStatistic.offerWaitingApp} ${t("select_piece")}`}
            />
            {/* <DetailText
              title={`${t("table_total_offer")}`}
              text={`${companyStatistic.offerTotal} ${t("select_piece")}`}
            /> */}
            <DetailText
              title={`${t("table_total_offer")}`}
              text={`${
                companyStatistic.offerSuccess +
                companyStatistic.offerUnSuccessful +
                companyStatistic.offerWaitingApp
              } ${t("select_piece")}`}
            />
          </>
        )}

        <DetailText
          title={t("table_score")}
          text={companyDetail.averageScore ? companyDetail.averageScore : "-"}
        />
      </div>
    </div>
  );
}

export default withNamespaces()(CompanyDetailCard);
