import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { AuthContextValue } from "../context/AuthContext";

function App() {
  const { token, loginUserId } = AuthContextValue();
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("disconnected");

  useEffect(() => {
    // SignalR bağlantısını oluşturun
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl("https://sosocrm.oetech.online/base-hub", {
        accessTokenFactory: () => token,
      }
      )
      .withAutomaticReconnect()
      .build();

    // Bağlantı durumu izleme
    newConnection.onclose((error) => {
      if (error) {
        setConnectionStatus("disconnected");
      } else {
        setConnectionStatus("disconnected");
      }
    });

    newConnection.on("ReceiveMessage", (user, message) => {
      const newMessage = `${user}: ${message}`;
      setMessages([...messages, newMessage]);
    });

    newConnection.on("receiveNotificationList", (title, body, values) => {
      const newMessage = `Bildirim: ${title} - ${body} - ${values}`;
      setMessages([...messages, newMessage]);
    });

    // Bağlantıyı başlatın ve sunucuya bağlanın
    newConnection
      .start()
      .then(() => {
        setConnection(newConnection);
        setConnectionStatus("connected");
      })
      .catch((err) => {
        setConnectionStatus("disconnected");
      });

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, []);

  const sendMessage = () => {
    if (connection && inputMessage && userId) {
      connection
        .invoke("SendMessage", userId, inputMessage)
        .then(() => {
          console.log("Mesaj başarıyla gönderildi.");
          setInputMessage("");
        })
        .catch((err) => {
          console.error("Mesaj gönderilirken bir hata oluştu:", err);
        });
    }
  };

  const sendNotification = () => {
    if (connection && inputMessage) {
      connection
        .invoke("SendNotification", {
          UserId: [userId],
          Title: "Başlık deneme",
          Body: inputMessage,
        })
        .then(() => {
          console.log("Bildirim başarıyla gönderildi.");
          setInputMessage("");
        })
        .catch((err) => {
          console.error("Bildirim gönderilirken bir hata oluştu:", err);
        });
    }
  };

  return (
    <div className="App">
      <h1>SignalR Example</h1>
      <div>
        <div>
          {connectionStatus === "disconnected" ? (
            <p>Bağlantı yok</p>
          ) : (
            <p>Bağlı</p>
          )}

          <input
            type="text"
            placeholder="UserID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />

          <input
            type="text"
            placeholder="Mesajınızı girin"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />

          <button onClick={sendMessage}>Gönder</button>
          <button onClick={sendNotification}>Bildirim Gönder</button>
        </div>

        <div>
          <h2>Gelen Mesajlar</h2>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
