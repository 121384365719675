import "./bannerPanel.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import Pagination from "../../components/Table/Pagination/Pagination";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import BannerPanelEditBox from "../../components/EditBox/BannerPanelEditBox";
import BannerEditForm from "../../components/BannerEditForm/BannerEditForm";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { Button, Modal } from "antd";
import { CrudContextValue } from "../../context/CrudContext";
import BannerAddForm from "../../components/BannerAddForm/BannerAddForm";
import { GetContextValue } from "../../context/GetContext";
import useFetchPost from "../../hooks/UseFetchPost";

// Format date to a more readable format
const formatReadableDate = (date) => {
  return new Date(date).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

function BannersPanel({ t }) {
  const { token, userRole } = AuthContextValue();
  const { updateMethod, createMethod } = CrudContextValue();
  const {
    notificationRender,
    setBannersContainerUseFetchRender,
    bannersContainerUseFetchRender,
  } = GetContextValue();

  const [loading, setLoading] = useState(false);

  const [editBanner, setEditBanner] = useState(null); // State for the banner to be edited
  const [confirmBanner, setConfirmBanner] = useState(null); // State for the banner to be confirmed
  const [isModalVisible, setIsModalVisible] = useState(false); // State for controlling modal visibility

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [bannerFilter, setBannerFilter] = useState("");
  const [bannerPage, setBannerPage] = useState(1);
  const [bannerRowsPerPage, setBannerRowsPerPage] = useState(10);

  // "" = "Hepsi"
  // 1 = "Onay Bekleyen"
  // 2 = "Aktif"
  // 3 = "Pasif"
  const {
    setUseFetchRender: setBannersUseFetchRender,
    useFetchRender: bannersUseFetchRender,
    error: bannersError,
    loading: bannersLoading,
    data: banners,
    total: bannersTotal,
  } = useFetchPost(
    `${process.env.REACT_APP_GET_MY_BANNER_LIST_URL}?Status=${bannerFilter}&Page=${bannerPage}&PageSize=${bannerRowsPerPage}`,
    null,
    token
  );

  useEffect(() => {
    setBannersUseFetchRender(!bannersUseFetchRender);
  }, [notificationRender]);

  useEffect(() => {
    setBannersContainerUseFetchRender(!bannersContainerUseFetchRender);
  }, [bannersUseFetchRender]);

  const handleOpenAddBannerModal = () => {
    setAddModalVisible(true);
  };
  const handleCloseAddBannerModal = () => {
    setAddModalVisible(false);
  };

  // YENİ REKLAM EKLEME İŞLEMİ
  const addBanner = async (addBannerData) => {
    console.log("addBannerData : ", addBannerData);
    setLoading(true);

    await createMethod(
      process.env.REACT_APP_POST_ADD_BANNER_URL,
      JSON.stringify(addBannerData),
      token
    );

    setLoading(false);

    handleCloseAddBannerModal();

    setBannersUseFetchRender(!bannersUseFetchRender);
  };

  // Function to handle editing a banner
  const handleEditBanner = (banner) => {
    setConfirmBanner(null);

    setEditBanner(banner);
    setIsModalVisible(true);
  };

  // REKLAM BİLGİLERİ GÜNCELLEME İŞLEMİ
  const performEditAction = async (updateBannerData) => {
    console.log("updateBannerData : ", updateBannerData);
    setLoading(true);

    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_BANNER_URL,
      JSON.stringify(updateBannerData),
      token
    );

    setLoading(false);

    setIsModalVisible(false);
    setEditBanner(null);

    setBannersUseFetchRender(!bannersUseFetchRender);
  };

  const handleActivateBanner = (banner) => {
    setEditBanner(null);

    setConfirmBanner({ action: "activate", data: banner });
    setIsModalVisible(true);
  };

  const handleDeactivateBanner = (banner) => {
    setEditBanner(null);

    setConfirmBanner({ action: "deactivate", data: banner });
    setIsModalVisible(true);
  };

  /* REKLAMI AKTİF ETME VE PASİFE ALMA İŞLEMLERİ */
  const performAction = async () => {
    setLoading(true);

    if (confirmBanner.action === "activate") {
      await updateMethod(
        `${process.env.REACT_APP_PUT_UPDATE_BANNER_STATUS_URL}?id=${
          confirmBanner.data.advertId
        }&status=${2}`,
        null,
        token
      );

      setBannersUseFetchRender(!bannersUseFetchRender);
    } else if (confirmBanner.action === "deactivate") {
      await updateMethod(
        `${process.env.REACT_APP_PUT_UPDATE_BANNER_STATUS_URL}?id=${
          confirmBanner.data.advertId
        }&status=${3}`,
        null,
        token
      );

      setBannersUseFetchRender(!bannersUseFetchRender);
    }

    setLoading(false);

    setIsModalVisible(false);
    setConfirmBanner(null);
  };

  if (userRole !== "Admin") {
    return;
  }

  return (
    <div className="banner-panel-container">
      <div className="banner-panel-edit-box">
        <BannerPanelEditBox
          tableFilter={bannerFilter}
          setTableFilter={setBannerFilter}
          useFetchRender={bannersUseFetchRender}
          setUseFetchRender={setBannersUseFetchRender}
          handleOpenAddBannerModal={handleOpenAddBannerModal}
        />
      </div>
      <div className="banner-panel-main">
        {bannersError ? (
          <ErrorComponent error={bannersError} />
        ) : bannersLoading ? (
          <LoadingCircular />
        ) : banners && banners.length > 0 ? (
          banners.map((banner, index) => {
            return (
              <div key={index} className="banner-card">
                <div className="image-box">
                  <img src={banner.image} alt=" banner-resim" />
                </div>
                <div className="detail-box">
                  <span
                    className={`banner-status ${
                      Number(banner.status) === 1
                        ? "banner-status-orange"
                        : Number(banner.status) === 2
                        ? "banner-status-green"
                        : "banner-status-red"
                    }`}
                  >
                    {Number(banner.status) === 1
                      ? t("banner_status_waiting")
                      : Number(banner.status) === 2
                      ? t("banner_status_active")
                      : t("banner_status_passive")}
                  </span>
                  <h4>
                    {t("banner_company_name")}: {banner.companyName}
                  </h4>
                  <p>
                    {t("banner_index")}: {banner.index}{" "}
                  </p>
                  <p>
                    {t("banner_duration")} : {banner.displayTime} (
                    {t("banner_duration_second")})
                  </p>
                  <p>
                    {t("banner_banner_size")} :{" "}
                    {Number(banner.advertSize) === 1
                      ? t("banner_small")
                      : Number(banner.advertSize) === 2
                      ? t("banner_medium")
                      : Number(banner.advertSize) === 3
                      ? t("banner_big")
                      : ""}{" "}
                  </p>
                  <p>
                    {t("banner_start_date")}:
                    <small> {formatReadableDate(banner.startDate)}</small>
                  </p>
                  <p>
                    {t("banner_end_date")}:
                    <small> {formatReadableDate(banner.finishDate)}</small>
                  </p>
                </div>

                <div className="edit-box">
                  <div className="status-buttons">
                    {(Number(banner.status) === 3 ||
                      Number(banner.status) === 1) && (
                      <Button
                        onClick={() => handleActivateBanner(banner)}
                        type="primary"
                      >
                        {t("banner_status_active_button")}
                      </Button>
                    )}
                    {(Number(banner.status) === 2 ||
                      Number(banner.status) === 1) && (
                      <Button
                        onClick={() => handleDeactivateBanner(banner)}
                        type="primary"
                        danger
                      >
                        {t("banner_status_passive_button")}
                      </Button>
                    )}
                  </div>
                  <Button
                    onClick={() => handleEditBanner(banner)}
                    type="primary"
                    ghost
                  >
                    {t("button_edit_update")}
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="table-null-text">{t("message_no_banner")}</div>
        )}

        {loading && <LoadingCircular />}
      </div>

      <div className="banner-panel-pagination">
        <Pagination
          page={bannerPage}
          setPage={setBannerPage}
          rowsPerPage={bannerRowsPerPage}
          setRowsPerPage={setBannerRowsPerPage}
          total={bannersTotal}
        />
      </div>

      <Modal
        style={{ top: 20 }}
        open={addModalVisible}
        onCancel={handleCloseAddBannerModal}
        footer={null}
      >
        <BannerAddForm
          onCancel={handleCloseAddBannerModal}
          onSubmit={addBanner}
        />
      </Modal>

      {/* Modal for editing or deleting */}
      <Modal
        style={{ top: 20 }}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        {editBanner && (
          <BannerEditForm
            banner={editBanner}
            onSubmit={performEditAction}
            onCancel={() => setIsModalVisible(false)}
          />
        )}

        {confirmBanner && (
          <div className="confirm-modal-container">
            <h3 className="modal-title">
              {t("banner_status_edit_modal_title")}
            </h3>
            <p>
              {confirmBanner.action === "activate"
                ? t("message_confirm_active_banner")
                : t("message_confirm_passive_banner")}
            </p>
            <div className="modal-actions-button">
              <Button type="primary" onClick={performAction}>
                {t("button_edit_confirm")}
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setConfirmBanner(null);
                  setIsModalVisible(false);
                }}
              >
                {t("button_edit_cancel")}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default withNamespaces()(BannersPanel);
