import { withNamespaces } from "react-i18next";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";


function ImageCropUploader({ t,image, setImage }) {
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };

  const onChange = ({ fileList: newFileList }) => {
    setImage(newFileList);
  };

  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  return (
    <ImgCrop showGrid rotationSlider aspectSlider showReset>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={image}
        onChange={onChange}
        onPreview={onPreview}
      >
        {image.length < 1 && t("button_edit_upload")}
      </Upload>
    </ImgCrop>
  );
}

export default withNamespaces()(ImageCropUploader);
