import "./pagination.css";

function Pagination({ total, page, setPage, rowsPerPage, setRowsPerPage }) {
  // const pages = [];
  // for (let i = 0; i < total; i++) {
  //     pages.push(i + 1);
  // }

  // Öğelerin sayfa sayısı hesaplanıyor
  const pageCount = Math.ceil(total / rowsPerPage);
  const pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push(i + 1);
  }

  // En fazla 5 sayfa göstermek için sayfa numaralarını sınırlayın
  const maxPages = 3;
  let startPage = Math.max(page - 1, 1);
  let endPage = Math.min(startPage + maxPages - 1, total);
  startPage = Math.max(endPage - maxPages + 1, 1);
  const limitedPages = pages.slice(startPage - 1, endPage);

  // İlk ve son sayfaları göster
  const firstPage = 1;
  const lastPage = pages.pop();
  const showFirst = page > firstPage + 1;
  const showLast = pageCount > 3 && pageCount - 1 > page;

  return (
    <div className="pagination">
      {showFirst && (
        <button
          key={firstPage}
          onClick={() => setPage(firstPage)}
          className={page === firstPage ? "pagination-active-button" : ""}
        >
          {firstPage}
        </button>
      )}

      {total < 10 ? null : page > 4 ? "..." : null}

      {pageCount >= 1 &&
        limitedPages.map((pageNumber) => {
          const firstItemIndex = (pageNumber - 1) * rowsPerPage;
          const lastItemIndex = firstItemIndex + rowsPerPage;
          const isActive = page === pageNumber;
          return (
            <button
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={isActive ? "pagination-active-button" : ""}
            >
              {pageNumber}
            </button>
          );
        })}

      {total < 10 ? null : page < lastPage - 4 ? "..." : null}

      {showLast && (
        <button
          key={lastPage}
          onClick={() => setPage(lastPage)}
          className={page === lastPage ? "pagination-active-button" : ""}
        >
          {lastPage}
        </button>
      )}
    </div>
  );
}

export default Pagination;

{
  /* limitedPages.map(pageNumber => {
                    return <button
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                        className={page === pageNumber ? "pagination-active-button" : ""}
                    >{pageNumber}</button>
                }) */
}
