import "../style/detailCardStyle/detailCardStyle.css";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";

import WhiteButtons from "../../form_components/Buttons/WhiteButton/WhiteButton";
import useFetch from "../../../hooks/UseFetch";
import { AuthContextValue } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import Loading from "../../Loading/Loading";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../../context/GetContext";

function TenderRevisedCard({t}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { notificationRender, setTendersPageLink } = GetContextValue();

  const status = 3;

  const {
    setUseFetchRender: setRevisedTendersUseFetchRender,
    useFetchRender: revisedTendersUseFetchRender,
    error: revisedTendersError,
    loading: revisedTendersLoading,
    data: revisedTenders,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?Status=${status}&CompanyId=${loginUserCompanyId}&Last=false&SortType=${3}&Page=${1}&PageSize=${5}`,
    token
  );

  useEffect(() => {
    setRevisedTendersUseFetchRender(!revisedTendersUseFetchRender);
  }, [notificationRender]);

  return (
    <div className="card-container">
      <div className="card-top">
        <div className="card-title">
          <i className="fa-solid fa-calendar-check"></i>
          <h5>{t("table_edit_tenders")}</h5>
        </div>
        <div className="card-buttons">
          <Link to={"/tenders"}>
            <WhiteButtons
              onClick={() => setTendersPageLink("Revize")}
              icon={<i className="fa-solid fa-info"></i>}
              tooltip={t("button_edit_detail")}
            />
          </Link>
        </div>
      </div>
      <div className="card-main">
        <ul>
          {revisedTendersError ? (
            <ErrorComponent error={revisedTendersError} />
          ) : revisedTendersLoading ? (
            <Loading />
          ) : revisedTenders !== null && revisedTenders.length > 0 ? (
            revisedTenders.map((tender) => {
              return (
                <li key={tender.tenderId}>
                  <p>{tender.productName}</p>
                  <p>{tender.createdUser}</p>
                </li>
              );
            })
          ) : (
            <p className="table-null-text">{t("message_no_revised_tender")}</p>
          )}
        </ul>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderRevisedCard);
