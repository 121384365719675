import "./styles/bannerCardStyles.css";
import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { GetContextValue } from "../../../context/GetContext";

function BannerCardSmall({
  t,
  bannerData,
  currentIndexSmallBanner,
  setCurrentIndexSmallBanner,
}) {
  
  useEffect(() => {
    let interval;

    if (bannerData) {
      interval = setInterval(() => {
        if (currentIndexSmallBanner < bannerData.length - 1) {
          setCurrentIndexSmallBanner(currentIndexSmallBanner + 1);
        } else {
          setCurrentIndexSmallBanner(0);
        }
      }, bannerData[currentIndexSmallBanner]?.displayTime * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [bannerData, currentIndexSmallBanner]);

  return (
    <div className="banner-card-container banner-small">
      {bannerData && bannerData.length > 0 ? (
        <div className="img-container">
          <img
            src={bannerData[currentIndexSmallBanner]?.image}
            alt={bannerData[currentIndexSmallBanner]?.companyName}
          />
        </div>
      ) : (
        <p className="table-null-text">{t("message_no_banner")}</p>
      )}
    </div>
  );
}

export default withNamespaces()(React.memo(BannerCardSmall));
