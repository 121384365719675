import React from 'react'
import { Outlet } from 'react-router-dom'

function CompanyListLayout() {
    return (
        <>

            <Outlet />

        </>
    )
}

export default CompanyListLayout
