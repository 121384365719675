import "./allTenders.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import AllTendersEditBox from "../../components/EditBox/AllTendersEditBox";

function AllTenders({ t }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [searchText, setSearchText] = useState("");
  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);

  const [allTendersPage, setAllTendersPage] = useState(1);
  const [allTendersRowsPerPage, setAllTendersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setAllTendersUseFetchRender,
    useFetchRender: allTendersUseFetchRender,
    error: allTendersError,
    loading: allTendersLoading,
    data: allTenders,
    total: allTendersTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_TENDER_LIST_URL
    }?Last=false&SortType=${3}&SearchedText=${searchText}&Page=${allTendersPage}&PageSize=${allTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: t("table_photo"),
    },
    {
      key: "status",
      header: t("table_status"),
    },
    {
      key: "tenderNumber",
      header: t("table_tender_number"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "minPrice",
      header:t("table_min_price"),
    },
    {
      key: "maxPrice",
      header: t("table_max_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setAllTendersUseFetchRender(!allTendersUseFetchRender);
  }, [notificationRender]);

  return (
    <div className="alltenders-container">
      <div className="alltenders-edit-box">
        <AllTendersEditBox
          searchText={searchText}
          setSearchText={setSearchText}
          tableFilter={tableFilter}
          setTableFilter={setTableFilter}
          useFetchRender={allTendersUseFetchRender}
          setUseFetchRender={setAllTendersUseFetchRender}
          selectValue={selectValue}
          selectValueId={selectValueId}
        />
      </div>
      <div className="alltenders-main">
        {allTendersError ? (
          <ErrorComponent error={allTendersError} />
        ) : allTendersLoading ? (
          <Loading />
        ) : allTenders !== null ? (
          <StandartTable
            type="tender"
            selectValueId={selectValueId}
            selectValue={selectValue}
            handleClickValue={handleClickValue}
            useFetchRender={allTendersUseFetchRender}
            setUseFetchRender={setAllTendersUseFetchRender}
            total={allTendersTotal}
            page={allTendersPage}
            setPage={setAllTendersPage}
            rowsPerPage={allTendersRowsPerPage}
            setRowsPerPage={setAllTendersRowsPerPage}
            columns={columns}
            data={allTenders}
          />
        ) : (
          <div className="table-null-text">{t("message_no_tender")}</div>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(AllTenders);
