import "./styles/bannerCardStyles.css";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { GetContextValue } from "../../../context/GetContext";

function BannerCardBig({
  t,
  bannerData,
  currentIndexBigBanner,
  setCurrentIndexBigBanner,
}) {

  useEffect(() => {
    if (bannerData) {
      const interval = setInterval(() => {
        if (currentIndexBigBanner < bannerData.length - 1) {
          setCurrentIndexBigBanner(currentIndexBigBanner + 1);
        } else {
          setCurrentIndexBigBanner(0);
        }
      }, bannerData[currentIndexBigBanner]?.displayTime * 1000);

      return () => clearInterval(interval);
    }
  }, [bannerData, currentIndexBigBanner]);

  return (
    <div className="banner-card-container banner-big">
      {bannerData && bannerData.length > 0 ? (
        <div className="img-container">
          <img
            src={bannerData[currentIndexBigBanner]?.image}
            alt={bannerData[currentIndexBigBanner]?.companyName}
          />
        </div>
      ) : (
        <p className="table-null-text">{t("message_no_banner")}</p>
      )}
    </div>
  );
}

export default withNamespaces()(React.memo(BannerCardBig));
