import "./sidebar.css";

import { Link, useLocation } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import DashboardIcon from "../Icons/DashboardIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import StarIcon from "../Icons/StarIcon";
import BuildingIcon from "../Icons/BuildingIcon";
import TicketIcon from "../Icons/TicketIcon";
import FactoryIcon from "../Icons/FactoryIcon";
import ReportIcon from "../Icons/ReportIcon";
import UsersIcon from "../Icons/UsersIcon";
import SettingIcon from "../Icons/SettingIcon";
import images from "../../assets/images/index";

import { AuthContextValue } from "../../context/AuthContext";
import { getFetch } from "../../hooks/getFetch";
import { useEffect, useState } from "react";
import { GetContextValue } from "../../context/GetContext";
import { Tooltip } from "antd";

function Sidebar({ t, openSideBar }) {
  const { pathname } = useLocation();
  const { userRole, loginUserCompanyId, token } = AuthContextValue();
  const { unReadCount } = GetContextValue();

  const [companyType, setCompanyType] = useState();

  // Aktif linkin kontrolü için bir fonksiyon oluşturun
  const isActiveLink = (link) => {
    const firstPath = pathname.split("/")[1]; // İlk rota

    return firstPath === link.split("/")[1];
  };

  useEffect(() => {
    async function getCompDetail() {
      await getFetch(
        `${process.env.REACT_APP_GET_COMPANY_DETAIL_URL}?id=${loginUserCompanyId}`,
        token
      ).then((response) =>
        response.data.data
          ? setCompanyType(response.data.data[0].companyType)
          : null
      );
    }

    if (loginUserCompanyId) {
      getCompDetail();
    }
  }, [loginUserCompanyId]);

  return (
    <div className="sidebar-container">
      <ul>
        <li>
          <Tooltip placement="top" title={t("sidebar_homepage")}>
            <Link
              to="/"
              className={
                isActiveLink("/")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i className="fa-solid fa-house"></i>
              {!openSideBar && <span>{t("sidebar_homepage")}</span>}
            </Link>
          </Tooltip>
        </li>

        <li className="notification-li">
          <Tooltip placement="top" title={t("sidebar_notifications")}>
            <Link
              to="/notifications"
              className={
                isActiveLink("/notifications")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i className="fa-solid fa-bell">
                {unReadCount ? (
                  <span className="notification-badge">{unReadCount}</span>
                ) : null}
              </i>

              {!openSideBar && <span>{t("sidebar_notifications")}</span>}
            </Link>
          </Tooltip>
        </li>

        <hr />

        {userRole === "Admin" && (
          <>
            <li>
              <Tooltip placement="top" title={t("sidebar_banner_panel")}>
                <Link
                  to="/banner-panel"
                  className={
                    isActiveLink("/banner-panel")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-regular fa-images"></i>
                  {!openSideBar && <span> {t("sidebar_banner_panel")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_support_request")}>
                <Link
                  to="/support-request"
                  className={
                    isActiveLink("/support-request")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-headset"></i>
                  {!openSideBar && <span>{t("sidebar_support_request")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_companylist")}>
                <Link
                  to="/company-list"
                  className={
                    isActiveLink("/company-list")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-table-list"></i>
                  {!openSideBar && <span>{t("sidebar_companylist")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_applicants")}>
                <Link
                  to="/companies-applying"
                  className={
                    isActiveLink("/companies-applying")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-house-circle-check"></i>
                  {!openSideBar && <span>{t("sidebar_applicants")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_tenders")}>
                <Link
                  to="/all-tender"
                  className={
                    isActiveLink("/all-tender")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-ticket-simple"></i>
                  {!openSideBar && <span>{t("sidebar_tenders")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_offers")}>
                <Link
                  to="/all-offer"
                  className={
                    isActiveLink("/all-offer")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i
                    className={`fa-solid fa-certificate ${
                      isActiveLink("/offers") ? "active-link" : ""
                    }`}
                  />
                  {!openSideBar && <span>{t("sidebar_offers")}</span>}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        {(companyType === 2 || companyType === 3) && (
          <>
            <li>
              <Tooltip placement="top" title={t("sidebar_opportunities")}>
                <Link
                  to="/opportunities"
                  className={
                    isActiveLink("/opportunities")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-star"></i>
                  {!openSideBar && <span>{t("sidebar_opportunities")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_my_offers")}>
                <Link
                  to="/offers"
                  className={
                    isActiveLink("/offers")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i
                    className={`fa-solid fa-certificate ${
                      isActiveLink("/offers") ? "active-link" : ""
                    }`}
                  />
                  {!openSideBar && <span>{t("sidebar_my_offers")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_main_companies")}>
                <Link
                  to="/maincompanies"
                  className={
                    isActiveLink("/maincompanies")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-building"></i>
                  {!openSideBar && <span>{t("sidebar_main_companies")}</span>}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        {(companyType === 1 || companyType === 3) && userRole !== "Admin" && (
          <>
            <li>
              <Tooltip placement="top" title={t("sidebar_tenders")}>
                <Link
                  to="/tenders"
                  className={
                    isActiveLink("/tenders")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-ticket-simple"></i>

                  {!openSideBar && <span>{t("sidebar_tenders")}</span>}
                </Link>
              </Tooltip>
            </li>

            <li>
              <Tooltip placement="top" title={t("sidebar_suppliers")}>
                <Link
                  to="/suppliers"
                  className={
                    isActiveLink("/suppliers")
                      ? "active-link"
                      : `${openSideBar ? "small-sidebar-links" : ""}`
                  }
                >
                  <i className="fa-solid fa-industry"></i>
                  {!openSideBar && <span>{t("sidebar_suppliers")}</span>}
                </Link>
              </Tooltip>
            </li>
          </>
        )}

        <li>
          <Tooltip placement="top" title={t("sidebar_procurement_process")}>
            <Link
              to="/supply-process"
              className={
                isActiveLink("/supply-process")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i
                className={
                  isActiveLink(
                    `/company-list/company-profile/${loginUserCompanyId}`
                  )
                    ? "active-link  fa-solid fa-boxes-packing"
                    : "fa-solid fa-boxes-packing"
                }
              ></i>
              {!openSideBar && <span>{t("sidebar_procurement_process")}</span>}
            </Link>
          </Tooltip>
        </li>

        <hr />

        <li>
          <Tooltip placement="top" title={t("sidebar_reports")}>
            <Link
              to="/reports"
              className={
                isActiveLink("/reports")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i className="fa-solid fa-chart-simple"></i>
              {!openSideBar && <span>{t("sidebar_reports")}</span>}
            </Link>
          </Tooltip>
        </li>

        <li>
          <Tooltip placement="top" title={t("sidebar_users")}>
            <Link
              to="/users"
              className={
                isActiveLink("/users")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i className="fa-solid fa-user-group"></i>
              {!openSideBar && <span>{t("sidebar_users")}</span>}
            </Link>
          </Tooltip>
        </li>

        {userRole === "Manager" && (
          <li>
            <Tooltip placement="top" title={t("sidebar_company_profile")}>
              <Link
                to={`/company-list/company-profile/${loginUserCompanyId}`}
                className={
                  isActiveLink(
                    `/company-list/company-profile/${loginUserCompanyId}`
                  )
                    ? "active-link"
                    : `${openSideBar ? "small-sidebar-links" : ""}`
                }
              >
                <i
                  className={
                    isActiveLink(
                      `/company-list/company-profile/${loginUserCompanyId}`
                    )
                      ? "active-link  fa-solid fa-warehouse"
                      : "fa-solid fa-warehouse"
                  }
                ></i>
                {!openSideBar && <span>{t("sidebar_company_profile")}</span>}
              </Link>
            </Tooltip>
          </li>
        )}

        <hr />

        {userRole !== "Admin" && (
          <li>
            <Tooltip placement="top" title={t("sidebar_advertise")}>
              <Link
                to="/banner-company"
                className={
                  isActiveLink("/banner-company")
                    ? "active-link"
                    : `${openSideBar ? "small-sidebar-links" : ""}`
                }
              >
                <i className="fa-regular fa-images"></i>
                {!openSideBar && <span>{t("sidebar_advertise")}</span>}
              </Link>
            </Tooltip>
          </li>
        )}
        {userRole !== "Admin" && (
          <li>
            <Tooltip placement="top" title={t("sidebar_support_request")}>
              <Link
                to="/support-request"
                className={
                  isActiveLink("/support-request")
                    ? "active-link"
                    : `${openSideBar ? "small-sidebar-links" : ""}`
                }
              >
                <i className="fa-solid fa-headset"></i>
                {!openSideBar && <span>{t("sidebar_support_request")}</span>}
              </Link>
            </Tooltip>
          </li>
        )}

        <li>
          <Tooltip placement="top" title={t("sidebar_howitwork")}>
            <Link
              to="/how-it-work"
              className={
                isActiveLink("/how-it-work")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i
                className={
                  isActiveLink(
                    `/company-list/company-profile/${loginUserCompanyId}`
                  )
                    ? "active-link  fa-solid fa-question"
                    : "fa-solid fa-question"
                }
              ></i>
              {!openSideBar && <span>{t("sidebar_howitwork")}</span>}
            </Link>
          </Tooltip>
        </li>

        <li>
          <Tooltip placement="top" title={t("sidebar_settings")}>
            <Link
              to="/settings"
              className={
                isActiveLink("/settings")
                  ? "active-link"
                  : `${openSideBar ? "small-sidebar-links" : ""}`
              }
            >
              <i className="fa-solid fa-gear"></i>
              {!openSideBar && <span>{t("sidebar_settings")}</span>}
            </Link>
          </Tooltip>
        </li>
      </ul>

      <div className="sidebar-logo">
        <img src={images.companyLogo} alt="company-logo" />
      </div>
    </div>
  );
}

export default withNamespaces()(Sidebar);
