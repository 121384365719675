import './confirmModal.css';
import ButtonDark from "../../form_components/Buttons/ButtonDark/ButtonDark";

function ConfirmModal({
    t,

    text,
    confirmButtonText,
    cancelButtonText,
    confirmIcon,
    cancelIcon,
    confirmFunc,
    handleCloseModal
}) {
    return (
        <div className='confirm-modal-container'>

            <div className='confirm-modal-main'>

                <div className="confirm-text-container">
                    <p>{text}</p>
                </div>

                <div className='confirm-modal-button-group'>
                    <ButtonDark
                        icon={confirmIcon}
                        text={confirmButtonText}
                        onClick={confirmFunc}
                        className="button-dark-active"
                    />
                    <ButtonDark
                        icon={cancelIcon}
                        text={cancelButtonText}
                        onClick={handleCloseModal}
                        className="button-dark-active"
                    />
                </div>

            </div>

        </div>
    )
}

export default ConfirmModal;
