import "./langBox.css";
import React from "react";
import images from "../../assets/images/index";

import i18n from "../../i18n/i18n";

function LangBox({ data, setData }) {
  const settings = JSON.parse(localStorage.getItem("setting-values"));

  const forceUpdate = React.useReducer(() => ({}), {})[1]; // forceUpdate işlevi

  function handleChangeLang(lang) {
    i18n.changeLanguage(lang);
    if (data && setData) {
      setData({ ...data, language: lang });
    }

    if (settings) {
      settings.systemLanguage = lang;

      console.log(settings);

      localStorage.setItem("setting-values", JSON.stringify(settings));
    }

    forceUpdate(); // Bileşeni yenilemek için forceUpdate işlevini çağırın
  }

  return (
    <div className="login-lang-container">
      <div
        className={`lang-button ${
          i18n.language === "tr" ? "active-lang" : "disabled-lang"
        }`}
        onClick={() => handleChangeLang("tr")}
      >
        <img src={images.turkishFlag} alt="" />
        <p className="text">Türkçe</p>
      </div>

      <div
        className={`lang-button ${
          i18n.language === "en" ? "active-lang" : "disabled-lang"
        }`}
        onClick={() => handleChangeLang("en")}
      >
        <img src={images.englishFlag} alt="" />
        <p className="text">English</p>
      </div>

      <div
        className={`lang-button ${
          i18n.language === "de" ? "active-lang" : "disabled-lang"
        }`}
        onClick={() => handleChangeLang("de")}
      >
        <img src={images.deutschFlag} alt="" />
        <p className="text">Deutsch</p>
      </div>

      <div
        className={`lang-button ${
          i18n.language === "fr" ? "active-lang" : "disabled-lang"
        }`}
        onClick={() => handleChangeLang("fr")}
      >
        <img src={images.françisFlag} alt="" />
        <p className="text">Français</p>
      </div>
    </div>
  );
}

export default LangBox;
