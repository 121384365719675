import i18n, { init } from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import tr_lang from "./lang/tr.json";
import en_lang from "./lang/en.json";
import de_lang from "./lang/de.json";
import fr_lang from "./lang/fr.json";

i18n.use(detector).use(reactI18nextModule);
init({
  debug: false,
  fallbackLng: "tr",
  resources: {
    tr: {
      translation: tr_lang,
    },
    en: {
      translation: en_lang,
    },
    de: {
      translation: de_lang,
    },
    fr: {
      translation: fr_lang,
    },
  },
});

export default i18n;
