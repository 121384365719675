import { createContext, useContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

function ThemeContextProvider({ children }) {
  const getSettingValues = JSON.parse(localStorage.getItem("setting-values"));

  const [theme, setTheme] = useState(
    getSettingValues ? getSettingValues.theme : "light"
  );

  useEffect(() => {
    localStorage.setItem(
      "setting-values",
      JSON.stringify({ ...getSettingValues, theme: theme })
    );


  }, [theme]);
console.log("aa");
  const values = {
    theme,
    setTheme,
    getSettingValues,
  };

  return (
    <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>
  );
}

export const ThemeContextValue = () => useContext(ThemeContext);

export default ThemeContextProvider;
