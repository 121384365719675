import "./notificationCard.css";
import React from "react";
import { GetContextValue } from "../../context/GetContext";

function NotificationCard() {
  const { contextHolder } = GetContextValue();

  return <>{contextHolder}</>;
}

export default NotificationCard;
