import "./styles/supplyProcess/supplyProcess.css";

import { useState } from "react";
import useFetch from "../../hooks/UseFetch";
import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";

import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import Loading from "../../components/Loading/Loading";
import SupplyProcessCardMainComp from "../../components/SupplyProcessCardMainComp/SupplyProcessCardMainComp";
import Pagination from "../../components/Table/Pagination/Pagination";

function ResultingOrders({ t, statusFilter, searchBar }) {
  const { token, loginUserCompanyId } = AuthContextValue();

  const [procurementPage, setProcurementPage] = useState(1);
  const [procurementRowsPerPage, setProcurementRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setProcurementUseFetchRender,
    useFetchRender: procurementUseFetchRender,
    error: procurementError,
    loading: procurementLoading,
    data: procurement,
    total: procurementTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_PROCUREMENT_LIST_URL}?TenderNumber=${searchBar}&TenderCompanyId=${loginUserCompanyId}&Status=5&Page=${procurementPage}&PageSize=${procurementRowsPerPage}`,
    token
  );

  return (
    <div className="procurement-container">
      <div className="procurement-content">
        {procurementError ? (
          <ErrorComponent error={procurementError} />
        ) : procurementLoading ? (
          <Loading />
        ) : procurement && procurement.length > 0 ? (
          procurement.map((tender) => {
            return (
              <SupplyProcessCardMainComp
                key={tender.TenderId}
                tenderId={tender.TenderId}
              />
            );
          })
        ) : (
          <div className="table-null-text">
            {t("message_no_tender_supplyprocess")}{" "}
          </div>
        )}
      </div>
      <div className="procurement-pagination">
        <Pagination
          page={procurementPage}
          setPage={setProcurementPage}
          rowsPerPage={procurementRowsPerPage}
          setRowsPerPage={setProcurementRowsPerPage}
          total={procurementTotal}
        />
      </div>
    </div>
  );
}

export default withNamespaces()(ResultingOrders);
