import React, { createContext, useContext, useState } from "react";

export const DemoContext = createContext();

function DemoContextProvider({ children }) {
  const [demoCompanyList, setDemoCompanyList] = useState([
    {
      addresses: [
        {
          buildNumber: "2",
          city: "İSTANBUL",
          district: "KADIKÖY",
          doorNumber: "2",
          floorNumber: "2",
          neighbourhood: "Moda",
          street: "Sokak",
        },
      ],
      averageScore: "7.5",
      categoryId: 150,
      categoryName: "Mühendislik",
      companyCategory: "Mühendislik",
      companyCode: "",
      companyId: 150,
      companyName: "Örnek Mühendislik",
      companyType: 1,
      createdAt: "06/15/2023 14:30:22",
      email: "ornek@muhendislik.com",
      logo: "http://example.com/logo.jpg",
      managerEmail: "ornek@muhendislik.com",
      managerName: "Örnek Kişi",
      phoneNumber: "987654321",
      refNo: null,
      taxLocation: "İstanbul",
      taxNumber: "12345678",
    },
    {
      addresses: [
        {
          buildNumber: "3",
          city: "ANKARA",
          district: "ÇANKAYA",
          doorNumber: "3",
          floorNumber: "3",
          neighbourhood: "Örnek Mahalle",
          street: "Örnek Sokak",
        },
      ],
      averageScore: "9.2",
      categoryId: 200,
      categoryName: "Tasarım",
      companyCategory: "Tasarım",
      companyCode: "",
      companyId: 200,
      companyName: "Örnek Tasarım",
      companyType: 1,
      createdAt: "07/01/2023 09:15:45",
      email: "ornek@tasarim.com",
      logo: "http://example.com/logo3.jpg",
      managerEmail: "ornek@tasarim.com",
      managerName: "Örnek Yönetici",
      phoneNumber: "1234567890",
      refNo: null,
      taxLocation: "Ankara",
      taxNumber: "98765432",
    },
  ]);
  const [demoSuppliers, setDemoSuppliers] = useState(
    [
      {
        companyId: 1,
        logo: "logo1.jpg",
        companyName: "Şirket 1",
        companyCategory: "Kategori 1",
        email: "ornek1@example.com",
        phoneNumber: "1234567890",
        distance: "5 km",
        city: "BURSA / YILDIRIM",
        averageScore: 7.5,
      },
      {
        companyId: 2,
        logo: "logo2.jpg",
        companyName: "Şirket 2",
        companyCategory: "Kategori 2",
        email: "ornek2@example.com",
        phoneNumber: "0987654321",
        distance: "3 km",
        city: "VARTO / MUŞ",
        averageScore: 5.5,
      },
    ].sort((a, b) => b.companyId - a.companyId)
  );
  const [demoTenders, setDemoTenders] = useState([
    {
      tenderId: 496,
      amount: 200,
      approveDate: "2023-06-26T10:01:59.5866667",
      approvedUser: "null",
      categories: [{ categoryId: 139, categoryName: "Afiş" }],
      companies: [
        { companyId: 1, companyName: "Şirket 1" },
        { companyId: 2, companyName: "Şirket 2" },
      ],
      companyId: 119,
      companyName: "Yılmaz Elektronik",
      confirmDescription: "Onaylandı",
      createdAt: "2023-06-26T10:00:04.3566667",
      createdUser: "Bayram Enes YILMAZ",
      currency: "TRY",
      customer: "string",
      deadline: "2023-07-07T00:00:00",
      description: "Cam bardak 3 ihalesi değiştirme denemesi",
      expireDate: "2023-07-02T00:00:00",
      imagePaths: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      images: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      maxPrice: 300000,
      minPrice: 320000,
      note: "null",
      productName: "Cam bardak 3",
      roleName: "Manager",
      status: "1",
      tenderNumber: "1000060",
      unitType: 1,
    },
    {
      tenderId: 497,
      amount: 200,
      approveDate: "2023-06-26T10:01:59.5866667",
      approvedUser: "null",
      categories: [{ categoryId: 139, categoryName: "Afiş" }],
      companies: [
        { companyId: 1, companyName: "Şirket 1" },
        { companyId: 2, companyName: "Şirket 2" },
      ],
      companyId: 119,
      companyName: "Yılmaz Elektronik",
      confirmDescription: "Onaylandı",
      createdAt: "2023-06-26T10:00:04.3566667",
      createdUser: "Bayram Enes YILMAZ",
      currency: "TRY",
      customer: "string",
      deadline: "2023-07-07T00:00:00",
      description: "Cam bardak 3 ihalesi değiştirme denemesi",
      expireDate: "2023-07-02T00:00:00",
      imagePaths: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      images: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      maxPrice: 300000,
      minPrice: 320000,
      note: "null",
      productName: "Cam bardak 3 Change",
      roleName: "Manager",
      status: "1",
      tenderNumber: "1000061",
      unitType: 1,
    },
  ]);
  const [demoRevisedTenders, setDemoRevisedTenders] = useState([
    {
      tenderId: 498,
      amount: 200,
      approveDate: "2023-06-26T10:01:59.5866667",
      approvedUser: "null",
      categories: [{ categoryId: 139, categoryName: "Afiş" }],
      companies: [
        { companyId: 1, companyName: "Şirket 1" },
        { companyId: 2, companyName: "Şirket 2" },
      ],
      companyId: 119,
      companyName: "Yılmaz Elektronik",
      confirmDescription: "Max. fiyatı 350.000 olarak revize ediniz",
      createdAt: "2023-06-26T10:00:04.3566667",
      createdUser: "Bayram Enes YILMAZ",
      currency: "TRY",
      customer: "string",
      deadline: "2023-07-07T00:00:00",
      description: "Cam bardak 3 ihalesi değiştirme denemesi",
      expireDate: "2023-07-02T00:00:00",
      imagePaths: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      images: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      maxPrice: 300000,
      minPrice: 320000,
      note: "null",
      productName: "Cam bardak 3",
      roleName: "Manager",
      status: "3",
      tenderNumber: "1000060",
      unitType: 1,
    },
    {
      tenderId: 499,
      amount: 200,
      approveDate: "2023-06-26T10:01:59.5866667",
      approvedUser: "null",
      categories: [{ categoryId: 139, categoryName: "Afiş" }],
      companies: [
        { companyId: 1, companyName: "Şirket 1" },
        { companyId: 2, companyName: "Şirket 2" },
      ],
      companyId: 119,
      companyName: "Yılmaz Elektronik",
      confirmDescription: "Onaylandı",
      createdAt: "2023-06-26T10:00:04.3566667",
      createdUser: "Bayram Enes YILMAZ",
      currency: "TRY",
      customer: "string",
      deadline: "2023-07-07T00:00:00",
      description: "Cam bardak 3 ihalesi değiştirme denemesi",
      expireDate: "2023-07-02T00:00:00",
      imagePaths: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      images: [
        "http://sosocrm.oetech.online/api/Images/images/Tender-119-495-1.png",
      ],
      maxPrice: 300000,
      minPrice: 320000,
      note: "null",
      productName: "Cam bardak 3 Change",
      roleName: "Manager",
      status: "3",
      tenderNumber: "1000061",
      unitType: 1,
    },
  ]);

  const values = {
    demoCompanyList,
    demoSuppliers,
    setDemoSuppliers,
    demoTenders,
    setDemoTenders,
    demoRevisedTenders,
    setDemoRevisedTenders,
  };

  return <DemoContext.Provider value={values}>{children}</DemoContext.Provider>;
}

export const DemoContextValue = () => useContext(DemoContext);

export default DemoContextProvider;
