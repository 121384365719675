import "./styles/tendersLayout/tendersLayout.css";

import { Outlet } from "react-router-dom";

function TendersLayout() {
  return (
    <>
      {/* Tablolar buraya gelicek */}
      <Outlet />
    </>
  );
}

export default TendersLayout;
 