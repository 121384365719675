import './deleteModal.css';

import { withNamespaces } from 'react-i18next';


import ButtonDark from '../../form_components/Buttons/ButtonDark/ButtonDark';

function DeleteModal({
    t,
    
    text,
    handleDelete,
    handleCloseModal
}) {
    return (
        <div className='delete-modal-container'>

            <div className='delete-modal-main'>

                <p>{text}</p>

                <div className='delete-modal-button-group'>
                    <ButtonDark
                        icon={<i className="fa-solid fa-trash-can"></i>}
                        text={t('button_edit_delete')}
                        onClick={handleDelete}
                        className="button-dark-active"
                    />
                    <ButtonDark
                        icon={<i className="fa-solid fa-xmark"></i>}
                        text={t('button_edit_cancel')}
                        onClick={handleCloseModal}
                        className="button-dark-active"
                    />
                </div>

            </div>

        </div>
    )
}

export default withNamespaces()(DeleteModal);
