import { Outlet } from 'react-router-dom';

function UsersLayout() {
    return (
        <>

            <Outlet />

        </>
    )
}

export default UsersLayout
