import { useState, useEffect } from 'react';
import axios from 'axios';

function useFetchIf(url, token, condition) {
    const [useFetchRender, setUseFetchRender] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(10);


    useEffect(() => {

        setError(null);
        setLoading(true);

        async function getData() {

            try {

                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json",
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${token}`
                    }
                })

                setData(response.data.data);

                if (response.data.isError) {

                    setError(response.data.errorMessageList[0]);

                }

                if (response.data.total > 0) {

                    setTotal(response.data.total);

                }

                setLoading(false);

            } catch (error) {

                // console.log("error : ", error);

                setError(error);

                setLoading(false);

            }

        }

        if (condition) {

            getData();

        }
        setLoading(false);

    }, [url, total, useFetchRender])

    return { error, loading, data, total, setUseFetchRender, useFetchRender }

}

export default useFetchIf

