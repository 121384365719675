import React from 'react'

function ThemeEditIcon() {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0077 7.35632L12.6598 3.01405L14.1176 1.55811C14.5098 1.16645 14.9916 0.970626 15.5632 0.970626C16.134 0.970626 16.6155 1.16645 17.0077 1.55811L18.4655 3.01405C18.8576 3.4057 19.0537 3.88659 19.0537 4.45671C19.0537 5.0275 18.8576 5.50873 18.4655 5.90038L17.0077 7.35632ZM0.792839 19.2082V14.8659L5.39642 10.2682L0 4.82759L4.83376 0L10.2558 5.44061L11.2276 4.46999L15.5754 8.78672L14.6036 9.75734L20 15.198L15.1918 20L9.74424 14.5849L5.14067 19.2082H0.792839ZM6.85422 8.81226L8.79795 6.87101L7.57033 5.64496L6.34271 6.84547L4.91049 5.41507L6.11253 4.18902L4.80818 2.91188L2.89003 4.85313L6.85422 8.81226ZM15.1662 17.1137L17.11 15.1724L15.8056 13.8953L14.6036 15.0958L13.1458 13.6654L14.3734 12.4393L13.1202 11.2133L11.1765 13.1545L15.1662 17.1137Z"

            />
        </svg>
    )
}

export default ThemeEditIcon;
