import "./labelInputInfo.css";

function LabelInputInfo({
  type,
  title,
  name,
  placeholder,
  disabled,
  info,
  loading,
  required,
  errorBorder,
  ...props
}) {
  return (
    <div className="label-input-info">
      <label htmlFor={name} title={props.value}>
        {title} :{" "}
      </label>
      <input
        {...props}
        required={required}
        title={props.value}
        disabled={disabled}
        className={
          disabled
            ? "label-input-info-disabled"
            : errorBorder
            ? "error-input"
            : ""
        }
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
      />
      {loading && <span className="label-input-loading">{loading}</span>}
      <span>{info}</span>
    </div>
  );
}

export default LabelInputInfo;
