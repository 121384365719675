import React from "react";
import "./customSelect.css";

const CustomSelect = ({ required, options, onChange, value }) => {
  return (
    <div className="custom-select">
      <select required={required} onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelect;
