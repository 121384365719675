import { useNavigate } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";

function CompanyProfileEditBox({
  t,

  title,
  companyId,
  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenDeleteCompanyModal,
}) {
  const navigate = useNavigate();
  const { userRole, loginUserCompanyId } = AuthContextValue();

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSelectValueId(null);
        setSelectValue(null);

        break;

      case "edit":
        console.log("Edit e tıklandı!");
        console.log("düzenlenicek value : ", selectValue);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        handleOpenDeleteCompanyModal();

        break;

      default:
        break;
    }
  }

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <WhiteButton
              icon={<i className="fa-solid fa-chevron-left"></i>}
              text={t("button_edit_back")}
              onClick={() => navigate(-1)}
            />
            <div className="title">
              <i className="fa-solid fa-users-line" />
              <h4 className="title">{title}</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={t("button_edit_render")}
              onClick={() => handleClick("render")}
            />
            {(userRole === "Admin" || companyId === loginUserCompanyId) && (
              <WhiteButton
                icon={<i className="fa-solid fa-pen-to-square"></i>}
                tooltip={t("button_edit_update")}
                onClick={() =>
                  navigate(
                    userRole === "Admin"
                      ? `/company-list/company-edit/${companyId}`
                      : "/company-profile-edit"
                  )
                }
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(CompanyProfileEditBox);
