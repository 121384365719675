import "./styles/userAdd/userAdd.css";
import { useState } from "react";
import { withNamespaces } from "react-i18next";


import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import ButtonDark from "../../components/form_components/Buttons/ButtonDark/ButtonDark";
import SelectRoles from "../../components/form_components/Select/SelectRoles";
import { CrudContextValue } from "../../context/CrudContext";
import { AuthContextValue } from "../../context/AuthContext";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";

function UserAdd({t}) {
  const { createMethod } = CrudContextValue();
  const { token } = AuthContextValue();
  const navigate = useNavigate();

  const [registerPersonelValues, setRegisterPersonelValues] = useState({
    email: "",
    role: t("select_roles"),
  });

  const [userAddLoading, setUserAddLoading] = useState(false);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const handleOpenAddUserModal = () => {
    if (
      !registerPersonelValues.email ||
      registerPersonelValues.role === t("select_roles") ||
      !registerPersonelValues.role
    ) {
      alert(t("message_no_select_role"));
    } else {
      console.log(registerPersonelValues);
      setOpenAddUserModal(true);
    }
  };
  const handleCloseAddUserModal = () => setOpenAddUserModal(false);

  function handleChange(e) {
    const { name, value } = e.target;

    if (name === "role") {
      const roleId = value.split(",")[0];
      const roleName = value.split(",")[1];

      setRegisterPersonelValues({
        ...registerPersonelValues,
        role: [roleId, roleName],
      });
    } else {
      setRegisterPersonelValues({
        ...registerPersonelValues,
        [name]: value,
      });
    }
  }

  async function handleSave(e) {
    e.preventDefault();

    if (!registerPersonelValues.email && !registerPersonelValues.role) {
      alert(t("message_emty_form"));
    } else {
      setUserAddLoading(true);

      const addUserResponse = await createMethod(
        `${process.env.REACT_APP_POST_REGISTER_PERSONEL_URL}?email=${registerPersonelValues.email}&role=${registerPersonelValues.role[1]}`,
        null,
        token
      );

      if (
        addUserResponse &&
        !addUserResponse.catchError &&
        !addUserResponse.isError
      ) {
        toast.info(t("message_create_user_success"), {
          autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
        });

        setTimeout(() => {
          navigate("/users");
        }, 3000);
      }

      setUserAddLoading(false);
    }

    handleCloseAddUserModal();
  }

  return (
    <div className="user-add-container">
      <div className="user-edit-box">
        {userAddLoading ? (
          <div>{t("loading")}</div>
        ) : openAddUserModal ? (
          <ConfirmModal
            text={
              <span>
                <strong>{registerPersonelValues.email}</strong>{t("message_confirm_create_user_text1")}<strong>{registerPersonelValues.role[1]}</strong>{" "}
                {t("message_confirm_create_user_text2")}
              </span>
            }
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_save")}
            confirmFunc={handleSave}
            handleCloseModal={handleCloseAddUserModal}
          />
        ) : (
          <>
            <div className="user-edit-title-container">
              <WhiteButton
                icon={<i className="fa-solid fa-chevron-left"></i>}
                text={t("button_edit_back")}
                onClick={() => navigate(-1)}
              />
              <i className="fa-solid fa-plus"></i>
              <h4>{t("button_add_user")}</h4>
            </div>

            <div className="user-edit-button-groups">
              <ButtonDark
                icon={<i className="fa-solid fa-floppy-disk" />}
                text={t("button_edit_save")}
                onClick={handleOpenAddUserModal}
                className="button-dark-active"
              />
            </div>
          </>
        )}
      </div>

      <form onSubmit={handleSave}>
        <div className="select-container">
          <label htmlFor="role-select">{t("input_role")} : </label>
          <SelectRoles
            id="role-select"
            name="role"
            value={registerPersonelValues.role}
            onChange={handleChange}
          />
        </div>
        <InputStandart
          type={"email"}
          title={t("input_email")}
          name={"email"}
          placeholder={`${t("input_email")}...`}
          onChange={handleChange}
          value={registerPersonelValues.email}
        />
      </form>
    </div>
  );
}

export default withNamespaces()(UserAdd);
