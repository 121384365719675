import './buttonText.css';

function ButtonText({ text, icon, onClick, className }) {
    return (
        <button
            onClick={onClick}
            className={`button-text-style ${className}`}
        >
            <span>{icon}</span>
            <span className='text'>{text}</span>
        </button>
    )
}

export default ButtonText;
