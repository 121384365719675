import { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import OpportunitiesEditBox from "../../components/EditBox/OpportunitiesEditBox";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { CrudContextValue } from "../../context/CrudContext";
import SumbittedOffers from "./SumbittedOffers";
import ConfirmationPendingOpportunities from "./ConfirmationPendingOpportunities";
import RevisedOpportunities from "./RevisedOpportunities";
import TendersWaitingForOffer from "./TendersWaitingForOffer";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function Opportunities({ t }) {
  const { token, loginUserCompanyId, userRole } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    //Sayfa link durumları
    opportunitiesPageLink,
    setOpportunitiesPageLink,
    notificationRender,
  } = GetContextValue();
  const { updateMethod } = CrudContextValue();

  const [tableRender, setTableRender] = useState(false);

  const [approvedLoading, setApprovedLoading] = useState(false);
  const [revisedLoading, setRevisedLoading] = useState(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [resultRevisedNote, setResultRevisedNote] = useState("");
  const [openResultRevisedNote, setOpenResultRevisedNoteModal] =
    useState(false);
  const handleOpenResultRevisedNoteModal = () =>
    setOpenResultRevisedNoteModal(true);
  const handleCloseResultRevisedNoteModal = () =>
    setOpenResultRevisedNoteModal(false);

  // tablo filtreleme
  // 1 = fiyata göre artan
  // 2 = fiyata göre azalan
  // 3 = en yeniden eskiye
  // 4 = en eskiden yeniye
  const [tableFilter, setTableFilter] = useState(3);
  const [searchText, setSearchText] = useState("");

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);

    if (value.revisedDescription) {
      setResultRevisedNote(value.revisedDescription);
    }
  }

  function handleClickLink(link) {
    setOpportunitiesPageLink(link);

    if (link !== "offers") {
      setOpportunitiesPageLink(link);
      setTableRender(!tableRender);
    }
  }

  // Manager tarafından yapılan ihalye katılma onayı
  async function tenderConfirm() {
    handleCloseConfirmModal();

    setApprovedLoading(true);

    await updateMethod(
      `${
        process.env.REACT_APP_PUT_TENDER_COMPANY_STATUS_URL
      }?tenderId=${selectValueId}&companyId=${loginUserCompanyId}&status=${"Onaylandı"}`,
      null,
      token
    );

    setApprovedLoading(false);

    setTableRender(!tableRender);
  }

  // Manager tarafından yapılan ihalye revize isteği
  async function tenderRevised(revisedDescription) {
    handleCloseRevisedModal();

    setRevisedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_COMPANY_STATUS_URL}?tenderId=${selectValueId}&companyId=${loginUserCompanyId}&status=Revize&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    setTableRender(!tableRender);
  }

  return (
    <div className="opportunities-container">
      <div className="opportunities-title-and-edit-buttons">
        {approvedLoading || revisedLoading ? (
          <LoadingCircular />
        ) : revisedModalOpen ? (
          <RevisedModal
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={tenderRevised}
            cancelText={t("button_edit_cancel")}
            confirmText={t("button_revised")}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            handleCloseModal={handleCloseConfirmModal}
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_join_tender")}
            text={t("message_join_tender_confirm_ask")}
            confirmFunc={tenderConfirm}
          />
        ) : (
          <OpportunitiesEditBox
            type="offer"
            pageLink={opportunitiesPageLink}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleClickValue={handleClickValue}
            searchText={searchText}
            setSearchText={setSearchText}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            useFetchRender={tableRender}
            setUseFetchRender={setTableRender}
            handleOpenRevisedModal={handleOpenRevisedModal}
            handleOpenConfirmModal={handleOpenConfirmModal}
            handleOpenResultRevisedNoteModal={handleOpenResultRevisedNoteModal}
          />
        )}

        {openResultRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={resultRevisedNote}
            openRevisedNote={openResultRevisedNote}
            handleCloseRevisedNote={handleCloseResultRevisedNoteModal}
          />
        )}
      </div>

      <div className="opportunities-link-groups">
        {(userRole === "Manager" || userRole === "Admin") && (
          <ButtonText
            icon={<i className="fa-solid fa-clock"></i>}
            text={t("button_confirmation_waiting")}
            onClick={() => handleClickLink("OnayBekliyor")}
            className={
              opportunitiesPageLink === "OnayBekliyor" ? "button-active" : ""
            }
          />
        )}

        <ButtonText
          icon={<i className="fa-solid fa-clock"></i>}
          text={t("button_offer_waiting")}
          onClick={() => handleClickLink("Onaylandı")}
          className={
            opportunitiesPageLink === "Onaylandı" ? "button-active" : ""
          }
        />

        <Link to={false}>
          <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={t("button_offer_sending")}
            onClick={() => handleClickLink("offers")}
            className={
              opportunitiesPageLink === "offers" ? "button-active" : ""
            }
          />
        </Link>

        <ButtonText
          icon={<i className="fa-solid fa-arrow-rotate-right"></i>}
          text={t("button_request_revised")}
          onClick={() => handleClickLink("Revize")}
          className={opportunitiesPageLink === "Revize" ? "button-active" : ""}
        />

        {/* <ButtonText
          icon={<i className="fa-solid fa-circle-minus"></i>}
          text={"Kapalılar"}
          onClick={() => handleClickLink("Close")}
          className={opportunitiesPageLink === "Close" ? "button-active" : ""}
        /> */}
      </div>

      <div
        className={`opportunities-table ${
          revisedLoading ||
          approvedLoading ||
          revisedModalOpen ||
          confirmModalOpen
            ? "disabled-box"
            : ""
        }`}
      >
        {opportunitiesPageLink === "OnayBekliyor" ? (
          <ConfirmationPendingOpportunities
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
          />
        ) : opportunitiesPageLink === "Onaylandı" ? (
          <TendersWaitingForOffer
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
          />
        ) : opportunitiesPageLink === "offers" ? (
          <SumbittedOffers
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
            setResultRevisedNote={setResultRevisedNote}
          />
        ) : opportunitiesPageLink === "Revize" ? (
          <RevisedOpportunities
            tableFilter={tableFilter}
            searchText={searchText}
            tableRender={tableRender}
            notificationRender={notificationRender}
          />
        ) : null}
      </div>
    </div>
  );
}

export default withNamespaces()(Opportunities);
