import "./tenderDetailCard.css";

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import DetailText from "../DetailText/DetailText";
import ImageDetailCard from "../ImageDetailCard/ImageDetailCard";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import { AuthContextValue } from "../../context/AuthContext";
import RevisedModal from "../Modals/RevisedModal/RevisedModal";
import { Alert, Space, Tooltip } from "antd";
import { tenderTimeOverCalcFunc } from "../../hooks/tenderTimeOver";

function TenderDetailCard({
  t,
  tenderDetail,
  setCardRender,
  cardRender,
  manager,
  opportunitiesConfirmStatus,
  opportunitiesRevisedResult,
  opportunitiesConfirmFunc,
  opportunitiesConfirmLoading,
  opportunitiesRevisedFunc,
  opportunitiesRevisedLoading,
}) {
  const navigate = useNavigate();
  const { loginUserCompanyId } = AuthContextValue();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [formattedTime, setFormattedTime] = useState("");
  const [price, setPrice] = useState("");
  const [frequencyType, setFrequencyType] = useState("");
  const [unitType, setUnitType] = useState("");

  const [tenderImages, setTenderImages] = useState([]);
  const [tenderDocuments, setTenderDocuments] = useState([]);

  useEffect(() => {
    if (tenderDetail && tenderDetail.expireDate) {
      const timeDiff = new Date(tenderDetail.expireDate) - new Date(); // farkı al

      if (timeDiff < 0) {
        setFormattedTime(t("table_edit_finish"));
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // gün hesabı
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24); // saat hesabı
        const minutes = Math.floor((timeDiff / 1000 / 60) % 60); // dakika hesabı

        const formattedTime = `${days} ${t("table_edit_day")}, ${hours} ${t(
          "table_edit_hours"
        )}, ${minutes} ${t("table_edit_minutes")}`; // formatlı hali

        setFormattedTime(formattedTime); // örnek çıktı: "4 Gün, 12 Saat, 23 Dakika"
      }
    }

    if (tenderDetail && tenderDetail.images) {
      let filterDocuments = [];
      let filterImages = [];

      tenderDetail.images.map((path) => {
        if (path.includes("pdf")) {
          filterDocuments.push(path);
        } else {
          filterImages.push(path);
        }
      });

      setTenderDocuments(filterDocuments);
      setTenderImages(filterImages);
    }
    if (tenderDetail && tenderDetail.expireDate) {
      if (tenderDetail.currency && tenderDetail.currency !== "string") {
        setPrice(
          Number(tenderDetail.minPrice).toLocaleString(tenderDetail.currency, {
            style: "currency",
            currency: tenderDetail.currency,
          }) +
            " - " +
            Number(tenderDetail.maxPrice).toLocaleString(
              tenderDetail.currency,
              {
                style: "currency",
                currency: tenderDetail.currency,
              }
            )
        );
      } else {
        setPrice(
          Number(tenderDetail.minPrice).toLocaleString("TRY", {
            style: "currency",
            currency: "TRY",
          }) +
            " - " +
            Number(tenderDetail.maxPrice).toLocaleString("TRY", {
              style: "currency",
              currency: "TRY",
            })
        );
      }
    }

    if (tenderDetail && tenderDetail.frequency && tenderDetail.frequency[0]) {
      const freqType =
        tenderDetail.frequency[0].frequencyType === 1
          ? t("select_daily")
          : tenderDetail.frequency[0].frequencyType === 2
          ? t("select_weekly")
          : tenderDetail.frequency[0].frequencyType === 3
          ? t("select_monthly")
          : tenderDetail.frequency[0].frequencyType === 4
          ? t("select_yearly")
          : "";

      setFrequencyType(freqType);
    }

    if (tenderDetail && tenderDetail.unitType) {
      const unType =
        tenderDetail.unitType === 1
          ? t("select_piece")
          : tenderDetail.unitType === 2
          ? t("select_ton")
          : tenderDetail.unitType === 3
          ? t("select_kg")
          : tenderDetail.unitType === 4
          ? t("select_g")
          : tenderDetail.unitType === 5
          ? t("select_meter")
          : tenderDetail.unitType === 6
          ? t("select_centimeter")
          : tenderDetail.unitType === 7
          ? t("select_milimeter")
          : tenderDetail.unitType === 8
          ? t("select_liter")
          : tenderDetail.unitType === 9
          ? t("select_inch")
          : "";
      setUnitType(unType);
    }
  }, [tenderDetail]);

  function formatDuration(duration) {
    const days = Math.ceil(duration / (24 * 60 * 60 * 1000));

    return `${days} ${t("table_edit_day")} `;
  }

  async function handleApprovedOpportunities() {
    await opportunitiesConfirmFunc();

    handleCloseConfirmModal();
  }

  async function handleRevisedOpportunities(revisedDescription) {
    console.log("denemeee : ", revisedDescription);
    await opportunitiesRevisedFunc(revisedDescription);
    await setCardRender(!cardRender);
    handleCloseRevisedModal();
  }

  return (
    <div className="tender-detail-box">
      <div className="tender-detail-top">
        {opportunitiesConfirmLoading || opportunitiesRevisedLoading ? (
          <LoadingCircular />
        ) : revisedModalOpen ? (
          <RevisedModal
            cancelText={t("button_edit_cancel")}
            confirmText={t("button_revised")}
            confirmFunc={handleRevisedOpportunities}
            handleCloseModal={handleCloseRevisedModal}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_join_tender")}
            text={t("message_join_tender_confirm_ask")}
            confirmFunc={handleApprovedOpportunities}
            handleCloseModal={handleCloseConfirmModal}
          />
        ) : (
          <>
            <div className="tender-detail-top-left">
              <WhiteButton
                icon={<i className="fa-solid fa-chevron-left"></i>}
                text={t("button_edit_back")}
                onClick={() => navigate(-1)}
              />
              <h4>{tenderDetail.productName}</h4>
            </div>
            <div className="tender-detail-top-right">
              {opportunitiesConfirmStatus === "1" ||
              opportunitiesConfirmStatus === "3" ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-arrows-rotate"></i>}
                    tooltip={t("button_tender_revised_request")}
                    onClick={handleOpenRevisedModal}
                    // className="tender-detail-top-edit-button"
                  />
                  <WhiteButton
                    tooltip={t("button_join_tender")}
                    icon={<i className="fa-solid fa-check"></i>}
                    className="tender-detail-top-edit-button"
                    onClick={handleOpenConfirmModal}
                  />
                </>
              ) : Number(tenderDetail?.companyId) ===
                Number(loginUserCompanyId) ? (
                <>
                  <Link to={`/tenders/tender-change/${tenderDetail.tenderId}`}>
                    <WhiteButton
                      icon={
                        <i className="fa-solid fa-arrow-right-arrow-left"></i>
                      }
                      tooltip={t("button_tender_change_same_tender_number")}
                    />
                  </Link>
                  <Link
                    to={`/tenders/tender-change-new-tender/${tenderDetail.tenderId}`}
                  >
                    <WhiteButton
                      icon={<i className="fa-solid fa-file-export"></i>}
                      tooltip={t("button_tender_same_tender_create")}
                    />
                  </Link>
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
      <div className="tender-detail-main">
        {opportunitiesRevisedResult === "2" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message={t(
                "message_alert_tender_company_revised_response_approved"
              )}
              type="success"
            />
          </Space>
        ) : opportunitiesRevisedResult === "1" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message={t(
                "message_alert_tender_company_revised_response_waiting"
              )}
              type="warning"
            />
          </Space>
        ) : opportunitiesRevisedResult === "4" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message={t(
                "message_alert_tender_company_revised_response_reject"
              )}
              type="error"
            />
          </Space>
        ) : null}

        {Number(loginUserCompanyId) !== Number(tenderDetail?.companyId) &&
        tenderTimeOverCalcFunc(
          new Date(tenderDetail?.expireDate) - new Date()
        ) === "overTime" ? (
          <Space
            direction="vertical"
            style={{ width: "100%", whiteSpace: "normal" }}
          >
            <Alert
              message={t("message_no_over_tender_alert")}
              type="error"
              showIcon={true}
            />
          </Space>
        ) : null}
        <div className="tender-detail-image">
          <ImageDetailCard images={tenderImages} />
        </div>

        <div className="tender-detail-info">
          <div className="tender-detail-box-documan-container">
            <p className="detail-text-title">{t("input_documents")} :</p>
            <div className="documan-box">
              {tenderDocuments && tenderDocuments.length >= 1
                ? tenderDocuments.map((doc, i) => {
                    return (
                      <a
                        key={i}
                        target="_blank"
                        href={doc}
                        className="detail-text"
                      >
                        {doc.split("images/")[1]}
                      </a>
                    );
                  })
                : "-"}
            </div>
          </div>

          <DetailText
            title={t("table_tender_number")}
            text={
              <Tooltip placement="top" title="İhale Detayı">
                <Link
                  style={{ fontSize: ".8rem", color: "rgb(19, 148, 223)" }}
                  to={
                    Number(tenderDetail?.companyId) ===
                    Number(loginUserCompanyId)
                      ? `/tenders/tender-detail/${tenderDetail.tenderId}`
                      : `/opportunities/tender-detail-supplier/${tenderDetail.tenderId}`
                  }
                >
                  {tenderDetail.tenderNumber}
                </Link>
              </Tooltip>
            }
          />
          <DetailText
            title={t("support_request_created_user")}
            text={tenderDetail.createdUser}
          />
          <DetailText
            title={t("table_edit_approved_user")}
            text={tenderDetail.approvedUser}
          />
          <DetailText
            title={t("table_start_date")}
            text={new Date(tenderDetail.createdAt).toLocaleString()}
          />
          <DetailText
            title={t("table_expire_date")}
            text={new Date(tenderDetail.expireDate).toLocaleString()}
          />
          <DetailText
            title={t("table_tender_period")}
            text={formatDuration(
              new Date(tenderDetail.expireDate) -
                new Date(tenderDetail.createdAt)
            )}
          />
          <DetailText title={t("table_remaining_time")} text={formattedTime} />
          <DetailText
            title={t("table_category")}
            text={tenderDetail?.categories
              ?.map((category) => category.categoryName)
              .join(", ")}
          />
          {/* <DetailText title={"Max Bütçe:"} text={tenderDetail.maxPrice} />
          <DetailText title={"Min Bütçe:"} text={tenderDetail.minPrice} /> */}
          {Number(loginUserCompanyId) === Number(tenderDetail.companyId) ? (
            <DetailText title={t("table_price")} text={price} />
          ) : (
            ""
          )}
          <DetailText
            title={t("table_amount")}
            text={`${tenderDetail.amount} ${unitType}`}
          />
          <DetailText
            title={t("table_deadline")}
            text={new Date(tenderDetail.deadline).toLocaleString()}
          />
          <DetailText
            title={t("table_description")}
            text={tenderDetail.description}
          />
          {tenderDetail.properties &&
            tenderDetail.properties.length > 0 &&
            tenderDetail.properties.map((prop) => {
              return (
                <DetailText
                  key={prop.propertyId}
                  title={prop.name}
                  text={prop.value}
                />
              );
            })}

          {tenderDetail.frequency && tenderDetail.frequency.length > 0 && (
            <div className="frequency-detail-box">
              <DetailText
                title={t("input_recursive_type")}
                text={frequencyType}
              />

              <DetailText
                title={t("table_frequency_startdate")}
                text={new Date(
                  tenderDetail.frequency[0]?.startDate
                ).toLocaleString()}
              />

              <DetailText
                title={t("table_frequency_finishdate")}
                text={new Date(
                  tenderDetail.frequency[0]?.finishDate
                ).toLocaleString()}
              />

              {tenderDetail.frequency[0]?.quantityDetailResponse.map(
                (detail, i) => {
                  return (
                    <DetailText
                      key={i}
                      title={`${t("table_frequency_specialdate")} ${i + 1}`}
                      text={`${detail.quantity} ${unitType} ${new Date(
                        detail.date
                      ).toLocaleString()}`}
                    />
                  );
                }
              )}

              <DetailText
                title={t("table_frequency_otherdate")}
                text={`${frequencyType} ${
                  tenderDetail.frequency[0]?.otherQuantity
                } ${t("select_piece")}`}
              />
            </div>
          )}

          <DetailText title={t("input_note")} text={tenderDetail.note} />
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(TenderDetailCard);
