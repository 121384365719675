import "./videoDetailModal.css";
import { useEffect } from "react";
import YouTube from "react-youtube";

function VideoDetailModal({
  data,
  handleCloseModal,
  nextBtn,
  prevBtn,
  status,
}) {

  function handleEndedVideo() {
    nextBtn();
  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 27) {
        // ESC key
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleCloseModal]);

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      // autoplay: 1,
    },
  };

  return (
    <div className="video-detail-modal-container">
      <div className="video-detail-modal-main">
        {/* <div className="modal-title-container">
          <h4 className="title" title={data.info}>
            {data.info}
          </h4>
        </div> */}

        {status === "iframe" ? (
          <div className="data-container">
            <YouTube
              videoId={data.videoUrl}
              opts={opts}
              style={{ height: "100%", width: "100%" }}
              onReady={onPlayerReady}
            />
          </div>
        ) : (
          <div className="data-container">
            <video
              src={data.video}
              autoPlay
              controls
              onEnded={handleEndedVideo}
            />
          </div>
        )}
        <button className="video-detail-close-btn" onClick={handleCloseModal}>
          {"X"}
        </button>
        {/* <button className="video-detail-next-btn" onClick={nextBtn}>
          {">"}
        </button>
        <button className="video-detail-prev-btn" onClick={prevBtn}>
          {"<"}
        </button> */}

        <div className="modal-buttons">
          <button className="video-detail-prev-btn" onClick={prevBtn}>
            {"<"}
          </button>
          <button className="video-detail-next-btn" onClick={nextBtn}>
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VideoDetailModal;
