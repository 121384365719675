import "./descriptionModal.css";
import Modal from "react-modal";

function DescriptionModal({
  title,
  descriptionNote,
  handleCloseDescriptionNote,
  openDescriptionNote,
}) {
  console.log("tıkladı");
  return (
    <Modal
      isOpen={openDescriptionNote}
      onRequestClose={handleCloseDescriptionNote}
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "60vh",
          minWidth: "300px",
          maxHeight: "80%",
          minHeight: "100px",
          overflow: "auto",
          // padding:0
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <button onClick={handleCloseDescriptionNote} className="des-close-btn">
        <i className="fa-regular fa-rectangle-xmark" />
      </button>
      <h4 className="des-title">{title}</h4>
      <p className="des-note">{descriptionNote}</p>
    </Modal>
  );
}

export default DescriptionModal;
