import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";

function ActiveTenders({
  t,
  tableFilter,
  searchText,
  tableRender,
  setRevisedNote,
}) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [activeTendersPage, setActiveTendersPage] = useState(1);
  const [activeTendersRowsPerPage, setActiveTendersRowsPerPage] = useState(10);

  const status = 2;

  const {
    setUseFetchRender: setActiveTendersUseFetchRender,
    useFetchRender: activeTendersUseFetchRender,
    error: activeTendersError,
    loading: activeTendersLoading,
    data: activeTenders,
    total: activeTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?Status=${status}&CompanyId=${loginUserCompanyId}&Last=false&SortType=${tableFilter}&SearchedText=${searchText}&Page=${activeTendersPage}&PageSize=${activeTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: t("table_photo"),
    },
    // {
    //   key: "offers",
    //   header: t("table_offers"),
    // },
    {
      key: "tenderNumber",
      header: t("table_tender_number"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "remainingTime",
      header: t("table_remaining_time"),
    },
    {
      key: "minPrice",
      header: t("table_min_price"),
    },
    {
      key: "maxPrice",
      header: t("table_max_price"),
    },
  ];
  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setActiveTendersUseFetchRender(!activeTendersUseFetchRender);
  }, [tableRender, notificationRender]);

  useEffect(() => {
    if (activeTenders) {
      setRevisedNote(activeTenders[0]?.confirmDescription);
    }

    console.log(activeTenders);
  }, [activeTenders]);



  return (
    <>
      {activeTendersError ? (
        <ErrorComponent error={activeTendersError} />
      ) : activeTendersLoading ? (
        <Loading />
      ) : activeTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={activeTendersUseFetchRender}
          setUseFetchRender={setActiveTendersUseFetchRender}
          total={activeTendersTotal}
          page={activeTendersPage}
          setPage={setActiveTendersPage}
          rowsPerPage={activeTendersRowsPerPage}
          setRowsPerPage={setActiveTendersRowsPerPage}
          columns={columns}
          data={activeTenders}
        />
      ) : (
        <div className="table-null-text">{t("message_no_active_tender")}</div>
      )}
    </>
  );
}

export default withNamespaces()(ActiveTenders);
