import './loading.css';

import { withNamespaces } from 'react-i18next';

import images from "../../assets/images/index";

function Loading({t}) {
  return (
    <div className='loading-container'>

        <img src={images.companyLogo} alt={t('loading')} />

    </div>
  )
}

export default withNamespaces()(Loading);
