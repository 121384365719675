import React from "react";
import { Outlet } from "react-router-dom";

function OffersLayout() {
  return (
    <>
      {/* Tablolar buraya gelicek */}
      <Outlet />
    </>
  );
}

export default OffersLayout;
