import "./styles/tenderDetail/tenderDetail.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import { CrudContextValue } from "../../context/CrudContext";

import useFetch from "../../hooks/UseFetch";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import Loading from "../../components/Loading/Loading";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import TenderDetailCard from "../../components/TenderDetailCard/TenderDetailCard";
import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import RevisedModal from "../../components/Modals/RevisedModal/RevisedModal";
import RevisedDescriptionModal from "../../components/Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import { getFetch } from "../../hooks/getFetch";
import { toast } from "react-toastify";
import TenderAddSupplier from "../../components/TenderAddSupplier/TenderAddSupplier";
import Modal from "antd/es/modal/Modal";

function TenderDetail({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const { token, loginUserCompanyId, userRole } = AuthContextValue();
  const { createMethod, updateMethod, deleteMethod } = CrudContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [offerOver, setofferOver] = useState(false);
  const [pageLink, setPageLink] = useState("Offers");

  const [revisedLoading, setRevisedLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [revisedApprovedLoading, setRevisedApprovedLoading] = useState(false);
  const [revisedRejectLoading, setRevisedRejectLoading] = useState(false);

  /* İhalediki tedarikçilerin durum sayıları */
  const [supplierStatus, setSupplierStatus] = useState({
    approvedSupp: 0,
    waitingSupp: 0,
    revisedSupp: 0,
  });

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [tenderAddSuppModalOpen, setTenderAddSuppModalOpen] = useState(false);
  const handleOpenTenderAddSuppModal = () => setTenderAddSuppModalOpen(true);
  const handleCloseTenderAddSuppModal = () => setTenderAddSuppModalOpen(false);

  const [revisedApprovedModalOpen, setRevisedApprovedModalOpen] =
    useState(false);
  const handleOpenRevisedApprovedModal = () =>
    setRevisedApprovedModalOpen(true);
  const handleCloseRevisedApprovedModal = () =>
    setRevisedApprovedModalOpen(false);

  const [revisedRejectModalOpen, setRevisedRejectModalOpen] = useState(false);
  const handleOpenRevisedRejectModal = () => setRevisedRejectModalOpen(true);
  const handleCloseRevisedRejectModal = () => setRevisedRejectModalOpen(false);

  // const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNoteModal] = useState(false);
  const handleOpenRevisedNoteModal = () => setOpenRevisedNoteModal(true);
  const handleCloseRevisedNoteModal = () => setOpenRevisedNoteModal(false);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  // console.log(tenderDetail);
  const [suppliersStatus, setSuppliersStatus] = useState("Onaylandı");
  const [suppliersPage, setSuppliersPage] = useState(1);
  const [suppliersRowsPerPage, setSuppliersRowsPerPage] = useState(10);

  const {
    setUseFetchRender: setSuppliersUseFetchRender,
    useFetchRender: suppliersUseFetchRender,
    error: suppliersError,
    loading: suppliersLoading,
    data: suppliers,
    total: suppliersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL}?TenderId=${tenderId}&Status=${suppliersStatus}&Page=${suppliersPage}&PageSize=${suppliersRowsPerPage}`,
    token
  );

  const supplierColumns = [
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "status",
      header: t("table_status"),
    },
    pageLink === "Revize" && {
      key: "result",
      header: t("table_result"),
    },
    {
      key: "companyName",
      header: t("table_supplier"),
    },
    {
      key: "city",
      header: t("table_city"),
    },
    {
      key: "distance",
      header: t("table_distance"),
    },
    {
      key: "averageScore",
      header: t("table_score"),
    },
  ];

  const [offersPage, setOffersPage] = useState(1);
  const [offersRowsPerPage, setOffersRowsPerPage] = useState(10);
  const {
    setUseFetchRender: setOffersUseFetchRender,
    useFetchRender: offersUseFetchRender,
    error: offersError,
    loading: offersLoading,
    data: offers,
    total: offersTotal,
  } = useFetch(
    `${
      process.env.REACT_APP_GET_OFFER_LIST_URL
    }?TenderCompanyId=${loginUserCompanyId}&TenderId=${tenderId}&Last=${
      tenderDetail?.companyId === loginUserCompanyId ? false : true
    }&SortType=1&Page=${offersPage}&PageSize=${offersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "images",
      header: t("table_photo"),
    },
    {
      key: "result",
      header: t("table_status"),
    },
    {
      key: "offerCompanyName",
      header: t("table_supplier"),
    },
    {
      key: "addresses",
      header: t("table_address"),
    },
    {
      key: "distance",
      header: t("table_distance"),
    },
    {
      key: "offerCreatedAt",
      header: t("table_create_date"),
    },
    {
      key: "totalPrice",
      header: t("table_total_price"),
    },
  ];

  useEffect(() => {
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setOffersUseFetchRender(!offersUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [notificationRender]);

  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, [suppliersUseFetchRender]);

  useEffect(() => {
    async function getSupplierStatus() {
      const approvedSupp = await getFetch(
        `${
          process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL
        }?TenderId=${tenderId}&Status=${2}&Page=${1}&PageSize=${1}`,
        token
      );
      const waitingSupp = await getFetch(
        `${
          process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL
        }?TenderId=${tenderId}&Status=${1}&Page=${1}&PageSize=${1}`,
        token
      );
      const revisedSupp = await getFetch(
        `${
          process.env.REACT_APP_GET_TENDER_COMPANY_LIST_URL
        }?TenderId=${tenderId}&Status=${3}&Page=${1}&PageSize=${1}`,
        token
      );

      setSupplierStatus({
        approvedSupp: approvedSupp.data.total,
        waitingSupp: waitingSupp.data.total,
        revisedSupp: revisedSupp.data.total,
      });
    }
    getSupplierStatus();
  }, [tenderDetail]);

  useEffect(() => {
    if (offers && offers.length > 0) {
      const offerOver = offers.find((off) => off.result === "2");
      console.log(offerOver);
      if (offerOver) {
        setofferOver(true);
      }
    }
  }, [offers]);

  function hanldeClickLink(link) {
    setSelectValue(null);
    setSelectValueId(null);

    setPageLink(link);

    if (link !== "Offers") {
      setSuppliersStatus(link);
      setSuppliersUseFetchRender(!suppliersUseFetchRender);
    }
  }

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function handleApprovedOffer() {
    console.log("Kabul edilen Teklif : ", selectValue);

    setApprovedLoading(true);
    /* burda teklif idsi alıp onaylama işlemi yapılacak */
    const approvedResponse = await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_RESULT_URL}?offerId=${selectValueId}&result=2`,
      null,
      token
    );
    setApprovedLoading(false);

    if (
      approvedResponse &&
      !approvedResponse.catchError &&
      !approvedResponse.isError
    ) {
      toast.info(t("message_info_approved_offer"), {
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/supply-process");
      }, 2000);
    }

    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setOffersUseFetchRender(!offersUseFetchRender);
    handleCloseConfirmModal();
  }

  async function handleRevisedOffer(revisedDescription) {
    setRevisedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_RESULT_URL}?offerId=${selectValueId}&result=3&ConfirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    handleCloseConfirmModal();

    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setOffersUseFetchRender(!offersUseFetchRender);
  }

  //Tedarikçiden gelen revize talebini Onaylamak
  async function handleRevisedApproved() {
    console.log("handleRevisedApproved");

    setRevisedApprovedLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_COMPANY_RESULT_URL}?tenderId=${tenderId}&companyId=${selectValueId}&result=2`,
      null,
      token
    );

    setRevisedApprovedLoading(false);

    handleCloseRevisedApprovedModal();
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }

  //Tedarikçiden gelen revize talebini Reddetmek
  async function handleRevisedReject() {
    console.log("handleRevisedReject");

    setRevisedRejectLoading(true);

    await updateMethod(
      `${process.env.REACT_APP_PUT_TENDER_COMPANY_RESULT_URL}?tenderId=${tenderId}&companyId=${selectValueId}&result=4`,
      null,
      token
    );

    setRevisedRejectLoading(false);

    handleCloseRevisedRejectModal();
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
  }

  async function handleDeleteSupplier() {
    console.log("silinen tedarikçi : ", selectValue);

    setDeleteLoading(true);

    //tedarikçi silme işlemi
    await deleteMethod(
      `${process.env.REACT_APP_DELETE_TENDER_COMPANY_URL}?tenderId=${tenderId}&companyId=${selectValue.ofCompanyId}`,
      token
    );

    setDeleteLoading(false);

    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setOffersUseFetchRender(!offersUseFetchRender);
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);

    handleCloseDeleteModal();
  }

  async function handleTenderAddSupp(tenderCompanyIds) {
    console.log(tenderCompanyIds);
    setRevisedRejectLoading(true);

    const postData = {
      tenderId: tenderId,
      tenderCompanies: tenderCompanyIds,
    };

    await createMethod(
      process.env.REACT_APP_POST_TENDER_ADD_COMPANY_URL,
      JSON.stringify(postData),
      token
    );

    setRevisedRejectLoading(false);

    handleCloseRevisedRejectModal();
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setTenderDetailUseFetchRender(!tenderDetailUseFetchRender);
  }

  if (userRole !== "Admin") {
    if (Number(tenderDetail?.companyId) !== Number(loginUserCompanyId)) {
      return (
        <div className="table-null-text">
          {t("message_error_not_user_authority")}
        </div>
      );
    }
  }

  return (
    <div className="tender-detail-container">
      <div className="tender-detail-left-box">
        {tenderDetailError ? (
          <ErrorComponent error={tenderDetailError} />
        ) : tenderDetailLoading ? (
          <Loading />
        ) : tenderDetail !== null ? (
          <TenderDetailCard tenderDetail={tenderDetail} />
        ) : null}
      </div>

      {tenderDetailError ? null : (
        <div className="tender-detail-tables">
          <div className="tender-detail-tables-links">
            {approvedLoading ||
            deleteLoading ||
            revisedLoading ||
            revisedApprovedLoading ||
            revisedRejectLoading ? (
              <LoadingCircular />
            ) : confirmModalOpen ? (
              <ConfirmModal
                cancelButtonText={t("button_edit_cancel")}
                confirmButtonText={t("button_edit_confirm")}
                confirmFunc={handleApprovedOffer}
                handleCloseModal={handleCloseConfirmModal}
                text={`${selectValue.offerCompanyName} firmasının teklifini kabul ediyor musunuz?`}
              />
            ) : revisedModalOpen ? (
              <RevisedModal
                cancelText={t("button_edit_cancel")}
                confirmText={t("button_revised")}
                confirmFunc={handleRevisedOffer}
                handleCloseModal={handleCloseRevisedModal}
              />
            ) : deleteModalOpen ? (
              <ConfirmModal
                cancelButtonText={t("button_edit_cancel")}
                confirmButtonText={t("button_tender_remove_suppliers_confirm")}
                confirmFunc={handleDeleteSupplier}
                handleCloseModal={handleCloseDeleteModal}
                text={`${selectValue.companyName} ${t(
                  "message_remove_supplier_in_tender"
                )}`}
              />
            ) : tenderAddSuppModalOpen ? (
              <Modal
                onCancel={handleCloseTenderAddSuppModal}
                width={"90vw"}
                bodyStyle={{ height: "70vh", overflow: "hidden" }}
                open={tenderAddSuppModalOpen}
                children={
                  <TenderAddSupplier
                    formData={tenderDetail?.companies}
                    tenderId={tenderId}
                    onSubmit={handleTenderAddSupp}
                    handleCloseTenderAddSuppModal={
                      handleCloseTenderAddSuppModal
                    }
                    supplerRender={tenderDetailUseFetchRender}
                  />
                }
                footer={false}
              />
            ) : revisedApprovedModalOpen ? (
              <ConfirmModal
                cancelButtonText={t("button_edit_cancel")}
                confirmButtonText={t("button_edit_confirm")}
                confirmFunc={handleRevisedApproved}
                handleCloseModal={handleCloseRevisedApprovedModal}
                text={`${selectValue.companyName} ${t(
                  "message_confirm_supp_revised_request_approved"
                )}`}
              />
            ) : revisedRejectModalOpen ? (
              <ConfirmModal
                cancelButtonText={t("button_edit_cancel")}
                confirmButtonText={t("button_edit_reject")}
                confirmFunc={handleRevisedReject}
                handleCloseModal={handleCloseRevisedRejectModal}
                text={`${selectValue.companyName}${t(
                  "message_confirm_supp_revised_request_reject"
                )}`}
              />
            ) : (
              <>
                <div className="table-link">
                  <button
                    onClick={() => hanldeClickLink("Offers")}
                    className={
                      pageLink === "Offers" ? "link-active" : "link-not-active"
                    }
                  >
                    {t("button_offers")}{" "}
                    {offers ? (
                      <span className="supp-count"> {offers.length}</span>
                    ) : null}
                  </button>

                  <button
                    onClick={() => hanldeClickLink("Onaylandı")}
                    className={
                      pageLink === "Onaylandı"
                        ? "link-active"
                        : "link-not-active"
                    }
                  >
                    {t("button_approvers")}{" "}
                    {supplierStatus.approvedSupp &&
                    supplierStatus.approvedSupp !== 0 ? (
                      <span className="supp-count">
                        {supplierStatus.approvedSupp}
                      </span>
                    ) : null}
                  </button>
                  <button
                    onClick={() => hanldeClickLink("OnayBekliyor")}
                    className={
                      pageLink === "OnayBekliyor"
                        ? "link-active"
                        : "link-not-active"
                    }
                  >
                    {t("button_waitings")}{" "}
                    {supplierStatus.waitingSupp &&
                    supplierStatus.waitingSupp !== 0 ? (
                      <span className="supp-count">
                        {" "}
                        {supplierStatus.waitingSupp}
                      </span>
                    ) : null}
                  </button>
                  <button
                    onClick={() => hanldeClickLink("Revize")}
                    className={
                      pageLink === "Revize" ? "link-active" : "link-not-active"
                    }
                  >
                    {t("button_reviseds")}{" "}
                    {supplierStatus.revisedSupp &&
                    supplierStatus.revisedSupp !== 0 ? (
                      <span className="supp-count">
                        {" "}
                        {supplierStatus.revisedSupp}
                      </span>
                    ) : null}
                  </button>
                </div>
                <div className="offer-edit-button">
                  <WhiteButton
                    icon={<i className="fa-solid fa-arrow-rotate-left" />}
                    tooltip={t("button_edit_render")}
                    onClick={() => {
                      setSuppliersUseFetchRender(!suppliersUseFetchRender);
                      setOffersUseFetchRender(!offersUseFetchRender);
                      setSelectValue(null);
                      setSelectValueId(null);
                    }}
                  />
                  {pageLink === "Offers" ? (
                    <>
                      <Link
                        to={
                          selectValueId || selectValue
                            ? `/tenders/tender-offer-detail/${selectValueId}`
                            : false
                        }
                      >
                        <WhiteButton
                          icon={<i className="fa-solid fa-info"></i>}
                          tooltip={t("button_edit_detail")}
                          disabled={selectValueId || selectValue ? false : true}
                        />
                      </Link>
                      {offerOver ? null : (
                        <>
                          <WhiteButton
                            icon={<i className="fa-solid fa-arrows-rotate"></i>}
                            tooltip={t("button_request_revision")}
                            onClick={handleOpenRevisedModal}
                            disabled={
                              !selectValueId ||
                              !selectValue ||
                              selectValue?.result === "3" ||
                              selectValue?.result === "2"
                                ? true
                                : false
                            }
                          />
                          <WhiteButton
                            icon={<i className="fa-solid fa-check"></i>}
                            tooltip={t("button_admit_it")}
                            onClick={handleOpenConfirmModal}
                            disabled={
                              !selectValueId ||
                              !selectValue ||
                              selectValue?.result === "2"
                                ? true
                                : false
                            }
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <WhiteButton
                        icon={<i className="fa-solid fa-plus"></i>}
                        tooltip={t("button_tender_add_supplier")}
                        onClick={handleOpenTenderAddSuppModal}
                      />
                      <WhiteButton
                        icon={<i className="fa-solid fa-trash"></i>}
                        tooltip={t("button_tender_remove_supplier")}
                        onClick={handleOpenDeleteModal}
                        disabled={selectValueId || selectValue ? false : true}
                      />
                      {pageLink === "Revize" && (
                        <>
                          <WhiteButton
                            icon={<i className="fa-solid fa-note-sticky"></i>}
                            tooltip={t("button_edit_revised_note")}
                            disabled={
                              selectValueId || selectValue ? false : true
                            }
                            onClick={handleOpenRevisedNoteModal}
                          />
                          <WhiteButton
                            icon={<i className="fa-solid fa-circle-check"></i>}
                            tooltip={t("button_edit_confirm")}
                            disabled={
                              !selectValueId ||
                              !selectValue ||
                              selectValue?.result === "2"
                                ? true
                                : false
                            }
                            onClick={handleOpenRevisedApprovedModal}
                          />
                          <WhiteButton
                            icon={<i className="fa-solid fa-ban"></i>}
                            tooltip={t("button_edit_reject")}
                            disabled={
                              !selectValueId ||
                              !selectValue ||
                              selectValue?.result === "4"
                                ? true
                                : false
                            }
                            onClick={handleOpenRevisedRejectModal}
                          />

                          {openRevisedNote && (
                            <RevisedDescriptionModal
                              revisedNote={selectValue?.confirmDescription}
                              openRevisedNote={openRevisedNote}
                              handleCloseRevisedNote={
                                handleCloseRevisedNoteModal
                              }
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className={`tender-detail-table  ${
              approvedLoading ||
              deleteLoading ||
              revisedLoading ||
              revisedModalOpen ||
              confirmModalOpen ||
              deleteModalOpen ||
              revisedApprovedModalOpen ||
              revisedRejectModalOpen
                ? "disabled-box"
                : ""
            }`}
          >
            {pageLink === "Offers" ? (
              <>
                {offersError ? (
                  <ErrorComponent error={offersError} />
                ) : offersLoading ? (
                  <Loading />
                ) : offers !== null && offers.length > 0 ? (
                  <StandartTable
                    type="offer"
                    selectValueId={selectValueId}
                    selectValue={selectValue}
                    handleClickValue={handleClickValue}
                    useFetchRender={offersUseFetchRender}
                    setUseFetchRender={setOffersUseFetchRender}
                    total={offersTotal}
                    page={offersPage}
                    setPage={setOffersPage}
                    rowsPerPage={offersRowsPerPage}
                    setRowsPerPage={setOffersRowsPerPage}
                    columns={columns}
                    data={offers}
                  />
                ) : (
                  <div className="table-null-text">
                    {t("message_no_incoming_in_tender")}
                  </div>
                )}
              </>
            ) : (
              <>
                {suppliersError ? (
                  <ErrorComponent error={suppliersError} />
                ) : suppliersLoading ? (
                  <Loading />
                ) : suppliers !== null && suppliers.length > 0 ? (
                  <StandartTable
                    type="ofCompany"
                    selectValueId={selectValueId}
                    selectValue={selectValue}
                    handleClickValue={handleClickValue}
                    useFetchRender={suppliersUseFetchRender}
                    setUseFetchRender={setSuppliersUseFetchRender}
                    total={suppliersTotal}
                    page={suppliersPage}
                    setPage={setSuppliersPage}
                    rowsPerPage={suppliersRowsPerPage}
                    setRowsPerPage={setSuppliersRowsPerPage}
                    columns={supplierColumns}
                    data={suppliers}
                  />
                ) : (
                  <div className="table-null-text">
                    {t("message_emty_companylist")}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withNamespaces()(TenderDetail);
