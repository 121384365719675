import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";

function NotificationEditBox({
  t,

  searchText,
  setSearchText,

  useFetchRender,
  setUseFetchRender,

  selectValue,

  handleSelectAll,
  handleDeleteNotification,
  handleMakeRead,
}) {
  /* selectValueId, selectValue => Tabloda seçilen kişi, firma, ihale veya teklif. */
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchRef = useRef(null);

  /* SEARCH-START */
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchOpen(false);
    }
  };
  /* SEARCH-END */

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        setSearchOpen(false);

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        setSearchOpen(true);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        setSearchOpen(false);

        handleDeleteNotification();

        break;

      case "make-read":
        console.log("make-read tıklandı!");

        setSearchOpen(false);

        handleMakeRead();

        break;
      case "all-select":
        console.log("all-select tıklandı!");

        setSearchOpen(false);

        handleSelectAll();

        break;

      default:
        break;
    }
  }

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <div className="title">
              <i className="fa-solid fa-bell"></i>
              <h4 className="title">{t("sidebar_notifications")}</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={t("button_edit_render")}
              onClick={() => handleClick("render")}
            />

            {/* <WhiteButton
              icon={<i className="fa-solid fa-magnifying-glass" />}
              text={
                isSearchOpen ? (
                  <div ref={searchRef} className="search-container">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                ) : null
              }
              onClick={() => handleClick("search")}
            />

            <WhiteButton
              icon={<i className="fa-solid fa-trash-can"></i>}
              onClick={() => handleClick("delete")}
              disabled={selectValue ? false : true}
            /> */}

            <WhiteButton
              icon={<i className="fa-solid fa-check-double"></i>}
              tooltip={t("button_all_select")}
              onClick={() => handleClick("all-select")}
            />

            <WhiteButton
              icon={<i className="fa-solid fa-check-to-slot"></i>}
              tooltip={t("button_make_read")}
              onClick={() => handleClick("make-read")}
              disabled={selectValue && selectValue.length > 0 ? false : true}
            />
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(NotificationEditBox);
