import "./bannerAddForm.css";
import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { GetContextValue } from "../../context/GetContext";
import { Radio, Select } from "antd";
import ImageCropUploader from "../ImageCropUploader/ImageCropUploader";

// Function to format date in "yyyy-MM-dd" format
const formatDateToISODate = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const BannerAddForm = ({ t, onSubmit, onCancel, allBannersTotal }) => {
  const { loginUserCompanyId, userRole } = AuthContextValue();
  const {
    companyDetail,
    allCompaniesError,
    allCompaniesLoading,
    allCompaniesData,
    categoryList,
    categoryListError,
  } = GetContextValue();

  const [image, setImage] = useState([]);

  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [allCompaniesDataOptions, setAllCompaniesDataOptions] = useState([]);
  const advertSize = [
    { label: t("banner_small"), value: 1 },
    { label: t("banner_medium"), value: 2 },
    { label: t("banner_big"), value: 3 },
  ];
  const advertType =
    userRole === "Admin"
      ? [
          { label: t("banner_all_saw"), value: 1 },
          { label: t("banner_select_companies_saw"), value: 2 },
          { label: t("banner_select_categories_saw"), value: 3 },
        ]
      : [
          { label: t("banner_all_saw"), value: 1 },
          { label: t("banner_select_categories_saw"), value: 3 },
        ];

  const [bannerImage, setBannerImage] = useState("");
  const [addedBanner, setAddedBanner] = useState({
    companyId: loginUserCompanyId ? Number(loginUserCompanyId) : "",
    index: 1,
    displayTime: "5",
    startDate: "",
    finishDate: "",
    images: [],
    type: 1,
    size: 1,
    relatedIds: [],
  });

  useEffect(() => {
    if (!categoryListError && categoryList) {
      const filterData = categoryList.map((categ) => ({
        label: categ.categoryName,
        value: categ.id,
      }));

      setCategoryListOptions(filterData);
    }
  }, [categoryList]);

  useEffect(() => {
    if (!allCompaniesError && allCompaniesData) {
      const filterData = allCompaniesData.map((comp) => ({
        label: comp.companyName,
        value: comp.companyId,
      }));

      setAllCompaniesDataOptions(filterData);
    }
  }, [allCompaniesData]);

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    const formattedValue =
      name === "startDate" || name === "finishDate"
        ? formatDateToISODate(value)
        : value;

    setAddedBanner((prevBanner) => ({
      ...prevBanner,
      [name]: formattedValue,
    }));
  };

  // Function to handle image input changes
  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];

    setBannerImage(URL.createObjectURL(imageFile));

    const reader = new FileReader();

    reader.onloadend = () => {
      setAddedBanner((prevBanner) => ({
        ...prevBanner,
        images: [reader.result.split(",")[1]], // Set the base64 encoded image data as the new image value
      }));
    };

    if (imageFile) {
      reader.readAsDataURL(imageFile);
    }
  };

  // Function to handle company selection
  const handleCompanySelect = (e) => {
    const selectedCompanyId = e.target.value;
    const selectedCompany = allCompaniesData.find(
      (company) => Number(company.companyId) === Number(selectedCompanyId)
    );

    setAddedBanner((prevBanner) => ({
      ...prevBanner,
      companyId: selectedCompanyId,
    }));
  };

  const handleChangeCategory = (value) => {
    setAddedBanner((prevBanner) => ({
      ...prevBanner,
      relatedIds: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image.length < 1) {
      alert(t("message_emty_form"));
      return;
    }

    const submitData = {
      ...addedBanner,
      images: image[0].thumbUrl ? [image[0].thumbUrl.split(",")[1]] : [],
    };

    console.log("add banner :", submitData);

    await onSubmit(submitData);
  };

  return (
    <form onSubmit={handleSubmit} className="banner-add-form-container">
      <div className="form-group">
        <div className="example-banner-box">
          <div className="banners">
            {addedBanner.size === 1 ? (
              <div className="small-banner">{t("banner_small")}</div>
            ) : addedBanner.size === 2 ? (
              <div className="medium-banner">{t("banner_medium")}</div>
            ) : addedBanner.size === 3 ? (
              <div className="big-banner">{t("banner_big")}</div>
            ) : null}
          </div>

          <p className="banner-example-info"> {t("banner_banner_sizes")}</p>
        </div>
        <label htmlFor="index">{t("banner_banner_size")} :</label>
        <Radio.Group
          name="size"
          onChange={handleChange}
          value={addedBanner.size}
        >
          {advertSize.map((type) => (
            <Radio key={type.value} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div className="form-group">
        <label>{t("input_photo")}:</label>
        <ImageCropUploader
          setAddedBanner={setAddedBanner}
          addedBanner={addedBanner}
          image={image}
          setImage={setImage}
        />
        {/* {bannerImage && (
          <div className="image-box">
            <img src={bannerImage} alt="videoImage" />
          </div>
        )}

        <label htmlFor="image" className="change-image-label">
          {t("input_photo_add")}
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
        /> */}
      </div>

      <div className="form-group">
        <label htmlFor="index">{t("banner_saw_people")}:</label>
        <Radio.Group
          name="type"
          onChange={handleChange}
          value={addedBanner.type}
        >
          {advertType.map((type) => (
            <Radio key={type.value} value={type.value}>
              {type.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      {addedBanner.type === 3 ? (
        <div className="form-group">
          <label htmlFor="index">{t("banner_special_category")}:</label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "200px",
            }}
            placeholder={t("table_category")}
            defaultValue={[]}
            onChange={handleChangeCategory}
            options={categoryListOptions}
            optionFilterProp="label"
          />
        </div>
      ) : addedBanner.type === 2 ? (
        <div className="form-group">
          <label htmlFor="index">{t("banner_special_companies")}:</label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "200px",
            }}
            placeholder={t("table_company_name")}
            defaultValue={[]}
            onChange={handleChangeCategory}
            options={allCompaniesDataOptions}
            optionFilterProp="label"
          />
        </div>
      ) : null}

      <div className="form-group">
        <label htmlFor="companyName">{t("table_issuing")}:</label>
        {userRole === "Admin" ? (
          <select
            required
            id="companyId"
            name="companyId"
            value={addedBanner.companyId}
            onChange={handleCompanySelect}
          >
            <option value="" disabled>
              {t("banner_company_select")}
            </option>
            {allCompaniesLoading ? (
              <option value="" disabled>
                {t("loading")}
              </option>
            ) : (
              allCompaniesData.map((company) => (
                <option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </option>
              ))
            )}
          </select>
        ) : (
          <h4>{companyDetail?.companyName}</h4>
        )}
      </div>

      {userRole === "Admin" && (
        <div className="form-group">
          <label htmlFor="index">{t("banner_index")}:</label>
          <input
            type="number"
            id="index"
            name="index"
            value={addedBanner.index}
            onChange={handleChange}
            disabled
          />
        </div>
      )}
      {userRole === "Admin" && (
        <div className="form-group">
          <label htmlFor="displayTime">
            {t("banner_duration")} ({t("banner_duration_second")}):
          </label>
          <input
            type="text"
            id="displayTime"
            name="displayTime"
            value={addedBanner.displayTime}
            onChange={handleChange}
          />
          <p className="note">{t("banner_duration_description")}</p>
        </div>
      )}
      <div className="date-container">
        <div className="form-group">
          <label htmlFor="startDate">{t("banner_start_date")}:</label>
          <input
            required
            type="date"
            id="startDate"
            name="startDate"
            value={formatDateToISODate(addedBanner.startDate)}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="finishDate">{t("banner_end_date")}:</label>
          <input
            required
            type="date"
            id="finishDate"
            name="finishDate"
            value={formatDateToISODate(addedBanner.finishDate)}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-buttons">
        <button type="submit">{t("button_edit_send")}</button>
        <button
          type="button"
          onClick={() => {
            onCancel();
            setBannerImage("");
          }}
        >
          {t("button_edit_cancel")}
        </button>
      </div>
    </form>
  );
};

export default withNamespaces()(BannerAddForm);
