import companyLogo from "./logo.png";
import loginImage from "./login_image.png";
import noImage from "./no-image-icon-6.png";
import turkishFlag from "./turkish.png";
import englishFlag from "./english.png";
import deutschFlag from "./deutsch.png";
import françisFlag from "./french.png";
import oeBanner from "./Frame 95.png";
import tableSelectGif from "./table_select.gif";

const images = {
  companyLogo,
  loginImage,
  noImage,
  turkishFlag,
  englishFlag,
  deutschFlag,
  françisFlag,
  oeBanner,
  tableSelectGif,
};

export default images;
