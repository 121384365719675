import React from 'react'

function PasswordAskIcon() {
    return (
        <svg
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.941214 4.77895C0.0991089 4.42105 -0.258786 3.37895 0.246478 2.62105C1.26753 1.10526 2.97279 0 5.22542 0C7.69911 0 9.39385 1.12632 10.257 2.53684C10.9938 3.74737 11.4254 6.01053 10.2886 7.69474C9.02542 9.5579 7.8149 10.1263 7.16227 11.3263C7.00437 11.6105 6.90964 11.8421 6.84648 12.3158C6.75174 13.0842 6.12016 13.6842 5.34121 13.6842C4.42543 13.6842 3.67806 12.8947 3.78332 11.9789C3.84648 11.4421 3.97279 10.8842 4.26753 10.3579C5.07806 8.89474 6.63595 8.03158 7.54121 6.73684C8.49911 5.37895 7.96227 2.84211 5.24648 2.84211C4.0149 2.84211 3.2149 3.48421 2.72016 4.25263C2.35174 4.85263 1.58332 5.04211 0.941214 4.77895ZM7.34121 17.8947C7.34121 19.0526 6.39385 20 5.23595 20C4.07806 20 3.13069 19.0526 3.13069 17.8947C3.13069 16.7368 4.07806 15.7895 5.23595 15.7895C6.39385 15.7895 7.34121 16.7368 7.34121 17.8947Z"

            />
        </svg>
    )
}

export default PasswordAskIcon
