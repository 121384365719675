import "./styles/suppliers.css";

import { withNamespaces } from "react-i18next";

import { GetContextValue } from "../../context/GetContext";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import { useEffect, useState } from "react";
import SupplierEditBox from "../../components/EditBox/SupplierEditBox";
import ButtonText from "../../components/form_components/Buttons/ButtonText/ButtonText";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { CrudContextValue } from "../../context/CrudContext";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { DemoContextValue } from "../../context/DemoContext";
import axios from "axios";
import { Button, Modal } from "antd";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function Suppliers({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();
  const { deleteMethod, createMethod, updateMethod } = CrudContextValue();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [addSupplierModalOpen, setAddSupplierModalOpen] = useState(false);
  const handleOpenAddSupplierModal = () => setAddSupplierModalOpen(true);
  const handleCloseAddSupplierModal = () => setAddSupplierModalOpen(false);
  const [addSuppLoading, setAddSuppLoading] = useState(false);

  const [approvedSupplierModalOpen, setApprovedSupplierModalOpen] =
    useState(false);
  const handleOpenApprovedSupplierModal = () =>
    setApprovedSupplierModalOpen(true);
  const handleCloseApprovedSupplierModal = () =>
    setApprovedSupplierModalOpen(false);
  const [approvedSuppLoading, setApprovedSuppLoading] = useState(false);

  const [rejectSupplierModalOpen, setRejectSupplierModalOpen] = useState(false);
  const handleOpenRejectSupplierModal = () => setRejectSupplierModalOpen(true);
  const handleCloseRejectSupplierModal = () =>
    setRejectSupplierModalOpen(false);
  const [rejectSuppLoading, setRejectSuppLoading] = useState(false);

  // tablo filtreleme
  // 1 = onay beklyen
  // 2 = tedarikçilerim
  // 3 = reddedilen
  const [tableFilter, setTableFilter] = useState(2);
  const [supplierStatus, setSupplierStatus] = useState("Tedarikçilerim");
  const [tableRender, setTableRender] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [recommended, setRecommended] = useState([]);
  const [recommendedLoading, setRecommendedLoading] = useState(false);
  const [recommendedError, setRecommendedError] = useState(null);
  const [recommendedRender, setRecommendedRender] = useState(false);

  const [mySuppliersPage, setMySuppliersPage] = useState(1);
  const [mySuppliersRowsPerPage, setMySuppliersRowsPerPage] = useState(10);
  const [mySuppliersTotal, setMySuppliersTotal] = useState(0);

  const [mySuppliers, setMySuppliers] = useState([]);
  const [mySuppliersLoading, setMySuppliersLoading] = useState(false);
  const [mySuppliersError, setMySuppliersError] = useState(null);
  const [mySuppliersRender, setMySuppliersRender] = useState(false);

  useEffect(() => {
    if (loginUserCompanyId) {
      const postDataMySup = {
        companyId: loginUserCompanyId,
        status: tableFilter,
        searchedText: searchText,
        companyCategoryIds: [],
        page: mySuppliersPage,
        pageSize: mySuppliersRowsPerPage,
      };
      console.log("postDataMySup : ", postDataMySup);
      async function getMySupplier() {
        setMySuppliersLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_GET_MY_SUPPLIER_URL,
            JSON.stringify(postDataMySup),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            // console.log(
            //   "REACT_APP_GET_MY_SUPPLIER_URL responseSupp.data.isError : ",
            //   responseSupp.data.errorMessageList[0]
            // );
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setMySuppliersError(responseSupp.data.isError);
            }
          } else {
            // console.log("responseSuppSupp : ", responseSupp);
            setMySuppliers(responseSupp.data.data);
          }

          if (responseSupp.data.total > 0) {
            setMySuppliersTotal(responseSupp.data.total);
          }
        } catch (error) {
          console.log("REACT_APP_GET_MY_SUPPLIER_URL, catchError :", error);
          setMySuppliersError(error);
        }
        setMySuppliersLoading(false);
      }

      if (token && loginUserCompanyId) {
        getMySupplier();
      }
    }
  }, [
    loginUserCompanyId,
    notificationRender,
    mySuppliersRender,
    searchText,
    tableFilter,
  ]);

  useEffect(() => {
    if (loginUserCompanyId) {
      const postData = {
        categoryIds: [],
        companyId: loginUserCompanyId,
      };

      async function getData() {
        setRecommendedLoading(true);
        try {
          const responseSupp = await axios.post(
            process.env.REACT_APP_POST_RECOMENDED_SUPPLIER_URL,
            JSON.stringify(postData),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (responseSupp.data.isError) {
            console.log(
              "REACT_APP_POST_RECOMENDED_SUPPLIER_URL responseSupp.data.isError : ",
              responseSupp.data.errorMessageList[0]
            );
            if (responseSupp.data.errorMessageList[0] !== "Sonuç bulunamadı") {
              setRecommendedError(responseSupp.data.isError);
            }
          } else {
            console.log("responseSuppSupp : ", responseSupp);
            setRecommended(responseSupp.data.data);
          }
        } catch (error) {
          console.log(
            "REACT_APP_POST_RECOMENDED_SUPPLIER_URL, catchError :",
            error
          );
          setRecommendedError(error);
        }
        setRecommendedLoading(false);
      }

      if (token && loginUserCompanyId) {
        getData();
      }
    }
  }, [loginUserCompanyId, notificationRender, recommendedRender]);

  const columns = [
    {
      key: "logo",
      header: t("table_photo"),
    },
    {
      key: "companyName",
      header: t("table_company_name"),
    },
    {
      key: "categories",
      header: t("table_category"),
    },
    {
      key: "email",
      header: t("table_email"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
    supplierStatus === "Tedarikçilerim" && {
      key: "distance",
      header: t("table_distance"),
    },
    {
      key: "addresses",
      header: t("table_address"),
    },
  ];

  useEffect(() => {
    setMySuppliersRender(!mySuppliersRender);
  }, [notificationRender]);

  useEffect(() => {
    if (supplierStatus === "Tedarikçilerim") {
      setMySuppliersRender(!mySuppliersRender);
    } else if (supplierStatus === "Tavsiye Edilenler") {
      setRecommendedRender(!recommendedRender);
    }
  }, [tableRender, notificationRender]);

  useEffect(() => {
    if (supplierStatus === "Tedarikçilerim") {
      setTableFilter(2);
    } else if (supplierStatus === "Onay Bekleyenler") {
      setTableFilter(1);
    } else if (supplierStatus === "Reddedilen") {
      setTableFilter(3);
    }
  }, [supplierStatus]);

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, [mySuppliers, recommended, supplierStatus]);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  async function deleteSupplier() {
    console.log(`companyId=${loginUserCompanyId}&supplierId=${selectValueId}`);
    await deleteMethod(
      `${process.env.REACT_APP_DELETE_SUPPLIER_URL}?companyId=${loginUserCompanyId}&supplierId=${selectValueId}`,
      token
    );
    setMySuppliersRender(!mySuppliersRender);
    handleCloseDeleteModal();
  }

  async function handleAddSupplier() {
    setAddSuppLoading(true);

    const newSupplier = {
      supplierCompanyId: selectValueId,
    };

    await createMethod(
      process.env.REACT_APP_POST_ADD_SUPPLIER_URL,
      JSON.stringify(newSupplier),
      token
    );

    setAddSuppLoading(false);

    handleCloseAddSupplierModal();

    setRecommendedRender(!recommendedRender);
    setMySuppliersRender(!mySuppliersRender);
  }

  async function handleApprovedSupplier() {
    setApprovedSuppLoading(true);

    const newSupplier = {
      supplierCompanyId: selectValueId,
      supplierStatus: 2,
    };

    console.log(newSupplier);

    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_SUPPLIER_STATUS_URL,
      JSON.stringify(newSupplier),
      token
    );

    setApprovedSuppLoading(false);

    handleCloseApprovedSupplierModal();

    setMySuppliersRender(!mySuppliersRender);
  }

  async function handleRejectSupplier() {
    setRejectSuppLoading(true);

    const newSupplier = {
      supplierCompanyId: selectValueId,
      supplierStatus: 3,
    };

    console.log(newSupplier);

    await updateMethod(
      process.env.REACT_APP_PUT_UPDATE_SUPPLIER_STATUS_URL,
      JSON.stringify(newSupplier),
      token
    );

    setRejectSuppLoading(false);

    handleCloseRejectSupplierModal();

    setMySuppliersRender(!mySuppliersRender);
  }

  return (
    <div className="suppliers-container">
      <div className="suppliers-edit-box">
        {/* Tabloda işlem yapmak için kullanılan butonlar */}
        {deleteModalOpen ? (
          <ConfirmModal
            handleCloseModal={handleCloseDeleteModal}
            cancelIcon={<i className="fa-solid fa-xmark"></i>}
            cancelButtonText={t("button_edit_cancel")}
            confirmIcon={<i className="fa-solid fa-trash-can"></i>}
            confirmButtonText={t("button_edit_delete")}
            text={t("message_delete_supplier")}
            confirmFunc={deleteSupplier}
          />
        ) : (
          <SupplierEditBox
            supplierStatus={supplierStatus}
            searchText={searchText}
            setSearchText={setSearchText}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
            useFetchRender={tableRender}
            setUseFetchRender={setTableRender}
            selectValueId={selectValueId}
            setSelectValueId={setSelectValueId}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenAddSupplierModal={handleOpenAddSupplierModal}
            handleOpenApprovedSupplierModal={handleOpenApprovedSupplierModal}
            handleOpenRejectSupplierModal={handleOpenRejectSupplierModal}
          />
        )}
      </div>

      <div className="supplier-page-link">
        <div className="opportunities-button-groups">
          <ButtonText
            icon={<i className="fa-solid fa-table-cells-large"></i>}
            text={t("button_suppliers")}
            onClick={() => setSupplierStatus("Tedarikçilerim")}
            className={
              supplierStatus === "Tedarikçilerim" ? "button-active" : ""
            }
          />
          <ButtonText
            icon={<i className="fa-solid fa-clock-rotate-left"></i>}
            text={t("button_confirmation_waiting")}
            onClick={() => setSupplierStatus("Onay Bekleyenler")}
            className={
              supplierStatus === "Onay Bekleyenler" ? "button-active" : ""
            }
          />
          <ButtonText
            icon={<i className="fa-solid fa-circle-exclamation"></i>}
            text={t("table_edit_reject")}
            onClick={() => setSupplierStatus("Reddedilen")}
            className={supplierStatus === "Reddedilen" ? "button-active" : ""}
          />
          <ButtonText
            icon={<i className="fa-solid fa-check"></i>}
            text={t("button_recommended")}
            onClick={() => setSupplierStatus("Tavsiye Edilenler")}
            className={
              supplierStatus === "Tavsiye Edilenler" ? "button-active" : ""
            }
          />

          {addSupplierModalOpen && (
            <Modal
              open={addSupplierModalOpen}
              onCancel={handleCloseAddSupplierModal}
              title={t("add_supplier_title")}
              children={
                <div className="confirm-modal-container">
                  <p>
                    {`${selectValue.companyName} ${t(
                      "message_confirm_add_supplier"
                    )}`}
                  </p>
                  <div className="modal-actions-button">
                    <Button type="primary" onClick={handleAddSupplier}>
                      {t("button_edit_confirm")}
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleCloseAddSupplierModal}
                    >
                      {t("button_edit_cancel")}
                    </Button>
                  </div>
                </div>
              }
              footer={null}
            />
          )}

          {rejectSupplierModalOpen && (
            <Modal
              open={rejectSupplierModalOpen}
              onCancel={handleCloseRejectSupplierModal}
              title={t("reject_request_supplier_title")}
              children={
                <div className="confirm-modal-container">
                  <p>
                    {`${selectValue.companyName} ${t(
                      "message_confirm_reject_supplier"
                    )}`}
                  </p>
                  <div className="modal-actions-button">
                    <Button type="primary" onClick={handleRejectSupplier}>
                      {t("button_edit_reject")}
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleCloseRejectSupplierModal}
                    >
                      {t("button_edit_cancel")}
                    </Button>
                  </div>
                </div>
              }
              footer={null}
            />
          )}

          {approvedSupplierModalOpen && (
            <Modal
              open={approvedSupplierModalOpen}
              onCancel={handleCloseApprovedSupplierModal}
              title={t("add_supplier_title")}
              children={
                <div className="confirm-modal-container">
                  <p>
                    {`${selectValue.companyName} ${t(
                      "message_confirm_add_supplier"
                    )}`}
                  </p>
                  <div className="modal-actions-button">
                    <Button type="primary" onClick={handleApprovedSupplier}>
                      {t("button_edit_confirm")}
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleCloseApprovedSupplierModal}
                    >
                      {t("button_edit_cancel")}
                    </Button>
                  </div>
                </div>
              }
              footer={null}
            />
          )}
        </div>
      </div>

      <div className="suppliers-table">
        {supplierStatus === "Tedarikçilerim" ||
        supplierStatus === "Onay Bekleyenler" ||
        supplierStatus === "Reddedilen" ? (
          <>
            {mySuppliersError ? (
              <ErrorComponent error={mySuppliersError} />
            ) : mySuppliersLoading ? (
              <Loading />
            ) : mySuppliers !== null ? (
              <StandartTable
                type="company"
                searchText={searchText}
                setSearchText={setSearchText}
                selectValueId={selectValueId}
                selectValue={selectValue}
                handleClickValue={handleClickValue}
                useFetchRender={mySuppliersRender}
                setUseFetchRender={setMySuppliersRender}
                total={mySuppliersTotal}
                page={mySuppliersPage}
                setPage={setMySuppliersPage}
                rowsPerPage={mySuppliersRowsPerPage}
                setRowsPerPage={setMySuppliersRowsPerPage}
                columns={columns}
                data={mySuppliers}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            )}
          </>
        ) : supplierStatus === "Tavsiye Edilenler" ? (
          <>
            {recommendedError ? (
              <ErrorComponent error={recommendedError} />
            ) : recommendedLoading ? (
              <Loading />
            ) : recommended !== null ? (
              <StandartTable
                type="company"
                searchText={searchText}
                setSearchText={setSearchText}
                selectValueId={selectValueId}
                selectValue={selectValue}
                handleClickValue={handleClickValue}
                useFetchRender={recommendedRender}
                setUseFetchRender={setRecommendedRender}
                total={null}
                page={null}
                setPage={null}
                rowsPerPage={null}
                setRowsPerPage={null}
                columns={columns}
                data={recommended}
              />
            ) : (
              <div className="table-null-text">
                {t("message_emty_companylist")}
              </div>
            )}
          </>
        ) : null}
      </div>
      {(approvedSuppLoading || addSuppLoading) && <LoadingCircular />}
    </div>
  );
}

export default withNamespaces()(Suppliers);
