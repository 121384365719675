import React, { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import { getFetch } from "../../../hooks/getFetch";
import { AuthContextValue } from "../../../context/AuthContext";

const CityComponent = ({
  required,
  setSelectedCity,
  selectedCity,
}) => {
  const { token } = AuthContextValue();

  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFetch(
          `${process.env.REACT_APP_GET_CITIES_URL}?cityId=${0}`,
          token
        );
        if (response.data) {
          setCities(response.data.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [token]);

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    console.log(event.target.value);
    setSelectedCity(selectedCityId);
  };

  return (
    <CustomSelect
      required={required}
      value={selectedCity}
      options={cities}
      onChange={handleCityChange}
    />
  );
};

export default CityComponent;

