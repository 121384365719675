import "./offerDetailCard.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import DetailText from "../DetailText/DetailText";
import Carousel from "../Carousel/Carousel";
import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";
import RevisedModal from "../Modals/RevisedModal/RevisedModal";
import { toast } from "react-toastify";
import LoadingCircular from "../LoadingCircular/LoadingCircular";
import RevisedDescriptionModal from "../Modals/RevisedDescriptionModal/RevisedDescriptionModal";
import { Alert, Space } from "antd";
import HistoryOffersModal from "../Modals/HistoryOffersModal/HistoryOffersModal";

function OfferDetailCard({
  t,
  offerDetail,
  setOfferDetailUseFetchRender,
  offerDetailUseFetchRender,
}) {
  const navigate = useNavigate();

  const { token, loginUserCompanyId } = AuthContextValue();
  const { deleteMethod, updateMethod } = CrudContextValue();

  const [revisedLoading, setRevisedLoading] = useState(false);
  const [approvedLoading, setApprovedLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [revisedModalOpen, setRevisedModalOpen] = useState(false);
  const handleOpenRevisedModal = () => setRevisedModalOpen(true);
  const handleCloseRevisedModal = () => setRevisedModalOpen(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const handleOpenConfirmModal = () => setConfirmModalOpen(true);
  const handleCloseConfirmModal = () => setConfirmModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const [revisedNote, setRevisedNote] = useState("");
  const [openRevisedNote, setOpenRevisedNote] = useState(false);
  const handleOpenRevisedNote = () => setOpenRevisedNote(true);
  const handleCloseRevisedNote = () => setOpenRevisedNote(false);

  const [resultRevisedNote, setResultRevisedNote] = useState("");
  const [openResultRevisedNote, setOpenResultRevisedNote] = useState(false);
  const handleOpenResultRevisedNote = () => setOpenResultRevisedNote(true);
  const handleCloseResultRevisedNote = () => setOpenResultRevisedNote(false);

  const [historyOffersOpen, setHistoryOffersOpen] = useState(false);
  const handleOpenHistoryOfferModal = () => setHistoryOffersOpen(true);
  const handleCloseHistoryOfferModal = () => setHistoryOffersOpen(false);

  const [document, setDocument] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (offerDetail !== null) {
      if (offerDetail.images !== null && offerDetail.images.length > 0) {
        let imagesPath = [];
        let documentPath = [];
        offerDetail.images.map((doc) => {
          if (doc.includes("pdf")) {
            documentPath.push(doc);
          } else {
            imagesPath.push(doc);
          }
        });
        setDocument(documentPath);
        setImages(imagesPath);
      }

      setRevisedNote(offerDetail.confirmDescription);
      setResultRevisedNote(offerDetail.revisedDescription);
    }
  }, [offerDetail]);

  async function handleDeleteOffer() {
    console.log(offerDetail.offerId, "idli teklif silindi");

    setDeleteLoading(true);
    console.log(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${offerDetail.offerId}`
    );
    const response = await deleteMethod(
      `${process.env.REACT_APP_DELETE_OFFER_URL}?id=${offerDetail.offerId}`,
      token
    );

    setDeleteLoading(false);

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      handleCloseDeleteModal();
      setOfferDetailUseFetchRender(!offerDetailUseFetchRender);

      toast.info(t("message_update_offer_success"), {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }
  }

  async function handleRevisedOffer(revisedDescription) {
    console.log(
      offerDetail.offerId,
      "idli teklif revize edildi",
      " açıklaması : ",
      revisedDescription
    );

    setRevisedLoading(true);

    const response = await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=3&confirmDescription=${revisedDescription}`,
      null,
      token
    );

    setRevisedLoading(false);

    handleCloseRevisedModal();

    if (response && !response.catchError && !response.isError) {
      setRevisedLoading(false);

      setOfferDetailUseFetchRender(!offerDetailUseFetchRender);
      handleCloseRevisedModal();

      toast.info(t("message_update_offer_success"), {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/offers");
      }, 3000);
    }
  }

  async function handleConfirmOffer() {
    console.log(offerDetail.offerId, "idli teklif onaylandı");

    setApprovedLoading(true);
    console.log(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=Onaylandı`
    );
    await updateMethod(
      `${process.env.REACT_APP_PUT_OFFER_STATUS_URL}?offerId=${offerDetail.offerId}&status=Onaylandı`,
      null,
      token
    );

    setApprovedLoading(false);

    handleCloseConfirmModal();

    setOfferDetailUseFetchRender(!offerDetailUseFetchRender);
  }

  return (
    <div className="offer-detail-card">
      <div className="offer-detail-card-top">
        {revisedLoading || deleteLoading || approvedLoading ? (
          <LoadingCircular />
        ) : deleteModalOpen ? (
          <ConfirmModal
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_delete")}
            handleCloseModal={handleCloseDeleteModal}
            confirmFunc={handleDeleteOffer}
            text={t("message_delete_offer")}
          />
        ) : revisedModalOpen ? (
          <RevisedModal
            cancelText={t("button_edit_cancel")}
            confirmText={t("button_revised")}
            handleCloseModal={handleCloseRevisedModal}
            confirmFunc={handleRevisedOffer}
          />
        ) : confirmModalOpen ? (
          <ConfirmModal
            cancelButtonText={t("button_edit_cancel")}
            confirmButtonText={t("button_edit_confirm")}
            handleCloseModal={handleCloseConfirmModal}
            confirmFunc={handleConfirmOffer}
            text={t("message_confirm_offer")}
          />
        ) : (
          <>
            <div className="title">
              <i className="fa-solid fa-bahai"></i>
              <h4 className="title">{t("button_edit_offer_detail")}</h4>
            </div>
            <div className="button-group">
              {Number(offerDetail.offerCompanyId) ===
                Number(loginUserCompanyId) && offerDetail.status !== "2" ? (
                <>
                  {offerDetail.status === "1" && (
                    <>
                      <WhiteButton
                        icon={<i className="fa-solid fa-check"></i>}
                        tooltip={t("button_edit_confirm")}
                        onClick={handleOpenConfirmModal}
                      />
                      <WhiteButton
                        icon={<i className="fa-solid fa-arrows-rotate"></i>}
                        tooltip={t("button_edit_revised")}
                        onClick={handleOpenRevisedModal}
                      />
                    </>
                  )}

                  {offerDetail.status === "3" && (
                    <WhiteButton
                      icon={<i className="fa-solid fa-note-sticky"></i>}
                      tooltip={t("button_edit_revised_note")}
                      onClick={handleOpenRevisedNote}
                    />
                  )}

                  <Link
                    to={
                      offerDetail.offerId
                        ? `/offers/offer-edit/${offerDetail.offerId}`
                        : false
                    }
                  >
                    <WhiteButton
                      icon={<i className="fa-solid fa-pen-to-square"></i>}
                      tooltip={t("button_edit_update")}
                    />
                  </Link>

                  <WhiteButton
                    icon={<i className="fa-solid fa-trash-can"></i>}
                    tooltip={t("button_edit_delete")}
                    onClick={handleOpenDeleteModal}
                  />
                </>
              ) : null}

              {Number(offerDetail.offerCompanyId) ===
                Number(loginUserCompanyId) && offerDetail.result === "3" ? (
                <>
                  <WhiteButton
                    icon={<i className="fa-solid fa-note-sticky"></i>}
                    tooltip={t("button_edit_revised_note")}
                    onClick={handleOpenResultRevisedNote}
                  />

                  <Link
                    to={
                      offerDetail.result !== "3"
                        ? false
                        : offerDetail.tenderId
                        ? `/opportunities/tender-detail-offer/${offerDetail.tenderId}`
                        : false
                    }
                  >
                    <WhiteButton
                      disabled={offerDetail.result !== "3" ? true : false}
                      icon={<i className="fa-solid fa-cart-plus"></i>}
                      tooltip={
                        offerDetail.result !== "3"
                          ? t("message_no_second_offer")
                          : t("button_request_offer")
                      }
                    />
                  </Link>

                  {offerDetail && offerDetail?.result === "3" ? (
                    <Link
                      to={`/opportunities/tender-detail-offer-justprice/${
                        offerDetail?.tenderId
                      }/${offerDetail && offerDetail?.offerId}`}
                    >
                      <WhiteButton
                        icon={
                          <i className="fa-solid fa-money-check-dollar"></i>
                        }
                        tooltip={t("message_info_send_offer_justprice")}
                      />
                    </Link>
                  ) : null}
                </>
              ) : null}
              {Number(offerDetail.offerCompanyId) !==
                Number(loginUserCompanyId) && (
                <WhiteButton
                  icon={<i className="fa-solid fa-clock-rotate-left"></i>}
                  tooltip={t("history_offer_title")}
                  onClick={handleOpenHistoryOfferModal}
                />
              )}
            </div>
          </>
        )}
        {/* Firma içi manager revize talep notu */}
        {openRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={revisedNote}
            openRevisedNote={openRevisedNote}
            handleCloseRevisedNote={handleCloseRevisedNote}
          />
        )}
        {/* İhale sahibi teklif revize talebi notu */}
        {openResultRevisedNote && (
          <RevisedDescriptionModal
            revisedNote={resultRevisedNote}
            openRevisedNote={openResultRevisedNote}
            handleCloseRevisedNote={handleCloseResultRevisedNote}
          />
        )}
      </div>
      <div className="offer-detail-card-main">
        {Number(offerDetail?.offerCompanyId) === Number(loginUserCompanyId) ? (
          <>
            {offerDetail.status === "1" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={t("message_alert_waitind_manager_confirmed")}
                  type="warning"
                />
              </Space>
            ) : offerDetail.status === "3" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={t("message_alert_revised_requested_offer_manager")}
                  type="error"
                />
              </Space>
            ) : offerDetail.result === "1" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={t("message_alert_waiting_tender_company_response")}
                  type="warning"
                />
              </Space>
            ) : offerDetail.result === "2" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={t("message_alert_approved_offer")}
                  type="success"
                />
              </Space>
            ) : offerDetail.result === "3" ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Alert
                  message={t(
                    "message_alert_revised_requested_offer_tender_company"
                  )}
                  type="error"
                />
              </Space>
            ) : null}
          </>
        ) : null}
        <div className="offer-detail-card-images-container">
          {images && images.length > 0 ? (
            <Carousel images={images} />
          ) : (
            <div className="no-offer-image">
              <h3>{t("message_no_photo")}</h3>
            </div>
          )}
        </div>

        <div className="offer-detail-card-info-container">
          <div className="offer-detail-card-documan-container">
            <p className="detail-text-title">{t("input_documents")}:</p>
            <div className="documan-box">
              {document && document.length >= 1
                ? document.map((doc, i) => {
                    return (
                      <a
                        key={i}
                        target="_blank"
                        // href={`${process.env.REACT_APP_GET_IMAGE_URL}${doc}`}
                        href={doc}
                        className="detail-text"
                      >
                        {doc.split("images/")[1]}
                      </a>
                    );
                  })
                : "-"}
            </div>
          </div>
          <DetailText
            title={t("table_create_date")}
            text={new Date(offerDetail.offerCreatedAt).toLocaleString()}
          />
          <DetailText title={t("support_request_created_user")} text={offerDetail.createdUser} />
          <DetailText
            title={t("table_deadline")}
            text={new Date(offerDetail.deadline).toLocaleString()}
          />
          <DetailText title={t("table_description")} text={offerDetail.description} />
          <DetailText
            title={t("table_labor_price")}
            text={Number(offerDetail.laborPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText
            title={t("table_materiel_price")}
            text={Number(offerDetail.materialPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText
            title={t("table_unit_price")}
            text={Number(
              offerDetail.laborPrice + offerDetail.materialPrice
            ).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          {offerDetail && Number(offerDetail.shippingPrice) > 0 ? (
            <DetailText
              title={t("table_unit_shipping_price")}
              text={Number(offerDetail.shippingPrice).toLocaleString(
                offerDetail?.currency === "string"
                  ? "TRY"
                  : offerDetail.currency,
                {
                  style: "currency",
                  currency:
                    offerDetail?.currency === "string"
                      ? "TRY"
                      : offerDetail.currency,
                }
              )}
            />
          ) : null}

          <DetailText
            title={t("table_total_price")}
            text={Number(offerDetail.totalPrice).toLocaleString(
              offerDetail?.currency === "string" ? "TRY" : offerDetail.currency,
              {
                style: "currency",
                currency:
                  offerDetail?.currency === "string"
                    ? "TRY"
                    : offerDetail.currency,
              }
            )}
          />

          <DetailText title={t("button_edit_note")} text={offerDetail.note} />
        </div>

        {historyOffersOpen && (
          <HistoryOffersModal
            supplierId={offerDetail.offerCompanyId}
            tenderId={offerDetail.tenderId}
            isModalOpen={historyOffersOpen}
            handleCloseModal={handleCloseHistoryOfferModal}
          />
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(OfferDetailCard);
