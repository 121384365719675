import "./ratingComponent.css"; // Stil dosyanızı ekleyin
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

import { Rate } from "antd";

const RatingComponent = ({t, handleRating, loading }) => {
  const [reactionScore, setReactionScore] = useState(0);
  const [achievementScore, setAchievementScore] = useState(0);
  const [deliveryScore, setDeliveryScore] = useState(0);

  const handleRate = async () => {
    const ratingData = {
      reactionScore: reactionScore,
      achievementScore: achievementScore,
      deliveryScore: deliveryScore,
    };

    await handleRating(ratingData);
  };

  return (
    <div className="rating-container">
      <div className="rating-main">
        <h3>{t("rating_modal_title")}</h3>
        <div className="rating-item">
          <label>{t("rating_modal_reaction_score")}:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={reactionScore}
            onChange={(value) => setReactionScore(value)}
          />
        </div>
        <div className="rating-item">
          <label>{t("rating_modal_success_score")}:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={achievementScore}
            onChange={(value) => setAchievementScore(value)}
          />
        </div>
        <div className="rating-item">
          <label>{t("rating_modal_delivery_score")}:</label>
          <Rate
            disabled={loading}
            allowHalf
            count={10}
            value={deliveryScore}
            onChange={(value) => setDeliveryScore(value)}
          />
        </div>
        <button onClick={handleRate} disabled={loading}>
          {loading ? t("loading") : t("button_vote")}
        </button>
      </div>
    </div>
  );
};

export default withNamespaces()(RatingComponent);
