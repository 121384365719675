export function tenderTimeOverCalcFunc(duration) {
  if (duration <= 0) {
    return "overTime";
  } else {
    return "time";
  }
}

function formatDuration(duration) {
  if (duration <= 0) {
    return "overTime";
  } else {
    return "time";
  }
}
