import "./navbar.css";

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { ThemeContextValue } from "../../context/ThemeContext";

import icons from "../../assets/icons/index";
import MenuIcon from "../Icons/MenuIcon";
import images from "../../assets/images";
import i18n from "../../i18n/i18n";
import { Dropdown } from "antd";

const { profileIcon, offerexLogoDark, offerexLogoLight } = icons;

function Navbar({ t, openSideBar, setOpenSideBar }) {
  const navigate = useNavigate();
  const { loginUserInfo, loginUserId } = AuthContextValue();
  const { setTheme, theme } = ThemeContextValue();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleThemeChange = (theme) => {
    setTheme(theme);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      event.target.className !== "profile"
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const settings = JSON.parse(localStorage.getItem("setting-values"));

  const forceUpdate = React.useReducer(() => ({}), {})[1]; // forceUpdate işlevi
  function handleChangeLang(lang) {
    i18n.changeLanguage(lang);

    if (settings) {
      settings.systemLanguage = lang;

      localStorage.setItem("setting-values", JSON.stringify(settings));
    }

    forceUpdate(); // Bileşeni yenilemek için forceUpdate işlevini çağırın
  }

  /* offerex ten çıkış yapmak için */
  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("setting-values");
    localStorage.removeItem("notify");

    window.location.reload();

    navigate("/login");
  }


  return (
    <div className="navbar-container">
      <div className="navbar-logo">
        <MenuIcon
          onClick={() => setOpenSideBar(!openSideBar)}
          // color="#344250"
          width="60"
          height="60"
        />
        <img
          src={
            theme === "dark"
              ? offerexLogoLight
              : theme === "light"
              ? offerexLogoDark
              : offerexLogoDark
          }
          alt="offerex-logo"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="navbar-right-box">
        <div className="button-group">{/* <InputSearch /> */}</div>

        <div className="navbar-profile">
          <div
            className={`profile-box ${
              isDropdownOpen
                ? "profile-dropdown-box-open"
                : "profile-dropdown-box-close"
            }`}
          >
            <div
              className={`profile ${
                isDropdownOpen
                  ? "dropdown-active-profile"
                  : "dropdown-none-profile"
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="profile-image">
                <img
                  src={loginUserInfo ? loginUserInfo.image : profileIcon}
                  alt="profile icon"
                />
              </div>

              <div className="profile-name">
                <h4>
                  {loginUserInfo?.firstName} {loginUserInfo?.lastName}
                </h4>
              </div>
            </div>

            <div
              className={`profile-dropdown-box ${
                isDropdownOpen ? "profile-dropdown-box-open" : ""
              }`}
              ref={dropdownRef}
            >
              <ul>
                <Link to={`/profile/${loginUserId}`}>
                  <li>{t("navbar_dropdown_profile")}</li>
                </Link>
                <li className="theme-li">
                  <span>{t("navbar_dropdown_theme")}</span>
                  <div className="theme-buttons">
                    <button
                      title={t("settings_color_light")}
                      className={theme === "light" ? "select-theme" : ""}
                      onClick={() => handleThemeChange("light")}
                    >
                      <i className="fa-solid fa-sun"></i>
                    </button>
                    <button
                      title={t("settings_color_dark")}
                      className={theme === "dark" ? "select-theme" : ""}
                      onClick={() => handleThemeChange("dark")}
                    >
                      <i className="fa-solid fa-moon"></i>
                    </button>
                  </div>
                </li>
                <li className="dropdown-lang-li">
                  <span>{t("navbar_dropdown_lang")}</span>
                  <div className="language-container">
                    <img
                      title={"tr"}
                      src={images.turkishFlag}
                      alt={i18n.language}
                      className={`lang-button-img ${
                        i18n.language === "tr"
                          ? "active-lang-img"
                          : "disabled-lang-img"
                      }`}
                      onClick={() => handleChangeLang("tr")}
                    />

                    <img
                      title={"en"}
                      src={images.englishFlag}
                      alt={i18n.language}
                      className={`lang-button-img ${
                        i18n.language === "en"
                          ? "active-lang-img"
                          : "disabled-lang-img"
                      }`}
                      onClick={() => handleChangeLang("en")}
                    />

                    <img
                      title={"de"}
                      src={images.deutschFlag}
                      alt={i18n.language}
                      className={`lang-button-img ${
                        i18n.language === "de"
                          ? "active-lang-img"
                          : "disabled-lang-img"
                      }`}
                      onClick={() => handleChangeLang("de")}
                    />

                    <img
                      title={"fr"}
                      src={images.françisFlag}
                      alt={i18n.language}
                      className={`lang-button-img ${
                        i18n.language === "fr"
                          ? "active-lang-img"
                          : "disabled-lang-img"
                      }`}
                      onClick={() => handleChangeLang("fr")}
                    />
                  </div>
                </li>
                <li className="dropdown-last-li" onClick={handleLogout}>
                  {t("navbar_dropdown_logout")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Navbar);
