import "./styles/editBox.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import { withNamespaces } from "react-i18next";

import WhiteButton from "../form_components/Buttons/WhiteButton/WhiteButton";
import { AuthContextValue } from "../../context/AuthContext";

function UserProfileEditBox({
  t,

  title,

  useFetchRender,
  setUseFetchRender,

  selectValueId,
  setSelectValueId,

  selectValue,
  setSelectValue,

  handleOpenResetPasswordModal,
  handleOpenDeleteUserModal,
}) {
  const navigate = useNavigate();
  const { userId } = useParams();

  const { loginUserId, userRole } = AuthContextValue();

  /* process => Yapılacak işlem (düzenle,sil,..) */
  function handleClick(process) {
    switch (process) {
      case "render":
        console.log("Tablo yenilendi!");

        setUseFetchRender(!useFetchRender);

        if (setSelectValue && selectValueId) {
          setSelectValueId(null);
          setSelectValue(null);
        }

        break;

      case "search":
        console.log("Bul a tıklandı!");
        // alert("Bul a tıklandı!");

        break;

      case "edit":
        console.log("Edit e tıklandı!");
        console.log("düzenlenicek value : ", selectValue);

        break;

      case "delete":
        console.log("Sil e tıklandı!");

        handleOpenDeleteUserModal();

        break;

      case "reset-password":
        console.log("reset-passwordtıklandı!");

        handleOpenResetPasswordModal();

        break;

      default:
        break;
    }
  }

  return (
    <div className="edit-container">
      <div className="edit-box">
        <>
          <div className="edit-container-title">
            <WhiteButton
              icon={<i className="fa-solid fa-chevron-left"></i>}
              text={t("button_edit_back")}
              onClick={() => navigate(-1)}
            />
            <div className="title">
              <i className="fa-solid fa-users-line" />
              <h4 className="title">{title}</h4>
            </div>
          </div>

          <div className="edit-container-button-group">
            <WhiteButton
              icon={<i className="fa-solid fa-arrow-rotate-right" />}
              tooltip={t("button_edit_render")}
              onClick={() => handleClick("render")}
            />

            <WhiteButton
              icon={<i className="fa-solid fa-trash-can"></i>}
              tooltip={t("button_edit_delete")}
              onClick={() => handleClick("delete")}
            />

            {(loginUserId === userId || userRole === "Admin") && (
              <Link
                to={
                  loginUserId === userId
                    ? `/profile/profile-edit/${userId}`
                    : `/users/user-edit/${userId}`
                }
              >
                <WhiteButton
                  icon={<i className="fa-solid fa-pen-to-square"></i>}
                  tooltip={t("button_edit_update")}
                  onClick={() => handleClick("edit")}
                />
              </Link>
            )}

            <WhiteButton
              icon={<i className="fa-solid fa-xmarks-lines"></i>}
              tooltip={t("button_form_reset_password")}
              onClick={() => handleClick("reset-password")}
            />
          </div>
        </>
      </div>
    </div>
  );
}

export default withNamespaces()(UserProfileEditBox);
