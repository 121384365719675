import "./styles/tenderChange.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { withNamespaces } from "react-i18next";

import { CrudContextValue } from "../../../context/CrudContext";
import { AuthContextValue } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import TenderChangeStepOne from "./TenderChangeStepOne";
import TenderChangeStepTwo from "./TenderChangeStepTwo";
import useFetch from "../../../hooks/UseFetch";

function generateId() {
  const id = Math.floor(Math.random() * 90000) + 10000; // 5 haneli rastgele sayı üret
  return id.toString(); // sayıyı string'e dönüştür ve geri döndür
}

function formatDate(dateValue) {
  const date = new Date(dateValue);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return [year, month, day].join("-");
}

function convertImageToBase64(imageUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous"; // CORS hatası önlemek için
    image.src = imageUrl;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);

      // Resmi base64 formatına dönüştürün
      const base64Data = canvas.toDataURL("image/png"); // veya diğer formatlarda
      resolve(base64Data);
    };

    image.onerror = (error) => {
      reject(error);
    };
  });
}

function TenderChange({ t }) {
  const { tenderId } = useParams();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { token } = AuthContextValue();
  const { createMethod } = CrudContextValue();

  const [changeLoading, setChangeLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState();
  const [properties, setProperties] = useState([]);

  const {
    setUseFetchRender: setTenderDetailUseFetchRender,
    useFetchRender: tenderDetailUseFetchRender,
    error: tenderDetailError,
    loading: tenderDetailLoading,
    data: tenderDetail,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_DETAIL_URL}?id=${tenderId}`,
    token
  );

  useEffect(() => {
    if (!tenderDetailError && tenderDetail && tenderDetail.minPrice) {
      setFormData({
        tenderNumber: tenderDetail.tenderNumber,
        tenderId: parseInt(tenderId),
        categoryId: tenderDetail.categories,
        description: tenderDetail.description,
        productName: tenderDetail.productName,
        amount: tenderDetail.amount,
        minPrice: tenderDetail.minPrice.toLocaleString(),
        maxPrice: tenderDetail.maxPrice.toLocaleString(),
        currency: tenderDetail.currency ? tenderDetail.currency : "TRY",
        expireDate: formatDate(tenderDetail.expireDate),
        deadline: formatDate(tenderDetail.deadline),
        customer: tenderDetail.customer,
        unitType: tenderDetail.unitType,
        tenderCompanyId: tenderDetail.companies,
        images: [],
        note: tenderDetail.note,
        properties: tenderDetail.properties ? tenderDetail.properties : [],
        recursive:
          tenderDetail.frequency && tenderDetail.frequency.length > 0
            ? true
            : false,
        details:
          tenderDetail.frequency && tenderDetail.frequency.length > 0
            ? {
                ...tenderDetail.frequency[0],
                quantityDistribution:
                  tenderDetail.frequency[0].quantityDetailResponse,
                frequency: tenderDetail.frequency[0].frequencyType,
              }
            : {},
      });

      if (tenderDetail.categories) {
        const newCategoryId = tenderDetail.categories.map((category) => ({
          label: category.categoryName,
          value: category.categoryId,
        }));
        setSelectedCategories(newCategoryId);
      }

      if (tenderDetail.properties) {
        setProperties(tenderDetail.properties ? tenderDetail.properties : []);
      }

      /* images */
      if (tenderDetail.images) {
        async function processImages() {
          const filterImg = [];
          const filterDocument = [];

          for (const img of tenderDetail.images) {
            if (img.includes("pdf")) {
              filterDocument.push(img);
            } else {
              try {
                const base64Data = await convertImageToBase64(img);
                filterImg.push(base64Data);
              } catch (error) {
                console.error(error);
              }
            }
          }

          setImages(
            filterImg.map((img) => ({
              id: generateId(),
              base64: img,
              url: null,
            }))
          );
          setDocuments(
            filterDocument.length > 0
              ? filterDocument.map((doc) => ({
                  id: generateId(),
                  fileName: doc,
                }))
              : []
          );
        }
        processImages().then(() => {
          // Kodunuzun geri kalanını burada çalıştırabilirsiniz.
        });
      }
    } else {
      console.log("tenderChange tehnderdetail error : ", tenderDetailError);
    }

  }, [tenderDetail]);

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handlePrev = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    setFormData({ ...formData, ...data });

    const newCategories = formData.categoryId.map((category) => {
      if (category.categoryId) {
        return category.categoryId;
      } else {
        return category;
      }
    });

    const imagesConvert = images.map(
      (image) => image.base64.split("base64,")[1]
    );

    const propertiesFilter = formData.properties.map((prop) => ({
      name: prop.name,
      value: prop.value,
    }));

    const newTender = {
      ...formData,
      ...data,
      images: imagesConvert,
      categoryId: newCategories,
      minPrice:
        typeof formData.minPrice === "string"
          ? parseInt(formData.minPrice.replace(/[,.]/g, ""))
          : formData.minPrice,
      maxPrice:
        typeof formData.maxPrice === "string"
          ? parseInt(formData.maxPrice.replace(/[,.]/g, ""))
          : formData.maxPrice,
      properties: propertiesFilter,
      details: {
        frequency: formData.details.frequency,
        startDate: formData.details.startDate,
        finishDate: formData.details.finishDate,
        quantityDistribution: formData.details.quantityDistribution,
      },
      deadline: formData.recursive
        ? formData.details.finishDate
        : formData.deadline,
    };

    console.log("Güncellenen ihale : ", newTender);

    setChangeLoading(true);

    const response = await createMethod(
      process.env.REACT_APP_POST_REVISED_TENDER_URL,
      JSON.stringify(newTender),
      token
    );

    if (response && !response.catchError && !response.data.isError) {
      toast.info(t("message_create_tender_success"), {
        autoClose: 3000, // 2 saniye sonra otomatik olarak kapat
      });

      setTimeout(() => {
        navigate("/tenders");
      }, 3000);
    }

    setChangeLoading(false);

    setStep(1);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tender-change">
      {formData && formData.productName && (
        <>
          {windowWidth < 1028 ? (
            <>
              {step === 1 ? (
                <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                  <TenderChangeStepOne
                    onNext={handleNext}
                    formData={formData}
                    step={step}
                    images={images}
                    setImages={setImages}
                    documents={documents}
                    setDocuments={setDocuments}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    properties={properties}
                    setProperties={setProperties}
                  />
                </div>
              ) : step === 2 ? (
                <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                  <TenderChangeStepTwo
                    onPrev={handlePrev}
                    onSubmit={handleSubmit}
                    formData={formData}
                    setFormData={setFormData}
                    step={step}
                    setStep={setStep}
                    changeLoading={changeLoading}
                  />
                </div>
              ) : (
                "null"
              )}
            </>
          ) : (
            <>
              <div className={`step-one ${step === 1 ? "" : "disabled"}`}>
                <TenderChangeStepOne
                  onNext={handleNext}
                  formData={formData}
                  step={step}
                  images={images}
                  setImages={setImages}
                  documents={documents}
                  setDocuments={setDocuments}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  properties={properties}
                  setProperties={setProperties}
                />
              </div>
              <div className={`step-two ${step === 2 ? "" : "disabled"}`}>
                <TenderChangeStepTwo
                  onPrev={handlePrev}
                  onSubmit={handleSubmit}
                  formData={formData}
                  setFormData={setFormData}
                  step={step}
                  setStep={setStep}
                  changeLoading={changeLoading}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withNamespaces()(TenderChange);
