import "./tutorialCard.css";

function TutorialCard({ onClick, video, info, index, videoImage }) {
  return (
    <div className="tutorial-card-container" onClick={() => onClick(index)}>
      <div className="video-container">
        {/* <video src={video}></video> */}
        <img src={videoImage} alt=" videoImage" />
        <i className="fa-solid fa-play" />
      </div>
      <div className="info-container">
        <p title={info}>{info}</p>
      </div>
    </div>
  );
}

export default TutorialCard;
