import "./TenderFormAddDetailModal.css";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

import { DatePicker, Input, Select, Button, Form } from "antd";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Item } = Form;

const TenderFormAddDetailModal = ({ t, stepOneForm, setStepOneForm }) => {
  const [form] = Form.useForm();

  const [unitType, setUnitType] = useState("");
  const [frequencyType, setfTrequencyType] = useState("");
  const [customDates, setCustomDates] = useState([
    ...stepOneForm.details.quantityDistribution,
  ]);
  const [selectedType, setSelectedType] = useState(1); // Varsayılan olarak günlük seçili
  const [remainingQuantityTotal, setRemainingQuantityTotal] = useState(0);
  const [otherDateAmount, setOtherDateAmount] = useState(0);

  // Yeni özel tarih eklemek için kullanılacak işlev
  const addCustomDate = () => {
    const newCustomDate = {
      date: "",
      quantity: 0,
    };
    setCustomDates([...customDates, newCustomDate]);
  };

  // Özel tarihlerin verilerini güncellemek için kullanılacak işlev
  const updateCustomDate = (index, key, value) => {
    const updatedCustomDates = [...customDates];
    if (key === "date") {
      updatedCustomDates[index][key] = new Date(value);
    } else {
      updatedCustomDates[index][key] = value;
    }
    setCustomDates(updatedCustomDates);

    const updatedDetails = {
      ...stepOneForm.details,
      quantityDistribution: updatedCustomDates,
    };

    const totalQuantity = customDates.reduce(
      (total, date) => total + Number(date.quantity),
      0
    );
    setRemainingQuantityTotal(totalQuantity);

    setStepOneForm({
      ...stepOneForm,
      recursive: true, // recursive: true olduğunda details dolu olacak
      details: updatedDetails,
    });
  };

  // Özel tarihleri kaldırmak için kullanılacak işlev
  const removeCustomDate = (index) => {
    const updatedCustomDates = [...customDates];
    updatedCustomDates.splice(index, 1);
    setCustomDates(updatedCustomDates);

    const totalQuantity = updatedCustomDates.reduce(
      (total, date) => total + Number(date.quantity),
      0
    );
    console.log("totalQuantity .", totalQuantity);
    setRemainingQuantityTotal(totalQuantity);

    const updatedDetails = {
      ...stepOneForm.details,
      quantityDistribution: updatedCustomDates,
    };

    setStepOneForm({
      ...stepOneForm,
      recursive: true, // recursive: true olduğunda details dolu olacak
      details: updatedDetails,
    });
  };

  useEffect(() => {
    const totalQuantity = customDates.reduce(
      (total, date) => total + Number(date.quantity),
      0
    );
    setRemainingQuantityTotal(totalQuantity);
    const unType =
      stepOneForm.unitType === 1
        ? t("select_piece")
        : stepOneForm.unitType === 2
        ? t("select_ton")
        : stepOneForm.unitType === 3
        ? t("select_kg")
        : stepOneForm.unitType === 4
        ? t("select_g")
        : stepOneForm.unitType === 5
        ? t("select_meter")
        : stepOneForm.unitType === 6
        ? t("select_centimeter")
        : stepOneForm.unitType === 7
        ? t("select_milimeter")
        : stepOneForm.unitType === 8
        ? t("select_liter")
        : stepOneForm.unitType === 9
        ? t("select_inch")
        : "";

    setUnitType(unType);

    // Bu fonksiyon, başlangıç ve bitiş tarihleri arasındaki farkı hesaplar.
    function calculateAmountByFrequency(
      startDate,
      finishDate,
      frequency,
      amount
    ) {
      const start = dayjs(startDate);
      const finish = dayjs(finishDate);
      let diff;

      switch (frequency) {
        case 1: // Günlük
          setfTrequencyType(t("select_daily"));
          diff = finish.diff(start, "day");

          return diff > 0
            ? amount / (diff - stepOneForm.details.quantityDistribution.length)
            : 0;
        case 2: // Haftalık
          setfTrequencyType(t("select_weekly"));
          diff = finish.diff(start, "week");

          return diff > 0
            ? amount / (diff - stepOneForm.details.quantityDistribution.length)
            : 0;
        case 3: // Aylık
          setfTrequencyType(t("select_monthly"));

          diff = finish.diff(start, "month");
          return diff > 0
            ? amount / (diff - stepOneForm.details.quantityDistribution.length)
            : 0;
        case 4: // Senelik
          setfTrequencyType(t("select_yearly"));

          diff = finish.diff(start, "year");
          return diff > 0
            ? amount / (diff - stepOneForm.details.quantityDistribution.length)
            : 0;
        default:
          return "";
      }
    }

    setOtherDateAmount(
      calculateAmountByFrequency(
        stepOneForm.details.startDate,
        stepOneForm.details.finishDate,
        stepOneForm.details.frequency,
        Number(stepOneForm.amount) - Number(remainingQuantityTotal)
      )
    );

    if (remainingQuantityTotal && remainingQuantityTotal > 0) {
      setOtherDateAmount(
        calculateAmountByFrequency(
          stepOneForm.details.startDate,
          stepOneForm.details.finishDate,
          stepOneForm.details.frequency,
          Number(stepOneForm.amount) - Number(remainingQuantityTotal)
        )
      );
    } else {
      setOtherDateAmount(
        calculateAmountByFrequency(
          stepOneForm.details.startDate,
          stepOneForm.details.finishDate,
          stepOneForm.details.frequency,
          Number(stepOneForm.amount)
        )
      );
    }
  }, [stepOneForm, otherDateAmount]);

  return (
    <Form form={form} className="tender-form-add-detail-form-container">
      <Form.Item label={t("table_frequency_startdate")}>
        <DatePicker
          value={
            stepOneForm.details.startDate
              ? dayjs(stepOneForm.details.startDate)
              : null
          }
          onChange={(date, dateString) =>
            setStepOneForm({
              ...stepOneForm,
              details: {
                ...stepOneForm.details,
                startDate: dateString ? dateString : "",
              },
            })
          }
        />
      </Form.Item>

      <Form.Item label={t("table_frequency_finishdate")}>
        <DatePicker
          value={
            stepOneForm.details.finishDate
              ? dayjs(stepOneForm.details.finishDate)
              : null
          }
          onChange={(date, dateString) =>
            setStepOneForm({
              ...stepOneForm,
              details: {
                ...stepOneForm.details,
                finishDate: dateString ? dateString : "",
              },
            })
          }
        />
      </Form.Item>

      <Form.Item
        label={t("input_recursive_type")}
        initialValue={stepOneForm.details.frequency}
      >
        <Select
          defaultValue={stepOneForm.details.frequency}
          onChange={(value) =>
            setStepOneForm({
              ...stepOneForm,
              details: { ...stepOneForm.details, frequency: value },
            })
          }
        >
          <Option value={1}>{t("select_daily")}</Option>
          <Option value={2}>{t("select_weekly")}</Option>
          <Option value={3}>{t("select_monthly")}</Option>
          <Option value={4}>{t("select_yearly")}</Option>
        </Select>
      </Form.Item>

      {customDates.map((customDate, index) => (
        <div key={index} className="custom-dates-container">
          <Button
            className="delete-button"
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeCustomDate(index)}
          />
          <Form.Item
            className="form-item"
            label={t("table_frequency_specialdate")}
          >
            <DatePicker
              required
              value={customDate.date ? dayjs(customDate.date) : null}
              onChange={(date, dateString) =>
                updateCustomDate(index, "date", dateString)
              }
              picker={
                stepOneForm.details.frequency === 1
                  ? "date"
                  : stepOneForm.details.frequency === 2
                  ? "week"
                  : stepOneForm.details.frequency === 3
                  ? "month"
                  : stepOneForm.details.frequency === 4
                  ? "year"
                  : ""
              }
            />
          </Form.Item>

          <Form.Item className="form-item" label={t("table_amount")}>
            <Input
              required
              type="number"
              value={customDate.quantity}
              onChange={(e) =>
                updateCustomDate(index, "quantity", e.target.value)
              }
            />
          </Form.Item>
        </div>
      ))}

      <Button className="add-special-date-button" onClick={addCustomDate}>
        {t("button_add_other_date")}
      </Button>

      {/* <hr />
      <br /> */}
      <div className="other-date-quantity">
      {t("table_frequency_otherdate")} :{" "}
        <span>
          {frequencyType} {otherDateAmount} {unitType}
        </span>
      </div>
    </Form>
  );
};

export default withNamespaces()(TenderFormAddDetailModal);
