import "./styles/bannerCardStyles.css";
import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { GetContextValue } from "../../../context/GetContext";

function BannerCardMedium({
  t,
  bannerData,
  currentIndexMediumBanner,
  setCurrentIndexMediumBanner,
}) {
  
  useEffect(() => {
    if (bannerData) {
      const interval = setInterval(() => {
        if (currentIndexMediumBanner < bannerData.length - 1) {
          setCurrentIndexMediumBanner(currentIndexMediumBanner + 1);
        } else {
          setCurrentIndexMediumBanner(0);
        }
      }, bannerData[currentIndexMediumBanner]?.displayTime * 1000);

      return () => clearInterval(interval);
    }
  }, [bannerData, currentIndexMediumBanner]);

  return (
    <div className="banner-card-container banner-medium">
      {bannerData && bannerData.length > 0 ? (
        <div className="img-container">
          <img
            src={bannerData[currentIndexMediumBanner]?.image}
            alt={bannerData[currentIndexMediumBanner]?.companyName}
          />
        </div>
      ) : (
        <p className="table-null-text">{t("message_no_banner")}</p>
      )}
    </div>
  );
}

export default withNamespaces()(React.memo(BannerCardMedium));
