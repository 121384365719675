import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import Loading from "../../components/Loading/Loading";
import StandartTable from "../../components/Table/StandartTable/StandartTable";
import { AuthContextValue } from "../../context/AuthContext";
import useFetch from "../../hooks/UseFetch";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import { GetContextValue } from "../../context/GetContext";

function CompanyTenders({ t, id, tableRender }) {
  const { token } = AuthContextValue();
  const {
    selectValueId,
    setSelectValueId,
    selectValue,
    setSelectValue,
    notificationRender,
  } = GetContextValue();

  const [companyTendersPage, setCompanyTendersPage] = useState(1);
  const [companyTendersRowsPerPage, setCompanyTendersRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setCompanyTenderssUseFetchRender,
    useFetchRender: companyTenderssUseFetchRender,
    error: companyTendersError,
    loading: companyTendersLoading,
    data: companyTenders,
    total: companyTendersTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_TENDER_LIST_URL}?CompanyId=${id}&Last=false&SortType=1&Page=${companyTendersPage}&PageSize=${companyTendersRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "image",
      header: t("table_photo"),
    },
    // {
    //   key: "status",
    //   header: t("table_status"),
    // },
    {
      key: "productName",
      header: t("table_product"),
    },
    {
      key: "description",
      header: t("table_detail"),
    },
    {
      key: "createdAt",
      header: t("table_create_date"),
    },
    {
      key: "expireDate",
      header: t("table_expire_date"),
    },
    {
      key: "minPrice",
      header: t("table_min_price"),
    },
    {
      key: "maxPrice",
      header: t("table_max_price"),
    },
  ];

  /* sayfaya girdiğimizde contextte var olan veriyi siler */
  useEffect(() => {
    setSelectValue(null);
    setSelectValueId(null);
  }, []);

  function handleClickValue(valueId, value) {
    setSelectValueId(valueId);
    setSelectValue(value);
  }

  useEffect(() => {
    setCompanyTenderssUseFetchRender(!companyTenderssUseFetchRender);
    setSelectValue(null);
    setSelectValueId(null);
  }, [tableRender, notificationRender]);

  return (
    <>
      {companyTendersError ? (
        <ErrorComponent error={companyTendersError} />
      ) : companyTendersLoading ? (
        <Loading />
      ) : companyTenders !== null ? (
        <StandartTable
          type="tender"
          selectValueId={selectValueId}
          selectValue={selectValue}
          handleClickValue={handleClickValue}
          useFetchRender={companyTenderssUseFetchRender}
          setUseFetchRender={setCompanyTenderssUseFetchRender}
          total={companyTendersTotal}
          page={companyTendersPage}
          setPage={setCompanyTendersPage}
          rowsPerPage={companyTendersRowsPerPage}
          setRowsPerPage={setCompanyTendersRowsPerPage}
          columns={columns}
          data={companyTenders}
        />
      ) : (
        <div className="table-null-text">
          {t("message_emty_company_tenders")}
        </div>
      )}
    </>
  );
}

export default withNamespaces()(CompanyTenders);
