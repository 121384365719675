import './buttonDark.css';

function ButtonDark({ icon, text, onClick, disabled, className }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`button-dark-style ${className}`}
    >
      {icon}

      <span>
        {text}
      </span>
    </button>
  )
}

export default ButtonDark
