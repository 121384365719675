
function LoginIcon() {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            
                d="M9.96722 0C4.82833 0 0.58722 3.89667 0.0327759 8.89H7.745V5.55667L13.3006 10.0011L7.745 14.4456V11.1122H0.0338869C0.589442 16.1044 4.82944 20 9.96722 20C15.4817 20 19.9672 15.5144 19.9672 10C19.9672 4.48556 15.4817 0 9.96722 0Z"
            />
        </svg>
    )
}

export default LoginIcon;
