import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

import ButtonDark from "../../components/form_components/Buttons/ButtonDark/ButtonDark";
import InputStandart from "../../components/form_components/Inputs/InputStandart/InputStandart";
import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import { toast } from "react-toastify";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";

function CompanyAdd({ t }) {
  const { createMethod } = CrudContextValue();
  const { token } = AuthContextValue();

  const [inviteSuppLoading, setInviteSuppLoading] = useState(false);

  const [inviteForm, setInviteForm] = useState({
    companyName: "",
    managerEmail: "",
    companyType: 1,
  });

  const [inviteFormErrors, setInviteFormErrors] = useState({
    companyNameError: false,
    managerEmailError: false,
    companyTypeError: false,
  });

  function handleChangeInvite(e) {
    const { name, value } = e.target;

    console.log(e.target);

    if (name === "companyType") {
      setInviteForm({
        ...inviteForm,
        [name]: Number(value),
      });
    } else {
      setInviteForm({
        ...inviteForm,
        [name]: value,
      });
    }

    setInviteFormErrors({
      companyNameError: false,
      managerEmailError: false,
      companyTypeError: false,
    });
  }

  async function handleInviteSupplier() {
    console.log("inviteForm: ", inviteForm);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!inviteForm.companyName) {
      inputErrors.companyNameError = true;
    }

    if (!inviteForm.managerEmail) {
      inputErrors.managerEmailError = true;
    }

    if (!inviteForm.companyType) {
      inputErrors.companyTypeError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setInviteFormErrors(inputErrors);
      alert(t("message_emty_form"));
    } else {
      setInviteSuppLoading(true);

      const postData = {
        companyType: inviteForm.companyType,
        companyName: inviteForm.companyName,
        managerEmail: inviteForm.managerEmail,
      };
      console.log("postData: ",postData);
      const inviteResponse = await createMethod(
        process.env.REACT_APP_POST_ADD_COMPANY_URL,
        JSON.stringify(postData),
        token
      );

      if (
        inviteResponse &&
        !inviteResponse.cathError &&
        !inviteResponse.isError
      ) {
        setInviteForm({
          companyName: "",
          managerEmail: "",
          companyType: 1,
        });
      }

      // toast.success(t("message_invite_supplier_success"), {
      //   autoClose: 3000,
      // });

      setInviteSuppLoading(false);
    }
  }

  return (
    <div className="company-add-container">
      <div className="company-add-edit-box">
        <div className="title-box">
          <i className="fa-solid fa-plus"></i>
          <h4 className="title">{t("button_edit_add_company")}</h4>
        </div>

        <div className="company-edit-button-groups">
          <ButtonDark
            icon={<i className="fa-solid fa-floppy-disk" />}
            text={inviteSuppLoading ? t("loading") : t("button_edit_send")}
            onClick={handleInviteSupplier}
            className="button-dark-active"
          />
        </div>
      </div>

      <div className="company-add-form-container">
        <form onSubmit={handleInviteSupplier}>
          <InputStandart
            required={true}
            errorBorder={inviteFormErrors.companyNameError}
            type={"text"}
            name={"companyName"}
            title={t("support_request_company_name")}
            placeholder={`${t("support_request_company_name")}...`}
            onChange={handleChangeInvite}
            value={inviteForm.companyName}
          />

          <InputStandart
            required={true}
            errorBorder={inviteFormErrors.managerEmailError}
            type={"email"}
            name={"managerEmail"}
            title={t("table_manager_mail")}
            placeholder={`${t("table_manager_mail")}...`}
            onChange={handleChangeInvite}
            value={inviteForm.managerEmail}
          />

          {/* <InputStandart
            required={true}
            errorBorder={inviteFormErrors.companyTypeError}
            type={"tel"}
            name={"companyType"}
            title={t("table_phone_number")}
            placeholder={`${t("table_phone_number")}...`}
            onChange={handleChangeInvite}
            value={inviteForm.companyType}
          /> */}

          <div className="role-select-container">
            <label htmlFor="companyType">* {t("table_company_type")} : </label>
            <select
              required
              name="companyType"
              id="companyType"
              value={inviteForm.companyType}
              onChange={handleChangeInvite}
            >
              <option value={1}>{t("select_options_maincompany")}</option>
              <option value={2}>{t("select_options_supplier")}</option>
              <option value={3}>
                {t("select_options_maincompany_supplier")}
              </option>
            </select>
          </div>
        </form>
      </div>

      {inviteSuppLoading && <LoadingCircular />}
    </div>
  );
}

export default withNamespaces()(CompanyAdd);
