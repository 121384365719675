import "./privacyPolicyModal.css";
import React from "react";
import privacyPdf from "../../../assets/pdf/gizlilik.pdf";

function PrivacyPolicyModal({ isOpen, onClose }) {
  return (
    <div
      onClick={onClose}
      className={`modal-container ${isOpen ? "open" : ""}`}
    >
      <div className="modal">
        <div className="modal-content">
          <span className="close-button" onClick={onClose}>
            &times;
          </span>
          <iframe
            src={privacyPdf}
            title="Privacy Policy"
            className="pdf-iframe"
          />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicyModal;
