import "./styles/supplierAdd/supplierAdd.css";

import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";

import { AuthContextValue } from "../../context/AuthContext";
import { CrudContextValue } from "../../context/CrudContext";
import useFetch from "../../hooks/UseFetch";

import WhiteButton from "../../components/form_components/Buttons/WhiteButton/WhiteButton";
import LabelInputInfo from "../../components/form_components/Inputs/LabelInputInfo/LabelInputInfo";
import BasicTable from "../../components/Table/BasicTable/BasicTable";
import Loading from "../../components/Loading/Loading";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import LoadingCircular from "../../components/LoadingCircular/LoadingCircular";
import { DemoContextValue } from "../../context/DemoContext";

function addressFilter(address) {
  let output = "";

  if (address.city) {
    output = address.city;
  }

  if (address.district) {
    output = output.concat("/", address.district);
  }

  if (address.neighbourhood) {
    output = output.concat("/", address.neighbourhood);
  }

  if (address.street) {
    output = output.concat("/", address.street);
  }

  if (address.floorNumber) {
    output = output.concat("/", address.floorNumber, ". kat");
  }

  if (address.doorNumber) {
    output = output.concat("/", address.doorNumber);
  }

  console.log(output);

  return output;
}

function SupplierAdd({ t }) {
  const { token, loginUserCompanyId } = AuthContextValue();
  const { createMethod } = CrudContextValue();
  const { demoCompanyList, setDemoSuppliers, demoSuppliers } =
    DemoContextValue();
  const [demoCompnies, setDemoCompnies] = useState([]);

  const navigate = useNavigate();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const [addSuppLoading, setAddSuppLoading] = useState(false);
  const [inviteSuppLoading, setInviteSuppLoading] = useState(false);

  const [searchSupllier, setSearchSupplier] = useState("");
  const [selectedCompany, setSelectedCompany] = useState();

  const [inviteForm, setInviteForm] = useState({
    companyName: "",
    email: "",
    phoneNumber: "",
  });

  const [inviteFormErrors, setInviteFormErrors] = useState({
    companyNameError: false,
    emailError: false,
    phoneNumberError: false,
  });

  const [companies, setCompanies] = useState([]);
  /* Firma Tipi 2 = tedarikçi */
  const {
    setUseFetchRender: setSuppliersUseFetchRender,
    useFetchRender: suppliersUseFetchRender,
    error: suppliersError,
    loading: suppliersLoading,
    data: suppliers,
  } = useFetch(
    `${process.env.REACT_APP_GET_ALL_COMPANY_URL}?searchedText=${searchSupllier}&companyType=2&page=1&pageSize=5`,
    token
  );

  /* Firma Tipi 3 = anafirma-tedarikçi */
  const {
    setUseFetchRender: setMainAndSuppliersUseFetchRender,
    useFetchRender: mainAndSuppliersUseFetchRender,
    error: mainAndSuppliersError,
    loading: mainAndSuppliersLoading,
    data: mainAndSuppliers,
  } = useFetch(
    `${process.env.REACT_APP_GET_ALL_COMPANY_URL}?searchedText=${searchSupllier}&companyType=3&page=1&pageSize=5`,
    token
  );

  // `${process.env.REACT_APP_GET_ALL_COMPANY_URL}?searhedText=${searchSupllier}&page=1&pageSize=5`
  useEffect(() => {
    setSuppliersUseFetchRender(!suppliersUseFetchRender);
    setMainAndSuppliersUseFetchRender(!mainAndSuppliersUseFetchRender);
    setSelectedCompany();
  }, [searchSupllier]);

  useEffect(() => {
    let companiesArray = [];
    if (suppliers) {
      companiesArray.push(...suppliers);
    } else if (mainAndSuppliers) {
      companiesArray.push(...mainAndSuppliers);
    }
    setCompanies(companiesArray);
  }, [mainAndSuppliers, suppliers]);

  const [companiesApplyingPage, setCompaniesApplyingPage] = useState(1);
  const [companiesApplyingRowsPerPage, setCompaniesApplyingRowsPerPage] =
    useState(10);

  const {
    setUseFetchRender: setCompaniesApplyingUseFetchRender,
    useFetchRender: companiesApplyingUseFetchRender,
    error: companiesApplyingError,
    loading: companiesApplyingLoading,
    data: companiesApplying,
    total: companiesApplyingTotal,
  } = useFetch(
    `${process.env.REACT_APP_GET_COMPANIES_APPLYING_URL}?id=${loginUserCompanyId}&page=${companiesApplyingPage}&pageSize=${companiesApplyingRowsPerPage}`,
    token
  );

  const columns = [
    {
      key: "status",
      header: t("table_status"),
    },
    {
      key: "invitersCompanyName",
      header: t("table_inviter"),
    },
    {
      key: "invitedCompanyName",
      header: t("table_company_name"),
    },
    {
      key: "phoneNumber",
      header: t("table_phone_number"),
    },
  ];

  function handleChangeInvite(e) {
    const { name, value } = e.target;

    console.log(e.target);

    setInviteForm({
      ...inviteForm,
      [name]: value,
    });

    setInviteFormErrors({
      companyNameError: false,
      emailError: false,
      phoneNumberError: false,
    });
  }

  async function handleAddSupplier() {
    setAddSuppLoading(true);

    const newSupplier = {
      supplierCompanyId: selectedCompany.companyId,
    };

    const responseAddSupp = await createMethod(
      process.env.REACT_APP_POST_ADD_SUPPLIER_URL,
      JSON.stringify(newSupplier),
      token
    );

    if (
      responseAddSupp &&
      !responseAddSupp.catchError &&
      !responseAddSupp.isError
    ) {
      toast.info(t("message_info_add_supplier_success"), {
        autoClose: 3000,
      });

      setTimeout(() => {
        navigate("/suppliers");
      }, 3000);
    }

    setAddSuppLoading(false);
    handleCloseConfirmModal();
  }

  async function handleInviteSupplier() {
    console.log("inviteForm: ", inviteForm);

    const inputErrors = {}; // Hata durumlarını saklamak için boş bir nesne oluşturun

    if (!inviteForm.companyName) {
      inputErrors.companyNameError = true;
    }

    if (!inviteForm.email) {
      inputErrors.emailError = true;
    }

    if (!inviteForm.phoneNumber) {
      inputErrors.phoneNumberError = true;
    }

    if (Object.keys(inputErrors).length > 0) {
      // Eğer hata durumları varsa, setFormInputErrors işlevini çağırın
      setInviteFormErrors(inputErrors);
      alert("Zorunlu alanları doldurunuz!");
    } else {
      setInviteSuppLoading(true);

      try {
        const inviteResponse = await createMethod(
          `${process.env.REACT_APP_POST_INVİTE_SUPPLIER_URL}?CompanyName=${inviteForm.companyName}&ManagerEmail=${inviteForm.email}&PhoneNumber=${inviteForm.phoneNumber}`,
          null,
          token
        );

        if (
          inviteResponse &&
          !inviteResponse.cathError &&
          !inviteResponse.isError
        ) {
          setInviteForm({
            companyName: "",
            email: "",
            phoneNumber: "",
          });
        }

        toast.success(t("message_info_invitemail_send_supplier_success"), {
          autoClose: 3000,
        });
      } catch (error) {
        console.log(error);
      }

      setInviteSuppLoading(false);
    }
  }

  useEffect(() => {
    if (demoCompanyList) {
      const filteredCompanies = demoCompanyList.filter((comp) =>
        comp.companyName.toLowerCase().includes(searchSupllier.toLowerCase())
      );
      setDemoCompnies(filteredCompanies);
    }
  }, [searchSupllier]);

  return (
    <div className="supplier-add-container">
      <div className="supplier-add-box">
        <div className="box-top">
          {addSuppLoading ? (
            <LoadingCircular />
          ) : openConfirmModal ? (
            <ConfirmModal
              cancelButtonText={t("button_edit_cancel")}
              confirmButtonText={t("button_edit_add")}
              text={`${selectedCompany.companyName} ${t(
                "message_confirm_add_supplier"
              )}`}
              handleCloseModal={handleCloseConfirmModal}
              confirmFunc={handleAddSupplier}
            />
          ) : (
            <>
              <div className="box-title">
                <WhiteButton
                  icon={<i className="fa-solid fa-chevron-left"></i>}
                  text={t("button_edit_back")}
                  onClick={() => navigate(-1)}
                />
                <i className="fa-solid fa-plus"></i>
                <h4>{t("add_supplier_title")}</h4>
              </div>
              <div className="box-buttons">
                <WhiteButton
                  icon={<i className="fa-solid fa-floppy-disk"></i>}
                  text={t("button_edit_save")}
                  onClick={handleOpenConfirmModal}
                />
              </div>
            </>
          )}
        </div>
        <div className="box-main add-box-main">
          <div className="add-box-search-container">
            <LabelInputInfo
              type={"text"}
              name={"companyName"}
              placeholder={t("input_company_search")}
              title={t("table_company_name")}
              value={
                selectedCompany ? selectedCompany.companyName : searchSupllier
              }
              onChange={(e) => setSearchSupplier(e.target.value)}
              info={t("message_info_add_supplier")}
              loading={
                suppliersError
                  ? t("error")
                  : suppliersLoading
                  ? t("loading")
                  : null
              }
            />

            {(!suppliersError || !mainAndSuppliersError) &&
            (!suppliersLoading || !mainAndSuppliersLoading) &&
            (suppliers !== null || mainAndSuppliers !== null) &&
            (suppliers?.length > 0 || mainAndSuppliers?.length > 0) &&
            searchSupllier.length > 2 &&
            !selectedCompany ? (
              <ul className="search-data-box">
                {companies.map((supp) => {
                  return (
                    <li
                      key={supp.companyId}
                      onClick={() => setSelectedCompany(supp)}
                    >
                      {supp.companyName}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </div>

          {selectedCompany && (
            <LabelInputInfo
              type={"text"}
              name={"address"}
              title={t("table_address")}
              disabled={true}
              value={addressFilter(selectedCompany?.addresses[0])}
              info={t("message_info_supplier_address_control")}
            />
          )}
        </div>
      </div>

      <div className="supplier-invite-box">
        <div className="box-top">
          <div className="box-title">
            <i className="fa-solid fa-paper-plane"></i>
            <h4>{t("invite_supplier_title")}</h4>
          </div>
          <div className="box-buttons">
            <WhiteButton
              icon={<i className="fa-solid fa-location-arrow"></i>}
              text={inviteSuppLoading ? t("loading") : t("button_invite")}
              onClick={handleInviteSupplier}
            />
          </div>
        </div>
        <div className="box-main invite-box-main">
          <LabelInputInfo
            required={true}
            errorBorder={inviteFormErrors.companyNameError}
            type={"text"}
            name={"companyName"}
            title={t("table_company_name")}
            placeholder={`${t("table_company_name")}...`}
            info={t("message_info_invite_companyname")}
            onChange={handleChangeInvite}
            value={inviteForm.companyName}
          />

          <LabelInputInfo
            required={true}
            errorBorder={inviteFormErrors.emailError}
            type={"email"}
            name={"email"}
            title={t("table_email")}
            placeholder={`${t("table_email")}...`}
            info={t("message_info_invite_send_email")}
            onChange={handleChangeInvite}
            value={inviteForm.email}
          />

          <LabelInputInfo
            required={true}
            errorBorder={inviteFormErrors.phoneNumberError}
            type={"tel"}
            name={"phoneNumber"}
            title={t("table_phone_number")}
            placeholder={`${t("table_phone_number")}...`}
            info={t("message_info_invite_company_phone")}
            onChange={handleChangeInvite}
            value={inviteForm.phoneNumber}
          />
        </div>
      </div>

      <div className="supplier-invitations-box">
        <div className="box-top">
          <div className="box-title">
            <i className="fa-solid fa-envelope-open-text"></i>
            <h4>{t("invitations_title")}</h4>
          </div>
        </div>
        <div className="invitations-box-main">
          {companiesApplyingError ? (
            <ErrorComponent error={companiesApplyingError} />
          ) : companiesApplyingLoading ? (
            <Loading />
          ) : companiesApplying !== null && companiesApplying.length > 0 ? (
            <BasicTable
              total={companiesApplyingTotal}
              page={companiesApplyingPage}
              setPage={setCompaniesApplyingPage}
              rowsPerPage={companiesApplyingRowsPerPage}
              setRowsPerPage={setCompaniesApplyingRowsPerPage}
              columns={columns}
              data={companiesApplying}
            />
          ) : (
            <small className="table-null-text">
              {t("message_no_invite_company")}
            </small>
          )}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(SupplierAdd);
