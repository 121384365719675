import "./styles/videosStyle/videosStyle.css";
import { useEffect, useState } from "react";

import { withNamespaces } from "react-i18next";


import {
  offerExTutorials,
  companyProfilePageTutorials,
  offersPageTutorials,
  opportunitiesPageTutorials,
  suppliersPageTutorials,
  tendersPageTutorials,
  usersPageTutorials,
} from "../../assets/videos/index";
import TutorialCard from "../../components/TutorialCard/TutorialCard";
import VideoDetailModal from "../../components/Modals/VideoDetailModal/VideoDetailModal";
import { useLocation } from "react-router-dom";

function VideosPage({t, searchBar }) {
  const { pathname } = useLocation();

  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [videoIndex, setVideoIndex] = useState(0);

  const [openVideoDetailModal, setOpenVideoDetailModal] = useState(false);
  const handleOpenVideoDetailModal = () => setOpenVideoDetailModal(true);
  const handleCloseVideoDetailModal = () => setOpenVideoDetailModal(false);

  function handleClikVideo(index) {
    setVideoIndex(index);
    handleOpenVideoDetailModal();
  }

  function handleNextVideo() {
    if (videoIndex < filteredVideos.length - 1) {
      setVideoIndex(videoIndex + 1);
    } else {
      setVideoIndex(0);
    }
  }
  function handlePrevVideo() {
    if (videoIndex > 0) {
      setVideoIndex(videoIndex - 1);
    } else {
      setVideoIndex(filteredVideos.length - 1);
    }
  }

  useEffect(() => {
    const route = pathname.split("/")[1];
    console.log(route);
    if (
      offerExTutorials &&
      companyProfilePageTutorials &&
      offersPageTutorials &&
      opportunitiesPageTutorials &&
      suppliersPageTutorials &&
      tendersPageTutorials &&
      usersPageTutorials
    ) {
      switch (route) {
        case "how-it-work":
          setVideos(offerExTutorials);
          break;
        case "company-list":
          setVideos(companyProfilePageTutorials);
          break;
        case "company-profile-edit":
          setVideos(companyProfilePageTutorials);
          break;
        case "offers":
          setVideos(offersPageTutorials);
          break;
        case "opportunities":
          setVideos(opportunitiesPageTutorials);
          break;
        case "suppliers":
          setVideos(suppliersPageTutorials);
          break;
        case "tenders":
          setVideos(tendersPageTutorials);
          break;
        case "users":
          setVideos(usersPageTutorials);
          break;

        default:
          setVideos(offerExTutorials);
          break;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (videos) {
      setFilteredVideos(videos);
    }
  }, [videos]);

  useEffect(() => {
    if (videos) {
      const searchFilterVideos = videos.filter((video) =>
        video.info.toLowerCase().includes(searchBar.toLowerCase())
      );
      setFilteredVideos(searchFilterVideos);
    }
  }, [searchBar]);

  return (
    <div className="videos-page-container">
      {filteredVideos && filteredVideos.length > 0 ? (
        filteredVideos.map((videoItem, i) => {
          return (
            <TutorialCard
              key={videoItem.id}
              video={videoItem.videoUrl}
              videoImage={videoItem.videoImage}
              info={videoItem.info}
              index={i}
              onClick={handleClikVideo}
            />
          );
        })
      ) : (
        <p className="table-null-text">{t("message_no_video")}</p>
      )}
      {openVideoDetailModal && (
        <VideoDetailModal
          status={"iframe"}
          data={filteredVideos[videoIndex]}
          handleCloseModal={handleCloseVideoDetailModal}
          nextBtn={handleNextVideo}
          prevBtn={handlePrevVideo}
        />
      )}
    </div>
  );
}

export default withNamespaces()(VideosPage);
