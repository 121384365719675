import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./styles/index/index.css";

import AuthContextProvider from "./context/AuthContext";
import CrudContextProvider from "./context/CrudContext";
import GetContextProvider from "./context/GetContext";
import ThemeContextProvider from "./context/ThemeContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

import "./i18n/i18n";
import NotificationCard from "./components/NotificationCard/NotificationCard";
import DemoContextProvider from "./context/DemoContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <ThemeContextProvider>
        <AuthContextProvider>

          <DemoContextProvider>
          
            <GetContextProvider>
              <CrudContextProvider>
                <App />
                <ToastContainer position="bottom-right" newestOnTop />
                <NotificationCard />
              </CrudContextProvider>
            </GetContextProvider>

          </DemoContextProvider>

        </AuthContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  // </React.StrictMode> 
);
