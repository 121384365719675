import { Outlet } from "react-router-dom";

function AllOffersLayout() {
  return (
    <>
      {/* Tablolar buraya gelicek */}
      <Outlet />
    </>
  );
}

export default AllOffersLayout;
